<template>
  <form
    v-tc-loader-pill="loading"
    class="tc-loader-pill-wrapper"
    @submit.prevent="save"
  >
    <fieldset class="container container-narrow">
      <div class="form-group">
        <label
          for="name"
        >{{ $gettext('Namn:') }}</label>
        <input
          id="name"
          v-model="fields.name"
          class="form-control"
          type="text"
        >
      </div>
      <div class="form-group">
        <small>{{ $gettext('Logotyp:') }}</small>
        <figure
          ref="logoFigure"
          class="group-box text-center"
        >
          <img
            v-if="fields.logo_image"
            :src="fields.logo_image"
            :alt="`${fields.name} logo`"
          >
          <small
            v-else
            class="subtle-text"
          >{{ $gettext('Logotyp saknas') }}</small>
        </figure>
        <label
          for="logo_image"
        >{{ $gettext('Ändra/lägg till logotyp:') }}</label>
        <div class="custom-file">
          <span
            ref="logoImageFileControl"
            class="custom-file-control"
            :data-placeholder="fileControlPlaceholder"
            :data-btn-text="fileControlButtonText"
          />
          <input
            id="logo_image"
            ref="logoImageFileInput"
            class="form-control-file custom-file-input"
            type="file"
            name="logo_image"
            @change="setPlaceholderText"
          >
        </div>
      </div>
      <div class="form-group buttons mt-4">
        <button
          type="submit"
          class="btn"
        >
          <i class="zmdi zmdi-save mr-1" />
          <span>{{ $gettext('Spara') }}</span>
        </button>
      </div>
    </fieldset>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { pickBy, identity } from 'lodash-es';
import customerApi from 'API/customer';

export default {
  name: 'CustomerSettingsAccount',
  data() {
    return {
      fields: {
        name: null,
        logo_image: null,
      },
      initialLogo: null,
      id: null,
      loading: false,
      fileControlPlaceholder: this.$gettext('Välj fil...'),
      fileControlButtonText: this.$gettext('Bläddra'),
    };
  },
  computed: {
    ...mapGetters([
      'customerId',
      'segmentId',
    ]),
  },
  mounted() {
    this.$nextTick(this.load);
  },
  methods: {
    ...mapActions([
      'notify',
      'fetchSegment',
    ]),
    save(e) {
      this.loading = true;
      let logoData = new FormData();
      let files = this.$refs.logoImageFileInput.files;
      let promises = [];

      Object.keys(files).forEach((key) => {
        if (files[key]) {
          logoData.append(this.$refs.logoImageFileInput.name, files[key]);
        }
      });

      if (files.length > 0) {
        promises.push(customerApi.updateLogo(this.id, logoData)
          .then((response) => {
            this.fields.logo_image = '';
            this.$nextTick(() => {
              this.fields.logo_image = response.body.logo_image;
            });
            this.$refs.logoImageFileInput.value = '';
            this.notify({
              text: this.$gettext('Logotyp uppdaterad'),
              level: 'success',
              type: 'pop',
            });
          }, (error) => this.notify({
            text: this.$gettext('Kunde inte spara logo'),
            level: 'warning',
          })));
      }

      const filledInFields = pickBy(this.fields, identity);
      promises.push(customerApi.update(this.id, filledInFields)
        .then((response) => {
          this.fetchSegment(this.segmentId); // Resets and gets the segment again
          this.notify({
            text: this.$gettext('Information uppdaterad'),
            level: 'success',
            type: 'pop',
          });
        }, (error) => this.notify({
          text: this.$gettext('Kunde inte spara'),
          level: 'warning',
        })));

      Promise.all(promises)
        .then(() => {
          this.loading = false;
        });
    },
    load() {
      this.loading = true;
      customerApi.get(this.customerId)
        .then((response) => {
          this.id = response.id;
          this.initialLogo = response.logo_image;
          // All savable data
          Object.keys(this.fields).forEach((key) => {
            this.fields[key] = response[key];
          });
        }, (error) => this.notify({
          text: this.$gettext('Kunde inte hämta data'),
          level: 'warning',
        }))
        .then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 0);
        });
    },
    setPlaceholderText() {
      const file = this.$refs.logoImageFileInput.files[0];
      this.$refs.logoImageFileControl.dataset.placeholder = file.name;
    },
  },
};
</script>
