export default {
  state: {
    hiddenParams: {},
  },
  mutations: {
    SET_HIDDEN_PARAMS(state, hiddenParams) {
      state.hiddenParams = { ...state.hiddenParams, ...hiddenParams };
    },
  },
  actions: {
    setHiddenParams({ commit }, hiddenParams = {}) {
      if (hiddenParams?.segmentId === null) return; // never set segmentId to null
      commit('SET_HIDDEN_PARAMS', hiddenParams);
    },
  },
};
