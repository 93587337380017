<template>
  <tr
    v-for="subRow in row.children.paginator?.stack"
    v-show="isRowToggled"
    :key="subRow"
    class="tc-table-sub-row"
    :class="{'toggled': isRowToggled}"
  >
    <td
      v-for="(subVal, subColIndex) in subRow"
      :key="subVal"
      v-tooltip="{
        content: typeof headers[subColIndex]?.tooltipFn === 'function' && subVal != null && subVal !== NO_VALUE
          ? headers[subColIndex].tooltipFn(subRow?.[subColIndex])
          : null,
        placement: 'top-start',
        container: 'body',
        distance: 8,
        arrowPadding: 32,
      }"
      :class="{ 'loading': row.children.paginator?.loading }"
    >
      <span
        v-if="headers[subColIndex].label.length"
        class="tc-table-cell-label"
      >{{ headers[subColIndex].label }}</span>
      <span
        class="tc-table-cell-value"
        :class="{
          'loading-item size-inherit loading-item-on-dark': row.children.paginator?.loading
            && headers[subColIndex].label === '',
          'tc-loading-text size-inherit tc-loading-text-on-dark': row.children.paginator?.loading
            && headers[subColIndex].label !== $gettext('Status')
            && headers[subColIndex].label !== '',
          'tc-loading-badge': row.children.paginator?.loading
            && headers[subColIndex].label === $gettext('Status')
            && headers[subColIndex].label !== '',
          'size-xxsmall': subVal === null || subVal != null && String(subVal)?.length < 6,
          'strong': subColIndex === 0,
        }"
      >
        <slot
          :name="'column-sub-' + subColIndex"
          :value="subVal"
        >
          {{ subVal }}
        </slot>
      </span>
    </td>
  </tr>
  <tr
    v-show="isRowToggled"
    v-if="row.children.paginator?.hasNext?.() || row.children.paginator?.hasPrevious?.()"
    class="tc-table-sub-row tc-table-sub-row-pagination"
  >
    <td :colspan="headers.length">
      <PaginationNav
        :key="row.slug"
        :page-size="row.children.pageSize"
        :total-items="row.children.paginator.count"
        :disabled="false"
        :current-page="page?.[row.slug] ?? 1"
        @update:current-page="handlePageChange(row, $event)"
      />
    </td>
  </tr>
</template>

<script setup>
import { ref } from 'vue';
import PaginationNav from 'Components/parts/pagination/PaginationNav';

const props = defineProps({
  row: Object,
  headers: Array,
  isRowToggled: {
    type: Boolean,
    default: false,
  },
});

const NO_VALUE = '–';

const page = ref({});
const getSubRowPaginatedData = (row, _page) => {
  const { children, slug } = row;
  const currentPage = page.value[slug] ?? 1;
  const isSamePage = _page === currentPage;
  const hasNextOrPrev = children.paginator?.hasNext?.() || children.paginator?.hasPrevious?.();

  if (!row || isSamePage || !hasNextOrPrev) return;
  if (_page === currentPage - 1) children.paginator.previous();
  else if (_page === currentPage + 1) children.paginator.next();
  else children.paginator.goToPage(_page);
};
const handlePageChange = (row, _page) => {
  const { slug } = row;
  if (!page.value?.[slug]) page.value[slug] = 1;
  getSubRowPaginatedData(row, _page);
  page.value[slug] = _page;
};
</script>
