import { baseRequest } from 'Utils/api';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext } = gettext;

function handleErrors(error) {
  store.dispatch('notify', {
    text: $gettext('Kunde inte hämta fler resultat: %{msg}', { msg: error.message }),
    level: 'error',
    type: 'pop',
  });
}

/**
 ** RequestPaginator
 *- Newer paginator implementation that can be used inside the classes:
 *  Populator, FilterPaginator & SaturatedPaginator.
 *- Same implementation as MockPaginator class but with real requests instead
 *
 * @param {Object} response
 *- Example of returned Paginated response = {
 *    results: [],
 *    next: null,
 *    previous: null,
 *  }
 * @param {Function} wrapResultsFn, function for remapping results
 *
 * @prop {Array} stack, current page results
 * @prop {Boolean} loading, load state
 *
 * @method {Boolean} hasNext()
 * @method {Boolean} hasPrevious()
 * @method {Promise} async next()
 * @method {Promise} async previous()
 */
export default class RequestPaginator {
  constructor(response, wrapResultsFn = (resp) => resp) {
    this._wrapResults = wrapResultsFn;
    this._dataset = this._wrapResults(response);
    this.stack = this._dataset.results;
    this.loading = false;
  }

  hasNext() {
    return this._dataset.next !== null;
  }

  hasPrevious() {
    return this._dataset.previous !== null;
  }

  async next() {
    if (this.loading || !this.hasNext()) return;
    await this._appendFromURL(this._dataset.next);
  }

  async previous() {
    if (this.loading || !this.hasPrevious()) return;
    await this._appendFromURL(this._dataset.previous);
  }

  async goToPage(page) {
    if (this.loading || !this.hasPrevious() && !this.hasNext()) return;
    const url = new URL(this._dataset.next) || new URL(this._dataset.previous);
    if (!url) return;
    url.searchParams.set('page', page);
    await this._appendFromURL(url.href);
  }

  async _appendFromURL(url) {
    this.loading = true; // TODO: Make sure this prop works as expected
    try {
      this._dataset = await baseRequest((request) => request.get(url)).then(this._wrapResults);
      this.stack = [...this._dataset.results];
    } catch (err) {
      handleErrors(err);
    } finally {
      this.loading = false;
    }
  }
}
