import { deburr } from 'lodash-es';
import { striphtml, markHits } from 'Utils/general';

/**
 ** wrapPropsIntoLabelValues
 *  converts from object with key: Value into array with `label` & `value` in object entries.
 *
 *  @param {Object} props, keys are set to `value`-prop. values are set to `label`-prop
 *  Ex.
 *  { ja: 'ss', nej: 'yy' }
 *
 *  @returns {Array} labelValueArray
 *  Ex.
 *  [ { value: 'ja', label: 'ss' }, { value: 'nej', label: 'yy'} ]
 */
export function wrapPropsIntoLabelValues(props) {
  if (Array.isArray(props)) return false;
  return Object.keys(props).reduce((arr, val) => {
    arr.push({
      value: val,
      label: props[val],
    });
    return arr;
  }, []);
}

/**
 ** wrapValue - use inside .map
 *  converts from value into object with `label` & `value` keys
 *
 *  @param {String, Number} value, value is set to `value`-prop & `label-prop.
 *  @example
 *  'ja'
 *
 *  @returns {Object} labelValueObject
 *  @example
 *  { value: 'ja', label: 'ja' }
 */
export function wrapValue(value) {
  if (!value) return value;
  if (value && value.label !== undefined) return value;
  return { value, label: value.toString() };
}

/**
 ** wrapValues - use instead of .map
 *  converts from array with values into object with `label` & `value` keys
 *
 *  @param {Array} values, values are set to `value`-prop & `label-prop.
 *  @example
 *  ['ja', 'nej']
 *
 *  @returns {Array} labelValueArray
 *  @example
 *  [ { value: 'ja', label: 'ja' }, { value: 'nej', label: 'nej'} ]
 */
export function wrapValues(values) {
  if (!Array.isArray(values)) return values;
  return values.map((value) => wrapValue(value));
}

// Detect hits of term in item.label
export function labelHit(term) {
  return (item) => {
    if (typeof term !== 'string') return false;
    if (typeof item.label !== 'string') return false;
    return striphtml(item.label).toLowerCase().indexOf(term.toLowerCase()) !== -1;
  };
}

// <mark></mark> hits in item.label
export function markLabel(term) {
  return (item) => {
    if (typeof term !== 'string' || term.length === 0) return item;
    const normalizedTerm = deburr(term.trim().toLowerCase());
    const normalizedLabel = deburr(striphtml(item.label).toLowerCase());
    if (normalizedLabel.indexOf(normalizedTerm) !== -1) {
      return {
        ...item,
        marked: markHits(
          item.label,
          [normalizedLabel.indexOf(normalizedTerm)],
          normalizedTerm.length,
        ),
      };
    }
    return item;
  };
}
