<template>
  <li
    v-if="item.value"
    class="badge badge-filter"
    :class="{'badge-overlapping': item.isCardFilter }"
  >
    <!-- eslint-disable-next-line max-len -->
    <span class="badge-compare-key">
      <span class="badge-compare--label">{{ item.label }}</span><span
        class="badge-compare--with"
        v-text="` ${$pgettext('Badge — Compare with', 'Med')}`"
      />
    </span>
    <span class="badge-compare-value">{{ value }}</span>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'BadgeListItemCompare',
  props: {
    item: Object,
  },
  computed: {
    ...mapGetters(['segmentTagKeyVal']),
    value() {
      if (this.item.slug === 'compare__tag') {
        if (!this.segmentTagKeyVal?.[this.item.value]) this.segmentTagKeyTranslation(this.item.value);
        return this.segmentTagKeyVal?.[this.item.value] || this.item.value;
      }

      return this.item.value;
    },
  //   badgeValuesTooltip() { return `<strong>Compare – ${this.item.label}</strong>: ${this.item.value}`; },
  },
  methods: {
    ...mapActions(['segmentTagKeyTranslation']),
  },
};
</script>
