<template>
  <svg
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="'0 0 ' + SIZE + ' ' + SIZE"
    :width="size"
    :height="size"
  >
    <title v-if="hasTitle">{{ name }}</title>
    <template v-if="includeMask">
      <mask
        id="mask__avatar_beam_circle"
        maskUnits="userSpaceOnUse"
        :x="0"
        :y="0"
        :width="SIZE"
        :height="SIZE"
      >
        <rect
          fill="#FFFFFF"
          :width="SIZE"
          :height="SIZE"
          :rx="SIZE * 2"
        />
      </mask>
      <mask
        id="mask__avatar_beam_square"
        maskUnits="userSpaceOnUse"
        :x="0"
        :y="0"
        :width="SIZE"
        :height="SIZE"
      >
        <rect
          fill="#FFFFFF"
          :width="SIZE"
          :height="SIZE"
        />
      </mask>
      <mask
        id="mask__avatar_beam_squircle"
        maskUnits="userSpaceOnUse"
        :x="0"
        :y="0"
        :width="SIZE"
        :height="SIZE"
      >
        <path
          fill="#FFF"
          d="M13.5 36C4.5 36 0 31.5 0 22.5V13.5C0 4.5 4.5 0 13.5 0H22.5C31.5 0 36 4.5 36 13.5V22.5C36 31.5 31.5 36 22.5 36Z"
          :width="SIZE"
          :height="SIZE"
        />
      </mask>
    </template>
    <g
      v-if="includeAvatar"
      :mask="mask"
    >
      <rect
        :width="SIZE"
        :height="SIZE"
        :fill="lessRandomColors ? data(name, colors).lessRandom.backgroundColor : data(name, colors).backgroundColor"
      />
      <rect
        x="0"
        y="0"
        :width="SIZE"
        :height="SIZE"
        :transform="getRectTransform()"
        :fill="lessRandomColors ? data(name, colors).lessRandom.wrapperColor : data(name, colors).wrapperColor"
        :rx="data(name, colors).isCircle ? SIZE : SIZE / 6"
      />
      <g :style="getGroupTransformCSS()">
        <path
          v-if="
            (chosenMouth === 'frown' || chosenMouth === 'smile')
              || (chosenMouth !== 'joy' && data(name, colors).isMouthOpen)
          "
          fill="none"
          strokeLinecap="round"
          :d="getClosedMouthData()"
          :stroke="data(name, colors).faceColor"
          stroke-width="1.5"
          :style="`transform: ${chosenMouth === 'frown' ? 'rotateX(180deg) translate(0, -6px)' : ''}`"
          transform-origin="center"
        />
        <path
          v-else
          :d="getOpenMouthData()"
          :fill="data(name, colors).faceColor"
        />

        <rect
          stroke="none"
          :x="14 - data(name, colors).eyeSpread"
          :y="13"
          :width="2"
          :height="3"
          :rx="1"
          :fill="data(name, colors).faceColor"
        />
        <rect
          stroke="none"
          :x="20 + data(name, colors).eyeSpread"
          :y="13"
          :width="2"
          :height="3"
          :rx="1"
          :fill="data(name, colors).faceColor"
        />
      </g>
    </g>
  </svg>
</template>

<script setup>
/* Stolen and modified from Boring Avatars & https://github.com/cmgriffing/boringer-avatars/blob/main/packages/lib-vue3/src/avatars/avatar-beam.vue */
import { computed } from 'vue';
import { generateData as data, SIZE } from './AvatarHelpers';

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: 'Avatar',
  },
  colors: {
    type: Array,
    default: () => ['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90'],
  },
  size: {
    type: Number,
    default: 36,
  },
  hasTitle: {
    type: Boolean,
    default: false,
  },
  mask: {
    type: String,
    default: 'squircle',
    validator: (val) => ['circle', 'square', 'squircle'].includes(val),
  },
  includeMask: { // ? Only need to includeMask once per page
    type: Boolean,
    default: true,
  },
  includeAvatar: {
    type: Boolean,
    default: true,
  },
  lessRandomColors: {
    type: Boolean,
    default: false,
  },
  chosenMouth: {
    type: String,
    default: null, // ? null = random
    validator: (val) => val === null || ['smile', 'joy', 'frown'].includes(val),
  },
});

const mask = computed(() => `url(#mask__avatar_beam_${props.mask})`);

function getRectTransform() {
  return (
    `translate(${
      data(props.name, props.colors).wrapperTranslateX
    } ${
      data(props.name, props.colors).wrapperTranslateY
    }) rotate(${
      data(props.name, props.colors).wrapperRotate
    } ${
      SIZE / 2
    } ${
      SIZE / 2
    }) scale(${
      data(props.name, props.colors).wrapperScale
    })`
  );
}

function getGroupTransformCSS() {
  return (
    `transform: translate(${
      data(props.name, props.colors).faceTranslateX
    }px, ${
      data(props.name, props.colors).faceTranslateY
    }px) rotate(${
      data(props.name, props.colors).faceRotate
    }deg) scale(${
      1.05
    });`
  );
}

function getClosedMouthData() {
  return (
    `M15 ${
      19 + data(props.name, props.colors).mouthSpread
    }c2.4 1.5 4.8 1.5 7.2 0`
  );
}

function getOpenMouthData() {
  return (
    `M13,${
      19 + data(props.name, props.colors).mouthSpread
    } a1,0.75 0 0,0 10,0`
  );
}
</script>
