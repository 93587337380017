<template>
  <button
    ref="chip"
    class="chip"
    :class="classes"
    data-testid="chipBtn"
    type="button"
    @click.stop="clickHandler"
    @keyup.delete.stop="remove"
  >
    <i
      v-show="preIcon"
      class="chip-icon"
      :class="iconClasses"
    /><span class="chip-label"><slot /></span><i
      v-if="removable"
      class="chip-remove-icon zmdi zmdi-close-circle cursor-pointer"
      :title="$gettext('Ta bort')"
      @click.stop="remove"
    />
  </button>
</template>

<script>
/*
 ** Supported chip-types:
 * action — Clickable buttons, default
 * input — Draggable and removable chips inside inputs
 * filter — Selectable chips for filtering, multiple choice. Uses ChipSet
 * choices — Checkbox button like chips for filtering, multi choice. Uses ChipSet
 * choice — Radio button like chips for filtering, single choice. Uses ChipSet
 *
 ** NOT Supported chip-types:
 * dropdown — Dropdownable chip for filtering, single choice. Use ChipDropdown.vue instead
*/
export default {
  name: 'Chip',
  props: {
    icon: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    default: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'removed'],
  data() {
    return {
      chipType: '',
      selected: false,
    };
  },
  computed: {
    preIcon() {
      return this.icon !== null
        || this.is('choice')
        || this.is('choices')
        || this.is('filter') && this.selected;
    },
    classes() {
      return {
        selected: this.selected,
        'chip-pad-right': this.removable,
        [`chip-${this.chipType}`]: true,
      };
    },
    iconClasses() {
      return {
        zmdi: true,
        'zmdi-hc-lg': !this.small,
        'zmdi-check': this.is('filter') && this.selected,
        'zmdi-square-o': this.is('choices') && !this.selected,
        'zmdi-check-square': this.is('choices') && this.selected,
        'zmdi-circle-o': this.is('choice') && !this.selected,
        'zmdi-dot-circle': this.is('choice') && this.selected,
        [this.icon]: this.icon !== null && !this.selected,
      };
    },
    removable() {
      return this.chipType === 'input';
    },
    small() {
      const fake = document.createElement('div');
      return (this.$parent || {}).small
        || ((this.$parent?.$el) || fake)?.classList.contains('chip-set-sm')
        || false;
    },
  },
  watch: {
    type(type) {
      this.init(type);
    },
    default(bool) { // ? Could be used as v-model alternative for single chip
      this.selected = bool;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init(this.type);
    });
  },
  methods: {
    is(checkType) {
      let type = Array.isArray(checkType)
        ? checkType : [checkType];
      return type.indexOf(this.chipType) > -1;
    },
    toggleSelected(selected) {
      this.selected = selected;
    },
    clickHandler(event) {
      if (this.chipType !== 'choice' && this.chipType !== 'action') this.toggleSelected(!this.selected);
      this.$emit('click', { value: this.$refs?.chip._value, selected: this.selected }); // Could use this.$el.key somehow perhaps
    },
    remove(event) {
      if (!this.$el.disabled) this.$emit('removed');
    },
    unselect(items) {
      if (items?.length) {
        items.forEach((element) => {
          element.selected = false;
        });
      }
    },
    init(type = null) {
      this.chipType = type || this.$parent?.$options?.name === 'ChipSet' && this.$parent.type || 'action';
      this.selected = this.default;
    },
  },
};
</script>
