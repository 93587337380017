let directivePrefix = 'tc-loader';

function tcLoader(suffix = 'spinner') {
  return {
    beforeMount(el, binding) {
      const loaderEl = document.createElement('div');
      loaderEl.classList.add(`${directivePrefix}-${suffix}`);
      el.loaderEl = loaderEl;

      if (suffix === 'bar' && binding.value) {
        el.appendChild(el.loaderEl);
        setTimeout(() => {
          el.loaderEl.classList.add('active');
        }, 0);
      }
    },
    updated(el, binding) {
      // value change
      if (binding.value !== binding.oldValue) {
        // is loading
        if (binding.value) {
          el.appendChild(el.loaderEl);
          setTimeout(() => {
            el.loaderEl.classList.add('active');
          }, 0);
        } else {
          // finished loading
          el.loaderEl.classList.add('finish');
          setTimeout(() => {
            el.loaderEl.classList.remove('finish');
            el.loaderEl.classList.remove('active');
            if (el.loaderEl.parentNode === el) {
              el.removeChild(el.loaderEl);
            }
          }, 250);
        }
      }
    },
    beforeUnmount(el) {
      if (el?.loaderEl?.parentNode === el) {
        el.removeChild(el.loaderEl);
      }
    },
  };
}

export default tcLoader;
