/**
 * For caching of boards requests
 * updates will remove cache and get boards again
 * components need to watch getters.boards
 */
import { isArray } from 'lodash-es';
import { captureMessage } from '@/plugins/sentry';
import boardsAPI, { createBoard } from 'API/boards';
import { markLabel, labelHit } from 'Utils/tcselectHelpers';
import { confirmLeaveUnsavedChanges } from 'Modal/dialogBoxes';
import gettext from '@/gettext';

const { $gettext } = gettext;

const boardOption = (board) => ({ value: board.id, label: board.name });

const createLocalPagination = (items, count, pageSize) => {
  if (!items) return [];
  if (!count) return [items];
  const pages = Math.ceil(count / pageSize);
  return Array.from({ length: pages }, (_, i) => items.slice(i * pageSize, (i + 1) * pageSize));
};

export default {
  state: {
    boards: {}, // { segmentId: { boardId: board } } | a shallow copy of board, not containing sections & cards currently, they're found in their own store
    boardsNextPage: null,
    boardsPreviousPage: null,
    fetchingBoards: false,
    isEditMode: false,
    isOrganizing: false,
    hintAboutEditMode: false,
    amountOfBoards: null,
    boardsPageSize: 50,
  },
  mutations: {
    SET_BOARD(state, { segmentId, board }) {
      if (!board.id) return captureMessage(new Error('Couldn’t SET_BOARD without board.id / Or not found in state.boards', board, segmentId, state.boards));
      const boardIndex = (board?.id && state.boards[segmentId]?.findIndex((storeBoard) => storeBoard.id === board.id))
        ?? -1;
      if (boardIndex > -1) state.boards[segmentId][boardIndex] = board;
      else if (isArray(state.boards[segmentId])) state.boards[segmentId].push(board);
      else state.boards[segmentId] = [board];
      return board;
    },
    SET_BOARDS(state, { segmentId, boards }) {
      // this is not replacing the boards, but adding to them, befor it was replacing them
      if (state.boards[segmentId]) {
        const newBoards = boards.filter((board) => !state.boards[segmentId].find((b) => b.id === board.id));
        state.boards[segmentId].push(...newBoards);
        state.boards[segmentId].sort((a, b) => a.name.localeCompare(b.name));
      } else state.boards[segmentId] = boards;
    },
    CLEAR_BOARDS(state, segmentId) {
      if (typeof segmentId === 'number') {
        state.boards[segmentId] = [];
      }
    },
    SET_FETCH_BOARD_STATE(state, fetchState) {
      state.fetchingBoards = fetchState;
    },
    SET_BOARD_IS_EDITMODE(state, isEditMode) {
      state.isEditMode = isEditMode;
    },
    SET_BOARD_IS_ORGANIZING(state, isOrganizing) {
      state.isOrganizing = isOrganizing;
    },
    SET_HINT_ABOUT_EDITMODE(state, hint) {
      state.hintAboutEditMode = hint;
    },
    SET_BOARDS_NEXT_PAGE(state, nextPage) {
      state.boardsNextPage = nextPage;
    },
    SET_BOARDS_PREVIOUS_PAGE(state, previousPage) {
      state.boardsPreviousPage = previousPage;
    },
    SET_BOARDS_AMOUNT(state, amount) {
      state.amountOfBoards = amount;
    },
  },
  getters: {
    boardsPagination: (state, getters) => createLocalPagination(getters.boards, state.amountOfBoards, state.boardsPageSize),
    boardsPageSize: (state) => state.boardsPageSize,
    amountOfBoards: (state) => state.amountOfBoards,
    fetchingBoards: (state) => state.fetchingBoards,
    boards: (state, getters) => state.boards[getters.segmentId],
    boardsNextPage: (state) => state.boardsNextPage,
    boardsPreviousPage: (state) => state.boardsPreviousPage,
    getBoardById: (state, getters) => (id) => state?.boards?.[getters?.segmentId]?.find((b) => b?.id === id),
    getBoardsBySegmentId: (state) => (segmentId) => state.boards[segmentId],
    boardOptions: (state, getters) => (term) => Promise.resolve(getters.boards
      .map(boardOption).filter(labelHit(term)).map(markLabel(term))),
    boardOptionsPlusAdd: (state, getters) => (term) => Promise.resolve(getters.boards
      .filter((board) => (getters.isCustomerUserInCurrentSegment ? true : board.owner.id === getters.me.id))
      .map(boardOption)
      .filter(labelHit(term)).map(markLabel(term))
      .concat([{
        value: 'newBoard',
        label: $gettext(
          '<i class="zmdi zmdi-plus pr-1"></i>%{name}',
          { name: $gettext('Ny rapport') },
        ),
      }])),
    isEditMode: (state) => state.isEditMode,
  },
  actions: {
    async setBoardIsEditMode({ commit, getters }, isEditMode) {
      const leavingEditMode = !isEditMode;

      if (leavingEditMode && getters.hasUnsavedChanges) {
        const confirmed = await confirmLeaveUnsavedChanges();
        if (!confirmed) return;
      }

      commit('SET_BOARD_IS_EDITMODE', isEditMode);
    },
    setBoardIsOrganizing({ commit }, isOrganizing) {
      commit('SET_BOARD_IS_ORGANIZING', isOrganizing);
    },
    setHintAboutEditMode({ commit }, hint) {
      commit('SET_HINT_ABOUT_EDITMODE', hint);

      if (hint) {
        setTimeout(() => {
          commit('SET_HINT_ABOUT_EDITMODE', false);
        }, 3000);
      }
    },
    setBoard({ getters, commit }, { board, segmentId = getters.segmentId }) {
      let boardToSet = board;
      if (typeof board.updateMetadata !== 'function') {
        boardToSet = createBoard(board);
        delete boardToSet.sections;
      }
      if (boardToSet && boardToSet?.id) commit('SET_BOARD', { segmentId, board: boardToSet });
    },
    setBoards({ getters, commit }, { boards, segmentId = getters.segmentId }) {
      let boardsToSet = boards;
      if (boardsToSet && boardsToSet.length) {
        boardsToSet = boardsToSet.map((board) => {
          if (typeof board.updateMetadata !== 'function') {
            const boardToSet = createBoard(board);
            delete boardToSet.sections;
            return boardToSet;
          }
          return board;
        });
        commit('SET_BOARDS', { segmentId, boards: boardsToSet });
      }
    },
    fetchBoard({ commit, dispatch }, { boardId }) {
      if (!boardId) return Promise.reject(new Error('Missing boardId'));
      return boardsAPI.get(boardId)
        .then((board) => {
          const { sections, ...shallowBoard } = board;

          const segmentId = shallowBoard.segment;
          shallowBoard.sectionIds = sections.map((sect) => sect.id); // ? Only keep the section ids for future store fetching
          dispatch('setBoard', { board: shallowBoard, segmentId });
          const { shallowSections, cards } = sections.reduce((acc, section) => {
            const { cards: sectionCards, ...shallowSection } = section;
            shallowSection.cardIds = sectionCards.map((card) => card.id);
            acc.shallowSections.push(shallowSection);
            const sectionCardsWithBoardId = sectionCards.map((card) => ({ ...card, board: board.id }));
            acc.cards = [...acc.cards, ...sectionCardsWithBoardId];
            return acc;
          }, { shallowSections: [], cards: [] });
          dispatch('setSections', { segmentId, sections: shallowSections });
          dispatch('setCards', { segmentId, cards });
          return board;
        });
    },
    async fetchBoards({ getters, commit, dispatch }, { clearBoards = true, page } = {}) {
      const segmentId = getters.segmentId;
      let shallowBoards = [];
      commit('SET_FETCH_BOARD_STATE', true);
      try {
        const response = await boardsAPI.list(segmentId, page, getters.boardsPageSize);
        const { results: boards, next, previous, count } = response;
        shallowBoards = boards.map((board) => {
          const { sections, ...shallowBoard } = createBoard(board);
          shallowBoard.sectionIds = sections.map((sect) => sect.id); // ? Only keep the section ids for future store fetching
          return shallowBoard;
        });
        if (clearBoards) commit('CLEAR_BOARDS', segmentId);
        dispatch('setBoards', { boards: shallowBoards, segmentId });
        const nextUrl = next ? new URLSearchParams(new URL(next).search).get('page') : null;
        const previousUrl = previous ? new URLSearchParams(new URL(previous).search).get('page') || 1 : null;
        commit('SET_BOARDS_AMOUNT', count);
        commit('SET_BOARDS_NEXT_PAGE', nextUrl);
        commit('SET_BOARDS_PREVIOUS_PAGE', previousUrl);
        commit('SET_FETCH_BOARD_STATE', false);
        return shallowBoards;
      } catch (error) { console.error('Error fetching boards:', error); } // eslint-disable-line no-console
      return shallowBoards;
    },
    async fetchAllBoardsBySegmentId(
      { dispatch, commit, getters },
      { segmentId = null, page = 1, boards = [], clearBoards = true } = {},
    ) {
      const segment = segmentId ?? getters.segmentId;
      let shallowBoards = [];
      commit('SET_FETCH_BOARD_STATE', true);
      let allBoards = [];
      try {
        const response = await boardsAPI.list(segment, page, getters.boardsPageSize);
        const { results: newBoards, next } = response;
        shallowBoards = newBoards.map((board) => {
          const { sections, ...shallowBoard } = createBoard(board);
          shallowBoard.sectionIds = sections.map((sect) => sect.id); // ? Only keep the section ids for future store fetching
          return shallowBoard;
        });
        if (clearBoards) commit('CLEAR_BOARDS', segment);
        allBoards = [...boards, ...shallowBoards];
        if (next) await dispatch('fetchAllBoardsBySegmentId', { segmentId: segment, page: page + 1, boards: allBoards, clearBoards: false });
        dispatch('setBoards', { segmentId: segment, boards: allBoards });
      } catch (error) { console.error('Error fetching all boards by segmentId:', error); } // eslint-disable-line no-console
      commit('SET_FETCH_BOARD_STATE', false);
      return allBoards;
    },
    fetchBoardsBySegmentId({ commit, dispatch }, { segmentId, clearBoards = true } = {}) {
      commit('SET_FETCH_BOARD_STATE', true);
      return boardsAPI.list(segmentId)
        .then((response) => {
          const { results: boards, next, previous, count } = response;
          if (clearBoards) commit('CLEAR_BOARDS', segmentId);
          dispatch('setBoards', { boards, segmentId });
          commit('SET_BOARDS_AMOUNT', count);
          commit('SET_BOARDS_NEXT_PAGE', next);
          commit('SET_BOARDS_PREVIOUS_PAGE', previous);
          commit('SET_FETCH_BOARD_STATE', false);
          return boards;
        });
    },
  },
};
