import { ref, nextTick } from 'vue';
import { throttle } from 'lodash-es';
import Muuri from 'muuri';
import { store } from '@/store';

var fromSectionId = null; // eslint-disable-line no-var
var toSectionId = null; // eslint-disable-line no-var

/**
 ** useMuuriSection - A hook to create a section grid.
 * @param {HTMLElement} sectionContentEl
 * @param {Object} options
 * @param {String} [options.dragAxis='y']
 * @param {Boolean} [options.dragEnabled=true]
 * @param {String} [options.dragHandle='.js-board-section-draghandle']
 * @param {MuuriOptions} [options.restOptions]
 * @returns {MuuriInstance} outerGrid
 */
export function useMuuriSection(sectionWrapperEl, options = {}) {
  const {
    dragAxis = 'y',
    dragSort = true,
    dragEnabled = true,
    dragHandle = '.js-board-section-draghandle',
    ...restOptions
  } = options ?? {};

  const draggedSections = new Set();

  const outerGrid = sectionWrapperEl ? new Muuri(sectionWrapperEl, {
    dragAxis,
    dragSort,
    dragEnabled,
    dragHandle,
    dragAutoScroll: {
      threshold: 65,
      safeZone: 0.25,
      targets: (item) => [
        { element: window, priority: 0 },
        { element: item.getGrid().getElement().parentNode, priority: 1 },
      ],
    },
    ...restOptions,
    sortData: {
      sectionOrder(item, element) {
        return element.getAttribute('data-section-order');
      },
    },
    dragStartPredicate(item, e) {
      if (!item?.getElement()?.querySelector('.is-draggable')) return false;
      return Muuri.ItemDrag.defaultStartPredicate(item, e, { distance: 10 });
    },
    //   Prevent last item from being dragged.
    // dragStartPredicate(item, event) {
    //   if (outerGrid.getItems().indexOf(item) === (outerGrid.getItems().length - 1)) {
    //     return false;
    //   }
    //   For other items use the default drag start predicate.
    //   return Muuri.ItemDrag.defaultStartPredicate(item, event);
    // },
    // dragSortPredicate(item) {
    //   let result = Muuri.ItemDrag.defaultSortPredicate(item);
    //   return result && result.index === (outerGrid.getItems().length - 1) ? false : result;
    // },
  })
    .on('dragInit', (item) => {
      if (typeof window !== 'undefined') window.scrollTo(0, 0);
      // Keep track of the dragged items as we want to keep the items minimized also in multi-drag scenarios.
      draggedSections.add(item);
      // Set width of the element so that scrollbar appearance/disappearance does not affect it's width.
      item.getElement().style.width = `${item.getWidth()}px`;
      // item.getElement().style.height = `${item.getHeight()}px`; // Screws up dragging height
      if (!sectionWrapperEl.classList.contains('board-section-wrapper--folded')) {
        // This timeout hack is _only_ needed to make the dragged item's minimize animation work.
        nextTick(() => {
          sectionWrapperEl.classList.add('board-section-wrapper--folded');
          outerGrid?.refreshItems().layout();
        });
      }
    })
    .on('dragReleaseStart', (item) => {
      draggedSections.delete(item);
      // Reset dragged element's forced width so that it can adjust to the width of it's container.
      item.getElement().style.width = '';
      item.getElement().style.height = '';
      outerGrid.refreshItems().layout();
      if (draggedSections.size === 0) {
        // If this is the last dragged item, let's unminimized the items and again refresh them and relayout section grids.
        sectionWrapperEl.classList.remove('board-section-wrapper--folded');
      }
    })
    .on('dragReleaseEnd', (item) => {
      outerGrid?.refreshItems().synchronize().layout();

      nextTick(() => {
        const sectionId = Number(item.getElement().dataset?.sectionId);
        const prevSectionId = Number(item.getElement().previousElementSibling?.dataset?.sectionId) || null;
        const nextSectionId = Number(item.getElement().nextElementSibling?.dataset?.sectionId) || null;
        store.dispatch('moveSection', {
          sectionId,
          orderBetween: {
            prevSectionId,
            nextSectionId,
          },
        });
      });
    })
    : null;
  // Refresh sort data whenever an item's data-card-order
  outerGrid
    .refreshSortData()
    .sort('secttionOrder', { layout: 'instant' })
    .refreshItems()
    .layout();

  return outerGrid;
}

/**
 ** useMuuriCards - A hook to create a inner grid.
 * @param {HTMLElement} sectionContentEl
 * @param {Object} options
 * @param {HTMLElement} [options.dragContainerEl=null]
 * @param {MuuriInstance} [options.outerGrid=null]
 * @param {Function} [options.dragSort=null]
 * @param {Boolean} [options.dragEnabled=true]
 * @returns {MuuriInstance} innerGrid
 */
export function useMuuriCards(sectionContentEl, options = {}) {
  const {
    dragContainerEl = null,
    outerGrid = null,
    dragSort = true,
    dragEnabled = true,
    ...restOptions
  } = options ?? {};

  const onLayoutStart = throttle((items) => {
    outerGrid?.refreshItems().layout(); // Is it a ref?
  }, 300, { leading: true });

  function checkIfElHasOnClickParentsUntilEl(el, untilEl) {
    if (el === undefined || el === untilEl) return false;
    if (el?._vei?.onClick !== undefined && el._vei.onClick !== null) return true;
    return checkIfElHasOnClickParentsUntilEl(el?.parentElement, untilEl);
  }

  const innerGrid = sectionContentEl ? new Muuri(sectionContentEl, {
    layout: { fillGaps: true },
    dragEnabled,
    dragSort,
    dragContainer: dragContainerEl,
    dragHandle: '.tc-card-header',
    dragPlaceholder: { enabled: true },
    dragSortHeuristics: {
      sortInterval: 10,
      minDragDistance: 50,
      minBounceBackAngle: Math.PI / 2,
    },
    dragAutoScroll: {
      threshold: 65,
      safeZone: 0.25,
      targets: (item) => [
        { element: window, priority: 0 },
        { element: item.getGrid().getElement().parentNode, priority: 1 },
      ],
    },
    sortData: {
      cardOrder(item, element) {
        return element.getAttribute('data-card-order');
      },
    },
    dragStartPredicate(item, e) {
      // Stop dra if item is not draggable
      if (!item?.getElement()?.querySelector('.is-draggable')) return false;
      // Stop drag if target has onClick event somewhere in the chain between handle and event target.
      if (e.isFirst && checkIfElHasOnClickParentsUntilEl(e.target, item?._drag?._handle)) return false;
      // TODO: Does this delay clicks on buttons by about 300ms?
      // TODO: Does this work on touch screens?

      // For other items use the default drag start predicate.
      return Muuri.ItemDrag.defaultStartPredicate(item, e, { distance: 10 });
    },
    ...restOptions,
  })
    .on('dragInit', (item) => {
      innerGrid.refreshItems().layout();
      // ? Sets dragged item inside dragContainer to be styled the same
      item.getElement().style.width = `${item.getWidth()}px`;
      item.getElement().style.height = '300px';

      const fromInnerGrid = item.getGrid();
      fromSectionId = Number(fromInnerGrid.getElement().dataset?.sectionId);
      fromInnerGrid.remove(item.getElement());
    })
    .on('dragReleaseEnd', (item) => {
      // ? Sets dropped item from dragContainer to default styling again and refresh grid
      item.getElement().style.width = '';
      item.getElement().style.height = '';
      innerGrid.refreshItems().layout();

      const cardId = Number(item.getElement().dataset?.cardId);
      const toInnerGrid = item.getGrid();
      toSectionId = Number(toInnerGrid.getElement().dataset?.sectionId);

      toInnerGrid.synchronize(); // ? Makes sure the DOM is in sync with the grid.

      const prevCardId = Number(item.getElement().previousElementSibling?.dataset?.cardId) || null;
      const nextCardId = Number(item.getElement().nextElementSibling?.dataset?.cardId) || null;

      if (toSectionId === 0) { // isPlaceholderAdder section
        // Create section then move
        store.dispatch('moveCard', {
          fromSectionId,
          toSectionId,
          cardId,
          orderBetween: {
            prevCardId,
            nextCardId,
          },
        });
      } else if (toSectionId) {
        store.dispatch('moveCard', {
          fromSectionId,
          toSectionId,
          cardId,
          orderBetween: {
            prevCardId,
            nextCardId,
          },
        });
      }

      fromSectionId = null;
      toSectionId = null;

      outerGrid?.refreshItems().layout();
    })
    .on('layoutStart', onLayoutStart)
    .on('layoutEnd', (items) => {
      items.forEach((item) => {
        const itemEl = item.getElement();
        const translateY = parseFloat(itemEl?.style?.transform.match(/translateY\((.*)px\)/)?.[1] || 0);
        let translateX = parseFloat(itemEl?.style?.transform.match(/translateX\((.*)px\)/)?.[1]) || 0;
        if (translateX === Math.floor(translateX)) return item;
        translateX = Math.floor(translateX);
        itemEl.style.transform = `translateY(${translateY}px) translateX(${translateX}px)`;
        return item;
      });
    })
    : null;
  // Refresh sort data whenever an item's data-card-order Sort the grid by cardOrder
  innerGrid
    .refreshSortData()
    .sort('cardOrder', { layout: 'instant' });
  outerGrid?.refreshItems().layout();

  return innerGrid;
}

/**
 ** useInnerGrids - A hook to handle drag sorting in muuri
 * @returns {Object} { innerGrids, innerDragSort, addInnerGrid }
 */
export function useInnerGrids() {
  const innerGrids = ref([]);
  const innerDragSort = () => innerGrids.value;
  const addInnerGrid = (innerGrid) => {
    if (innerGrids.value.find((iG) => iG?._id === innerGrid?._id)) return;
    innerGrids.value.push(innerGrid);
  };

  return { innerGrids, innerDragSort, addInnerGrid };
}
