<template>
  <div
    v-show="pageCountLoaded && listFullCount > pageSize"
    class="tc-pagination"
  >
    <button
      class="btn-link btn"
      :class="{ 'hide': !canWrap() }"
      @keypress.left="prev"
      @click.stop="prev"
    >
      <i class="zmdi zmdi-chevron-left" />
    </button>
    <span class="page-counter"><strong>{{ currentPage }}</strong>&nbsp;/&nbsp;{{ pageCount }}</span>
    <button
      class="btn-link btn"
      :class="{ 'hide': !canWrap() }"
      @keypress.right="next"
      @click.stop="next"
    >
      <i class="zmdi zmdi-chevron-right" />
    </button>
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es';
import { getBaseUrl, handleServerError } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

const invalidPageMessages = ['Invalid page.', 'Ogiltig sida.'];

export default {
  props: {
    // ? Either you supply a listFunction OR a url
    listFn: Function,
    url: String,
    page: Array,
    count: Number,
    ordering: String,
    searchQuery: {
      type: String,
      default: '',
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    prefetchNext: {
      type: Boolean,
      default: false,
    },
    prefillState: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['load', 'loading', 'update:loading', 'update:count', 'update:page', 'pagination-state'],
  data() {
    return {
      currentPage: 1,
      listFullCount: 0,
      pageCountLoaded: false,
      caches: {},
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.listFullCount / this.pageSize);
    },
  },
  watch: {
    listFn() { this.refresh(); },
    url(newUrl) { this.refresh(); },
    ordering() { this.refresh(); },
    searchQuery(newVal, oldVal) {
      const searchDiffers = newVal !== oldVal;
      if (searchDiffers) this.clear();
      this.refresh(null, !searchDiffers);
    },
  },
  mounted() {
    if (!isEmpty(this.prefillState)) {
      const { page, listFullCount, pageCountLoaded, caches } = this.prefillState;
      this.currentPage = page ?? 1; // eslint-disable-line vue/no-mutating-props
      this.listFullCount = listFullCount ?? 0;
      this.pageCountLoaded = pageCountLoaded ?? false;
      this.caches = caches ?? {};
      this.$nextTick(() => {
        this.caches?.[this.currentPage].then(({ results, count }) => {
          this.$emit('update:count', count);
          this.$emit('update:page', results);
          this.$emit('update:loading', false);
          this.$emit('loading', false);
        });
      });
    } else {
      this.$nextTick(() => {
        this.refresh().then(() => {
          this.$emit('load');
        });
      });
    }
  },
  beforeUnmount() {
    if (this.pageCountLoaded) {
      this.$emit('pagination-state', {
        page: this.currentPage,
        listFullCount: this.listFullCount,
        pageCountLoaded: this.pageCountLoaded,
        caches: this.caches,
      });
    }
  },
  methods: {
    handleResponse(data) {
      this.listFullCount = data.count;
      this.pageCountLoaded = true;
      return data;
    },
    clear() {
      this.currentPage = 1;
      this.listFullCount = 0;
      this.pageCountLoaded = false;
      this.caches = {};
    },
    fetch(page, skipPrefetch = false) {
      const pageNumber = (page >= this.pageCount && this.pageCount > 1) ? 'last' : page;
      let p;
      if (this.prefetchNext && this.caches[page] !== undefined) {
        p = this.caches[page];
      } else if (this.listFn) {
        p = this.listFn(pageNumber)
          .then(this.handleResponse, (error) => {
            const detail = error.response?.body?.detail;
            if (invalidPageMessages.indexOf(detail) > -1) return null;
            throw error;
          })
          .catch(handleServerError);
      } else {
        p = getBaseUrl(this.url)
          .query({
            ordering: this.ordering,
            page_size: this.pageSize,
            page: pageNumber,
            search: this.searchQuery,
          })
          .then((response) => response.body, handleAuthError)
          .then(this.handleResponse, (error) => {
            if (invalidPageMessages.indexOf(error.message) > -1) return null;
            throw error;
          })
          .catch(handleServerError);
      }
      if (this.prefetchNext) {
        this.caches[page] = p;

        p.then(() => {
          if (!skipPrefetch && page < this.pageCount) {
            this.fetch(page + 1, true);
          }
        });
      }
      return p;
    },
    refresh(toPage = null, skipPrefetch = false) {
      const page = toPage || this.currentPage;
      this.$emit('update:loading', true);
      this.$emit('loading', true);
      return this.fetch(page, skipPrefetch)
        .then((response) => {
          const data = response;
          this.$emit('update:count', data.count);
          this.currentPage = page;
          this.$emit('update:page', data.results);
          this.$emit('update:loading', null);
          this.$emit('loading', null);
        }, (error) => {
          const detail = error.response?.body?.detail;
          if (invalidPageMessages.indexOf(detail) > -1) {
            this.prev();
          }
        });
    },
    hasPrev() {
      return this.currentPage > 1;
    },
    hasNext() {
      return this.currentPage < this.pageCount;
    },
    canWrap() {
      return this.pageCount > 1;
    },
    next() {
      if (this.hasNext()) {
        this.refresh(this.currentPage + 1);
      } else {
        this.refresh(1);
      }
    },
    prev() {
      if (this.hasPrev()) {
        this.refresh(this.currentPage - 1);
      } else {
        this.refresh(this.pageCount);
      }
    },
  },
};
</script>
