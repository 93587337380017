<template>
  <div
    class="tc-ranking-gauge-indicator-body"
    :class="{ 'is-prize': showPrize }"
  >
    <div class="tc-ranking-gauge-indicator-bar">
      <svg
        overflow="visible"
        viewBox="0 -4 236 275"
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          fill="#ECEFEF"
          stroke="#D7E0E0"
          stroke-miterlimit="10"
          stroke-width="2"
          d="M176.2 241.9l-16.9-29.5c25.7-14.8 41.7-42.4 41.7-72 0-45.8-37.2-83-83-83s-83 37.2-83 83c0 29.7 16 57.3 41.7 72l-16.9 29.5C23.5 221.1 1 182.2 1 140.4c0-64.5 52.5-117 117-117s117 52.5 117 117c0 41.8-22.5 80.7-58.8 101.5z"
        />

        <path
          id="globe"
          fill="#4a4a4a"
          d="M115.4-2.6c-5.3,1.5-8.4,7-6.9,12.4s7,8.4,12.4,6.9s8.4-7,6.9-12.4C126.2-1,120.7-4.1,115.4-2.6zM119.7,14.8c-3.9,0.6-7.8-1.8-8.9-5.7c-0.2-0.6-0.3-1.2-0.3-1.8l5.9,3.3l0.3,1c0.3,1.1,1.4,1.7,2.5,1.4L119.7,14.8z M125.7,10.5c-0.5-0.7-1.3-1.1-2.2-0.8l-1,0.3l-0.8-2.9c-0.2-0.5-0.7-0.8-1.2-0.7L114.7,8l-0.5-1.9l1.9-0.5c0.5-0.2,0.8-0.7,0.7-1.2l-0.5-1.9l1.9-0.5c1.1-0.3,1.7-1.4,1.4-2.5l-0.1-0.4c3.1,0.3,5.9,2.5,6.8,5.8C126.8,6.7,126.5,8.7,125.7,10.5z"
        />
        <defs>
          <path
            id="mask-path"
            d="M 178 241 l -16.9 -29.5 c 25.7 -14.8 41.7 -42.4 41.7 -72 c 0 -45.8 -37.2 -83 -83 -83 s -83 37.2 -83 83 c 0 29.1 15.4 56.2 39.2 70.5 l -17 29 C 22.9 220 1 181.6 1 140.4 c 0 -64.5 52.5 -117 117 -117 s 117 52.5 117 117 c 0 41.8 -22.5 80.7 -57 100.6 z"
          />
        </defs>
        <clipPath id="mask">
          <use
            overflow="visible"
            xlink:href="#mask-path"
          />
        </clipPath>
        <path
          v-show="!noData"
          id="score"
          fill="none"
          :stroke="trafficLightColor"
          :stroke-dasharray="scoreDash"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="30"
          d="M66.8 226.3C37.6 208.9 18 176.9 18 140.4c0-55.2 44.8-100 100-100s100 44.8 100 100c0 36.2-19.2 67.9-48 85.5"
          clip-path="url(#mask)"
        />

        <!-- dividers -->
        <path
          id="global-divider"
          fill="none"
          stroke="#D7E0E0"
          stroke-miterlimit="10"
          stroke-width="2"
          stroke-linecap="round"
          d="M 118 56 l 0 -36"
        />
        <text
          v-if="benchmarkLabel"
          transform="translate(0 -32)"
          class="tc-ranking-gauge-indicator-helpers"
        >
          <textPath
            href="#mask-path"
            fill="#4a4a4a"
            startOffset="804"
            text-anchor="middle"
          >{{ benchmarkLabel }}</textPath>
        </text>

        <path
          id="bottom-divider"
          fill="none"
          stroke="#D7E0E0"
          stroke-miterlimit="10"
          stroke-width="2"
          stroke-linecap="round"
          d="M 118 54 l 0 -32"
          style="transform: rotate(-77.5deg); transform-origin: center;"
        />
        <text
          transform="translate(2 -1)"
          class="tc-ranking-gauge-indicator-helpers"
        >
          <textPath
            href="#mask-path"
            fill="#769BA3"
            startOffset="415"
            text-anchor="middle"
          >{{ $pgettext('Label — Bottom performing', 'Nedersta') }} 25%</textPath>
        </text>
        <path
          id="top-divider"
          fill="none"
          stroke="#D7E0E0"
          stroke-miterlimit="10"
          stroke-width="2"
          stroke-linecap="round"
          d="M 118 54 l 0 -32"
          style="transform: rotate(77.5deg); transform-origin: center;"
        />
        <text
          transform="translate(-4 -2)"
          class="tc-ranking-gauge-indicator-helpers"
        >
          <textPath
            href="#mask-path"
            fill="#769BA3"
            startOffset="85"
            text-anchor="middle"
          >{{ $pgettext('Label — Top performing', 'Översta') }} 25%</textPath>
        </text>
        <!-- <text>
          <textPath
            href="#mask-path"
            fill="#4a4a4a"
            startOffset="755"
          >{{ 'Benchmark' }} 50%</textPath>
        </text> -->

        <!-- medal -->
        <g v-if="showPrize">
          <path
            :class="medalClass + '-dark'"
            d="M148,201.5l-41.2,6.5l37.3,63.4l7.1-26.7l26.6,6.7L148,201.5"
          />
          <path
            d="M129.2,205.7l-35.7,66.4l-8-26.2l-27.3,8.7L83.5,208L129.2,205.7z"
            :class="medalClass"
          />
          <circle
            cx="118"
            cy="140"
            r="80"
            :class="medalClass"
          />
          <circle
            cx="118"
            cy="140"
            r="65"
            fill="#fff"
          />
          <circle
            cx="118"
            cy="140"
            r="62"
            :class="medalClass"
          />
          <text
            x="50%"
            y="166"
            text-anchor="middle"
            class="tc-ranking-gauge-inner-text medal-number bold"
          > {{ position }}</text>
        </g>
        <!-- innertext -->
        <template v-else>
          <g>
            <text
              class="tc-ranking-gauge-text"
              x="50%"
              :y="notEnoughData ? '52.5%': '46%'"
              :font-size="notEnoughData ? '15px': '18px'"
              text-anchor="middle"
              :fill="notEnoughData ? '#769BA3' : '#4a4a4a'"
            >{{ innerGaugeText }}</text>
            <text
              v-if="score"
              class="tc-ranking-gauge-score"
              x="53%"
              y="63%"
              text-anchor="middle"
              font-size="48px"
              fill="#4a4a4a"
            >{{ score }}<tspan
              class="medium-number"
              font-size="20px"
            >%</tspan>
            </text>
            <!-- zmdi -->
          </g>
          <g
            v-if="!notEnoughData"
            style="transform:translateY(7px)"
          >
            <path
              v-show="!loadingTrend"
              :fill="trendProps.fill"
              :d="trendProps.path"
            />
            <text
              v-tooltip="{
                content: trendUnitTooltip,
                placement: 'bottom',
                boundary: $parent.$el,
              }"
              x="50%"
              y="87.5%"
              class="small-text"
              text-anchor="middle"
              alignment-baseline="central"
              fill="#4a4a4a"
            >{{ loadingTrend ? $gettext('Laddar trend…') : `Trend ${trendPercentage}` }}</text>
          </g>
        </template>
      </svg>
    </div>
    <div
      v-if="respondentCount"
      class="tc-ranking-gauge-indicator-helpers tc-ranking-gauge-respondent-count"
    >
      {{ $pgettext("Title - totalCount", "Antal svar:") }} {{ respondentCount }}
    </div>
  </div>
</template>

<script>
const SVG_MAXLENGTH = 520;
const NO_DATA = '–';

export default {
  name: 'RankingGaugeIndicator',
  props: {
    score: {
      type: Number,
      default: 50,
    },
    position: {
      type: [Number, null],
      default: null,
    },
    excluded: {
      type: Boolean,
      default: true,
    },
    trend: {
      type: Number,
      default: 0,
    },
    loadingTrend: {
      type: Boolean,
      default: false,
    },
    applicantsLeft: {
      type: Number,
      default: 0, // answers left to get results.
    },
    comment: {
      type: String,
      default: '',
    },
    benchmarkLabel: {
      type: String,
      default: '',
    },
    respondentCount: {
      type: Number,
      default: () => null,
    },
  },
  computed: {
    noData() {
      if (this.comment === '') return false;
      return true;
    },
    notEnoughData() {
      return this.noData || this.applicantsLeft > 0;
    },
    scoreDash() {
      let meterScore = this.score !== null ? this.score / 100 * SVG_MAXLENGTH : SVG_MAXLENGTH / 2;
      return `${meterScore} ${SVG_MAXLENGTH}`;
    },
    trendPercentage() {
      if (this.trend === 0) return `±${this.trend}%`;
      if (this.trend > 0) return `+${this.trend}%`;
      return this.trend && `${this.trend.toString()}%` || NO_DATA;
    },
    trendProps() {
      if (this.trend === null) return { path: 'M129.6,215.5l-4-4v3h-15v2h15v3L129.6,215.5z', fill: '#ffbf0d' };
      if (this.trend > 0) return { path: 'M124.1,209.5l2.3,2.3l-4.9,4.9l-4-4l-7.4,7.4l1.4,1.4l6-6l4,4l6.3-6.3l2.3,2.3v-6H124.1z', fill: '#02AD8C' };
      if (this.trend < 0) return { path: 'M124.1,221.5l2.3-2.3l-4.9-4.9l-4,4l-7.4-7.4l1.4-1.4l6,6l4-4l6.3,6.3l2.3-2.3v6H124.1z', fill: '#ff5555' };
      return { path: 'M129.6,215.5l-4-4v3h-15v2h15v3L129.6,215.5z', fill: '#ffbf0d' };
    },
    trendUnitTooltip() {
      if (this.trend === null) return this.$pgettext('Ranking gauge - No data', 'För lite data');
      return this.$gettextInterpolate(
        this.$npgettext('Ranking-gauge – Percent', '%{num} procentenhet sen föregående period', '%{num} procentenheter sen föregående period', this.trend),
        { num: this.trendPercentage },
      );
    },
    showPrize() {
      return this.position !== null;
    },
    trafficLightColor() {
      if (this.excluded || this.score === null) return '#D7E0E0';
      if (this.score < 25) return '#ff5555'; // red
      if (this.score >= 50) return '#02AD8C'; // green
      return '#ffbf0d'; // yellow
    },
    medalClass() {
      switch (this.position) {
        case 1:
          return 'gold-medal';
        case 2:
          return 'silver-medal';
        case 3:
          return 'bronze-medal';
        default:
          return '';
      }
    },
    innerGaugeText() {
      if (this.notEnoughData) return this.$pgettext('Ranking gauge - No data', 'För lite data');
      if (this.score === null) return '';
      // if (this.applicantsLeft > 0) {
      //   return this.$gettextInterpolate(
      //     this.$pgettext('Gauge - low count message', 'Det saknas %{count} svar för en ranking'),
      //     { count: this.applicantsLeft },
      //   );
      // }
      // return this.$pgettext('Gauge - inner text', 'Ditt företag är bättre än');
      if (this.score < 50) return this.$pgettext('Gauge - inner text', 'Nedersta');
      return this.$pgettext('Gauge - inner text', 'Bättre än');
    },
  },
};
</script>
