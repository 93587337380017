<template>
  <div>
    <div
      v-if="!demoData"
      class="tc-table-wrapper-outerinfo"
    >
      <compare-date-filter
        :date-label="dateLabel"
        :date-icon="null"
      />
    </div>
    <div class="tc-table-wrapper tc-table-wrapper-responsive">
      <table class="tc-table tc-table-hover tc-table-airy tc-table-minus follow-up-table">
        <thead>
          <tr>
            <th
              v-for="(theadHeader, index) in theadHeaders"
              :key=" theadHeader.id"
              :class="index !== 0 ? 'table-col-1' : ''"
            >
              {{ theadHeader.label }}
            </th>
            <th class="table-col-1" />
            <th
              class="text-right table-col-1"
            />
          </tr>
        </thead>
        <tbody>
          <template v-if="loading">
            <tr
              v-for="trIndex in pageSize"
              :key="trIndex"
            >
              <td
                v-for="(tdIndex, i) in 7"
                :key="tdIndex"
              >
                <span
                  class="td-thead-label hidden-large-up tc-loading-text tc-loading-text-inherit tc-loading-text-on-dark"
                />
                <span
                  class="tc-loading-text tc-loading-text-inherit tc-loading-text-on-dark"
                  :class="i === 0 ? 'size-medium' : 'size-xsmall'"
                />
              </td>
            </tr>
          </template>
          <template v-if="!loading && demoData && filteredDemoApplicants.length > 0">
            <follow-up-table-row
              v-for="applicant in filteredDemoApplicants"
              :key="applicant.id"
              :applicant="applicant"
              :thead-headers="theadHeaders"
              :table-is-grid="tableIsGrid"
              :demo-data="demoData"
              @open-applicant-modal="(p) => openApplicantModal(p, true)"
            />
          </template>
          <template v-else-if="!loading && applicants && applicants.length > 0">
            <follow-up-table-row
              v-for="applicant in applicants"
              :key="applicant.id"
              :applicant="applicant"
              :thead-headers="theadHeaders"
              :table-is-grid="tableIsGrid"
              @open-applicant-modal="openApplicantModal"
            />
          </template>
          <tr v-else-if="!loading && applicants.length <= 0">
            <td colspan="7">
              <p class="text-center">
                {{ $gettext('Inga fler respondenter hittades.') }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { subDays } from 'date-fns';
import { get } from 'API/FollowUp';
import { validQuery } from 'Utils/urls';
import { equalLiteral } from 'Utils/general';
import { getKeyByDate } from 'Utils/date';
import dynamicImport from 'Utils/dynamicImport';
import Paginator from 'Utils/Paginator';
import FollowUpTableRow from 'Components/parts/follow-up/FollowUpTableRow';
import CompareDateFilter from 'Components/parts/filters/CompareDateFilter';

export const validator = {
  pageSize: (value) => /[0-9]+/.test(value),
  level: (value) => typeof value === 'string',
  handled: (value) => typeof value === 'string',
};

export default {
  name: 'FollowUpTable',
  components: {
    FollowUpTableRow,
    CompareDateFilter,
  },
  props: {
    demoData: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['reset-index', 'send-page-amount', 'next-index', 'previous-index'],
  data() {
    return {
      currentIndex: null,
      promise: Promise.resolve(),
      paginator: null,
      pageSize: 20,
      query: null,
      count: null,
      loading: false,
      applicants: [],
      demoApplicants: [
        {
          applicant_id: 'demo-1',
          name: 'Example Emily',
          level: 'step1',
          email: 'example+emily@trustcruit.com',
          phone_number: '',
          handled: false,
          comment: this.$pgettext('Example - Follow Up comment 1', 'Blev kontaktad väldigt sent'),
          note: '',
          answer: 2,
          creation_time: subDays(new Date(), 2),
        },
        {
          applicant_id: 'demo-sec',
          name: 'Example Eduardo',
          level: 'step2',
          email: 'example+eduardo@trustcruit.com',
          phone_number: '555-123 4567',
          handled: true,
          comment: this.$pgettext('Example - Follow Up comment 2', 'Rekryteraren brydde sig inte alls'),
          note: this.$pgettext('Example - Follow Up note 2', 'Rekryteraren Sven har kontaktat Eduardo'),
          answer: 1,
          creation_time: subDays(new Date(), 7),
        },
      ],
      filteredDemoApplicants: [],
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'segmentFilter',
      'activeModal',
    ]),
    dateLabel() {
      const { date } = this.segmentFilter;
      if (getKeyByDate(date) === 'this_year' || getKeyByDate(date) === 'all_time') return this.$pgettext('dateLabel (this_year & all_time) — StepForms', 'Visa kandidater för');
      if (date.type === 'relative') return this.$pgettext('dateLabel (type: relative) — StepForms', 'Visa kandidater för');
      return this.$pgettext('dateLabel (type: absolute)', 'Visa kandidater');
    },
    tableIsGrid() {
      return typeof window !== 'undefined' ? window.innerWidth < 1279 : true;
    },
    theadHeaders() {
      return [
        {
          label: this.$pgettext('Table headers - sending log', 'Svarande'),
          value: 'email',
        }, {
          label: this.$pgettext('Table headers - sending log', 'Formulär'),
          value: 'step',
        }, {
          label: this.$pgettext('Table headers - sending log', 'Anledning'),
          value: 'flagReason',
        }, {
          label: this.$pgettext('Table headers - sending log', 'Kommentar'),
          value: 'comment',
        }, {
          label: this.$pgettext('Table headers - sending log', 'Anteckning'),
          value: 'internalNote',
        },
      ];
    },
    lastIndex() {
      return this.prevIndex(this.applicants.length);
    },
    routeQuery() {
      return this.$route.query;
    },
    applicantFromUrl() {
      return Number(this.$route.params.applicantId);
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.applicantId === '') this.setIndex(null);
      else if (to.params.applicantId != null) this.openApplicantModal(to.params.applicantId);
    },
    routeQuery: {
      deep: true,
      handler: debounce(function debouncedFn(query) {
        if (equalLiteral(query, this.query)) return;
        this.$emit('reset-index');
        this.setup(this.segmentId, query, this.segmentFilter);
      }, 1000),
    },
    segmentFilter(segmentFilter) {
      this.setup(this.segmentId, this.routeQuery, segmentFilter);
    },
  },
  updated() {
    this.$nextTick(() => {
      if (!this.applicantFromUrl) return;
      if (this.paginator) this.openApplicantModal(this.applicantFromUrl);
    });
  },
  mounted() {
    if (!this.segmentId) return;
    this.setup(this.segmentId, this.routeQuery, this.segmentFilter);
  },
  methods: {
    ...mapActions([
      'openModal',
      'updateModal',
    ]),
    setup(segment, query, filter = {}) {
      this.loading = true;
      this.query = { ...query };
      if (this.demoData) {
        this.filteredDemoApplicants = this.demoApplicants.filter((app) => {
          let handled = true;
          let level = true;
          if (query.handled) handled = query.handled.indexOf(String(app.handled)) > -1;
          if (query.level) level = query.level.indexOf(String(app.level)) > -1;
          return handled && level;
        });
        this.loading = false;
      }
      this.promise = this.promise.then(() => {
        this.paginator = new Paginator(
          get(segment, this.pageSize, { ...validQuery(this.query, validator), ...filter }),
        );
        return this.paginator.then((response) => {
          this.applicants = response.results;
          this.$emit('send-page-amount', response.count > this.pageSize ? Math.ceil(response.count / this.pageSize) : 1);
          this.loading = false;
        });
      });
    },
    next() {
      this.loading = true;
      if (!this.paginator.hasNext()) return Promise.resolve();
      this.$emit('next-index');
      return this.paginator.next()
        .then((response) => {
          if (response?.results?.length > 0) this.applicants = response.results;
          this.loading = false;
          return response;
        });
    },
    previous() {
      this.loading = true;
      if (!this.paginator.hasPrevious()) return Promise.resolve();
      this.$emit('previous-index');
      return this.paginator.previous()
        .then((response) => {
          if (response?.results.length > 0) this.applicants = response.results;
          this.loading = false;
          return response;
        });
    },
    openApplicantModal(id, demo) {
      this.$router.push({ params: { applicantId: id }, query: { ...this.$route.query } }).catch(() => {});
      const applicantName = this.demoData
        ? this.demoApplicants.filter((app) => id === app?.applicant_id)[0]?.name
        : this.applicants.filter((app) => id === app?.applicant_id)[0]?.name
        || '';
      const ApplicantDetails = dynamicImport(() => import(/* webpackChunkName: "ApplicantDetails", webpackPrefetch: true */ 'Components/parts/applicants/ApplicantDetails'));
      const options = {
        name: 'ApplicantDetails',
        component: ApplicantDetails,
        dataObject: {
          props: {
            applicantName,
            flaggedApplicantId: typeof id === 'string' ? parseInt(id, 10) : id,
            applicants: this.applicants,
            paginator: this.paginator,
            demo: this.demoData,
          },
          on: {
            prev: (i) => this.setIndex(this.prevIndex(i)),
            next: (i) => this.setIndex(this.nextIndex(i)),
            'set-index': this.setIndex,
          },
        },
        size: 'large',
        callback: (newDataObject) => this.$router.push({ name: 'follow-up', query: this.$route.query })
          .catch((err) => {}),
      };

      if (this.activeModal) this.updateModal(options);
      else this.openModal(options);
    },
    setIndex(i = null) {
      this.currentIndex = i;
    },
    prevIndex(index) {
      if (index === null) return null;
      let i = index;
      do i = i === 0 ? null : i - 1;
      while (!this.applicants[i]?.applicant_id && i > 0);
      if (index !== 0) {
        this.$router.push({
          params: { applicantId: this.applicants[i]?.applicant_id }, query: { ...this.$route.query },
        })
          .catch(() => {});
      }
      return i;
    },
    nextIndex(index) {
      if (index === null) return null;
      let i = index;
      do i = i === this.applicants.length ? null : i + 1;
      while (!this.applicants[i]?.applicant_id && i < this.lastIndex);
      if (this.applicants.length !== i) {
        this.$router.push({
          params: { applicantId: this.applicants[i]?.applicant_id }, query: { ...this.$route.query },
        }).catch(() => {});
      }
      return i;
    },
  },
};
</script>
