import { h as createElement } from 'vue';

/*
 * Able to v-model if type="filter", type="choices" or type="choice"
*/
const ChipSet = {
  name: 'ChipSet',
  props: {
    type: String,
    modelValue: [String, Array],
    outline: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    classes() {
      return {
        'chip-set': true,
        'chip-set-sm': this.small,
        'chip-set-outline': this.outline,
        'chip-set-no-border': this.noBorder,
        [`chip-set-${this.type}`]: this.type,
      };
    },
    slotNodes() {
      const children = this.$slots?.default?.()?.[0]?.children;
      if (children?.[0]?.key) return children; // If <Chip> is using v-for, return the children
      return this.$slots.default?.() || []; // If <Chip> is not using v-for, return direct nodes
    },
  },
  watch: {
    modelValue: {
      deep: true,
      immediate: true,
      handler(value) {
        this.selected = Array.isArray(value) ? [...value] : [value];
      },
    },
  },
  methods: {
    itemClicked({ value, selected }) {
      if (this.type === 'action') return;
      // beware running functions and
      // setting props on child directly
      if (this.type === 'choice') {
        // select one value
        this.selected = [value];
      } else if (this.type === 'filter' || this.type === 'choices') {
        // toggle current value
        let existing = this.selected.findIndex((v) => v === value);
        if (existing === -1) {
          this.selected.push(value);
        } else {
          this.selected.splice(existing, 1);
        }
      }
      this.update();
    },
    update(array = this.selected) {
      if (this.type === 'choice') this.$emit('update:modelValue', array[0]);
      else this.$emit('update:modelValue', array);
    },
  },
  render() {
    return createElement(
      'div',
      {
        class: this.classes,
        dataType: this.type || 'action',
      },
      this.slotNodes.map((chip) => createElement(
        chip,
        {
          ref: chip.key, // Make sure the key exists and is unique if using v-for
          onClick: (evt) => this.itemClicked(evt),
          default: this.selected.indexOf(chip.props.value) > -1,
        },
      )),
    );
  },
};
export default ChipSet;
