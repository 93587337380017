<template>
  <div
    v-if="showCounts"
    class="tc-list-group-header user-list-count"
  >
    <span v-if="hasUsers">{{ count }} {{ userString }}
    </span> <span v-if="hasInvites">
      <span
        v-if="hasUsers"
      >{{ $gettext('och') }}</span> <span>
        {{ $npgettext(
          'Concat — Invitecount',
          '%{ inviteCount } väntande inbjudan',
          '%{ inviteCount } väntande inbjudningar',
          inviteCount,
          { inviteCount }
        ) }}
      </span>
    </span>
  </div>
  <div
    v-else-if="loading"
    class="tc-list-group-header user-list-count user-list-count-list-loading"
  >
    {{ $gettext('Laddar...') }}
  </div>
  <div
    v-else
    class="tc-list-group-header user-list-count user-list-count-no-count"
  >
    {{ $gettext('Det finns inga medlemmar i segmentet, det kan finnas användare i organisationen som ser segmentet.') }}
  </div>
</template>

<script>
export default {
  props: ['count', 'inviteCount', 'memberType', 'loading'],
  computed: {
    userString() {
      const plural = this.count > 1;
      if (this.memberType === 'member') {
        return plural
          ? this.$gettext('personer är medlemmar')
          : this.$gettext('person är medlem');
      }
      return plural
        ? this.$gettext('personer är användare')
        : this.$gettext('person är användare');
    },
    hasUsers() {
      return this.count && this.count > 0;
    },
    hasInvites() {
      return this.inviteCount && this.inviteCount > 0;
    },
    showCounts() {
      return this.hasInvites || this.hasUsers;
    },
  },
};
</script>
