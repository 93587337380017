<template>
  <div>
    <div class="page-content-header pl-2">
      <h2>
        <i class="zmdi zmdi-city pr-1" />
        {{ $gettext('Inställningar för') }} {{ customerName }}
      </h2>
    </div>
    <card>
      <template #header>
        <nav class="tc-card-navigation p-card">
          <ul class="tc-card-navigation-list tc-card-navigation-list-wrapped">
            <li
              v-if="isAdmin"
              class="tc-card-navigation-list-item"
            >
              <router-link
                :to="{name: 'customerSettingsAccount'}"
                :class="{ 'exact-active': routeName === 'customerSettingsAccount' }"
                class="h3"
              >
                <span>{{ $gettext('Mitt företag') }}</span>
              </router-link>
            </li>
            <li class="tc-card-navigation-list-item">
              <router-link
                :to="{name: 'customerSettingsReports'}"
                :class="{ 'exact-active': routeName === 'customerSettingsReports' }"
                class="h3"
              >
                <!-- <i class="zmdi zmdi-file-text mr-0.5" /> -->
                <span>  {{ $pgettext('Plural - Customer settings navigation', 'Rapport PDF:er') }}</span>
              </router-link>
            </li>
            <li class="tc-card-navigation-list-item">
              <router-link
                :to="{name: 'customerSettingsSegment'}"
                :class="{ 'exact-active': routeName === 'customerSettingsSegment' }"
                class="h3"
              >
                <!-- <i class="zmdi zmdi-group-work mr-0.5" /> -->
                <span>{{ $pgettext('Plural - Customer settings navigation', 'Segment') }}</span>
              </router-link>
            </li>
            <li
              v-if="isAdmin"
              class="tc-card-navigation-list-item"
            >
              <router-link
                :to="{name: 'customerSettingsUsers'}"
                :class="{ 'exact-active': routeName === 'customerSettingsUsers' }"
                class="h3"
              >
                <span>{{ $pgettext('Plural - Customer settings navigation', 'Användare') }}</span>
              </router-link>
            </li>
            <li
              v-if="isAdmin"
              class="tc-card-navigation-list-item"
            >
              <router-link
                :to="{name: 'customerSettingsInvite'}"
                :class="{ 'exact-active': routeName === 'customerSettingsInvite' }"
                class="h3"
              >
                <span>{{ $gettext('Bjud in') }}</span>
              </router-link>
            </li>
            <li class="tc-card-navigation-list-item">
              <router-link
                :to="{name: 'customerSettingsImportExport'}"
                :class="{ 'exact-active': routeName === 'customerSettingsImportExport' }"
                class="h3"
              >
                <span>{{ $pgettext('Navigation — Customer Settings', 'Importera/Exportera') }}</span>
              </router-link>
            </li>
            <li class="tc-card-navigation-list-item">
              <router-link
                :to="{name: 'customerSettingsSendingLog'}"
                :class="{ 'exact-active': routeName === 'customerSettingsSendingLog' }"
                class="h3"
              >
                <span>{{ $gettext('Utskickslogg') }}</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </template>
      <template #body>
        <router-view />
      </template>
    </card>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const routeName = computed(() => route.name);

const store = useStore();
const customerName = computed(() => store.getters.customerName ?? '');
const isAdmin = computed(() => store.getters.role === 'admin' || false);
</script>
