import { isEmpty } from 'lodash-es';
import answersAPI from 'API/answers';
import { wrapValue, markLabel, labelHit } from 'Utils/tcselectHelpers';

export default {
  state: {
    answers: {}, // Answers (keys) under questtionId which is under segmentId, ex { 425: { "502":  ['0', '1', '2'] } }
    fetchingAnswers: Promise.resolve(),
    answersLatestFetch: null,
  },
  mutations: {
    SET_ANSWER(state, { segmentId, answerKey, answerValues }) {
      if (segmentId) {
        if (state.answers?.[segmentId]?.[answerKey]) {
          state.answers[segmentId][answerKey] = { ...state.answers[segmentId][answerKey], ...answerValues[answerKey] }; // eslint-disable-line max-len
        } else if (state.answers?.[segmentId]) {
          state.answers[segmentId][answerKey] = answerValues;
        } else {
          const answer = { [answerKey]: answerValues };
          state.answers[segmentId] = answer;
        }
      }
    },
    SET_ANSWERS_FETCH_PROMISE(state, fetchState) {
      state.fetchingAnswers = fetchState;
    },
    SET_ANSWERS_LATEST_FETCH(state, latestFetchDate) {
      state.answersLatestFetch = latestFetchDate;
    },
    CLEAR_ANSWER_CACHE(state) {
      state.answers = {};
    },
  },
  getters: {
    fetchingAnswerValues: (state) => state.fetchingAnswers,
    // answerKeys: you need to get the keys from getAllQuestions action for example, it actually also contains baseValues/answers
    answerValues: (state, getters) => (answerKey) => getters.segmentId
      && state.answers[getters.segmentId]?.[answerKey]
      || [], // Ex. this.answerValues('405') >>> [1,2,5,9,10]
    answerValuesOptions: (state, getters) => (answerKey, term) => state.fetchingAnswers
      .then((fetchedAnswerKey) => getters.answerValues(answerKey)
        ?.map(wrapValue)
        .filter(labelHit(term))
        .map(markLabel(term))
      || []),
  },
  actions: {
    /**
     ** fetchAnswerValues - memoized get for answers
     * @param {array} answerKeys, supports multiple. ex. ['405', '502']
     * @param {object} question?, OPTIONAL can be used to get deductible answers. ex. { question_id: '405', question_text: 'How many times did you...' }
     * @returns {promise} answerPromise
     */
    fetchAnswerValues({ state, getters, commit }, { answerKeys = [], question = {} } = {}) {
      const { segmentId } = getters;
      const ONE_HOUR = 60 * 60 * 1000;
      if (question?.question_type && !isEmpty(question?.options || {})) {
        const fillRange = (start, end) => Array(end - start + 1).fill().map((val, index) => String(start + index));
        const answerKey = question?.id || question?.question_id;
        let answerValues;
        if (question.question_type === 'yesno') answerValues = ['ja', 'nej'];
        else if (question.question_type === 'rating' || question.options?.cnps) answerValues = fillRange(0, 10);

        if (!isEmpty(answerValues)) {
          const resolvedPromise = Promise.resolve({ [answerKey]: answerValues });
          commit('SET_ANSWER', { segmentId, answerKey, answerValues });
          commit('SET_ANSWERS_FETCH_PROMISE', resolvedPromise);
          return resolvedPromise;
        }
      }
      if (answerKeys) {
        let answerProm;
        if (
          answerKeys.every((answerKey) => getters.answerValues(answerKey).length > 0) // ? is available in store
          && state.answersLatestFetch !== null
          && ((new Date()) - state.answersLatestFetch) < ONE_HOUR // ? answersLatestFetch is not older than 1 hour
        ) {
          const resolvedPromise = Promise.resolve(
            answerKeys.reduce((o, answerKey) => ({ ...o, [answerKey]: getters.answerValues(answerKey) }), {}),
          );
          commit('SET_ANSWERS_FETCH_PROMISE', resolvedPromise);
          return resolvedPromise;
        }

        answerProm = answersAPI.get({
          segmentId,
          questionIds: answerKeys,
        })
          .then((response) => {
            const answerKeyValues = response?.[0] || {};
            if (answerKeyValues?.error) return Promise.reject(answerKeyValues.error);
            const formattedResponse = {};
            Object.keys(answerKeyValues).forEach((answerKey) => { // ? answerKey is questionId
              commit('SET_ANSWER', {
                segmentId,
                answerKey,
                answerValues: Object.keys(answerKeyValues[answerKey]),
              });
              formattedResponse[answerKey] = Object.keys(answerKeyValues[answerKey]);
            });
            return formattedResponse;
          })
          .catch((err) => Promise.reject(err));

        commit('SET_ANSWERS_FETCH_PROMISE', answerProm);
        commit('SET_ANSWERS_LATEST_FETCH', new Date());

        return answerProm;
      }
      return Promise.reject(new Error('[TC] fetchAnswerValues needs a answerKey'));
    },
    clearAnswerCache({ commit }) {
      commit('CLEAR_ANSWER_CACHE');
    },
  },
};
