import { requestData } from 'API/data';

async function stats(payload = {}, abortToken = null) {
  return requestData([payload], 'response/', abortToken); // , this.$pgettext('Error - Card API data', 'svarsfrekvens för företaget')
}

async function statsGlobal(payload = {}, abortToken = null) {
  return requestData([payload], 'response/global/', abortToken); // , this.$pgettext('Error - Card API data', 'svarsfrekvens för benchmark')
}

export default {
  stats,
  statsGlobal,
};
