<template>
  <ul class="tc-query-list">
    <li
      v-if="Object.keys(q).length === 0"
      class="px-pane mb-0"
    >
      <p class="subtle-text alert alert-default mb-0">
        <i class="zmdi zmdi-info-outline" />
        <span class="strong">
          {{ $gettext('Har inga avgränsningar.') }}
        </span><br>
        <span>{{ $gettext('Segmentet visar all data.') }}</span>
      </p>
    </li>
    <segment-query-item
      v-for="(value, key, index) in q"
      v-else
      :ref="`segmentQueryItem-${key}-${index}`"
      :key="`${key}-${index}`"
      :name="key"
      :value="value"
      :is-last="isLast(key, Object.keys(q))"
      :removable="removableItems"
      :editable="isEditableQueryKey(key)"
      @edit="edit"
      @remove="remove"
      @convert="convert"
    />
  </ul>
</template>

<script>
import { pickBy } from 'lodash-es';
import { isLast } from 'Utils/array';
import SegmentQueryItem from './SegmentQueryItem';

// ? List of keys that will be able to be edited
// ? Can use "starting wildcards" by appending an asterisk, ex. "tags__*"
const editableQueryKeys = ['tags__*', 'customer_proxy_id__in', 'level__in', 'level'];

export default {
  components: {
    SegmentQueryItem,
  },
  props: {
    query: Object,
    removableItems: {
      type: Boolean,
      default: true,
    },
  },
  expose: ['disableEdit'],
  emits: ['update:query', 'removed-key'],
  data() {
    return {
      q: this.query,
    };
  },
  watch: {
    query(query) {
      this.load(query);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    isLast,
    isEditableQueryKey(key) {
      return !!editableQueryKeys.find((eqKey) => {
        const startingWildcard = eqKey.split('*');
        if (startingWildcard.length > 1) return key.startsWith(startingWildcard[0]);
        return eqKey === key;
      });
    },
    disableEdit() {
      Object.keys(this.q).forEach((key, i) => {
        const ref = `segmentQueryItem-${key}-${i}`;
        if (this.$refs[ref]?.[0]) this.$refs[ref][0].disableEdit();
      });
    },
    edit({ key, value } = {}) {
      // TODO: Recursive support for deeper than first-level [ch2852 & ch4552]
      this.q = { ...this.q, [key]: value };
      this.$emit('update:query', this.q);
    },
    remove(key) {
      const newQuery = { ...this.q };
      delete newQuery[key];
      this.q = newQuery;
      this.$emit('removed-key', key);
      this.$emit('update:query', this.q);
    },
    convert({ tag, path, depth }) {
      // TODO: Recursive support for deeper than first-level [ch2852]
      const firstDot = path.indexOf('.');
      const firstLevel = firstDot > -1 ? path.slice(0, firstDot) : tag;
      if (firstLevel === 'or') { // ? Convert to AND
        if (depth > 1) return;

        const newQAnd = this.q.or.reduce((ac, ob) => ({ ...ac, ...ob }), {}); // this.q[Object.keys(this.q)[0]]
        const newQ = pickBy(this.q, (v, key) => key !== 'or');
        this.q = { ...newQ, ...newQAnd };
      } else { // ? Convert to OR
        if (Object.keys(this.q).length <= 1) return; // To create an or, at least 2 keys needs to exist in the query
        if (depth >= 1) return;

        const newQOr = { or: [] };
        Object.entries(this.q).forEach(([k, v]) => {
          newQOr.or.push({ [k]: v });
        });
        this.q = newQOr;
      }
      this.$emit('update:query', this.q);
    },
    load(query = this.query) {
      this.q = query;
    },
  },
};
</script>
