<template>
  <tfoot>
    <tr>
      <td>
        <span>{{ $pgettext('Label — Table total row', 'Total') }}</span>
      </td>
      <td
        v-for="(column, index) in columns"
        :key="index"
        :class="cellClasses(column)"
      >
        <cnps-bar
          v-if="column.value === 'cnpsBar' && !hideTotals"
          :stats="cnpsStats"
        />
        <table
          v-else-if="column.value === 'benchmarks'"
          class="small-text tc-table tc-table-slim-jim tc-table-centeralign tc-table-pipe tc-table-borderless-outer"
        >
          <tbody>
            <tr>
              <template v-for="(col, key) in benchmarkCols">
                <td
                  v-if="col"
                  :key="col.id"
                  v-tooltip="{ content: col.title, container: 'body' }"
                  :class="benchmarkClasses"
                >
                  <i :class="col.classes" /><br>
                  <i
                    v-if="col.loading"
                    class="zmdi zmdi-spinner zmdi-hc-spin"
                  />
                  <span v-else>
                    <template v-if="benchmarks[0] && typeof benchmarks[0][key] === 'object'">
                      <span
                        v-for="(tuple, tupkey) in benchmarkTotalsTups[key]"
                        :key="tuple.id"
                        class="cell-tuple"
                        :class="tupleClasses(key)"
                      >
                        <b>{{ translateTerm(tupkey) }}</b><br>
                        {{ roundNumber(tuple) }}%
                      </span>
                    </template>
                    <template v-else>
                      {{ optionalPrefix(benchmarkTotals[key]) }}
                    </template>
                    <small v-if="benchmarkTotals[key] == null || Number.isNaN(benchmarkTotals[key])">
                      {{ noDataText }}
                    </small>
                  </span>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
        <template v-else>
          <span
            v-if="!hideTotals"
            v-tooltip.top="{ content: () => totalTooltip(column), container: 'body' }"
            class="cursor-help"
          >
            <i
              v-if="column.value === 'cnps' && benchmarkCols.customer.loading && segmentShowsAllData"
              class="zmdi zmdi-spinner zmdi-hc-spin"
            />
            <template v-else>{{ stat(column.value, column.showPercentages) }}</template>
          </span>
        </template>
      </td>
    </tr>
    <tr class="show-if-scrollbar">
      <td />
      <td
        v-for="column in columns"
        :key="column.id"
        :class="cellClasses(column)"
      />
    </tr>
  </tfoot>
</template>

<script>
import { mapGetters } from 'vuex';
import { meanBy, sumBy } from 'lodash-es';
import { prefixNPSValue, translateTerm, hydrateMissingStatsKeys, roundNumber, objectMap, globalTuplets } from 'Utils/general';
import { processStats } from 'Utils/stat';
import CnpsBar from 'Components/parts/graph/CNPSBar';

const NO_VALUE = '–';

export default {
  components: {
    CnpsBar,
  },
  props: {
    hideTotals: {
      type: Boolean,
      default: false,
    },
    rows: Array,
    show: Object,
    columns: Array,
    question: Object,
    benchmarks: Array,
    benchmarkCols: Object,
    reverseStats: Boolean,
    totalCount: Array,
    segmentTotal: Object,
    totalRowAverage: Object,
    benchmarkCompanyTotal: Object,
    benchmarkGlobalTotal: Object,
    totalDistribution: Object,
  },
  computed: {
    ...mapGetters(['segmentShowsAllData']),
    noDataText() { return this.$gettext('Ingen data'); },
    hydratedBens() {
      // ? For filling out keys that are not received from request by presuming their value
      if (!this.benchmarks) return [];
      return this.benchmarks.map((bench) => objectMap(bench, (ben) => {
        if (ben !== null && typeof ben === 'object') return hydrateMissingStatsKeys(ben);
        return ben;
      }));
    },
    benchmarkTotals() {
      return {
        customer: this.benchmarkCompanyTotal.average,
        global: this.benchmarkGlobalTotal.average,
      };
    },
    benchmarkTotalsTups() {
      if (this.question.question_type === 'yesno') {
      // benchmarkTotals.customer and benchmarkTotals.global holds the average score
        return {
          customer: globalTuplets(this.benchmarkTotals.customer, this.reverseStats),
          global: globalTuplets(this.benchmarkTotals.global, this.reverseStats),
        };
      }
      // ? Too tired right now.. Do we even need
      if (!this.hydratedBens || !this.benchmarkCols) return [];

      const totalCountSum = this.benchmarkCompanyTotal.count;

      // ? This never fires anymore. Remove it?
      if (this.hydratedBens[0] && typeof this.hydratedBens[0].global === 'object') { // ?if yesno or list-/listmany question type
        const weightedTuplets = JSON.parse(JSON.stringify(this.hydratedBens) ?? '[]')
          .map((benchMarkTuplet, i) => {
            if (benchMarkTuplet?.customer) {
              Object.keys(benchMarkTuplet.customer).forEach(((yesno) => {
                benchMarkTuplet.customer[yesno] *= this.totalCount[i];
              }));
            }
            return benchMarkTuplet;
          });

        return Object.keys(this.benchmarkCols)
          .map((key) => key)
          .filter((key) => this.hydratedBens[0]?.[key] != null)
          .reduce((acc, ben) => ({
            ...acc,
            [ben]: {
              ...Object.keys(this.hydratedBens[0][ben])
                .filter((key) => this.showTuple(key)) // show only visible cols
                // eslint-disable
                .reduce((acc2, key) => (ben === 'global'
                  ? ({ ...acc2, [key]: roundNumber(meanBy(this.hydratedBens, `${ben}.${key}`), 1) })
                  : ({ ...acc2, [key]: roundNumber(sumBy(weightedTuplets, `${ben}.${key}`) / totalCountSum, 1) })), {}),
            },
            // eslint-enable
          }), {});
      }
      // ? This object doesn't seem to be used anywhere either. What needs to be returned when not a yesno q
      const weightedBenchMark = this.hydratedBens.map((v, i) => ({
        customer: Number.isFinite(this.totalCount[i]) ? v.customer * this.totalCount[i] : 0,
        global: Number.isFinite(this.totalCount[i]) ? v.global * this.totalCount[i] : 0,
      }));
      return Object.keys(this.benchmarkCols)
        .map((key) => key) //! refactor remove unnecessary
        .filter((key) => this.hydratedBens[0] && this.hydratedBens[0][key] != null)
        .reduce((acc, b) => (b === 'global'
          ? ({ ...acc, [b]: roundNumber(meanBy(this.hydratedBens, b), 1) })
          : ({ ...acc, [b]: roundNumber(sumBy(weightedBenchMark, b) / totalCountSum, 1) }) // ? customer total is weighted
        ), {});
    },
    benchmarkClasses() {
      switch (Object.keys(this.benchmarkTotalsTups).length) {
        case 2: return 'col-xs-6';
        case 3: return 'col-xs-4';
        case 4: return 'col-xs-3';
        default: return 'col-xs-6';
      }
    },
    tupleAmount() {
      return this.benchmarkTotalsTups
        && Object.values(this.benchmarkTotalsTups)[1] // used to be 0, the company tuplets. Safer with global tuplets for styling
        && Object.values(Object.values(this.benchmarkTotalsTups)[1]).length > 0
        ? Object.values(Object.values(this.benchmarkTotalsTups)[1]).length
        : Object.values(Object.values(this.benchmarkTotalsTups)[0]).length;
    },

    cnpsStats() {
      if (this.rows === undefined) return {};
      if (!this.show?.columns.cnps) return {};
      // eslint-disable-next-line max-len
      return Object.keys(this.totalDistribution).reduce((acc, type) => ({ ...acc, [type]: this.totalDistribution[type].count }), { detractor: 0, passive: 0, promoter: 0 });
    },
    stats() {
      if (this.rows === undefined) return {};
      let stats = {};
      let count = 0;
      this.rows.forEach((row) => {
        if (row.stats?.stats) {
          Object.entries(row.stats.stats).forEach(([key, value]) => {
            if (stats[key] === undefined) stats[key] = 0;
            stats[key] += value;
          });
          count = row.stats.count || 0;
        }
      });
      if (this.question.question_type === 'yesno' && this.show?.percentages) {
        // totalRowAverage kommer direkt från QueryResultTable som i sin tur hämtar direkt från API-response.
        stats = this.totalRowAverage;
      }
      return processStats()({ stats, count });
    },
  },
  methods: {
    translateTerm,
    roundNumber,
    tupleAmountTotalRow(key) {
      return this.benchmarkTotalsTups
        && Object.values(this.benchmarkTotalsTups[key]) // used to be 0, the company tuplets. Safer with global tuplets for styling
        && Object.values(Object.values(this.benchmarkTotalsTups[key])).length;
    },
    tupleClasses(key) {
      switch (this.tupleAmountTotalRow(key)) {
        case 1: return 'col-xs-12';
        case 2: return 'col-xs-6';
        case 3: return 'col-xs-4';
        case 4: return 'col-xs-3';
        default: return 'col-xs-12';
      }
    },
    optionalPrefix(val) {
      if (val === undefined || val === null) return val;
      return this.show?.columns.cnps ? prefixNPSValue(roundNumber(val)) : roundNumber(val, 1, true);
    },
    showTuple(tupkey) {
      return this.columns.find(({ value }) => tupkey === value); // show only visible cols
    },
    totalTooltip(column) {
      if (column.showPercentages
        || column.value === 'cnps'
        || column.value === 'average') {
        return this.$pgettext('Tooltip - Average', 'Totala genomsnittet');
      }
      return this.$pgettext('Tooltip - Total Sum', 'Total summa unika respondenter');
    },
    cellClasses(column) {
      return {
        'tc-cnps-cell': column.value === 'cnps',
        'tc-cnps-bar-cell': column.value === 'cnpsBar',
        'tc-benchmarks-cell': column.value === 'benchmarks',
        'tc-benchmarks-cell-large': column.value === 'benchmarks' && this.benchmarkTotalsTups && this.tupleAmount > 1,
        'tc-table-number-cell': column.numberCell,
      };
    },
    stat(key, percentages = false) {
      if (key === 'cnps') return prefixNPSValue(roundNumber(this.segmentTotal.average * 100, 0));
      if (key === 'average') return this.segmentTotal.average ? roundNumber(this.segmentTotal.average, 1, true) : NO_VALUE;
      if (key === 'count') return this.segmentTotal.count;
      if (this.question.question_type === 'listmany' || this.question.question_type === 'list') {
        if (!this.totalDistribution[key]) return NO_VALUE;
        return percentages ? `${roundNumber((this.totalDistribution[key].count / this.segmentTotal.count * 100), 1, true)}%` : this.totalDistribution[key].count;
      }
      const location = this.stats.displayValues;
      if (location) {
        if (percentages && location.percentages[key]) return location.percentages[key];
        if (location[key]) return location[key];
      }
      return NO_VALUE;
    },
  },
};
</script>
