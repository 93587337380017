<template>
  <tc-select
    v-model="segment"
    :multiselect="true"
    :disabled="disabled"
    :on-change="onChange"
    :placeholder="placeholder || $pgettext('Placeholder — New segment options select', 'Välj segment')"
    :no-options-text="$gettext('Inga fler segment finns')"
    @update:model-value="update"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { markLabel, labelHit } from 'Utils/tcselectHelpers';

export default {
  name: 'SegmentFilter',
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:filter', 'update:modelValue'],
  data() {
    return {
      segment: [],
    };
  },
  computed: {
    ...mapGetters(['segmentOptions']),
  },
  watch: {
    modelValue(newVal, oldVal) {
      if (newVal !== oldVal) this.setup(newVal);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$nextTick(() => this.setup(this.modelValue));
    });
  },
  methods: {
    update(segments) {
      this.$emit('update:modelValue', segments.map((seg) => seg.value));
      this.$emit('update:filter', segments);
    },
    onChange(term = '') {
      return Promise.resolve(
        this.segmentOptions()
          .filter(labelHit(term))
          .map(markLabel(term)),
      );
    },
    setup(filter = this.modelValue) {
      const filterIds = filter.map((entry) => entry?.value || entry);
      this.segment = this.segmentOptions().filter((segment) => filterIds.includes(segment.value));
    },
  },
};
</script>
