<template>
  <div>
    <div class="page-content-title">
      <span class="h2">
        <i class="zmdi zmdi-accounts-alt" />
      </span>
      <span>
        <h2>{{ $pgettext('Sidebar button', 'Hantera användare') }}</h2>
        <p class="subtle-text my-0 mr-1">
          {{ $gettext('Se en lista över medlemmar i detta segment eller ge åtkomst för enbart denna delen.') }}
        </p>
      </span>
    </div>
    <card v-if="isAdmin || segmentCanEdit">
      <template #header>
        <nav class="tc-card-navigation p-card">
          <ul class="tc-card-navigation-list">
            <li
              v-if="isAdmin || segmentCanEdit"
              class="tc-card-navigation-list-item"
            >
              <router-link
                :to="{ name: 'segmentSettingsMembers' }"
                class="h3"
              >
                <span>{{ $gettext('Medlemmar') }}</span>
              </router-link>
            </li>
            <li
              v-if="isAdmin || segmentCanEdit"
              class="tc-card-navigation-list-item"
            >
              <router-link
                :to="{ name: 'segmentSettingsInvite' }"
                class="h3"
              >
                <span>{{ $gettext('Bjud in') }}</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </template>
      <template #body>
        <router-view />
      </template>
    </card>
    <span
      v-else
      data-testid="no-access"
    >
      {{ $gettext('Du har inte behörighet att se denna sida.') }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Card from 'Components/parts/Card';

const store = useStore();
const segmentCanEdit = computed(() => store.getters.segmentCanEdit);
const isAdmin = computed(() => store.getters.role === 'admin');
</script>
