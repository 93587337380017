<template>
  <div>
    <div
      v-tc-loader-pill="loading"
      class="tc-loader-pill-wrapper"
    >
      <div class="tc-list-group-header">
        <div class="align-center flex-row">
          <div class="col-sm-6 col-xs-12">
            <h3 class="h4">
              Segment
            </h3>
          </div>
          <div class="col-sm-6 col-xs-12 text-right">
            <button
              class="btn"
              :aria-disabled="loading || null"
              @click.prevent.stop="addSegment"
            >
              <i class="zmdi zmdi-plus" />
              <span>{{ translations.newSegment }}</span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <div class="form-group mb-0">
              <label
                for="showRelated"
              >{{ $gettext('Visa alla relaterade') }}</label>
              <div class="form-group mb-0">
                <chip-set
                  v-model="showRelated"
                  :outline="true"
                  type="filter"
                >
                  <chip
                    v-for="related in showRelatedOptions"
                    :key="related.value"
                    :value="related.value"
                  >
                    {{ related.label }}
                  </chip>
                </chip-set>
              </div>
            </div>
          </div>
          <div class="col-xs-6 mt-2">
            <tc-select
              v-model="ordering"
              :options="orderingOptions"
            />
          </div>
        </div>
      </div>
      <ul class="segment-list-update tc-list-group">
        <template v-if="segments && segments.length !== 0">
          <segment-list-update-single
            v-for="segment in segments"
            :key="segment.id"
            :initial-segment="segment"
            :show-related="showRelated"
            @removed-segment="removedSegment"
          />
        </template>
        <li
          v-else-if="loading"
          class="tc-list-group-item"
        >
          <segment-list-update-single
            v-for="placeholder in [1, 2, 3]"
            :key="placeholder.id"
            :initial-segment="{}"
            :show-related="showRelated"
          />
        </li>
        <li
          v-else
          class="tc-list-group-item"
        >
          <h3>
            {{ $gettext('Just nu finns det inga segment som du får lov att ändra') }}
          </h3>
        </li>
      </ul>
      <pagination
        ref="pagination"
        v-model:count="count"
        v-model:page="segments"
        :page-size="pageSize"
        :ordering="ordering[0].value"
        :url="url"
        :search-query="term"
        @loading="paginatorLoading"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChipSet from 'Components/parts/chips/ChipSet';
import Chip from 'Components/parts/chips/Chip';
import Pagination from 'Components/parts/Pagination';
import { openPane, closePane } from 'Components/parts/pane/Pane';
import PaneSettingsSegment from 'Components/parts/pane/PaneSettingsSegment';
import SegmentListUpdateSingle from './SegmentListUpdateSingle';

export default {
  components: {
    ChipSet,
    Chip,
    Pagination,
    SegmentListUpdateSingle,
  },
  props: {
    term: {
      type: String,
      default: '',
    },
  },
  emits: ['update:loadChange'],
  data() {
    return {
      loading: false,
      count: null,
      pageSize: 20,
      segments: [],
      ordering: [{
        label: this.$gettext('Bokstavsordning (A–Ö)'),
        value: 'name',
      }],
      orderingOptions: [{
        label: this.$gettext('Bokstavsordning (A–Ö)'),
        value: 'name',
      }, {
        label: this.$gettext('Bokstavsordning (Ö–A)'),
        value: '-name',
      }, {
        label: this.$gettext('Nyast först'),
        value: '-id',
      }, {
        label: this.$gettext('Äldst först'),
        value: 'id',
      }],
      showRelated: [],
      showRelatedOptions: [{
        value: 'boards',
        label: this.$pgettext('Label — boards in segment manager', 'Rapporter'),
      }, {
        value: 'users',
        label: this.$pgettext('Label — users in segment manager', 'Användare'),
      }],
    };
  },
  computed: {
    ...mapGetters([
      'customerId',
    ]),
    translations() {
      return {
        newSegment: this.$pgettext('Label - Button in segment manager', 'Nytt segment'),
      };
    },
    url() { return `/feedback/api/segment/?customer=${this.customerId}&can_edit=1`; },
  },
  methods: {
    ...mapActions([
      'setLastSearchState',
    ]),
    paginatorLoading(status) {
      this.loading = status;
      this.$emit('update:loadChange', status);
    },
    createdSegment() {
      this.setLastSearchState?.({});
      return this.$refs.pagination.refresh(1);
    },
    removedSegment(id) {
      this.setLastSearchState?.({});
      return this.$refs.pagination.refresh(1);
    },
    addSegment() {
      if (!this.loading) {
        closePane();
        openPane({
          context: {
            segment: {
              id: 0,
              name: '',
              query: {},
              tags: [],
            },
            onCreated: () => {
              this.createdSegment();
            },
          },
          component: PaneSettingsSegment,
        });
      }
    },
  },
};
</script>
