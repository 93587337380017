<template>
  <div class="select-filter-type-value">
    <filter-select
      v-if="filterType === 'tags' || filterType === 'benchmark'"
      ref="filterSelectTags"
      v-model="chosenValues"
      :label="label"
      :change="tagValueOptions"
      :populate-store-fn="populateStoreFn"
      :loading-store-promise="fetchingTagValues"
      :search-is-separate-req="isUsingSearch"
      :disabled="disabled"
      :placeholder="valuesPlaceholder"
      @update:model-value="updateValue"
    />

    <answer-filter
      v-if="filterType === 'answers'"
      ref="filterTypeValuesAnswers"
      v-model="chosenValues"
      :disabled="disabled"
      :question-id="filterTypeKey"
      :placeholder="valuesPlaceholder"
      :label="false"
      use-question-base-values
      @update:model-value="updateValue"
    />

    <proxy-filter
      v-if="filterType === 'customerProxies'"
      ref="filterTypeValuesProxies"
      v-model="chosenValues"
      :disabled="disabled"
      @update:filter="updateValue"
    />

    <segment-filter
      v-if="filterType === 'segment'"
      ref="filterTypeValuesSegment"
      v-model="chosenValues"
      :disabled="disabled"
      @update:model-value="updateValue"
    />

    <date-filter
      v-if="filterType === 'date'"
      ref="filterTypeValuesDate"
      :date-object="chosenValue"
      :disabled="disabled"
      :empty-allowed="true"
      @update:date-object="updateValue"
    />

    <topic-filter
      v-if="filterType === 'topics'"
      ref="filterTypeValuesTopic"
      v-model="chosenValues"
      :disabled="disabled"
      @update:model-value="updateValue"
    />

    <level-filter
      v-if="filterType === 'level'"
      ref="filterTypeValuesLevel"
      v-model="chosenValues"
      :disabled="disabled"
      @update:filter="updateValue"
    />
  </div>
</template>

<script>
import { isArray, isEmpty } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import tagValuesAPI from 'API/tagValues';
import { wrapValue, labelHit, markLabel } from 'Utils/tcselectHelpers';
import AnswerFilter from 'Components/parts/filters/AnswerFilter';
import ProxyFilter from 'Components/parts/filters/ProxyFilter';
import SegmentFilter from 'Components/parts/filters/SegmentFilter';
import DateFilter from 'Components/parts/filters/DateFilter';
import TopicFilter from 'Components/parts/filters/TopicFilter';
import LevelFilter from 'Components/parts/filters/LevelFilter';
import FilterSelect from './FilterSelect';

export default {
  name: 'SelectFilterTypeValue',
  components: {
    FilterSelect,
    AnswerFilter,
    ProxyFilter,
    SegmentFilter,
    DateFilter,
    TopicFilter,
    LevelFilter,
  },
  props: {
    modelValue: Array,
    filterType: {
      type: String,
      required: true,
    },
    filterTypeKey: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    customer: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: true,
    },
    returnUnwrappedValues: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      chosenValues: [],
      chosenValue: {},
      isUsingSearch: false,
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'customerId',
      'segmentTagValuesOptions',
      'customerTagValuesOptions',
      'allBenchmarkSectorOptions',
      'allBenchmarkSizeOptions',
      'allBenchmarkIndustryOptions',
      'allBenchmarkLocationOptions',
      'fetchingTagValues',
    ]),
    tagValueOptions() {
      if (this.filterType === 'tags') {
        return (term) => {
          if (term?.length > 0) {
            this.isUsingSearch = true;
            return this.searchTagValues(term, {
              segment: !this.customer ? this.segmentId : null,
              customer: this.customer ? this.customerId : null,
            });
          }
          this.isUsingSearch = false;
          return this.customer
            ? this.customerTagValuesOptions(this.filterTypeKey, term)
            : this.segmentTagValuesOptions(this.filterTypeKey, term);
        };
      }
      if (this.filterType === 'benchmark') {
        switch (this.filterTypeKey) {
          case 'sector': return this.allBenchmarkSectorOptions;
          case 'size': return this.allBenchmarkSizeOptions;
          case 'industry': return this.allBenchmarkIndustryOptions;
          case 'location': return this.allBenchmarkLocationOptions;
          default: return [];
        }
      }
      return [];
    },
    valuesPlaceholder() {
      const key = this.filterType === 'answers'
        ? this.$npgettext('Placeholder — Select filterTypeValues key answers', 'fråga', 'svar', !this.filterTypeKey)
        : this.$npgettext('Placeholder — Select filterTypeValues key tags', 'tag', 'tags', !this.filterTypeKey);
      return this.disabled
        ? this.$gettextInterpolate(
          this.$npgettext(
            'Placeholder — Select filterTypeValues disabled',
            'Välj en %{key} först',
            'Välj en %{key} först',
            this.filterType === 'answers' ? 1 : 2, // an/a
          ),
          { key },
        )
        : this.$gettextInterpolate(
          this.$pgettext('Placeholder — Select filterTypeValues enabled', 'Välj %{key}'),
          { key },
        );
    },
  },
  watch: {
    filterType(newVal, oldVal) {
      if (newVal !== oldVal) this.clear();
    },
    filterTypeKey(newVal, oldVal) {
      if (this.filterType === 'tags') this.fetchTagValues({ tagKey: newVal, customer: this.customer });
      if (this.filterType === 'answers' && newVal !== '') this.fetchAnswerValues({ answerKeys: [newVal] });

      if (newVal !== oldVal) this.clear();
    },
    modelValue: {
      handler(newChipset, oldChipset) {
        if ((isArray(newChipset) && newChipset.length === 0) || isEmpty(newChipset[newChipset.length - 1])) return false; // eslint-disable-line max-len
        if (!this.multiselect) {
          this.chosenValue = newChipset[newChipset.length - 1];
        }
        if (newChipset.length !== oldChipset.length) {
          if (newChipset.every((v) => typeof v === 'string')) this.chosenValues = newChipset;
          if (newChipset.some((v) => typeof v === 'object')) {
            let mixed = {
              objects: newChipset.filter((v) => typeof v === 'object'),
              strings: newChipset.filter((v) => typeof v === 'string'),
            };
            if (mixed.strings.length > mixed.objects.length) {
              this.chosenValues = mixed.strings;
            } else {
              this.chosenValues = mixed.objects;
            }
          }
          return false;
        }
        return false;
      },
      deep: true,
    },
  },
  mounted() {
    this.setup(this.modelValue);
  },
  methods: {
    ...mapActions([
      'fetchTagValues',
      'fetchAnswerValues',
      'fetchAllBenchmark',
      'setTagValues',
      // 'setAnswerValues',
    ]),
    searchTagValues(term, { customer = null, segment = null }) {
      return tagValuesAPI.list(this.filterTypeKey, {
        search: term, segment, customer, paginated: true,
      }).then(
        (data) => ({ ...data, results: data.results?.map(wrapValue).filter(labelHit(term)).map(markLabel(term)) }),
      );
    },
    populateStoreFn(valuesPaginator) {
      switch (this.filterType) {
        case 'tags':
          return this.setTagValues({ tagKey: this.filterTypeKey, tagValuesPaginator: valuesPaginator, customer: this.customer }); // eslint-disable-line max-len
          // case 'answers':
          //  return this.setAnswerValues({ answerKey: this.filterTypeKey, answerValuesPaginator: valuesPaginator });

        default:
          return () => {};
      }
    },
    clear() {
      this.chosenValues = [];
      this.chosenValue = {};
      if (this.$refs.filterSelectTags) this.$refs.filterSelectTags.clearPaginator();
    },
    updateValue(value) {
      if (!this.disabled) {
        if (this.multiselect) {
          const preppedValue = this.returnUnwrappedValues ? value.map((v) => v.value) : value;
          this.chosenValues = preppedValue;
          this.$emit('update:modelValue', preppedValue);
        } else {
          const preppedValue = this.returnUnwrappedValues ? value.value : value;
          this.chosenValue = preppedValue;
          this.$emit('update:modelValue', [preppedValue]); // rewrap into array
        }
      }
    },
    setup(value) {
      if (this.filterType === 'tags' && this.filterTypeKey) this.fetchTagValues({ tagKey: this.filterTypeKey, customer: this.customer });
      if (this.filterType === 'benchmark' && this.filterTypeKey) this.fetchAllBenchmark(this.filterTypeKey);
      if (this.filterType === 'answers' && this.filterTypeKey !== '') {
        this.fetchAnswerValues({ answerKeys: [Number(this.filterTypeKey)] });
      }

      if (this.multiselect) this.chosenValues = value;
      else this.chosenValue = value[0]; // unwrap from array
    },
  },
};
</script>
