import { store } from '@/store';
import gettext from '@/gettext';
import Confirm from './Confirm';
import Prompt from './Prompt';

const { $pgettext } = gettext;

export const dialogBox = {
  dialogs: [],
  current: null,
  isOpen: false,
  closePromise: Promise.resolve(),
  validProps: ['title', 'confirmText', 'dismissText', 'options', 'selectedOption', 'helperText', 'theme'],
  add(dialog) {
    this.dialogs.push(dialog);
    this.next();
  },
  next() {
    return this.closePromise.then(() => {
      if (this.isOpen) return;

      if (this.dialogs.length === 0) {
        store.dispatch('closeModal');
        return;
      }
      this.current = this.dialogs.shift();
      store.dispatch('openModal', {
        name: 'dialogBox',
        component: this.current.template,
        dataObject: {
          props: this.validProps
            .reduce((acc, prop) => {
              if (this.current.props[prop]) acc[prop] = this.current.props[prop];
              return acc;
            }, {}),
          on: { dismiss: (value) => dialogBox.close(true, value) },
        },
        position: 'center',
        callback: () => this.close(false, false),
      });
      this.isOpen = true;
    });
  },
  close(runCloseModal, value) {
    this.current.success(value);
    this.isOpen = false;
    if (runCloseModal) store.dispatch('closeModal');
    return this.closePromise;
  },
};

function add(props, template = Confirm) {
  return new Promise((success) => {
    dialogBox.add({ props, template, success });
  });
}

export function confirm({ title = null, confirmText = null, dismissText = null, theme = 'primary' } = {}) {
  return add({ title, dismissText, confirmText, theme }, Confirm);
}

export function prompt(title, props = {}, theme = 'primary') {
  return add({ title, theme, ...props }, Prompt);
}

export async function confirmLeaveUnsavedChanges() {
  const answer = confirm({
    title: $pgettext('Confirm — Leave board title', 'Lämna osparade ändringar?'),
    confirmText: $pgettext('Confirm — Leave board confirm', 'Ja, lämna utan att spara'),
    dismissText: $pgettext('Confirm — Leave board cancel', 'Stanna'),
    theme: 'danger',
  }).then((confirmed) => confirmed);
  return answer;
}
