/**
 * For caching of Customer benchmark options
 * components need to watch getters.*options
 */
import { isEmpty, isArray } from 'lodash-es';
import customerAPI from 'API/customer';
import { translateTerm, translateCountryCode, nameCase } from 'Utils/general';
import { markLabel, labelHit } from 'Utils/tcselectHelpers';
import { getSurveysInOrder } from 'Utils/survey';
import { vm } from '@/vm';
import gettext from '@/gettext';

const { $pgettext } = gettext;

const promisedArray = (term = '') => Promise.resolve([]);

function isWithinPlanDates(plan) {
  const { start_date, end_date } = plan;
  const today = new Date();
  const start = new Date(start_date);
  if (end_date) {
    const end = new Date(end_date);
    return today >= start && today <= end;
  }
  return today >= start;
}

const benchmarkOptionsPromise = (dimension, rootState, custId, useAllBenchmark = true) => async (term = '') => {
  const LANG = vm()?.$language?.current || 'en-us' || '';

  const yourCompanyText = $pgettext('Sidelabel — Your company', '(Ert företag har data här)');
  const dim = dimension === 'locations' ? 'countries' : dimension;
  const benchmarkArray = useAllBenchmark
    ? rootState.benchmarks.allBenchmark[dimension]
    : rootState.customer.customer[custId][dim];

  return Promise.resolve(benchmarkArray
    .map((dimValue) => {
      const val = dimValue.value || dimValue.shortcode || dimValue.name || dimValue;
      let customerBelongsToDimension = useAllBenchmark && rootState.customer.customer[custId][dim].includes(val);

      switch (dimension) {
        case 'sectors':
          return {
            value: val,
            label: translateTerm(val),
            ...(customerBelongsToDimension && { sidelabel: yourCompanyText }),
          };
        case 'sizes':
          return {
            value: val,
            label: nameCase(translateTerm(val.toLowerCase())),
            ...(customerBelongsToDimension && { sidelabel: yourCompanyText }),
            description: useAllBenchmark
              ? rootState.benchmarks.allBenchmark[dimension]?.find((v) => v.name === val).description
              : rootState.customer.customer[custId].sizes_description[val],
          };
        case 'industries':
          customerBelongsToDimension = rootState.customer.customer[custId][dimension].find((v) => v.name === val);
          return {
            value: val,
            label: dimValue.translations.find((element) => element.language === LANG).translation || val,
            ...(customerBelongsToDimension && { sidelabel: yourCompanyText }),
          };
        case 'locations':
          return {
            value: val,
            label: translateCountryCode(val),
            ...(customerBelongsToDimension && { sidelabel: yourCompanyText }),
          };
        default:
          return {
            value: val,
            label: translateTerm(val),
          };
      }
    })
    .sort((a) => !a?.sidelabel)
    .filter(labelHit(term))
    .map(markLabel(term)));
};

export default {
  state: {
    customer: {},
    fetchingCustomer: false,
  },
  mutations: {
    SET_CUSTOMER(state, { customer, customerId }) {
      state.customer[customerId] = customer;
    },
    SET_CUSTOMER_PLAN(state, { customerPlan, customerId }) {
      state.customer[customerId].plan = customerPlan;
    },
    SET_CUSTOMER_FETCH_STATE(state, fetchState) {
      state.fetchingCustomer = fetchState;
    },
  },
  getters: {
    currentCustomer: (state, getters) => state?.customer?.[getters.customerId] || {},
    customerInactiveSteps: (state, getters) => state?.customer?.[getters.customerId]?.inactive_steps || [],
    customerAllSteps: (state, getters) => getSurveysInOrder(state?.customer?.[getters.customerId]?.all_steps || []),
    customerAllStepsOptions: (state, getters) => getters.customerAllSteps.map((term) => ({
      label: translateTerm(term),
      value: term,
      ...(getters.customerInactiveSteps.includes(term) && { inactive: true }),
    })),
    customerUsedFeatures: (state, getters) => state?.customer?.[getters.customerId]?.used_features || {},
    customerSizes: (state, getters) => state?.customer?.[getters.customerId]?.sizes || [],
    customerIsFetching: (state) => state.fetchingCustomer,
    customerPlan: (state, getters) => state?.customer?.[getters.customerId]?.plan || {},
    customerPlanHasPaid: (state, { customerPlan }) => {
      if (customerPlan?.plan_type === undefined) return false;
      return ['standard', 'enterprise'].includes(customerPlan.plan_type) && isWithinPlanDates(customerPlan);
    },
    customerPlanHasActiveTrial: (state, { customerPlan }) => customerPlan?.plan_type === 'free_trial' && isWithinPlanDates(customerPlan),
    customerPlanCanStartFreeTrial: (state, { customerPlan }) => customerPlan?.used_free_trial === false,
    showPaywalls: (state, getters, rootState) => !(
      getters.customerPlanHasPaid || getters.customerPlanHasActiveTrial || rootState?.me?.is_staff
    ),

    customerSectorOptions: (state, getters, rootState) => (
      getters.customerIsFetching
        ? promisedArray
        : benchmarkOptionsPromise('sectors', rootState, getters.customerId, false)
    ),
    allBenchmarkSectorOptions: (state, getters, rootState) => (
      getters.customerIsFetching || getters.allBenchmarkIsFetching('sectors')
        ? promisedArray
        : benchmarkOptionsPromise('sectors', rootState, getters.customerId)
    ),

    customerSizeOptions: (state, getters, rootState) => (
      getters.customerIsFetching
        ? promisedArray
        : benchmarkOptionsPromise('sizes', rootState, getters.customerId, false)
    ),
    allBenchmarkSizeOptions: (state, getters, rootState) => (
      getters.customerIsFetching || getters.allBenchmarkIsFetching('sizes')
        ? promisedArray
        : benchmarkOptionsPromise('sizes', rootState, getters.customerId)
    ),

    customerIndustryOptions: (state, getters, rootState) => (
      getters.customerIsFetching
        ? promisedArray
        : benchmarkOptionsPromise('industries', rootState, getters.customerId, false)
    ),
    allBenchmarkIndustryOptions: (state, getters, rootState) => (
      getters.customerIsFetching || getters.allBenchmarkIsFetching('industries')
        ? promisedArray
        : benchmarkOptionsPromise('industries', rootState, getters.customerId)
    ),
    // customerIndustryList: (state, getters) => { // ? Unused
    //   const LANG = router?.app?.$language?.current || '';
    //   return state.customer?.[getters.customerId]?.industries
    //     .map((val) => {
    //       let value = val.name || val;
    //       let label = val.translations?.find((element) => element.language === LANG)?.translation || value;
    //       return ({ label, value });
    //     }) || [];
    // },

    customerLocationOptions: (state, { customerId }) => async (term) => {
      const mappedCountries = await Promise.all(state.customer[customerId].countries
        .map(async (countryCode) => ({ label: translateCountryCode(countryCode), value: countryCode })));
      return Promise.resolve(mappedCountries.filter(labelHit(term)).map(markLabel(term)));
    },
    allBenchmarkLocationOptions: (state, getters, rootState) => (
      getters.customerIsFetching || getters.allBenchmarkIsFetching('locations')
        ? promisedArray
        : benchmarkOptionsPromise('locations', rootState, getters.customerId)
    ),
  },
  actions: {
    /**
     ** setCustomerPlanTo
     * @param {object} vuex - arguments from vuex
     * @param {object} args - arguments for store fn
     * @param {number} args.customerId
     * @param {object} args.customerPlan - object with plan info
     * @param {('free'|'free_trial'|'standard'|'enterprise')} args.customerPlan.plan_type - customers plan name
     * @param {Date} args.customerPlan.start_date - 'YYYY-MM-DD'
     * @param {Date} args.customerPlan.end_date - 'YYYY-MM-DD'
     * @param {boolean} args.customerPlan.used_free_trial - if customer has started free_trial or not
     * @returns {boolean} true if customerPlan was correct syntax and set
    */
    setCustomerPlanTo({ commit, getters }, { customerId, customerPlan } = {}) {
      const custId = customerId ?? getters.customerId;
      if (customerPlan.plan_type && typeof customerPlan.used_free_trial === 'boolean') {
        commit('SET_CUSTOMER_PLAN', { customerId: custId, customerPlan });
        return true;
      }
      return false;
    },
    setCustomerFromSegment({ commit, getters }, customer) {
      // ? Customer gotten from Segment endpoint doesn't have all fields (benchmark options etc.)
      const custId = customer.id ?? getters.customerId;
      commit('SET_CUSTOMER', { customer, customerId: custId });
    },
    fetchCustomer({ state, commit, getters }, customerId) {
      const custId = customerId ?? getters.customerId;
      if (
        (isArray(state.customer?.[custId]?.sizes) && !isEmpty(state.customer?.[custId] || {}))
        || state.fetchingCustomer
      ) return state.customer[custId]; // ? memoization returns cache
      commit('SET_CUSTOMER_FETCH_STATE', true);
      return customerAPI.get(custId)
        .then((customer) => {
          commit('SET_CUSTOMER', { customer, customerId: custId });
          commit('SET_CUSTOMER_FETCH_STATE', false);
          return customer;
        });
    },
  },
};
