<template>
  <li
    class="tc-list-group-item"
    :class="{'tc-list-group-divider': isLast}"
  >
    <div class="item-row">
      <p
        class="item-col my-1"
        data-private
      >
        {{ invite.email }}
        <span
          v-if="invite.expired"
          class="badge mx-1"
        >{{ $gettext('Inbjudan utgången') }}</span>
        <span
          v-else
          class="badge mx-1"
        >{{ $gettext('Väntande inbjudan') }}</span>
      </p>
      <div
        v-if="role === 'admin'"
        class="chip-set chip-set-outline chip-set-sm"
      >
        <chip
          icon="zmdi-block"
          :disabled="disabled"
          @click="remove"
        >
          {{ revokeText }}
        </chip>
        <chip
          icon="zmdi-mail-send pr-0"
          @click="resend"
        >
          {{ resendText }}
        </chip>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { create as ciCreate, remove as ciRemove } from 'API/customerUserInvitation';
import { create as siCreate, remove as siRemove } from 'API/segmentUserInvitation';
import Chip from 'Components/parts/chips/Chip';

export default {
  components: {
    Chip,
  },
  props: {
    invite: Object,
    type: String,
    isLast: {
      type: Boolean,
      default: false,
    },
    singleSegmentId: {
      required: false,
      type: Number,
    },
  },
  emits: ['removed', 'updated', 'sent'],
  data() {
    return {
      api: {},
      disabled: false,
      scopeId: false,
    };
  },
  computed: {
    ...mapGetters([
      'role',
      'segmentId',
      'customerId',
    ]),
    revokeText() {
      return this.disabled ? this.$gettext('Tar bort...') : this.$gettext('Återkalla');
    },
    resendText() {
      return this.invite.expired ? this.$gettext('Förnya') : this.$gettext('Skicka igen');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.load();
    });
  },
  methods: {
    ...mapActions([
      'notify',
    ]),
    remove() {
      this.disabled = true;
      return this.api.remove(this.invite.id)
        .catch((error) => this.notify({
          text: this.$gettext('Fel vid borttagning av inbjudan.'),
          level: 'warning',
        }))
        .then(() => {
          this.$emit('removed');
          this.$emit('updated');
        });
    },
    resend() {
      this.remove()
        .then(() => {
          this.api.create(this.scopeId, this.invite.email)
            .catch((error) => this.notify({
              text: this.$gettext('Fel vid återsändning av inbjudan.'),
              level: 'warning',
            }))
            .then(() => {
              this.$emit('sent');
            });
        })
        .then(() => {
          this.$emit('updated');
        });
    },
    load() {
      this.scopeId = this.type === 'segment' ? this.invite.segment : this.customerId;
      this.api = this.type === 'segment' ? { create: siCreate, remove: siRemove } : { create: ciCreate, remove: ciRemove };
    },
  },
};
</script>
