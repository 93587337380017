import StarterKit from '@tiptap/starter-kit';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Highlight from '@tiptap/extension-highlight';
import plugins from './plugins';

const extensions = [
  StarterKit.configure({
    heading: {
      levels: [1, 2, 3, 4],
    },
  }),
  TextStyle,
  Color,
  TaskList,
  TaskItem.configure({
    itemTypeName: 'taskItem',
    nested: true,
  }),
  Highlight.configure({ multicolor: true }),
  plugins.FocusHighlight,
];

export default {
  extensions,
};
