import { getBaseUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

function list(segmentId, { tiny = false, pageSize = 100, step = '', paginated = true, active } = {}) {
  const url = tiny ? '/shinyform/api/tinystepforms/' : '/shinyform/api/stepforms/';
  const query = {
    segment: segmentId,
    page_size: pageSize,
    ...(step ? { level: step } : {}),
    ...(active ? { active } : {}),
  };
  return getBaseUrl(url)
    .query(query)
    .then((response) => (paginated ? response.body : response.body.results), handleAuthError);
}

function get(level, segment, { tiny = false } = {}) {
  const url = tiny ? '/shinyform/api/tinystepforms/' : '/shinyform/api/stepforms/';
  return getBaseUrl(url)
    .query({ level, segment })
    .then((response) => response.body.results, handleAuthError);
}

function getForm(form, { tiny = false } = {}) {
  const url = tiny ? '/shinyform/api/tinystepforms/' : '/shinyform/api/stepforms/';
  return getBaseUrl(`${url}${form}/`)
    .then((response) => response.body, handleAuthError);
}

export default {
  list,
  get,
  getForm,
};
