import { isEmpty } from 'lodash-es';
import { processText, translateTerm, translateBaseValue } from 'Utils/general';
import { getStepNPSName } from 'Utils/constants';
import gettext from '@/gettext';

const { $pgettext } = gettext;

export function NPS_NAMES({ plurality = true } = {}) {
  const detractor = plurality ? $pgettext('Plural — CNPS category', 'Kritiker') : $pgettext('Singular — CNPS category', 'Kritiker');
  const passive = plurality ? $pgettext('Plural — CNPS category', 'Neutrala') : $pgettext('Singular — CNPS category', 'Neutral');
  const promoter = plurality ? $pgettext('Plural — CNPS category', 'Ambassadörer') : $pgettext('Singular — CNPS category', 'Ambassadör');
  return { promoter, passive, detractor };
}

export function getQuestionType(question) {
  return question?.options?.cnps ? getStepNPSName(question?.level) : question?.question_type || '';
}

export function getQuestionSidelabel(question) {
  return `${translateTerm(getQuestionType(question))}・${translateTerm(question.level)}`;
}

export const questionOption = (question, useSidelabel = false) => {
  if (question.error) {
    return {
      value: String(question.id),
      label: processText(question.error),
    };
  }
  return {
    value: String(question.id),
    label: processText(question.translation.question),
    ...(useSidelabel ? { sidelabel: getQuestionSidelabel(question) } : {}),
  };
};

export function baseValueOption(question) {
  return (value) => ({ value, label: translateBaseValue(value, question) });
}

export function questionTypesPreferredOrderSort(a, b) {
  const preferredOrder = [
    'cnps',
    'rating',
    'yesno',
    'list',
    'listmany',
    'text',
  ];
  const hasA = preferredOrder.includes(a);
  const hasB = preferredOrder.includes(b);
  if (hasA && !hasB) return -1;
  if (!hasA && hasB) return 1;
  if (hasA && hasB) return preferredOrder.indexOf(a) - preferredOrder.indexOf(b);
  return 0;
}

export function extractQuestionTypes(questions) {
  return questions
    ? Array.from(questions.reduce((acc, q) => (q?.question_type ? acc.add(getQuestionType(q)) : acc), new Set()))
      .sort(questionTypesPreferredOrderSort)
    : [];
}

export function questionTypesTooltip(question) {
  if (question === undefined || isEmpty(question)) return '';

  const tooltips = {
    nps: $pgettext('Tooltip — Question type filter', '"Net Promoter Score"-fråga där svaret är en gradering på skalan 0-10 | Kritiker: 0-6, Neutrala: 7-8, Ambassadörer: 9-10. NPS-värdet räknas sedan ut genom procentantal Ambassadörer minus procentantal Kritiker på en skala mellan -100 och +100'),
    enps: $pgettext('Tooltip — Question type filter', '"Employee Net Promoter Score"-fråga där svaret är en gradering på skalan 0-10 | Kritiker: 0-6, Neutrala: 7-8, Ambassadörer: 9-10. ENPS-värdet räknas sedan ut genom procentantal Ambassadörer minus procentantal Kritiker på en skala mellan -100 och +100'),
    cnps: $pgettext('Tooltip — Question type filter', '"Candidate Net Promoter Score"-fråga där svaret är en gradering på skalan 0-10 | Kritiker: 0-6, Neutrala: 7-8, Ambassadörer: 9-10. CNPS-värdet räknas sedan ut genom procentantal Ambassadörer minus procentantal Kritiker på en skala mellan -100 och +100'),
    rating: $pgettext('Tooltip — Question type filter', 'Frågor där svaret är en gradering på skalan 0-10'),
    yesno: $pgettext('Tooltip — Question type filter', 'Frågor som bara kan besvaras med ja eller nej'),
    list: $pgettext('Tooltip — Question type filter', 'Envalsfrågor, där en bara kan välja ett alternativ'),
    listmany: $pgettext('Tooltip — Question type filter', 'Flervalsfrågor, där en kan välja flera alternativ'),
    text: $pgettext('Tooltip — Question type filter', 'Frågor med fritextsvar'),
  };
  if (typeof question === 'string') return tooltips?.[question?.toLowerCase?.()] || ''; // ? If passed questionType string directly

  const questionType = getQuestionType(question);
  const helpText = (questionType?.translation?.options?.help_text && ` | ${questionType.translation.options.help_text}`) || '';
  return `${tooltips?.[questionType?.toLowerCase?.()]}${helpText}` || '';
}
