import {
  format as fnsFormat,
  formatDistance as fnsFormatDistance,
  formatDistanceToNowStrict as fnsFormatDistanceToNowStrict,
} from 'date-fns';
import { sv, enUS } from 'date-fns/locale';
import { vm } from '@/vm';

const dateFunctionsLocales = {
  'en-us': enUS,
  'sv-se': sv,
};

export const adjustForUTCOffset = (date) => new Date(
  date.getUTCFullYear(),
  date.getUTCMonth(),
  date.getUTCDate(),
  date.getUTCHours(),
  date.getUTCMinutes(),
  date.getUTCSeconds(),
);

/* Can be used as `new UTCDate(date)` */
export function UTCDate(date) {
  return adjustForUTCOffset(date ? new Date(date) : new Date());
}

export const nowUTC = new UTCDate();

export function format(date, syntax = 'yyyy-MM-dd — HH:mm', options = {}) {
  const localeString = vm()?.$language.current || 'en-us';
  const locale = dateFunctionsLocales[localeString] || dateFunctionsLocales['en-us'];
  const newOptions = { locale, ...options };
  return fnsFormat(date, syntax, newOptions);
}

export function formatDistance(date, baseDate = new Date(), options = {}) {
  const localeString = vm()?.$language.current || 'en-us';
  const locale = dateFunctionsLocales[localeString] || dateFunctionsLocales['en-us'];
  const newOptions = { locale, ...options };
  return fnsFormatDistance(date, baseDate, newOptions);
}

export function formatDistanceToNowStrict(baseDate = new Date(), options = {}) {
  const localeString = vm()?.$language.current || 'en-us';
  const locale = dateFunctionsLocales[localeString] || dateFunctionsLocales['en-us'];
  const newOptions = { locale, ...options };
  return fnsFormatDistanceToNowStrict(baseDate, newOptions);
}
