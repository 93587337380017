import { markRaw } from 'vue';

/**
 * @typedef PaneObject
 * @type {object}
 * @prop {string} name – unique name of pane/component
 * @prop {string} drilldownData – drilldownData of pane, filtering etc
 * @prop {function|VueComponent} component – a function/import that returns a Vue component
 * @prop {object} [dataObject] – options for the component
 * @prop {string} [dataObject.props] – passed props
 * @prop {string} [dataObject.on] – passed listeners
 * @prop {function} [callback] – callback function that is run after closing the pane, returns the popped dataObject
 */

export default {
  state: {
    /** @type {PaneObject[]|[]} */
    openPanes: [], // ? An array of open panes
  },
  getters: {
    activePane: (state) => (state.openPanes.length > 0 ? state.openPanes[state.openPanes.length - 1] : null),
    allOpenPanes: (state) => state.openPanes,
  },
  mutations: {
    OPEN_PANE: (state, payload) => {
      document.body.classList.add('is-drilling-down');
      // document.body.style.overflow = 'hidden';
      state.openPanes.push(markRaw(payload));
    },
    UPDATE_PANE: (state, payload) => { state.openPanes[state.openPanes.length - 1] = markRaw(payload); },
    REPLACE_PANE: (state, payload) => { state.openPanes = [markRaw(payload)]; }, // Replaces all open panes
    CLOSE_PANE: (state, callbackData) => {
      // document.body.style.overflow = '';
      const poppedPane = state.openPanes.pop();
      if (state.openPanes.length === 0) {
        document.body.classList.remove('is-drilling-down');
      }
      if (poppedPane?.callback && typeof poppedPane.callback === 'function') {
        poppedPane.callback({ ...poppedPane.dataObject, ...callbackData });
      }
    },
    CLOSE_ALL_PANES: (state) => {
      // document.body.style.overflow = '';

      state.openPanes = [];
      document.body.classList.remove('is-drilling-down');
    },
  },
  actions: {
    openPane: ({ commit }, payload) => commit('OPEN_PANE', payload),
    updatePane: ({ commit }, payload) => commit('UPDATE_PANE', payload),
    replacePane: ({ commit }, payload) => commit('REPLACE_PANE', payload),
    closePane: async ({ commit, state }, { callbackData = {} } = {}) => {
      const closedPane = commit('CLOSE_PANE', callbackData);
      // if (state.openPanes.length === 0) commit('RESET_PANE_STATE');
      return closedPane;
    },
    closeAllPanes: ({ commit }) => {
      // commit('RESET_PANE_STATE');
      commit('CLOSE_ALL_PANES');
    },
  },
};
