<template>
  <button
    v-tooltip.right="{ content: upgradeTooltip, container: 'body' }"
    tabindex="0"
    class="sidebar-plan"
    :aria-label="buttonText"
    @click="openUpgradeModal"
  >
    <!-- eslint-disable -->
    <svg width="24" height="26" fill="none" viewBox="0 0 24 26">
      <defs>
        <path id="svg-upgrade-icon-outer" fill-rule="evenodd" d="M12 22.68c-5.26 0-8.2 0-10.08-1.82C0 19.05 0 16.32 0 11.34c0-4.97 0-7.7 1.92-9.52C3.85 0 6.74 0 12 0s8.2 0 10.08 1.82C24 3.64 24 6.37 24 11.34c0 4.98 0 7.7-1.92 9.52-.91.86-2.1 1.34-3.63 1.56V26L12 22.68z" clip-rule="evenodd"/>
        <path id="svg-upgrade-icon-inner" d="M17.6 7.96L12 4 6.4 7.96a.93.93 0 00-.4.77v8.33a.95.95 0 00.5.83.91.91 0 00.95-.06L12 14.62l4.55 3.21a.91.91 0 001.32-.28.95.95 0 00.13-.49V8.73a.95.95 0 00-.4-.77zM12 5.7l4.62 3.26v2.74L12 8.45l-4.62 3.26V8.97L12 5.71zm0 7.2l-4.62 3.26v-2.74L12 10.16l4.62 3.27v2.74L12 12.9z"/>
      </defs>
      <use xlink:href="#svg-upgrade-icon-outer" fill="inherit"/>
      <use xlink:href="#svg-upgrade-icon-inner" fill="#006f6f" />
      <!-- Use <use /> for reusing this svg elsewhere, there one can set custom fills as well -->
    </svg>
    <!-- eslint-enable -->
    <span>{{ buttonText }}</span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
import ga from '@/plugins/googleAnalytics';
import { formatDistance } from 'Utils/dateHelpers';

export default {
  name: 'SidebarFooterPlan',
  computed: {
    ...mapGetters([
      'customerPlanHasActiveTrial',
      'customerPlanCanStartFreeTrial',
      'customerPlan',
    ]),
    upgradeTooltip() {
      if (this.customerPlanHasActiveTrial) {
        return this.$pgettext(
          'Button — Footer upgrade',
          'Provperioden slutar %{timeLeft}',
          { timeLeft: formatDistance(new Date(this.customerPlan.end_date), new Date(), { addSuffix: true }) },
        );
      }
      if (!this.customerPlanHasActiveTrial && !this.customerPlanCanStartFreeTrial) return this.$pgettext('Button — Footer upgrade', 'Upgradera din Trustcruit plan');
      if (!this.customerPlanHasActiveTrial && this.customerPlanCanStartFreeTrial) return this.$pgettext('Button — Footer upgrade', 'Prova gratis utan förbindelser');

      return '';
    },
    buttonText() {
      if (this.customerPlanCanStartFreeTrial) {
        return this.$pgettext('Button — Footer plan', 'Uppgradera i 14 dagar');
      }
      return this.$pgettext('Button — Footer plan', 'Uppgradera nu');
    },
  },
  methods: {
    openUpgradeModal() {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Dashboard',
        eventAction: 'open-upgrade-modal',
        eventLabel: `clicked ${this.buttonText} in SidebarFooterPlan.vue`,
      });
      this.$$eventBus.$emit('modal-open-upgrade', { canStartFreeTrial: this.customerPlanCanStartFreeTrial });
    },
  },
};
</script>
