/**
 * Components can mapState to watch state.tasks or state.[TASK_TYPE]Tasks
 */
import { isEmpty } from 'lodash-es';
import reportsAPI from 'API/reports';
import subscriptionsAPI from 'API/subscriptions';

// ? Task types for future support
const TASK_TYPE_REPORT = 'report';
const TASK_TYPE_SUBSCRIPTION = 'subscription';

export default {
  state: {
    // tasks: {}, // ? tasks are under segmentId key
    // fetchingTasks: false,
    reportTasks: {}, // ? tasks are under segmentId key
    fetchingReportTasks: false,
    subscriptionTasks: {}, // ? tasks are under segmentId key
    fetchingSubscriptionTasks: false,
  },
  mutations: {
    ADD_TASKS(state, { taskType, segmentId, tasks }) {
      switch (taskType) {
        case TASK_TYPE_REPORT:
          state.reportTasks[segmentId] = state.reportTasks[segmentId]
            ? [...Array.from(new Set(tasks, state.reportTasks[segmentId]))] // ? Removes duplicates
            : [...tasks];
          break;
        case TASK_TYPE_SUBSCRIPTION:
          state.subscriptionTasks[segmentId] = state.subscriptionTasks[segmentId]
            ? [...Array.from(new Set(tasks, state.subscriptionTasks[segmentId]))] // ? Removes duplicates
            : [...tasks];
          break;
        default:
          // state.tasks[segmentId] = SAME_AS_ABOVE;
          break;
      }
    },
    ADD_TASK(state, { taskType, segmentId, task }) {
      switch (taskType) {
        case TASK_TYPE_REPORT:
          state.reportTasks[segmentId] = state.reportTasks[segmentId]
            ? [...Array.from(new Set(state.reportTasks[segmentId], task))]
            : [task];
          break;
        case TASK_TYPE_SUBSCRIPTION:
          state.subscriptionTasks[segmentId] = state.subscriptionTasks[segmentId]
            ? [...Array.from(new Set(state.subscriptionTasks[segmentId], task))]
            : [task];
          break;
        default:
          // state.tasks[segmentId] = SAME_AS_ABOVE;
          break;
      }
    },
    SET_TASKS_FETCH_STATE(state, { taskType, fetchState }) {
      switch (taskType) {
        case TASK_TYPE_REPORT:
          state.fetchingReportTasks = fetchState;
          break;
        case TASK_TYPE_SUBSCRIPTION:
          state.fetchingSubscriptionTasks = fetchState;
          break;

        default:
          // state.fetchingTasks = fetchState;
          break;
      }
    },
  },
  getters: {
    reportTask: (state, getters) => (taskId) => state.reportTasks?.[getters.segmentId]
      ?.find(({ id }) => taskId === id) || {},
    subscriptionTask: (state, getters) => (taskId) => state.subscriptionTasks?.[getters.segmentId]
      ?.find(({ id }) => taskId === id) || {},
    subscriptionTasks: (state, getters) => state.subscriptionTasks?.[getters.segmentId] || [],
    reportTasks: (state, getters) => state.reportTasks?.[getters.segmentId] || [],
    fetchingReportTasks: (state) => state.fetchingReportTasks,
    fetchingSubscriptionTasks: (state) => state.fetchingSubscriptionTasks,
  },
  actions: {
    async fetchTask({ getters, commit }, { taskType, taskId }) {
      const segmentId = getters.segmentId;
      const isSubscriptionTask = taskType === 'subscription';
      const api = isSubscriptionTask ? subscriptionsAPI : reportsAPI;
      const memodTask = isSubscriptionTask ? getters.subscriptionTask(taskId) || {} : getters.reportTask(taskId) || {};
      const allTasks = isSubscriptionTask ? getters.subscriptionTasks : getters.reportTasks;

      if (!isEmpty(allTasks) && !isEmpty(memodTask)) return memodTask;// ? memoization returns cache
      commit('SET_TASKS_FETCH_STATE', { taskType, fetchState: true });
      try {
        const task = await api.get(taskId);
        commit('ADD_TASK', { segmentId, taskType, task });
        commit('SET_TASKS_FETCH_STATE', { taskType }, false);
        return task;
      } catch (err) {
        commit('SET_TASKS_FETCH_STATE', { taskType }, false);
        return Promise.reject(err);
      }
    },

    async fetchTasks({ commit }, { taskType, filter = {} }) {
      // const segmentId = getters.segmentId;
      // const customerId = getters.customerId;
      // TODO: ta reda på om bi vill ha alla (customerId) eller bara ett (valfritt) segmentId
      const api = taskType === 'subscription' ? subscriptionsAPI : reportsAPI;
      commit('SET_TASKS_FETCH_STATE', { taskType, fetchState: true });

      try {
        const paginatedTasks = await api.list({
          filter: {
            // segment: segmentId,
            // customer: customerId,
            page_size: 10,
            page: 1,
            ordering: '-id', // latest first
            ...filter,
          },
        });
        // commit('ADD_TASKS', { segmentId, taskType, tasks: paginatedTasks.results });
        commit('SET_TASKS_FETCH_STATE', { taskType }, false);
        return paginatedTasks;
      } catch (err) {
        commit('SET_TASKS_FETCH_STATE', { taskType }, false);
        return Promise.reject(err);
      }
    },

    async sendTask({ dispatch }, { taskType, task }) {
      const api = taskType === 'subscription' ? subscriptionsAPI : reportsAPI;

      try {
        const response = await api.create(task);
        await dispatch('fetchTasks', { taskType });
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async sendTasks({ dispatch }, { taskType, tasks }) {
      const api = taskType === 'subscription' ? subscriptionsAPI : reportsAPI;

      try {
        const response = await Promise.all(tasks.map(async (task) => api.create(task)));
        await dispatch('fetchTasks', { taskType });
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async updateTask({ dispatch }, { taskType, task, id }) {
      const api = taskType === 'subscription' ? subscriptionsAPI : null;

      if (api !== null) {
        try {
          const response = await api.update(id, task);
          await dispatch('fetchTasks', { taskType });
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }
      return Promise.reject(new Error('Provided API doesn’t support .update()'));
    },

    async removeTask({ dispatch }, { taskType, id }) {
      const api = taskType === 'subscription' ? subscriptionsAPI : null;

      if (api !== null) {
        try {
          const response = await api.remove(id);
          await dispatch('fetchTasks', { taskType });
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }
      return Promise.reject(new Error('Provided API doesn’t support .remove()'));
    },
  },
};
