<template>
  <filter-select
    v-model="selected"
    class="tc-select--wrap"
    :label="labelText.label"
    :aria-label="labelText.aria"
    :placeholder="placeholder"
    :searchable="searchable"
    :disabled="disabled"
    :multiselect="multiselect"
    :change="useQuestionBaseValues ? getQuestionBaseValues(questionId) : getAnswers(questionId)"
    :loading-store-promise="fetchingAnswerValues"
    @update:model-value="update"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from 'lodash-es';
import { translateBaseValue, processText } from 'Utils/general';
import { baseValueOption } from 'Utils/question';
import { markLabel, labelHit } from 'Utils/tcselectHelpers';
import FilterSelect from './FilterSelect';

export default {
  name: 'AnswerFilter',
  components: {
    FilterSelect,
  },
  props: {
    modelValue: Array,
    questionId: [Number, String],
    placeholder: String,
    label: {
      type: [Boolean, String],
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    useQuestionBaseValues: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      question: {},
      selected: [],
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'fetchingAnswerValues',
    ]),
    labelText() {
      if (this.label === true) {
        return {
          label: this.question.translation ? processText(this.question.translation.question) : '',
          aria: '',
        };
      }
      if (this.label === false) {
        return {
          label: '',
          aria: this.question.translation ? processText(this.question.translation.question) : '',
        };
      }
      return {
        label: this.label,
        aria: '',
      };
    },
  },
  watch: {
    questionId(id) {
      this.go(id, this.modelValue);
    },
    modelValue(selected) {
      this.go(this.questionId, selected);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.go(this.questionId, this.modelValue);
    });
  },
  methods: {
    ...mapActions([
      'getQuestionById',
      'fetchAnswerValues',
    ]),
    update(answers) {
      this.$emit('update:model-value', answers.map((answer) => answer.value));
    },
    async getQuestion(id) {
      const question = await this.getQuestionById(id);
      this.question = question;
      return question;
    },
    getQuestionBaseValues(questionId = this.questionId) {
      return async (term/* , options, current */) => {
        if (questionId === undefined || questionId === '') return Promise.resolve([]);
        const question = this.question || await this.getQuestion(questionId) || {};
        let list = [];
        if (question.question_type === undefined) list = [];
        else if (question.question_type === 'yesno') list = ['ja', 'nej'];
        else if (question.question_type === 'rating') list = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
        else list = question?.options?.base_values || [];
        return list
          .map(baseValueOption(question))
          .filter(labelHit(term))
          .map(markLabel(term));
      };
    },
    getAnswers(questionId = this.questionId) {
      return async (term/* , options, current */) => {
        if (questionId === undefined || questionId === '') return Promise.resolve([]);
        const question = this.question || await this.getQuestion(questionId) || {};
        return this.fetchAnswerValues({ answerKeys: [questionId], question })
          .then((r) => {
            if (!isEmpty(r) && r?.[questionId] !== 'undefined') {
              return r[questionId]
                .map(baseValueOption(question))
                .filter(labelHit(term))
                .map(markLabel(term));
            }
            return [];
          })
          .catch((err) => {});
      };
    },
    go(questionId, selected) {
      if (questionId === undefined || questionId === '' || selected === undefined) {
        return;
      }

      this.getQuestion(questionId)
        .then((question) => {
          this.selected = selected
            .map((value) => {
              if (value.value) return value;
              return { value, label: translateBaseValue(value, question) };
            });
        });
    },
  },
};
</script>
