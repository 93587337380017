<template>
  <tr
    v-if="applicant"
    :class="{'cursor-pointer': !tableIsGrid}"
  >
    <td
      class="small-text"
      data-private
      @click="!tableIsGrid ? $emit('open-applicant-modal', applicant.applicant_id) : null"
    >
      <div class="table-row-respondent">
        <div class="respondent-details flex-row direction-column">
          <span class="flex-col">
            <RespondentAvatar
              :id="applicant.applicant_id"
              :is-sadface="!handled && applicant.answer <= 6"
              :include-mask="false"
              :size="44"
              :show-a-pulse="handled"
              class="mb-1"
            />
          </span>
          <h3 class="flex-col mb-0">
            {{ applicant.name }}
          </h3>
          <div
            v-if="applicant.email"
            class="flex-col flex"
          >
            <span :class="{'subtle-text': !applicant.email, 'link': applicant.email }">
              <a
                :href="`mailto:${applicant.email}`"
                tabindex="0"
                @click.stop.self
              >{{ applicant.email }}</a>
            </span>
            <span class="link cursor-pointer">
              <i
                v-tooltip="$gettext('Klicka för att kopiera') + ' ' + applicant.email"
                class="zmdi zmdi-copy "
                tabindex="0"
                @keyup.exact.enter="copyText(applicant.email)"
                @click.stop.self="copyText(applicant.email)"
              />
            </span>
          </div>
          <span
            v-if="applicant.phone_number"
            class="subtle-text flex-col"
          >
            {{ applicant.phone_number }}

          </span>
          <span
            v-tooltip.bottom="format(new Date(applicant.creation_time), 'yyyy-MM-dd')"
            class="subtle-text flex-col"
          >
            <i class="zmdi zmdi-time" />
            {{ timeFromNow(applicant.creation_time) }}</span>
        </div>
      </div>
    </td>
    <td
      class="small-text"
      @click="!tableIsGrid ? $emit('open-applicant-modal', applicant.applicant_id) : null"
    >
      <span
        v-if="tHeaderKey.step"
        class="td-thead-label strong hidden-xxlarge-up"
      >{{ tHeaderKey.step }}:</span>
      {{ translateTerm(applicant.level) }}
    </td>
    <td
      class="small-text"
      data-private
      @click="!tableIsGrid ? $emit('open-applicant-modal', applicant.applicant_id) : null"
    >
      <span
        v-if="tHeaderKey.flagReason"
        class="td-thead-label strong hidden-xxlarge-up"
      >{{ tHeaderKey.flagReason }}:</span>
      {{ translations.flagReasonText }}
    </td>
    <td
      class="small-text"
      @click="!tableIsGrid ? $emit('open-applicant-modal', applicant.applicant_id) : null"
    >
      <span
        v-if="tHeaderKey.comment"
        class="td-thead-label strong hidden-xxlarge-up"
      >{{ tHeaderKey.comment }}:</span>
      <template v-if="applicant.comment && applicant.comment !== '-'">
        {{ applicant.comment }}
      </template>
      <template v-else>
        <i class="zmdi zmdi-minus" />
      </template>
    </td>
    <td class="small-text">
      <span
        v-if=" tHeaderKey.internalNote"
        class="td-thead-label strong hidden-xxlarge-up"
      >
        {{ tHeaderKey.internalNote }}:</span>
      <span
        ref="internalNote"
        :class="{ 'content-editing': editNote }"
        :contenteditable="editNote"
        class="content-edit"
        data-input="true"
        @keydown.esc.prevent="cancelEditNote"
        @keydown.enter.prevent="saveNote"
        @blur="onEdit"
        @paste="onPaste"
      >
        {{ internalNote }}
        <i
          v-if="internalNote.length === 0 && !editNote"
          class="zmdi zmdi-minus"
        /> </span>
      <transition-group
        class="minitools-wrapper mr-3"
        name="slide-fade"
        mode="in-out"
        tag="span"
      >
        <span
          v-if="!isPrintViewRoute"
          v-show="!editNote"
          key="nameChange"
          class="tc-card-minitools"
        >
          <span
            v-tooltip.right="translations.nameChangeText"
            class="on-hover btn-link no-wrap"
            @keyup.enter="startEdit"
            @click="startEdit"
          >
            <i class="zmdi zmdi-edit" />
          </span>
        </span>
        <span
          v-show="editNote"
          key="nameChanging"
          class="tc-card-minitools no-wrap"
        >
          <span
            v-tooltip="translations.nameCancelText"
            class="on-hover btn-link"
            @keyup.enter="cancelEditNote"
            @click="cancelEditNote"
          >
            <i class="zmdi zmdi-close" />
          </span>
          <span
            v-tooltip="translations.nameSaveText"
            class="on-hover btn-link"
            @keyup.enter="saveNote"
            @click="saveNote"
          >
            <i class="zmdi zmdi-save" />
          </span>
        </span>
      </transition-group>
    </td>
    <td class="small-text text-right">
      <button
        class="btn handled-btn"
        :class="{'btn-failure': !handled}"
        @click.prevent="handled = !handled"
      >
        <i
          v-if="handled"
          class="zmdi zmdi-check"
        />
        {{ translations.handledStateTranslation }}
      </button>
    </td>
    <td
      class="text-right link"
      @click="$emit('open-applicant-modal', applicant.applicant_id)"
    >
      <span
        class="hidden-xxlarge-up"
      > {{ $gettext('Se svar') }}
      </span> <i class="zmdi zmdi-chevron-right" />
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex';
import { patch } from 'API/FollowUp';
import { striphtml, translateTerm, copyToClipboard } from 'Utils/general';
import { format, formatDistance } from 'Utils/dateHelpers';
import RespondentAvatar from 'Components/parts/avatars/RespondentAvatar';

export default {
  name: 'FollowUpTableRow',
  components: {
    RespondentAvatar,
  },
  props: {
    applicant: Object,
    theadHeaders: Array,
    tableIsGrid: Boolean,
    demoData: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'open-applicant-modal': (val) => typeof val === 'number',
  },
  data() {
    return {
      editNote: false,
      saving: false,
      internalNote: this.applicant?.note || '',
      noteHistory: '',
      handled: this.applicant?.handled || false,
    };
  },
  computed: {
    isPrintViewRoute() { return this.$route?.name?.indexOf('print-') > -1; },
    translations() {
      return {
        nameSaveText: this.$pgettext('Tooltip — Save note', 'Spara anteckning'),
        nameCancelText: this.$pgettext('Tooltip — Cancel note edit icon', 'Avbryt anteckning'),
        nameChangeText: this.$pgettext('Tooltip - Edit note in follow up', 'Ändra anteckning'),
        handledStateTranslation: this.handled ? this.$pgettext('Button - Handle state of respondent', 'Hanterad')
          : this.$pgettext('Button - Handle state of respondent', 'Ej hanterad'),
        flagReasonText: this.$gettextInterpolate(
          this.$pgettext('flagReasonText', 'Svarade %{answer} av 10'),
          { answer: this.applicant?.answer ?? '' },
        ),
      };
    },
    tHeaderKey() {
      return this.theadHeaders.reduce((ac, a) => ({ ...ac, [a.value]: a.label }), {});
    },
  },
  watch: {
    editNote() {
      this.$nextTick(() => (this.editNote ? this.$refs.internalNote.focus() : null));
    },
    async handled(newVal, oldVal) {
      if (!this.demoData && newVal !== oldVal) {
        try {
          await this.updateApplicant(this.applicant?.applicant_id, { handled: this.handled });
        } catch (error) {
          this.notify?.({
            type: 'pop',
            level: 'error',
            text: error,
          });
        }
      }
    },
  },
  methods: {
    ...mapActions([
      'notify',
    ]),
    format,
    translateTerm,
    timeFromNow(date) {
      return formatDistance(new Date(date), new Date(), { addSuffix: true });
    },
    copyText(text) {
      return copyToClipboard(text).then(() => {
        this.notify({
          type: 'pop',
          level: 'success',
          text: `${text} ${this.$pgettext('Icon - Copy text', 'har kopierats')}`,
        });
      });
    },
    startEdit() {
      this.noteHistory = this.$refs.internalNote.innerText;
      this.editNote = !this.editNote;
    },
    onEdit(e) {
      this.setNote(e.target.innerText);
    },
    onPaste(e) {
      setTimeout(() => { // ? The pasted string doesn’t exist yet
        this.setNote(e.target.innerText);
      }, 20);
    },
    setNote(string) {
      if (string) this.internalNote = striphtml(string.trim());
    },
    cancelEditNote(e) {
      this.setNote(this.noteHistory || '');
      this.editNote = !this.editNote;
    },
    async saveNote(e) {
      this.setNote(this.$refs.internalNote.innerText);
      if (!this.demoData) {
        this.saving = true;
        try {
          await this.updateApplicant(this.applicant.applicant_id, { note: this.internalNote });
        } catch (error) {
          this.notify({
            type: 'pop',
            level: 'error',
            text: error,
          });
        }
        this.saving = false;
      }
      this.editNote = false;
    },
    updateApplicant(id, props) {
      return patch(id, props);
    },
  },
};
</script>
