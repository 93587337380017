<template>
  <DropdownSelect
    :groups="groups"
    :dropdown-settings="props.dropdownSettings"
    @selected="emit('selected', $event)"
    @load-more="loadMore"
    @update:search-term="searchTerm = $event"
  />
</template>

<script setup>
import { ref, computed } from 'vue';
import { computedAsync } from '@vueuse/core';
import { markLabel } from 'Utils/tcselectHelpers';
import RequestPaginator from 'Utils/fresh-paginators/RequestPaginator';
import SearchPopulator from 'Utils/fresh-paginators/SearchPopulator';
import DropdownSelect from 'Components/parts/filter-dropdowns/DropdownSelect';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext } = gettext;

const props = defineProps({
  selectedCompare: {
    type: [Object, String, null],
    default: () => (null),
  },
  dropdownSettings: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['selected']);

const searchTerm = ref('');

const hasProxies = computed(() => Object.keys(store.getters?.customerProxies ?? {}).length > 1);
const defaultCompares = computed(() => ([
  {
    value: 'date',
    label: $gettext('Datum'),
    icon: 'zmdi-calendar',
    selected: props.selectedCompare === 'date' ?? false,
  },
  {
    value: 'segment',
    label: $gettext('Segment'),
    icon: 'zmdi-group-work',
    selected: props.selectedCompare === 'segment' ?? false,
  },
  ...(hasProxies.value && [{
    value: 'customerProxy',
    label: $gettext('Proxies'),
    icon: 'zmdi-input-antenna',
    selected: props.selectedCompare === 'customerProxy' ?? false,
  }] || []),
]));

const isLoadingMoreSegmentTagKeys = ref(false);
const isLoadingSegmentTagKeys = computedAsync(
  async () => {
    await store.getters.fetchingTagKeys;
    return false;
  },
  true,
);

const searchFn = (opt) => {
  if (searchTerm.value === '') return true;
  const optLowerCase = opt?.label?.toLowerCase() ?? opt?.value?.toLowerCase() ?? '';
  return optLowerCase.indexOf(searchTerm.value.toLowerCase()) > -1;
};

const wrapResultsFn = (response) => {
  if (response?.results?.length > 0) {
    return {
      ...response,
      results: response.results.map((opt) => ({
        value: opt.key ?? opt.value,
        label: opt.translation ?? opt.value,
        icon: 'zmdi-labels',
        selected: props.selectedCompare === opt.key ?? false,
      })),
    };
  }
  return { next: null, prev: null, results: [], ...response };
};

const segmentTagKeyOptionsPopulator = computed(() => {
  if (isLoadingSegmentTagKeys.value) return {};
  const response = store.getters.segmentTagKeysPaginator;
  const paginator = new RequestPaginator(response, wrapResultsFn);
  const populator = new SearchPopulator(paginator, searchFn);
  populator.loadMore();
  return populator ?? {};
});

const tagKeyOptions = computed(() => {
  if (isLoadingSegmentTagKeys.value) return [];
  return segmentTagKeyOptionsPopulator.value?.filteredStack ?? [];
});

const loadMore = async (groupSlug) => {
  if (groupSlug !== 'tags' || segmentTagKeyOptionsPopulator.value?.hasMore?.() !== true) return;

  isLoadingMoreSegmentTagKeys.value = true;
  await segmentTagKeyOptionsPopulator.value.loadMore();

  store.dispatch('setTagKeys', {
    tagKeysPaginator: segmentTagKeyOptionsPopulator.value._paginator._dataset, // Depends on layers of paginator classes
    customer: false,
  });
  isLoadingMoreSegmentTagKeys.value = false;
};

const groups = computed(() => [
  {
    groupSlug: '',
    groupTitle: '',
    loading: false,
    options: defaultCompares.value.filter(searchFn).map(markLabel(searchTerm.value)) ?? [],
  },
  {
    groupSlug: 'tags',
    groupTitle: 'Tags',
    loading: isLoadingMoreSegmentTagKeys.value || isLoadingSegmentTagKeys.value || segmentTagKeyOptionsPopulator.value?.loading === true, // eslint-disable-line max-len
    hasMore: segmentTagKeyOptionsPopulator.value?.hasMore?.() || false, // TODO Validate
    options: tagKeyOptions.value.map(markLabel(searchTerm.value)) ?? [],
  },
]);

</script>
