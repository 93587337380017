<template>
  <div class="toggle-icon-wrapper">
    <span
      v-for="(icon, state) in states"
      :key="state"
      v-tooltip="tooltips?.[state]"
      :class="{ active: state === modelValue }"
      class="toggle-item-icon"
      @click="select(state)"
    >
      <slot :name="state">
        <i
          v-if="icon"
          class="zmdi zmdi-hc-lg"
          :class="icon"
        />
      </slot>
    </span>
  </div>
</template>

<script setup>

// You can use namedSlots to pass icons if needed, else icons from states will be used on a zmdi i
// One example might be if you dont want to use zmdi icons and want to use custom icons

// <ToggleIcon
//  :model-value="currentView"
//  :states="{
//      table: '',
//      card: ''
//  }"
//  :tooltips="{
//      table: $gettext('Visa som lista'),
//      card: $gettext('Visa som kort'),
//  }"
//  @update:model-value="setCurrentView"
// >
//  <template #table>
//      <i class="zmdi zmdi-account" /> <!-- Custom Grid View Icon -->
//   </template>
//   <template #card>
//      <i class="zmdi zmdi-check" /> <!-- Custom Grid View Icon -->
//   </template>
// </ToggleIcon>

const props = defineProps({
  states: {
    type: Object,
    required: true,
    default: () => ({
      checked: 'zmdi-check',
      unchecked: 'zmdi-close',
    }),
  },
  tooltips: {
    type: Object,
    required: false,
    default: null,
  },
  modelValue: {
    type: String,
    default: 'checked',
  },
});
const emit = defineEmits(['update:modelValue']);

const select = (state) => {
  emit('update:modelValue', state);
};

</script>
