<!-- Readonly version for rendering editor content. Like rendering html while maintaining interactivity.  -->
<template>
  <editor-content :editor="editor" />
</template>

<script setup>
import { watch, onMounted } from 'vue';
import { useEditor, EditorContent } from '@tiptap/vue-3';
import { useRouter } from 'vue-router';
import config from './config';
import plugins from './plugins';

const props = defineProps({ content: Object, style: String });

const router = useRouter();

const editor = useEditor({
  extensions: [
    ...config.extensions,
    plugins.TCLink(router),
  ],
  editorProps: {
    attributes: {
      spellcheck: 'false',
      style: 'min-height: 0;',
    },
  },
  content: props.content,
  editable: false,
});
const emit = defineEmits(['loading']);

watch(() => props.content, (val) => {
  editor.value.commands.setContent(val);
});

onMounted(() => {
  emit('loading', false);
});

</script>
