import sanitizer from './sanitizer';

// Creates a default TipTap doc
const whatsUpDoc = () => ({
  type: 'doc',
  content: [
    {
      type: 'paragraph',
    },
  ],
});

export default {
  sanitizeDoc: sanitizer.sanitizeDoc,
  unSanitizeDoc: sanitizer.unSanitizeDoc,
  createDoc: whatsUpDoc,
};
