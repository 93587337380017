<template>
  <ul
    v-if="rows?.length !== 0"
    class="bar-graph-list tc-list-group no-border mb-0"
  >
    <template
      v-for="(row) in scaledRows"
      :key="row.index"
    >
      <transition
        name="slide-down-fade"
        @after-leave="updateLayout"
      >
        <bar-graph-row
          ref="BarGraphRowEls"
          :key="row.index"
          :question-type="questionType"
          :row="row"
          :categories="categories"
          :skeleton-loader="skeletonLoader"
        />
      </transition>
    </template>
  </ul>
</template>

<script>
import { mapState } from 'vuex';
import { isNaN } from 'lodash-es';
import BarGraphRow from './BarGraphRow';

export default {
  name: 'BarGraph',
  components: {
    BarGraphRow,
  },
  props: {
    questionType: String,
    rows: Array,
    categories: Array,
    cardId: {
      type: Number,
      required: false,
    },
    skeletonLoader: {
      type: Boolean,
      default: false,
    },
    useNiceScale: {
      type: Boolean,
      default: true,
    },
    totalCount: {
      type: Object,
      default: () => ({ segment: 0, customer: 0 }),
    },
  },
  emits: ['update-drag-area'],
  computed: {
    ...mapState({
      shownCardIds: (state) => state.cards.shownCardIds || [],
    }),
    scaledRows() {
      if (this.useNiceScale === false) return this.rows;

      const highestPoint = this.rows.reduce((acc, row) => {
        const hiData = row?.data?.reduce((acc2, data) => {
          if (data > acc2) acc2 = data;
          return acc2;
        }, 0) || 0;
        if (hiData > acc) acc = hiData;
        return acc;
      }, 0);

      const newScale = 100 / highestPoint;
      return this.rows.map((row) => {
        row.width = [];
        if (row?.totalCount === undefined) row.totalCount = [this.totalCount.segment, this.totalCount.customer];
        row?.data?.forEach((data) => {
          if (isNaN(data)) row.width.push(0);
          const width = data * newScale;
          row.width.push(width);
        });
        return row;
      });
    },
  },
  watch: {
    shownCardIds(newVal) {
      if (newVal.includes(this.cardId)) {
        // Fixes https://app.shortcut.com/trustcruit/story/17772/bug-report-percentage-not-showing-fully-on-bar-graph
        this.$refs?.BarGraphRowEls.forEach((rowRef) => {
          rowRef.resizeIndicator();
        });
      }
    },
  },
  methods: {
    updateLayout() {
      // emits when transition is done
      this.$emit('update-drag-area');
    },
  },
};
</script>
