<template>
  <div
    ref="container"
    v-tooltip="vTooltip"
    :class="['radio-button', props.class, { disabled: props.disabled }]"
    role="radiogroup"
    tabindex="0"
    :style="{
      '--overlay-position-left': `${overlayLeft}px`,
      '--option-width': `${optionWidth}px`
    }"
    @keydown.enter="onKeydownEnter"
  >
    <label
      v-for="option in options"
      :key="option.label"
      :aria-label="option.label"
    >
      <input
        hidden
        :checked="option.value === props.value"
        type="radio"
        :value="option.value"
        :disabled="props.disabled"
        @input="onChange"
      >
      <i :class="['zmdi', option.icon]" />
      <span class="hidden-small-down">{{ option.label }}</span>
    </label>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useElementSize } from '@vueuse/core';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  vTooltip: {
    type: Object,
    default: () => ({}),
  },
  // Array<{ label: string; icon: string; value: string }>
  options: {
    type: Array,
    required: true,
  },
  class: {
    type: String,
    default: '',
  },
  value: String,
});

const emit = defineEmits(['change']);

const container = ref(null);
const { width } = useElementSize(container);

const selectedIndex = computed(() => props.options.findIndex((option) => option.value === props.value));
const optionWidth = computed(() => width.value / props.options.length);
const overlayLeft = computed(() => (width.value / props.options.length) * selectedIndex.value);

const onChange = (ev) => {
  // Undo change event (native radio button behavior)
  // as to have checked state entirely controlled by props
  ev.target.checked = !ev.target.checked;
  emit('change', ev.target.value);
};

const onKeydownEnter = () => {
  const nextIndex = selectedIndex.value === props.options.length - 1 ? 0 : selectedIndex.value + 1;
  const nextValue = props.options[nextIndex].value;
  emit('change', nextValue);
};
</script>
