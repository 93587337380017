import { h as createElement } from 'vue';
import { mapState, mapGetters } from 'vuex';
import { filterMerge } from 'Utils/general';
import { getAllowlistCompiledFilter, graphTypeElement, KEY_METRIC_GRAPH_TYPES } from 'Utils/graph';
import eventBus from 'Utils/eventBus';
import Card from 'Components/parts/Card';
import SWRanking from 'Components/parts/graph/SWRanking';
import RankingGauge from 'Components/parts/graph/RankingGauge';
import PerformanceScore from 'Components/parts/graph/PerformanceScore';
import ResponseRate from 'Components/parts/graph/ResponseRate';
import KeyMetricResultHeader from './KeyMetricResultHeader';

const GRAPH_TYPE_COMPONENTS = {
  SWRanking,
  RankingGauge,
  PerformanceScore,
  ResponseRate,
};

const KeyMetricResult = {
  name: 'KeyMetricResult',
  props: {
    actions: Array,
    links: Array,
    card: {
      type: Object,
      required: true,
    },
    board: Object,
    contextFilter: {
      type: Object,
      default: () => ({}),
    },
    contextBenchmark: {
      type: Object,
      default: () => ({}),
    },
    contextCompare: {
      type: [Object, null],
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    enableOverlay: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    temporaryCard: {
      type: Boolean,
      default: false,
    },
    isInBoard: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    hydrateProps: null,
  },
  emits: ['toggle', 'update:col-class', 'update-drag-area', 'loaded', 'download-card-png'],
  data() {
    return {
      loading: false,
      hydratePropsInternal: null,
    };
  },
  mounted() {
    eventBus.$on(`download-card-${this.card.id}`, () => this.$emit('download-card-png'));
  },
  unmounted() {
    eventBus.$off(`download-card-${this.card.id}`);
  },
  computed: {
    ...mapState({
      isOverlayed: (state) => state.boards.isOrganizing,
    }),
    ...mapGetters([
      'segmentId',
      'segmentFilter',
    ]),
    // hasGraph() {
    //   return this.card.metadata?.graphType.selected
    //     && graphTypeElement(GRAPH_TYPE_COMPONENTS, this.card.metadata.graphType.selected);
    // },
    colClass() {
      return this.card.metadata.colClass || 'col-xs-12';
    },
    graphType() { return this.card.metadata.graphType?.selected ?? ''; },
    keyMetricHasCompare() { return ['bar'].includes(this.card.metadata.graphType?.settings?.[KEY_METRIC_GRAPH_TYPES.ResponseRate]?.type); },
    omitCompare() { return !this.keyMetricHasCompare; },
    compiledFilter() {
      return getAllowlistCompiledFilter({ filter: filterMerge(this.segmentFilter, this.contextFilter, this.card.metadata?.filter), graphTypeSelected: this.card.metadata.graphType.selected, cardType: 'keyMetric' }); // eslint-disable-line max-len
    },
    compiledBenchmark() {
      return getAllowlistCompiledFilter({ filter: filterMerge(this.contextBenchmark, this.card.metadata?.benchmark), graphTypeSelected: this.card.metadata.graphType.selected, cardType: 'keyMetric' }); // eslint-disable-line max-len
    },
    compiledCompare() {
      if (this.omitCompare) return null;
      if (this.card.metadata?.compare != null) { // ? Doesn’t merge compare values currently
        return { ...this.card.metadata.compare };
      }
      return this.contextCompare;
    },
  },
  render() {
    return createElement(
      Card,
      {
        class: 'tc-card-key-metric-wrapper',
        isDraggable: this.isDraggable || null,
        isOverlayed: this.isOverlayed || null,
        enableOverlay: this.enableOverlay || null,
        colClass: this.colClass,
        onToggle: (card) => this.$emit('toggle', card),
        'onUpdate:col-class': (colClass) => this.$emit('update:col-class', colClass),
      },
      {
        header: () => createElement(KeyMetricResultHeader, {
          actions: this.actions,
          links: this.links,
          card: this.card,
          board: this.board,
          name: this.name,
          description: this.description,
          visible: !this.isOverlayed,
          isInBoard: this.isInBoard || null,
          previewMode: this.previewMode || null,
          contextFilter: this.contextFilter,
          contextBenchmark: this.contextBenchmark,
          contextCompare: this.contextCompare,
          compiledFilter: this.compiledFilter,
          compiledBenchmark: this.compiledBenchmark,
          compiledCompare: this.compiledCompare,
          hydrateProps: this.hydrateProps || this.hydratePropsInternal,
          temporaryCard: this.temporaryCard || null,
          cardLoading: this.loading,
          'onUpdate-drag-area': () => this.$emit('update-drag-area'),
          'onDownload-card-png': () => {
            this.$emit('download-card-png');
          },
        }),
        body: () => createElement(graphTypeElement(GRAPH_TYPE_COMPONENTS, this.card.metadata.graphType.selected), {
        // class: !this.hasGraph ? 'query-result query-result-no-graph' : 'query-result',
          ref: 'keymetric',
          card: this.card,
          board: this.board,
          compiledFilter: this.compiledFilter,
          compiledBenchmark: this.compiledBenchmark,
          compiledCompare: this.compiledCompare,
          contextBenchmark: this.contextBenchmark,
          isInBoard: this.isInBoard || null,
          previewMode: this.previewMode || null,
          hydrateProps: this.hydrateProps || this.hydratePropsInternal,
          'onUpdate-drag-area': () => this.$emit('update-drag-area'),
          onLoading: (state) => {
            this.loading = state;
            if (!state) this.$emit('loaded', !state);
          },
          'onHydrate-props': (state) => {
          // ? hydrateProps can (recursively bad) come from the card in the board or from the card in the preview
            this.hydratePropsInternal = state;
          },
        }),
      },
    );
  },
};
export default KeyMetricResult;
