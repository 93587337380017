<template>
  <div class="board-section-actions btn-toolbar-section">
    <VDropdown
      v-model:shown="isAddCardDropdownShown"
      :triggers="['click']"
      :skidding="20"
    >
      <button
        class="btn btn-toolbar btn-toolbar--sm btn-icon-text"
        :class="{ 'btn-primary': props.isEmpty }"
        type="button"
        tabindex="0"
      >
        <i class="zmdi zmdi-plus" />
        <span>{{ $gettext('Lägg till Card') }}</span>
      </button>

      <template #popper>
        <ul class="block-links">
          <li
            v-close-popper
            class="block-link block-link-nopad btn-icon-text cursor-pointer py-1"
            data-testid="addKeyMetricBtn"
            type="button"
            tabindex="0"
            @click="openKeyMetricModal"
            @keypress.enter="openKeyMetricModal"
          >
            <span>
              <i class="zmdi zmdi-plus mr-0.5" />{{ $gettext('Nyckeltal') }}
            </span>
            <br>
            <span class="ml-1 tiny-text subtle-text text-wrap">
              {{ $pgettext('Description — Key Metric', 'Hitta ditt fokusområde') }}
            </span>
          </li>
          <li><hr class="my-0 hr-thin block-links"></li>
          <li
            v-close-popper
            class="block-link block-link-nopad btn-icon-text cursor-pointer py-1"
            data-testid="addQuestionBtn"
            type="button"
            tabindex="0"
            @click="openAddQuestionsModal"
            @keypress.enter="openAddQuestionsModal"
          >
            <span>
              <i class="zmdi zmdi-plus mr-0.5" />{{ $gettext('Fråga') }}
            </span>
            <br>
            <span class="ml-1 tiny-text subtle-text text-wrap">
              {{ $pgettext('Description — Question', 'Dyk in i formulärfrågor') }}
            </span>
          </li>
          <li><hr class="my-0 hr-thin block-links"></li>
          <li
            v-close-popper
            class="block-link block-link-nopad btn-icon-text cursor-pointer py-1"
            data-testid="addGoalnBtn"
            type="button"
            tabindex="0"
            @click="openAddQuestionsModal({ isGoal:true })"
            @keypress.enter="openAddQuestionsModal({ isGoal:true })"
          >
            <span>
              <i class="zmdi zmdi-plus mr-0.5" />{{ $gettext('Mål') }}
            </span>
            <br>
            <span class="ml-1 tiny-text subtle-text text-wrap">
              {{ $pgettext('Description — Goal', 'Följ upp dina framsteg') }}
            </span>
          </li>
          <li><hr class="my-0 hr-thin block-links"></li>
          <li
            v-close-popper
            class="block-link block-link-nopad btn-icon-text cursor-pointer py-1"
            data-testid="addTextBtn"
            type="button"
            tabindex="0"
            @click="openAddTextCardModal"
            @keypress.enter="openAddTextCardModal"
          >
            <span>
              <i class="zmdi zmdi-plus mr-0.5" />{{ $gettext('Text') }}
            </span>
            <br>
            <span class="ml-1 tiny-text subtle-text text-wrap">
              {{ $pgettext('Description — Text', 'Lägg till fritext') }}
            </span>
          </li>
        </ul>
      </template>
    </VDropdown>
    <template v-if="props.isEditing">
      <button
        class="btn btn-toolbar btn-toolbar--sm btn-icon-text"
        type="button"
        tabindex="0"
        @click="emit('update:isEditing', !props.isEditing)"
      >
        <i class="zmdi zmdi-close-circle-o" />
        <span>{{ $gettext('Avbryt') }}</span>
      </button>
      <button
        v-if="props.showRemove"
        class="btn btn-toolbar btn-toolbar--sm btn-icon-text"
        type="button"
        tabindex="0"
        @click="emit('removeSection')"
      >
        <i class="zmdi zmdi-delete" />
        <span class="hidden-small-down">{{ $gettext('Ta bort Sektion') }}</span>
        <span class="hidden-small-up">{{ $gettext('Ta bort') }}</span>
      </button>
    </template>

    <button
      class="btn btn-toolbar btn-toolbar--sm btn-icon-text"
      type="button"
      tabindex="0"
      @click="() => {
        if (!props.isEditing) emit('update:isEditing', !props.isEditing);
        else emit('save-section');
      }"
    >
      <template v-if="props.isEditing">
        <i class="zmdi zmdi-save" />
        <span class="hidden-small-down">{{ $gettext('Spara Sektion') }}</span>
        <span class="hidden-small-up">{{ $gettext('Spara') }}</span>
      </template>
      <template v-else>
        <i class="zmdi zmdi-edit" />
        <span>{{ $gettext('Ändra Sektion') }}</span>
      </template>
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useCardModals } from 'Composables/useCardModals';

const props = defineProps({
  isEditing: {
    type: Boolean,
    default: false,
  },
  isEmpty: {
    type: Boolean,
    default: false,
  },
  showRemove: {
    type: Boolean,
    default: true,
  },
  boardId: Number,
  sectionId: Number,
});
const emit = defineEmits(['update:isEditing', 'add:cards', 'removeSection', 'save-section']);

const {
  openAddQuestionsModal,
  openKeyMetricModal,
  openAddTextCardModal,
} = useCardModals({
  boardId: props.boardId,
  sectionId: props.sectionId,
  emit,
});

const isAddCardDropdownShown = ref(false);
const showAddCardDropdown = () => {
  isAddCardDropdownShown.value = true;
};

defineExpose({ showAddCardDropdown });
</script>
