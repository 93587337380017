<template>
  <div class="form-group">
    <!-- eslint-disable vue/no-mutating-props -->
    <input
      ref="email"
      v-model="email.email"
      type="email"
      class="form-control"
      data-private="lipsum"
      :autofocus="email.autofocus"
      :required="email.required"
      :disabled="email.sending"
      :placeholder="inputPlaceholder"
      :class="{ 'is-invalid': !email.valid }"
      @input="updateValidity"
    >
    <!-- eslint-enable vue/no-mutating-props -->
    <div
      v-if="email.validationMessage"
      class="help-block validation-message"
      :class="validationMessageClass"
    >
      {{ email.validationMessage }}
    </div>
    <div
      v-if="email.sending"
      class="help-block disabled-message"
    >
      {{ $gettext('Skickar') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: {
      required: true,
    },
  },
  data() {
    return {
      frontendValid: true,
    };
  },
  computed: {
    validationMessageClass() {
      return {
        'is-valid': this.frontendValid,
        'is-invalid': !this.frontendValid,
      };
    },
    inputPlaceholder() {
      const sendingText = this.$gettext('Skickar...');
      const placeholder = this.$gettext('Ange email');
      return this.email.sending ? sendingText : placeholder;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.email.focus();
    });
  },
  methods: {
    updateValidity() {
      this.frontendValid = this.$refs.email.validity.valid;
    },
  },
};
</script>
