import { isEmpty } from 'lodash-es';
import segmentAPI from 'API/segment';
import { uniqBy } from 'Utils/general';
import { markLabel, labelHit } from 'Utils/tcselectHelpers';
import gettext from '@/gettext';

const { $gettext } = gettext;
const segmentsOption = (segment) => ({ value: segment.id, label: segment.name });

export default {
  state: {
    segments: {}, // Paginated segments under customerId
    fetchingSegmentsPromise: Promise.resolve(),
    fetchingSegments: true,
    lastSearchTerm: '',
    lastSearchState: {},
  },
  mutations: {
    SET_SEGMENTS(state, { customerId, segmentsPaginator }) {
      if (customerId) {
        if (state.segments[customerId]) {
          const mergedPaginator = {
            ...segmentsPaginator,
            results: uniqBy([
              ...(state.segments[customerId].results || []),
              ...(segmentsPaginator.results || []),
            ], 'id'),
          };
          state.segments[customerId] = { ...state.segments[customerId], ...mergedPaginator };
        } else {
          state.segments[customerId] = segmentsPaginator;
        }
      }
    },
    SET_SEGMENTS_FETCH_STATE(state, { fetchPromise, fetchState }) {
      state.fetchingSegmentsPromise = fetchPromise;
      state.fetchingSegments = fetchState;
    },
    CLEAR_SEGMENTS(state, customerId) {
      state.segments[customerId] = [];
    },
    SET_LAST_SEARCH_TERM(state, searchTerm) {
      state.lastSearchTerm = searchTerm;
    },
    SET_LAST_SEARCH_STATE(state, lastSearchState) {
      state.lastSearchState = lastSearchState;
    },
  },
  getters: {
    segmentsPaginator: (state, getters) => () => getters.customerId && state.segments[getters.customerId] || {},
    segments: (state, getters) => (customerId) => state.segments[customerId || getters.customerId]?.results || [],
    segmentsOptions: (state, getters) => (term = '') => state.fetchingSegmentsPromise
      .then(async () => {
        const segVals = await getters.segmentsPaginator();
        if (!isEmpty(segVals)) {
          return {
            ...segVals,
            results: segVals?.results
              .map(segmentsOption)
              .filter(labelHit(term))
              .map(markLabel(term)),
          };
        }
        return [];
      }),
    segmentsOptionsAndAllSegments: (state, getters) => (term = '') => state.fetchingSegmentsPromise
      .then(async () => {
        const segVals = await getters.segmentsPaginator();
        if (!isEmpty(segVals)) {
          return {
            ...segVals,
            results: [{
              value: null,
              label: $gettext('Alla segment'),
            }].concat(segVals?.results
              .map(segmentsOption)
              .filter(labelHit(term))
              .map(markLabel(term))),
          };
        }
        return [];
      }),
    fetchingSegmentsPromise: (state) => state.fetchingSegmentsPromise,
    fetchingSegments: (state) => state.fetchingSegments,
  },
  actions: {
    clearSegments: ({ getters, commit }, customerId) => {
      const custId = customerId ?? getters.customerId;
      if (typeof customerId === 'number') {
        commit('CLEAR_SEGMENTS', custId);
        commit('SET_LAST_SEARCH_STATE', {});
        return true;
      }
      return new Error('[TC] clearSegments needs a custId');
    },
    fetchSegments: async ({ getters, commit }, customerId) => {
      const custId = customerId ?? getters.customerId;
      if (custId) {
        if (getters.segments(custId).length > 0) {
          const resolvedPromise = Promise.resolve(getters.segments(custId));
          commit('SET_SEGMENTS_FETCH_STATE', { fetchPromise: resolvedPromise, fetchState: false });
          return resolvedPromise;
        }

        const segProm = segmentAPI.list(custId, {}, true)
          .then((segmentsPaginator) => {
            commit('SET_SEGMENTS', { customerId: custId, segmentsPaginator });
          });

        commit('SET_SEGMENTS_FETCH_STATE', { fetchPromise: segProm, fetchState: false });
        return segProm;
      }
      return new Error('[TC] fetchSegments needs a custId');
    },
    setSegmentsValues({ getters, state, commit }, { segmentsPaginator, customerId }) {
      const custId = customerId ?? getters.customerId;
      // eslint-disable-next-line no-console
      if (isEmpty(segmentsPaginator)) console.error(new Error('[TC] setSegmentsValues needs segmentsPaginator'));
      state.fetchingSegmentsPromise.then(() => {
        commit('SET_SEGMENTS', { customerId: custId, segmentsPaginator });
      });
    },
    setLastSearchTerm({ commit }, term = '') {
      commit('SET_LAST_SEARCH_TERM', term);
      return term;
    },
    setLastSearchState({ commit }, lastSearchState = {}) {
      commit('SET_LAST_SEARCH_STATE', lastSearchState);
      return lastSearchState;
    },
    async getSegmentById({ commit, getters }, segmentId) {
      const finding = Object.values(getters.segments?.())?.find((seg) => seg.id === segmentId);
      if (finding) return finding;
      return segmentAPI.get(segmentId)
        .then((segment) => {
          commit('SET_SEGMENTS', { customerId: segment.customer_id, segmentsPaginator: { results: [segment] } });
          return segment;
        });
    },
  },
};
