<template>
  <div class="tc-card-takeover">
    <div class="tc-card tc-card-primary">
      <div class="tc-card-header p-card">
        <h3 v-if="hasValidInvite">
          {{ translations.title.valid }}
          <br><img
            v-if="companyLogo"
            :src="companyLogo"
            alt="company logotype"
            class="mt-2"
            style="height:2rem"
          >
        </h3>
        <h3 v-else>
          {{ translations.title.default }}
        </h3>
      </div>
      <div class="tc-card-body">
        <div
          v-if="hasValidInvite"
          class="button-info"
        >
          <p v-if="customerName">
            {{ translations.body.one }} <br>
            {{ translations.body.two.valid }} <em>{{ customerName }}</em>.
          </p>
          <p v-else>
            {{ translations.body.one }} <br>{{ translations.body.two.default }}.
          </p>
          <div class="text-center">
            <button
              class="btn"
              :class="{'btn-loading': loading, 'btn-loaded': finishedLoading}"
              type="submit"
              @click="navigateTo()"
            >
              {{ translations.button }}
            </button>
          </div>
        </div>
        <!-- If we don't have a valid invite (hasValidInvite)
        and we don't have a response (hasData) then it's just blank -->
        <div
          v-else-if="hasValidInvite === false || hasData"
          class="button-info"
        >
          <p>
            <b>{{ translations.invalid.one }}</b><br>
            {{ translations.invalid.two }}<br>
          </p>
        </div>
        <div
          v-else
          v-tc-loader-bar="true"
          class="button-info"
        >
          <p>{{ $gettext('Kollar inbjudan...') }}</p>
        </div>
        <login-footer />
      </div>
    </div>
  </div>
</template>

<script>
import { has } from 'lodash-es';
import { mapActions } from 'vuex';
import { clearIntercomStorage } from '@/plugins/intercom';
import loginAPI from 'API/login';
import { check as customerInviteCheck } from 'API/customerUserInvitation';
import { check as segmentInviteCheck } from 'API/segmentUserInvitation';
import { clearLoginLocalStorage } from 'Utils/authHelpers';
import { formatUserDisplayName } from 'Utils/general';
import { localStorageIsAvailable } from 'Utils/storage';
import LoginFooter from 'Components/parts/LoginFooter';

export default {
  components: {
    LoginFooter,
  },
  props: [
    'id',
    'token',
  ],
  data() {
    return {
      isNewUser: false,
      role: '',
      loading: false,
      finishedLoading: false,
      emailAddress: '',
      segmentName: '',
      customerName: '',
      companyLogo: '',
      hasValidInvite: null,
      checkResponse: {},
    };
  },
  computed: {
    invitedBy() {
      return formatUserDisplayName(this.checkResponse.invited_by);
    },
    hasData() {
      return Object.keys(this.checkResponse).length > 0;
    },
    translations() {
      return {
        title: {
          valid: this.$gettext('Trustcruit för %{customerName}', { customerName: this.customerName }),
          default: this.$gettext('Inbjudan till Trustcruit'),
        },
        body: {
          one: this.$gettext('%{invitedBy} har bjudit in dig', { invitedBy: this.invitedBy }),
          two: {
            valid: this.$gettext('till Trustcruit för'),
            default: this.$gettext('till Trustcruit'),
          },
        },
        invalid: {
          one: this.$gettext('Denna inbjudan är utgången.'),
          two: this.$gettext('Den har förbrukats eller är över 14 dagar gammal.'),
        },
        button: this.isNewUser ? this.$pgettext('Accept-invite button label', 'Skapa nytt konto') : this.$pgettext('Accept-invite button label', 'Acceptera inbjudan'),

      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkValidInvite().then((response) => {
        if (response !== undefined) {
          if (this.$route.meta.invitationType === 'segmentUser' && response.segment) {
            this.customerName = response.segment?.customer?.name || '';
            this.segmentName = response.segment?.name || '';
            this.companyLogo = response.segment?.customer?.logo_image || '';
          } else {
            this.customerName = response?.customer?.name || '';
            this.companyLogo = response?.customer?.logo_image || '';
          }
          // ! what about expired? this.hasValidInvite verkar inte kolla response.expired
          this.checkResponse = response;
          this.emailAddress = response?.email || '';
          this.isNewUser = response?.signup || false; // ? not for customer users?
          this.role = response.role;
        }

        if (this.isNewUser) {
          clearIntercomStorage();
          clearLoginLocalStorage(); // ? Remove if logged in user tries to accept invite for a new user
          if (typeof window !== 'undefined') {
            window.intercomSettings = {
              ...window.intercomSettings,
              segment_id: response.segment?.id,
              segment_name: response.segment?.name,
              ...(has(response.segment, 'customer') && {
                company: {
                  company_id: response.segment.customer?.id,
                  name: response.segment.customer?.name,
                  plan: response.segment.customer?.plan.plan_type,
                },
              }),
            };
          }
        }
        if (localStorageIsAvailable) {
          // ? Set invite to check on /new-account
          localStorage.setItem('invite', JSON.stringify({
            token: this.$route.params.token,
            id: this.$route.params.id,
            type: this.$route.meta.invitationType,
          }));
          // ? Set emailAddress to use on /new-account or /otp
          localStorage.setItem('emailAddress', this.emailAddress);
        }
      });
    });
  },
  methods: {
    ...mapActions([
      'notify', 'setHiddenParams',
    ]),
    navigateTo() {
      if (this.isNewUser) {
        this.setHiddenParams({ email: this.emailAddress });
        this.$router.push({ name: 'new-account', params: { id: this.id, token: this.token } });
      } else {
        this.loading = true;
        this.requestLogin();
      }
    },
    checkValidInvite() {
      let checkFunction = customerInviteCheck;
      if (this.$route.meta.invitationType === 'segmentUser') {
        checkFunction = segmentInviteCheck;
      }
      return checkFunction(this.$route.params.id, this.$route.params.token).then(
        (response) => {
          this.hasValidInvite = true;
          return response;
        },
        (err) => {
          this.hasValidInvite = false;
        },
      );
    },
    async requestLogin() {
      const { AUTH_ERROR, AUTH_TYPE } = loginAPI;

      try {
        const res = await loginAPI.requestLogin(this.emailAddress);

        if (res.auth_type === AUTH_TYPE.OTP) {
          if (localStorageIsAvailable) localStorage.setItem('gdprAccepted', res.gdpr_accepted);
          // Do we need setTimeout here?
          setTimeout(() => {
            this.setHiddenParams({ email: this.emailAddress });
            this.$router.push({ name: 'otp' });
          }, 200);
        }
        if (res.auth_type === AUTH_TYPE.SSO) window.location.href = res.redirect_url; // redirect to SSO provider
      } catch (err) {
        switch (err.message) {
          case AUTH_ERROR.NO_GDPR_FOR_SSO:
            this.$router.push({ name: 'accept-terms' });
            break;
          default:
            if (err?.response?.body) {
              throw err.response.body?.error || err.response.body;
            }
        }
      } finally {
        this.finishedLoading = true;
      }
    },

  },
};
</script>
