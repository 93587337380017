import { pickBy, identity } from 'lodash-es';
import {
  postUrl,
  // deleteUrl,
  getBaseUrl,
} from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

/**
 ** Interface for reportTask-objects (printTask)
 * send →
 * Ex:
 {
    params: {
      dateGte: ['2018-12-03'],
      dateLte: ['2019-12-04']
    },
    language: 'sv-se', // Only affects to_emails
    to_emails: ['sven@trustcruit.com', 'gi@nni.no'],
    to_users: [328, 1],
    board: 1970,
    card: null,
    metadata : {
      label: 'Nej tack kommentarer',
    }
  }
 *
 ** Interface for reportTask-responses
 * receive <-
 * Ex:
  {
    "id": 1,
    "url": "http://localhost:8000/divoc/api/reports/1/",
    "print_url": "http://localhost:8080/print/board/1970?dateGte=2018-12-03",
    "to_emails": ["sven@trustcruit.com", "gi@nni.no"],
    "to_users": [328, 1],
    "language": "sv-se",
    "creation_date": "2020-03-19T17:49:56.625732+01:00",
    "rendered_date": null,
    "status": "processing",
    "user": {
      "id": 328,
      "first_name": "",
      "last_name": "",
      "username": "sven",
      "email": "sven@trustcruit.com",
      "url": "http://localhost:8000/feedback/accounts/api/user/328/"
    },
    "board": 1970,
    "card": null,
    "metadata": {
      "label": "Nej tack kommentarer"
    },
    "print_result": null
  }
 */

// ? You probably want to use the vuex (`store/modules/tasks.js`) instance instead of this API
const reportsAPI = {
  create({
    params,
    language,
    question_language,
    to_emails,
    to_users,
    board,
    card,
    name,
    metadata,
  }) {
    return postUrl('/divoc/api/reports/', pickBy({
      params,
      language,
      question_language,
      to_emails,
      to_users,
      board,
      card,
      name,
      metadata,
    }, identity));
  },

  /**
   ** reportsAPI.list()
   * @param {Object} filter?, date / ordering / page_size / page
   * @param {Number} subscriptionId?, An optional id for the subscription you want to list reports for
   * @param {Boolean} paginated?, return paginated response or not
   */
  list({ filter = null, subscriptionId = null } = {}, paginated = true) {
    return getBaseUrl('/divoc/api/reports/')
      .query(subscriptionId ? { ...filter, subscription: subscriptionId } : { ...filter })
      .then((response) => (
        paginated && response.body.next !== undefined ? response.body : response.body.results
      ), handleAuthError);
  },

  /**
   ** reportsAPI.get()
   * @param {Number} id, The reportId for what report to get
   */
  get(id) {
    return getBaseUrl(`/divoc/api/reports/${id}/`)
      .then((response) => response.body, handleAuthError);
  },
  /**
   ** reportsAPI.restrictionMultipleUsers()
   * @param {Number} customerId, The boardId
   */
  restrictionMultipleUsers(customerId) {
    return postUrl('/divoc/api/reports/restriction_multiple_users/', { customer: customerId }).catch(handleAuthError);
  },

  // ? PUT & DELETE are disabled for reports in backend
  // update(id, {
  //   params,
  //   language,
  //   to_emails,
  //   to_users,
  //   board,
  //   card,
  //   name,
  //   metadata,
  // }) {
  //   return putUrl(`/divoc/api/reports/${id}/`, {
  //     params,
  //     language,
  //     to_emails,
  //     to_users,
  //     board,
  //     card,
  //     name,
  //     metadata,
  //   });
  // },

  // remove(id) {
  //   return deleteUrl(`/divoc/api/reports/${id}/`);
  // },
};

export default reportsAPI;
