/**
 * For caching of proxies requests
 * updates will remove cache and get proxies again
 * components need to watch getters.proxies
 */
import customerProxiesAPI from 'API/customerProxies';
import { uniqBy } from 'Utils/general';
import Paginator from 'Utils/Paginator';
import { markLabel, labelHit } from 'Utils/tcselectHelpers';

const proxyOption = (proxy) => ({ value: proxy.id, label: proxy.internal_name || proxy.name });

// eslint-disable-next-line consistent-return
async function getAllCustomerProxies(response, oldPagObj = {}) {
  const promise = Promise.resolve(response);
  const pag = new Paginator(promise);
  const pagObj = await pag;

  const mergedPaginator = {
    ...pagObj,
    results: uniqBy([
      ...(oldPagObj?.results || []),
      ...(pagObj?.results || []),
    ], 'id'),
  };
  if (pag.hasNext()) {
    const nextResponse = await pag.next();
    return getAllCustomerProxies(nextResponse, mergedPaginator);
  }
  return mergedPaginator;
}

export default {
  state: {
    customerProxies: {}, // Paginated proxies under customerId
    fetchingPromise: Promise.resolve(),
    fetchingProxies: true,
  },
  mutations: {
    SET_PROXIES(state, { customerId, customerProxiesPaginator }) {
      state.customerProxies[customerId] = customerProxiesPaginator;
    },
    CLEAR_PROXIES(state, customerId) {
      if (typeof customerId === 'number') {
        state.customerProxies[customerId] = [];
      }
    },
    SET_PROXIES_FETCH_PROMISE(state, fetchPromise) {
      state.fetchingPromise = fetchPromise;
    },
    SET_PROXIES_FETCH_STATE(state, fetchState) {
      state.fetchingProxies = fetchState;
    },
  },
  getters: {
    fetchingProxies: (state) => state.fetchingProxies,
    customerProxies: (state, getters) => getters.customerId
    && state.customerProxies?.[getters.customerId]?.results
    || [],
    customerProxiesOptions: (state, getters) => (term = '') => state.fetchingPromise
      .then(() => getters.customerProxies
        .map(proxyOption)
        .filter(labelHit(term))
        .map(markLabel(term))),
    getProxyById: (state, getters) => (id) => getters.customerId
    && state.customerProxies[getters.customerId]?.results.find((b) => b.id === id),
    // customerProxiesPaginator: (state, getters) => getters.customerId && state.customerProxies[getters.customerId],
  },
  actions: {
    getAllProxies: async ({
      dispatch, state, getters, commit,
    }, customerId) => {
      const custId = customerId ?? getters?.customerId;
      if (custId) {
        if (state.customerProxies?.[custId]?.results.length > 0) {
          const resolvedPromise = Promise.resolve(state.customerProxies?.[custId]?.results);
          commit('SET_PROXIES_FETCH_PROMISE', resolvedPromise);
          commit('SET_PROXIES_FETCH_STATE', false);
          if (getters.segmentShowsOneProxy) {
            const singleProxy = state.customerProxies?.[custId]?.results
              .find((proxy) => proxy.id === getters.segmentShowsOneProxy) || false;
            if (singleProxy) dispatch('setCustomerNameTextFilter', singleProxy.name);
          }
          return resolvedPromise;
        }
        const proxiesPromise = customerProxiesAPI.list(custId, {}, true);
        const response = await proxiesPromise;
        const customerProxiesPaginator = await getAllCustomerProxies(response);
        dispatch('addStepDisplayNames', customerProxiesPaginator.results);
        commit('SET_PROXIES', { customerId: custId, customerProxiesPaginator });
        commit('SET_PROXIES_FETCH_PROMISE', proxiesPromise);
        commit('SET_PROXIES_FETCH_STATE', false);
        if (getters.segmentShowsOneProxy) {
          const singleProxy = customerProxiesPaginator?.results
            .find((proxy) => proxy.id === getters.segmentShowsOneProxy) || false;
          if (singleProxy) dispatch('setCustomerNameTextFilter', singleProxy.name);
        }
        return proxiesPromise;
      }
      return new Error('[TC] getAllProxies needs a customerId');
    },
  },
};

// fetchProxies({ dispatch, getters, commit }, customerId) {
//   const custId = customerId ?? getters.customerId;
//   let proxiesProm;

//   if (custId) {
//     if (getters?.customerProxies?.length > 0) {
//       const resolvedPromise = Promise.resolve(getters?.customerProxies);
//       commit('SET_PROXIES_FETCH_STATE', resolvedPromise);
//       return resolvedPromise;
//     }
//     proxiesProm = customerProxiesAPI.list(custId, {}, true)
//       .then((customerProxiesPaginator) => {
//         dispatch('addStepDisplayNames', customerProxiesPaginator); //! make sure this works
//         commit('SET_PROXIES', { customerId: custId, customerProxiesPaginator });
//         commit('SET_PROXIES_FETCH_STATE', proxiesProm);
//       });

//     return proxiesProm;
//   }
//   return new Error('[TC] fetchProxies needs a customerId');
// },
// setCustomerProxies({ getters, state, commit }, customerProxiesPaginator) {
//   const custId = getters?.customerId;
//   // eslint-disable-next-line no-console
//   if (!custId || isEmpty(customerProxiesPaginator)) console.error(new Error('[TC] setCustomerProxies needs customer and customerProxiesPaginator'));
//   state.fetchingProxies.then(() => {
//     commit('SET_PROXIES', { customerId: custId, customerProxiesPaginator });
//   });
// },
