import { defer, getBaseUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

class BulkFetcher {
  constructor(baseUrl, urlKey = 'id__in') {
    if (baseUrl === undefined) {
      throw new Error('baseUrl is not defined.');
    }
    if (typeof baseUrl !== 'string') {
      throw new Error('baseUrl is not a string.');
    }
    this.baseUrl = baseUrl;
    this.promises = {};
    this.urlKey = urlKey;
  }

  fetch() {
    return getBaseUrl(this.baseUrl)
      .query({ [this.urlKey]: Object.keys(this.promises).join(',') })
      .then((response) => response.body.results, handleAuthError)
      .then((results) => {
        // resolve promises in result
        results.forEach((question) => {
          this.promises[question.id].resolve(question);
          delete this.promises[question.id];
        });
        // reject all promises left
        Object.keys(this.promises).forEach((id) => {
          this.promises[id].reject(new Error(`Item(${id}) not found.`));
        });
        return results;
      });
  }

  add(id) {
    if (id === undefined) {
      throw new Error('ID is not defined.');
    }
    if (this.promises[id] === undefined) {
      this.promises[id] = defer();
    }
    return this.promises[id].promise;
  }
}

export default BulkFetcher;
