import { getBaseUrl } from 'Utils/api';
import { superagentFilter } from 'Utils/apiHelpers';
import { handleAuthError } from 'Utils/authHelpers';

/**
 ** tagValuesAPI.list() - Lists all available tags values in a paginated list with filter support.
 *
 * @param {String} key - the tagKey to list tagValues for
 * @param {Object} listArgs - the object for listArgs
 *
 * @param {Integer} [listArgs.segment=null] - the segmentId, for getting tagValues based on segment level
 * @param {Integer} [listArgs.customer=null] - the customerId, for getting tagValues based on customer level
 * @param {String} [listArgs.search=null] - the term to search tagValues for
 * @param {Object} [listArgs.filter=null] - a filter to restrict what tagValues to return
 * @param {Boolean} [listArgs.paginated=false] - true if response.body should contain `.next`
 *
 * @returns {Promise} (un-)paginated response from `/feedback/api/tags/values/`
*/
function list(key, {
  segment = null,
  search = null,
  filter = null,
  customer = null,
  paginated = false,
} = {}) {
  let params = { key, segment };
  if (search?.length > 0) {
    params.search = search;
  }
  if (customer) {
    params.customer = customer;
  }
  return getBaseUrl('/feedback/api/tags/values/')
    .query(params)
    .use(superagentFilter(filter))
    .then((response) => (
      paginated && response.body.next !== undefined ? response.body : response.body.results
    ), handleAuthError);
}
export default {
  list,
};
