<template>
  <div class="my-3 pl-5">
    <div class="flex-row align-items-baseline pb-1">
      <h4 class="flex-col-xs-6">
        {{ listTypeTitle.plural }}
        <i
          v-show="loading"
          class="zmdi zmdi-spinner zmdi-hc-spin"
        />
      </h4>
      <div class="flex-col-xs-6 text-right">
        <div class="btn-group">
          <a
            v-tooltip="newSubText.tooltip"
            class="btn btn-icon-text btn-ghost"
            tabindex
            @click="newBoardOrMember"
          >
            <i class="zmdi zmdi-plus" />
            <span>{{ newSubText.headline }}</span>
          </a>
        </div>
      </div>
    </div>
    <template v-if="listType === 'user'">
      <user-list
        user-type="segment"
        :single-segment-id="singleSegmentId"
      />
    </template>
    <template v-if="listType === 'board'">
      <div class="flex-row">
        <div class="flex-col-xs-12">
          <ul class="tc-list-group">
            <li
              v-if="loading"
              class="page-content page-content-message-sm mb-0"
            >
              Loading...
            </li>
            <li
              v-else-if="boards.length <= 0"
              class="page-content page-content-message-sm mb-0"
            >
              {{ emptyListText }}
            </li>
            <li
              v-for="board in boards"
              v-else
              :key="board.id"
              class="tc-list-group-item"
            >
              <div class="item-row item-row-nowrap">
                <span class="item-col my-1">
                  <i
                    v-if="board?.is_overview"
                    v-tooltip="$pgettext('Tooltip - Compass icon', 'Översikt — Visas som första rapport')"
                    class="zmdi zmdi-compass cursor-help mr-0.5"
                  />
                  <i
                    v-if="!board.is_public"
                    v-tooltip="$pgettext('Tooltip - Key icon', 'Privat — Visas endast för dig')"
                    class="zmdi zmdi-key cursor-help mr-0.5"
                  />
                  <content-editable
                    :content="board.name"
                    :is-editing="isEditingNameId === board.id"
                    @cancel="isEditingNameId = null"
                    @save="onSaveName($event, board)"
                  />
                </span>
                <div
                  v-if="segmentCanEdit"
                  class="chip-set chip-set-outline chip-set-sm"
                >
                  <VMenu
                    :delay="0"
                    :distance="4"
                    :triggers="['click', 'hover']"
                    no-auto-focus
                    placement="left"
                    style="display:inline;"
                  >
                    <chip icon="zmdi-edit">
                      {{ listActionText.edit }}
                    </chip>

                    <template #popper>
                      <dropdown-board-settings
                        :board="board"
                        :disabled-buttons="false"
                        @edit-name="() => isEditingNameId = board.id"
                        @fetch-boards="load"
                      />
                    </template>
                  </VMenu>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { klona } from 'klona';
import { list as customerInviteList } from 'API/customerUserInvitation';
import { list as segmentInviteList } from 'API/segmentUserInvitation';
import segmentUserAPI from 'API/segmentUser';
import customerUserAPI from 'API/customerUser';
// import { newBoardForSegment } from 'API/boards';
import { sortBoardsFn } from 'API/boards';
import { flatten } from 'Utils/array';
import eventBus from 'Utils/eventBus';
import dynamicImport from 'Utils/dynamicImport';
import Chip from 'Components/parts/chips/Chip';
import UserList from 'Components/parts/user/UserList';
import ContentEditable from 'Components/parts/widgets/ContentEditable';
import DropdownBoardSettings from 'Components/parts/dropdowns/DropdownBoardSettings';

export default {
  components: {
    Chip,
    UserList,
    ContentEditable,
    DropdownBoardSettings,
  },
  props: {
    listType: {
      type: String,
      default: '', // user or board
      validator(value) {
        // The value must match one of these strings
        return ['user', 'board'].indexOf(value) !== -1;
      },
    },
    singleSegmentId: {
      required: true,
      type: Number,
      default: -1,
    },
    userType: {
      required: false,
      type: String,
      default: 'segment',
    },
  },
  emits: ['loading'],
  data() {
    return {
      boards: [],
      invites: [],
      members: [],
      removing: false,
      loadingInvites: false,
      loadingMembers: false,
      isEditingNameId: null,
      listInvitesAPI: () => ([]),
    };
  },
  computed: {
    ...mapGetters([
      'getBoardsBySegmentId',
      'fetchingBoards',
      'segmentCanEdit',
      'customerId',
      'segment',
      'role',
      'me',
    ]),
    hasUsers() {
      return this.members.length + this.invites.length > 0;
    },
    loading() {
      if (this.listType === 'board') return this.fetchingBoards;
      if (this.listType === 'user') return this.loadingInvites || this.loadingMembers;
      return false;
    },
    newSubText() {
      return {
        headline: this.$gettextInterpolate(
          this.$pgettext('Button — new board/user', 'Ny %{type}'),
          { type: String(this.listTypeTitle.singular).toLowerCase() },
        ),
        tooltip: this.$gettextInterpolate(
          this.$pgettext('Tooltip — new board/user', 'Lägg till en ny %{type}'),
          { type: String(this.listTypeTitle.singular).toLowerCase() },
        ),
      };
    },
    emptyListText() {
      return this.$gettextInterpolate(
        this.$pgettext('Placeholder — no boards/users to show', 'Det finns inga %{type} för detta segment ännu.'),
        { type: String(this.listTypeTitle.plural).toLowerCase() },
      );
    },
    listActionText() {
      return {
        edit: this.$gettextInterpolate(
          this.$pgettext('Button — edit sub list type', 'Redigera %{type}'),
          { type: String(this.listTypeTitle.singular).toLowerCase() },
        ),
        remove: this.$gettextInterpolate(
          this.$pgettext('Button — remove sub list type', 'Ta bort %{type}'),
          { type: String(this.listTypeTitle.singular).toLowerCase() },
        ),
      };
    },
    listTypeTitle() {
      if (this.listType === 'user') {
        return {
          singular: this.$pgettext('Fragment — singular', 'Användare'),
          plural: this.$pgettext('Fragment — plural', 'Användare'),
        };
      }
      if (this.listType === 'board') {
        return {
          singular: this.$pgettext('Fragment — singular', 'Rapport'),
          plural: this.$pgettext('Fragment — plural', 'Rapporter'),
        };
      }
      return this.listType;
    },
  },
  created() {
    eventBus.$on('removed-board', this.load);
  },
  unmounted() {
    eventBus.$off('removed-board', this.load);
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions([
      'fetchAllBoardsBySegmentId',
      'notify',
      'openModal',
    ]),
    async onSaveName(newName, board) {
      this.isEditingNameId = null;
      try {
        const newBoard = klona(board);
        if (typeof newBoard.save !== 'function') throw new Error(newName);
        newBoard.name = newName;
        await newBoard.save();
        this.load();
      } catch (err) {
        this.notify({
          type: 'pop',
          level: 'warning',
          text: this.$gettext(
            'Kunde inte spara: %{msg}',
            { msg: err?.response?.body?.detail ?? err?.message ?? err },
          ),
        });
      }
    },
    newBoardOrMember() {
      if (this.listType === 'user') this.$router.push({ name: 'segmentSettingsInvite', params: { segmentId: this.singleSegmentId } }).catch((err) => {});
      else if (this.listType === 'board') this.addBoard();
    },
    addBoard() {
      // TODO: this is an interim solution. When the new dialog is done, [addBoard, newBoardForSegment, prompt] are no longer needed.
      const AddBoardDetails = dynamicImport(() => import(/* webpackChunkName: "AddBoardDetails", webpackPrefetch: true */ 'Components/parts/details/AddBoardDetails'));
      this.openModal({
        name: 'AddBoardDetails',
        component: AddBoardDetails,
        position: 'center',
        dataObject: {
          props: {
            segmentId: this.singleSegmentId,
          },
        },
        callback: (newDataObject) => {
          if (newDataObject?.boardId) {
            if (newDataObject?.boardId === undefined) return;
            this.load();
          }
        },
      });
    },
    fetchInvites() {
      this.loadingInvites = true;
      this.$emit('loading', this.loading);
      this.listInvitesAPI(this.scopeId)
        .then((invites) => {
          this.invites = invites;
        })
        .then(() => {
          this.loadingInvites = false;
          this.$emit('loading', this.loading);
        });
    },
    isLastInvite(index) {
      return index + 1 === this.invites.length;
    },
    getMembers() {
      this.loadingMembers = true;
      this.members = [];
      let su = segmentUserAPI.list({ segmentId: this.singleSegmentId });
      let cu = customerUserAPI.list({ customer: this.customerId, excludeFromSegment: this.singleSegmentId });
      Promise.all([su, cu])
        .then((members) => {
          this.members = flatten(members);
          this.loadingMembers = false;
        });
    },
    load() {
      this.$nextTick(() => {
        if (this.listType === 'board') {
          this.fetchAllBoardsBySegmentId({ segmentId: this.singleSegmentId })
            .then((res) => {
              // this.boards = res; // boards (res) are returned in the then function of fetchBoardsBySegmentId
              this.boards = klona(this.getBoardsBySegmentId(this.singleSegmentId))?.sort(sortBoardsFn);
            });
        }
        if (this.listType === 'user') {
          this.scopeId = this.userType === 'segment' ? this.singleSegmentId : this.customerId;
          this.listInvitesAPI = this.userType === 'segment' ? segmentInviteList : customerInviteList;
          this.api = this.userType === 'segment' ? segmentUserAPI : customerUserAPI;
          this.getMembers();
          this.fetchInvites();
        }
      });
    },
  },
};
</script>
