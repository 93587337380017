export function dataLayerPush(data) {
  if (window.dataLayer) {
    window.dataLayer.push(data);
  }
}

function googleAnalytics(...args) {
  if (process.env.NODE_ENV !== 'production') {
    /* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */
    console.log('[TC] GA Send', args);
  } else if (window.ga && typeof window.ga === 'function') {
    window.ga(...args);
  }
}

// Add dataLayer method to googleAnalytics
googleAnalytics.dataLayerPush = dataLayerPush;

export default googleAnalytics;
