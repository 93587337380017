<template>
  <footer class="login-footer info-text small-text">
    <!-- eslint-disable -->
    <svg
      class="logo-symbol"
      alt="Trustcruit logo symbol"
      width="30px"
      height="35px"
      viewBox="0 0 30 35"
      version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.414"
    >
      <path
        d="M14.992 29.952c-6.568 0-10.22-.001-12.606-2.383C0 25.186 0 21.538 0 14.975 0 8.413 0 4.765 2.386 2.382 4.772-.001 8.426-.001 15-.001c6.574 0 10.228 0 12.614 2.383C30 4.765 30 8.412 30 14.975s0 10.211-2.387 12.594c-1.147 1.145-2.586 1.74-4.502 2.049v4.702l-8.12-4.368zM14.6 5.38a.447.447 0 01.797 0l2.609 5.144a.75.75 0 00.547.4l5.672.904a.45.45 0 01.246.762l-4.06 4.084a.75.75 0 00-.209.648l.897 5.703a.451.451 0 01-.179.434.445.445 0 01-.466.037l-5.117-2.62a.744.744 0 00-.677 0l-5.117 2.62a.446.446 0 01-.466-.037.452.452 0 01-.18-.434l.898-5.703a.75.75 0 00-.21-.648l-4.059-4.083a.45.45 0 01.246-.763l5.672-.904a.749.749 0 00.548-.4L14.6 5.38z"
        fill="#088"
      />
    </svg>
    <!-- eslint-enable -->
    <br>
    <slot>
      <p v-html="loginInformationText" />
      <router-link
        v-if="loginLink"
        to="/login"
      >
        {{ $gettext('Gå till login') }}
      </router-link>
    </slot>
  </footer>
</template>

<script>
export default {
  props: {
    loginLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    loginInformationText() {
      return this.$gettext(`Du behöver en inbjudan för att kunna logga in på Trustcruits dashboard.
        Be din administratör om en inbjudan eller
        <a href="mailto:support@trustcruit.com">kontakta oss</a>
        för mer information.`.replace(/\s+/g, ' '));
    },
  },
};
</script>
