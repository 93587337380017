import { getIndexHtml } from 'Utils/api';

export var previousHtml; // eslint-disable-line import/no-mutable-exports, no-var

// ? Won't run correctly for the first time unless initializeHtml: true has been run
export async function isNewerSPAVersionAvailable(initializeHtml = false) {
  try {
    const response = await getIndexHtml();

    if (!response) throw new Error('no response');
    if (response.status !== 200) throw new Error('offline');
    const html = response.text;

    if (initializeHtml) {
      previousHtml = html;
      return false;
    }
    if (!previousHtml) return false; // ? needs to be initialized first
    if (previousHtml !== html) return true; // ? new version is available
    return false;
  } catch (err) {
    return false;
  }
}

export default isNewerSPAVersionAvailable;
