<template>
  <card
    ref="cardEl"
    :is-overlayed="isOverlayed"
    :custom-card-class="!title ? 'tc-card-header-fixed hide-print' : ''"
    enable-overlay
  >
    <template #header>
      <div
        v-if="possibleActions && possibleActions.length > 0 || title"
        class="text-card-header p-card"
      >
        <div class="flex-apart flex-row-actions align-items-center justify-space-between">
          <div>
            <h3
              v-if="title"
              class="h2 text-wrap"
              v-text="title"
            />
          </div>
          <section
            v-if="possibleActions && possibleActions.length > 0"
            class="filter-popover-boxes filter-popover-btns hide-print"
          >
            <template v-if="possibleActions.indexOf('editButton') > -1">
              <button
                v-tooltip="$gettext('Ändra cardet')"
                class="btn btn-primary btn-thin btn-icon-text"
                :tabindex="isOverlayed ? -1 : null"
                @click.prevent="openModal"
              >
                <i class="zmdi zmdi-edit ml-0" /> <span>{{ $gettext('Ändra') }}</span>
              </button>
            </template>
            <template v-if="possibleActions.indexOf('removeButton') > -1">
              <button
                v-tooltip="$gettext('Ta bort cardet')"
                class="btn btn-failure btn-thin btn-icon btn-icon-sm"
                :tabindex="visible ? null : -1"
                @click.prevent="deleteCard"
              >
                <i class="zmdi zmdi-delete ml-0" />
              </button>
            </template>
          </section>
        </div>
      </div>
    </template>
    <template #body>
      <div class="tc-card-body">
        <div
          v-if="isRemovingCard"
          class="p-card"
        >
          <div class="alert alert-info mb-0">
            {{ $gettext('Tar bort...') }}
          </div>
        </div>
        <editor-content
          v-else
          :content="content"
          @loading="emit('loaded', $event)"
        />
      </div>
    </template>
  </card>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useResizeObserver } from '@vueuse/core';
import dynamicImport from 'Utils/dynamicImport';
import eventBus from 'Utils/eventBus';
import { confirm } from 'Modal/dialogBoxes';
import { EditorContent } from 'Components/parts/widgets/TipTap';
import Card from 'Components/parts/Card';
import utils from 'Components/parts/widgets/TipTap/utils';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext, $pgettext } = gettext;

const props = defineProps({
  actions: Array,
  card: Object,
  board: Object,
  enableOverlay: Boolean,
  visible: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['loaded']);
const cardEl = ref(null);
const observer = ref(null);

const content = computed(() => utils.unSanitizeDoc(props.card.metadata.markdownDescription));
const title = computed(() => props.card.metadata.name);
const isOverlayed = computed(() => store.state.boards.isOrganizing);
const possibleActions = computed(() => props.actions.filter((action) => !['downloadPng'].includes(action)));

const isRemovingCard = ref(false);

onMounted(() => {
  const overlay = cardEl.value.$el.querySelector('.tc-card-overlay-body');
  const cardBody = cardEl.value.$el.querySelector('.tc-card-body');

  observer.value = useResizeObserver(overlay, (entries) => {
    const entry = entries[0];
    cardBody.style.minHeight = `${entry.target.clientHeight}px`;
  });
});

onUnmounted(() => {
  observer.value.stop();
});

const deleteCard = () => confirm({
  title: $pgettext('Confirm — Delete card title', 'Är du säker på att du vill ta bort cardet?'),
  confirmText: $pgettext('Confirm — Delete card confirmText', 'Ta bort card'),
  dismissText: $pgettext('Confirm — Delete card dismissText', 'Avbryt'),
  theme: 'danger',
})
  .then((confirmed) => {
    if (!confirmed) return Promise.resolve();
    isRemovingCard.value = true;
    return store.dispatch('removeCard', ({ cardId: props.card.id, card: props.card }))
      .then(() => { eventBus.$emit('remove-card', props.card); })
      .catch((err) => {
        isRemovingCard.value = false;
        store.dispatch('notify', ({
          type: 'pop',
          level: 'error',
          text: $gettext('Kunde inte ta bort cardet. %{err}', { err: err?.response?.body?.detail || err?.response || err }),
        }));
      });
  });

const openModal = () => {
  const AddTextDetails = dynamicImport(
    () => import(
      /* webpackChunkName: "AddTextDetails", webpackPrefetch: true */ 'Components/parts/details/AddTextDetails'
    ),
  );

  store.dispatch('openModal', {
    name: 'AddTextDetails',
    component: AddTextDetails,
    dataObject: {
      props: {
        card: props.card,
      },
    },
    size: 'large',
  });
};
</script>
