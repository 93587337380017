import { localStorageIsAvailable } from 'Utils/storage';

const intercomDashboardTourSV = 239191;
const intercomDashboardTourEN = 237070;
let tries = 0;

const startDashboardTour = () => {
  if (process.env.NODE_ENV !== 'production') return;
  if (window?.Intercom) {
    let intercomDashboardTour = intercomDashboardTourEN;
    if ((document?.documentElement?.lang || '').substring(0, 2) === 'sv') intercomDashboardTour = intercomDashboardTourSV;
    window.Intercom('startTour', intercomDashboardTour);
  } else if (tries < 3) {
    tries++;
    setTimeout(startDashboardTour, 500);
  }
};

export function clearIntercomStorage() {
  if (window?.Intercom) window.Intercom('shutdown');
  if (localStorageIsAvailable) {
    localStorage.removeItem('intercom-state');
    localStorage.removeItem('intercom.intercom-state');
  }
}

export default {
  startDashboardTour,
  clearIntercomStorage,
};
