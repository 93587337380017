import { handleAuthError } from 'Utils/authHelpers';
import AutoBulkFetcher from 'Utils/autoBulkFetcher';
import { createCacheKey } from 'Utils/general';
import { processStats } from 'Utils/stat';

let cache = {};
let statsBf = new AutoBulkFetcher('/shinyform/api/answers/stats_global/', 'question__in');

function stats(question, filter) {
  let cacheKey = createCacheKey([question, filter.date, filter.customer]);
  let cachePromise = cache[cacheKey];
  if (cachePromise instanceof Promise) {
    return cachePromise;
  }
  let promise = statsBf.add(question, null, filter)
    .catch(handleAuthError)
    .then(processStats(question, filter), () => ({}));
  cache[cacheKey] = promise;
  return promise;
}

export default {
  stats,
};
