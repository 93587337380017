import { isEmpty } from 'lodash-es';
import tagsAPI from 'API/tags';
import tagValuesAPI from 'API/tagValues';
import { markLabel, labelHit } from 'Utils/tcselectHelpers';
import { store } from '@/store';

const mergePaginators = (statePaginator, newPaginator) => ({
  ...newPaginator,
  results: Array.from(new Set([ // Removes duplicates, cuz of finicky solution     otherwise
    ...(statePaginator?.results || []),
    ...(newPaginator.results || []),
  ])),
});

const createUniqueArray = (stateArray, newArray) => {
  const mergedArray = [
    ...(stateArray || []),
    ...(newArray || []),
  ];

  return mergedArray.reduce((acc, current) => {
    const duplicateItemIndex = acc.findIndex((item) => Object.keys(item).every((key) => item[key] === current[key]));
    const currentLength = Object.keys(current).length;
    const existingLength = Object.keys(acc[duplicateItemIndex] || {}).length;
    if (duplicateItemIndex === -1) {
      acc.push(current);
    } else if (currentLength > existingLength) {
    /*
      Swap existing item with a new one if it has more properties. This ensures the new item is fully populated,
      even if getTagKeyTranslation previously set the existing item with fewer keys due to a missing tagKey.
    */
      acc[duplicateItemIndex] = current;
    }
    return acc;
  }, []);
};

const setTagFn = (state, id, tagPaginator, type, tagKey, searchTerm = '') => {
  const usedTagKey = searchTerm ? `${tagKey}-${searchTerm}` : tagKey;
  if (usedTagKey) {
    if (state[type]?.[id]?.[usedTagKey]) {
      const mergedPaginator = mergePaginators(state[type][id][usedTagKey], tagPaginator);
      state[type][id][usedTagKey] = { ...state[type][id][usedTagKey], ...mergedPaginator }; // eslint-disable-line max-len
    } else if (state?.[type]?.[id]) {
      state[type][id][usedTagKey] = tagPaginator;
    } else {
      const tagValuesPaginator = { [usedTagKey]: tagPaginator };
      state[type][id] = tagValuesPaginator;
    }
  } else if (state[type]?.[id]) {
    const uniqueArray = createUniqueArray(state[type][id].results, tagPaginator.results);
    const mergedPaginator = { ...tagPaginator, results: uniqueArray };
    state[type][id] = { ...state[type][id], ...mergedPaginator }; // eslint-disable-line max-len
  } else {
    state[type][id] = tagPaginator;
  }
};

export default {
  state: {
    segmentTagKeys: {}, // Paginated Segment-Tags (keys) under segmentId
    customerTagKeys: {}, // Paginated Customer-Tags (keys) under customerId
    segmentTags: {}, // Paginated Segment-Tags (keys & values) under segmentId
    customerTags: {}, // Paginated Customer-Tags (keys & values) under customerId
    fetchingTags: Promise.resolve(), // Used for both segmentTags & customerTags
    fetchingTagKeyList: new Map(),
    segmentTagsLatestFetch: null,
    customerTagsLatestFetch: null,
    fetchingTagKeys: Promise.resolve(), // Used for both segmentTags & customerTags
    segmentTagKeysLatestFetch: null,
    customerTagKeysLatestFetch: null,
  },
  mutations: {
    SET_TAG_KEYS(state, { segmentId, customerId, tagKeysPaginator }) {
      if (customerId) setTagFn(state, customerId, tagKeysPaginator, 'customerTagKeys');
      else if (segmentId) setTagFn(state, segmentId, tagKeysPaginator, 'segmentTagKeys');
    },
    SET_TAG(state, { segmentId, customerId, tagKey, tagValuesPaginator, searchTerm = '' }) {
      if (customerId) setTagFn(state, customerId, tagValuesPaginator, 'customerTags', tagKey, searchTerm);
      else if (segmentId) setTagFn(state, segmentId, tagValuesPaginator, 'segmentTags', tagKey, searchTerm);
    },
    SET_TAGS_FETCH_PROMISE(state, { fetchState, tagValues = true }) {
      if (tagValues) {
        state.fetchingTags = fetchState;
      } else state.fetchingTagKeys = fetchState;
    },
    SET_TAGS_LATEST_FETCH(state, { latestFetchDate, customer, tagValues = true }) {
      if (tagValues) {
        if (customer) state.customerTagsLatestFetch = latestFetchDate;
        else state.segmentTagsLatestFetch = latestFetchDate;
      } else if (customer) state.customerTagKeysLatestFetch = latestFetchDate;
      else state.segmentTagKeysLatestFetch = latestFetchDate;
    },
    CLEAR_TAGS_CACHE(state) {
      state.segmentTagKeys = {};
      state.segmentTags = {};
      state.customerTagKeys = {};
      state.customerTags = {};
      state.segmentTagsLatestFetch = null;
      state.customerTagsLatestFetch = null;
    },
    SET_FETCHING_TAG_KEY(state, value) {
      state.fetchingTagKeyList.set(value);
    },
    REMOVE_FETCHING_TAG_KEY(state, value) {
      state.fetchingTagKeyList.delete(value);
    },
  },
  getters: {
    fetchingTagValues: (state) => state.fetchingTags,
    fetchingTagKeys: (state) => state.fetchingTagKeys,
    segmentTagKeyVal: (state, getters) => {
      if (getters.segmentId && state.segmentTagKeys[getters.segmentId]?.results) {
        const result = state.segmentTagKeys[getters.segmentId]?.results
          .reduce((acc, obj) => {
            const key = obj.key;
            const value = obj.translation || obj.value;
            return { ...acc, [key]: value };
          }, {});
        return result;
      }
      return {};
    },
    customerTagKeyVal: (state, getters) => {
      if (getters.customerId && state.customerTagKeys[getters.customerId]?.results) {
        const result = state.customerTagKeys[getters.customerId]?.results
          .reduce((acc, obj) => {
            const key = obj.key;
            const value = obj.value;
            return { ...acc, [key]: value };
          }, {});
        return result;
      }
      return {};
    },
    tagKeys: (state, getters) => (customer = false) => (customer
      ? Object.keys(getters.customerTagKeyVal)
      : Object.keys(getters.segmentTagKeyVal)),
    segmentTagValues: (state, getters) => (tagKey) => getters.segmentId
      && state.segmentTags[getters.segmentId]?.[tagKey]?.results
      || [], // Ex. this.segmentTagValues('City') >>> ['Sthlm']
    customerTagValues: (state, getters) => (tagKey) => getters.customerId
      && state.customerTags[getters.customerId]?.[tagKey]?.results
      || [], // Ex. this.customerTagValues('City') >>> ['Sthlm']
    segmentTagValuesPaginator: (state, getters) => (tagKey) => getters.segmentId
      && state.segmentTags[getters.segmentId]?.[tagKey]
      || {}, // Ex. this.segmentTagValuesPaginator('City') >>> {'next':'https://...', 'previous':null, 'results':['Sthlm']}
    customerTagValuesPaginator: (state, getters) => (tagKey) => getters.customerId
      && state.customerTags[getters.customerId]?.[tagKey]
      || {}, // Ex. this.customerTagValuesPaginator('City') >>> {'next':'https://...', 'previous':null, 'results':['Sthlm']}
    segmentTagKeysPaginator: (state, getters) => getters.segmentId
      && state.segmentTagKeys[getters.segmentId]
      || {},
    customerTagKeysPaginator: (state, getters) => getters.customerId
      && state.customerTagKeys[getters.customerId]
      || {},
    tagOptions: (state, getters) => (term, tags = getters.segmentTagKeysPaginator, responseUsesArray = false) => {
      if (!tags) return {};
      return {
        ...tags,
        results: tags?.results
          ?.map((entry) => tagsAPI.selectTag(responseUsesArray ? { value: entry } : entry))
          .filter(labelHit(term))
          .map(markLabel(term)) || [],
      };
    },
    segmentTagKeyOptions: (state, getters) => (term) => state.fetchingTagKeys
      .then(() => getters.tagOptions(term, getters.segmentTagKeysPaginator, false)),
    customerTagKeyOptions: (state, getters) => (term) => state.fetchingTagKeys
      .then(() => getters.tagOptions(term, getters.customerTagKeysPaginator, false)),
    segmentTagValuesOptions: (state, getters) => (tagKey, term) => state.fetchingTags
      .then(() => getters.tagOptions(term, getters.segmentTagValuesPaginator(tagKey), true)),
    customerTagValuesOptions: (state, getters) => (tagKey, term) => state.fetchingTags
      .then(() => getters.tagOptions(term, getters.customerTagValuesPaginator(tagKey), true)),
  },
  actions: {
    clearTagsCache({ commit }) {
      commit('CLEAR_TAGS_CACHE');
    },
    async segmentTagKeyTranslation({ state, getters }, tagKey) {
      if (getters?.segmentTagKeyVal?.[tagKey]) return getters.segmentTagKeyVal[tagKey];
      if (state.fetchingTagKeyList.has(tagKey)) return tagKey;
      return store.dispatch('getTagKeyTranslation', { customer: false, tagKey }) || tagKey;
    },
    async customerTagKeyTranslation({ state, getters }, tagKey) {
      if (getters?.customerTagKeyVal?.[tagKey]) return getters.customerTagKeyVal[tagKey];
      if (state.fetchingTagKeyList.has(tagKey)) return tagKey;
      return store.dispatch('getTagKeyTranslation', { customer: true, tagKey }) || tagKey;
    },
    async getTagKeyTranslation({ getters, commit, state }, { customer = false, tagKey = '' }) {
      if (!tagKey) return {};
      const isCustomer = customer ? { customer: getters.customerId } : { segment: getters.segmentId };
      if (state.fetchingTagKeyList.has(tagKey)) return tagKey;
      let response;
      try {
        commit('SET_FETCHING_TAG_KEY', tagKey);
        response = await tagsAPI.get({ ...isCustomer, tagKey });
        if (!Object.keys(response).length) {
          response = isCustomer ? { key: tagKey, value: tagKey } : { key: tagKey, translation: tagKey };
        }
      } catch (error) {
        response = {};
      } finally {
        commit('SET_TAG_KEYS', { ...(customer ? { customerId: getters.customerId } : { segmentId: getters.segmentId }), tagKeysPaginator: { results: [response] } });
        commit('REMOVE_FETCHING_TAG_KEY', tagKey);
      }
      return response;
    },
    fetchTagKeys({ state, getters, commit }, customer = false) {
      const ONE_HOUR = 60 * 60 * 1000;
      const id = customer ? getters.customerId : getters.segmentId;
      const tagKeys = customer ? getters.tagKeys(true) : getters.tagKeys(false);
      const latestFetch = customer ? state.customerTagKeysLatestFetch : state.segmentTagKeysLatestFetch;
      const tagKeyVal = customer ? getters.customerTagKeyVal : getters.segmentTagKeyVal;

      if (
        tagKeys?.length > 0
        && latestFetch !== null
        && ((new Date()) - latestFetch) < ONE_HOUR
      ) {
        const resolvedPromise = Promise.resolve(tagKeyVal);
        commit('SET_TAGS_FETCH_PROMISE', { fetchState: resolvedPromise, tagValues: false });
        return resolvedPromise;
      }

      const tagKeysProm = tagsAPI.list({
        [customer ? 'customer' : 'segment']: id,
        paginated: true,
      }).then((tagKeysPaginator) => {
        commit('SET_TAG_KEYS', { [customer ? 'customerId' : 'segmentId']: id, tagKeysPaginator });
      });

      commit('SET_TAGS_FETCH_PROMISE', { fetchState: tagKeysProm, tagValues: false });
      commit('SET_TAGS_LATEST_FETCH', { latestFetchDate: new Date(), customer, tagValues: false });

      return tagKeysProm;
    },
    fetchTagValues({ state, getters, commit }, { tagKey, customer = false, searchTerm = '' }) {
      const ONE_HOUR = 60 * 60 * 1000;
      const usedTagKey = searchTerm ? `${tagKey}-${searchTerm}` : tagKey;
      const id = customer ? getters.customerId : getters.segmentId;
      const latestFetch = customer ? state.customerTagsLatestFetch : state.segmentTagsLatestFetch;
      const tagValues = customer ? getters.customerTagValues(usedTagKey) : getters.segmentTagValues(usedTagKey);

      if (tagKey) {
        if (
          tagValues.length > 0
          && latestFetch !== null
          && ((new Date()) - latestFetch) < ONE_HOUR
        ) {
          const resolvedPromise = Promise.resolve(tagValues);
          commit('SET_TAGS_FETCH_PROMISE', { fetchState: resolvedPromise, tagValues: true });
          return resolvedPromise;
        }

        const tagProm = tagValuesAPI.list(tagKey, {
          [customer ? 'customer' : 'segment']: id,
          paginated: true,
          search: searchTerm,
        })
          .then((tagValuesPaginator) => {
            commit('SET_TAG', { [customer ? 'customerId' : 'segmentId']: id, tagKey, searchTerm, tagValuesPaginator });
          });

        commit('SET_TAGS_FETCH_PROMISE', { fetchState: tagProm, tagValues: true });
        commit('SET_TAGS_LATEST_FETCH', { latestFetchDate: new Date(), customer });

        return tagProm;
      }

      return new Error('[TC] fetchTagValues needs a tagKey');
    },
    setTagValues({ getters, state, commit }, { tagKey, tagValuesPaginator, searchTerm = '', customer = false }) {
      const customerId = getters.customerId;
      const segmentId = getters.segmentId;
      // eslint-disable-next-line no-console
      if (!tagKey || isEmpty(tagValuesPaginator)) console.error(new Error('[TC] setTagValues needs tagKey and tagValuesPaginator'));
      // ? Makes sure potential race condition with fetchTagValues clears first
      state.fetchingTags.then(() => {
        commit('SET_TAG', {
          customerId: customer ? customerId : null,
          segmentId: !customer ? segmentId : null,
          tagKey,
          tagValuesPaginator,
          searchTerm,
        });
      });
    },
    setTagKeys({ getters, state, commit }, { tagKeysPaginator, customer = false }) {
      const customerId = getters.customerId;
      const segmentId = getters.segmentId;
      // eslint-disable-next-line no-console
      if (isEmpty(tagKeysPaginator)) console.error(new Error('[TC] setTagValues needs tagKey and tagValuesPaginator'));
      state.fetchingTagKeys.then(() => {
        commit('SET_TAG_KEYS', {
          customerId: customer ? customerId : null,
          segmentId: !customer ? segmentId : null,
          tagKeysPaginator,
        });
      });
    },
  },
};
