<template>
  <div class="colorpicker">
    <button
      v-for="color in sectionColors"
      :key="color.label"
      type="button"
      class="colorpicker-color"
      :class="`${color.value} ${color.value === modelValue ? 'selected' : ''}`"
      :title="color.value === modelValue
        ? $pgettext('Title - Colorpicker selected color', '%{color}, är vald', { color: color.label })
        : $pgettext('Title - Colorpicker color', '%{color}', { color: color.label })
      "
      @click="emit('update:modelValue', color.value)"
    />
  </div>
</template>

<script setup>
import gettext from '@/gettext';

const { $pgettext } = gettext;

// ? These arbitrary value names should be matching in $section-themes in scss
const sectionColors = [
  { label: $pgettext('Label - Colorpicker color', 'Grå'), value: '' },
  { label: $pgettext('Label - Colorpicker color', 'Lila'), value: 'alberto' },
  { label: $pgettext('Label - Colorpicker color', 'Blå'), value: 'balsalm' },
  { label: $pgettext('Label - Colorpicker color', 'Grön'), value: 'checo' },
  { label: $pgettext('Label - Colorpicker color', 'Gul'), value: 'donny' },
  { label: $pgettext('Label - Colorpicker color', 'Orange'), value: 'ernesto' },
  { label: $pgettext('Label - Colorpicker color', 'Röd'), value: 'fuengirola' },
];

defineProps({
  modelValue: {
    type: String,
    default: '',
    // validator: (val) => sectionColors.find((sC) => sC.value === val),
  },
});
const emit = defineEmits(['update:modelValue']);
</script>
