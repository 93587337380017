import { assign, isArray } from 'lodash-es';
import {
  isWeekend, isSaturday, isSunday, subDays, subMonths, addMonths, endOfMonth, differenceInMonths,
} from 'date-fns';
import { queryList } from 'Utils/apiHelpers';
import { format, adjustForUTCOffset, nowUTC } from 'Utils/dateHelpers';
import { getRestriction /* getFrequency */ } from 'Utils/rrule';
import gettext from '@/gettext';

const { $pgettext } = gettext;

export const DATE_OPTIONS_LOOKUP = {
  // not used anymore
  // '1m': {
  //   type: 'relative',
  //   offset: null,
  //   span: {
  //     months: 1,
  //   },
  // },
  '3m': {
    type: 'relative',
    offset: null,
    span: {
      months: 3,
    },
  },
  '6m': {
    type: 'relative',
    offset: null,
    span: {
      months: 6,
    },
  },
  '12m': {
    type: 'relative',
    offset: null,
    span: {
      months: 12,
    },
  },
  this_year: {
    type: 'relative',
    offset: null,
    span: {
      years: 1,
    },
  },
  last_month: {
    type: 'relative',
    offset: {
      months: 1,
    },
    span: {
      months: 1,
    },
  },
  last_quarter: {
    type: 'relative',
    offset: {
      quarters: 1,
    },
    span: {
      quarters: 1,
    },
  },
  last_year: {
    type: 'relative',
    offset: {
      years: 1,
    },
    span: {
      years: 1,
    },
  },
  all_time: {
    type: 'relative',
    offset: null,
    span: {
      allTime: true,
    },
  },
};

export const FAKE_ABS_REL_DATE_OPTIONS = ['this_year', 'last_month', 'last_quarter', 'last_year'];

export const SENDLOG_DATE_OBJECTS = {
  '62_days_back_and_forward': {
    type: 'relative',
    offset: { days: 62 },
    span: { future: true },
  },
  '60_days_back': {
    type: 'relative',
    offset: null,
    span: { days: 60 },
  },
  this_month: {
    type: 'relative',
    offset: null,
    span: { months: 1 },
  },
  next_month: {
    type: 'relative',
    offset: { months: -1 },
    span: { months: 1 },
  },
};
export const SENDLOG_DATE_OPTIONS_I18N = () => ({
  '62_days_back_and_forward': $pgettext('Option — Sendlog date 62_days_back_and_forward', 'Alla utskicksdatum'),
  '60_days_back': $pgettext('Option — Sendlog date 60_days_back', 'Upp till 60 dagar bakåt'),
  this_month: $pgettext('Option — Sendlog date this_month', 'Denna månad'),
  next_month: $pgettext('Option — Sendlog date next_month', 'Nästa månad'),
});
export const SENDLOG_DATE_OPTIONS_SIDELABEL = () => ({
  this_month: format(nowUTC, 'LLL'),
  next_month: format(addMonths(nowUTC, 1), 'LLL'),
});
export const DEFAULT_SENDLOG_DATE = SENDLOG_DATE_OBJECTS['62_days_back_and_forward'];

// ? Creates an object with currently evaluated dates for DATE_OPTIONS_LOOKUP
const DATE_OPTIONS_LOOKUP_DATES = () => ({
  ...Object.entries(DATE_OPTIONS_LOOKUP)
    .map(([k, v]) => ({
      [k]: { ...queryList('date', v).reduce((acc, val) => assign(acc, val), {}) },
    }))
    .reduce((acc, val) => assign(acc, val), {}),
});

/**
 ** convertDateObjectToQueryDates — For converting dateObject into queriable dates via filterQueryTransforms
 * @param {Object} dateObject, Ex. { type: 'absolute', dateGte, dateLte } or { type: 'relative', span, offset }
 * @param {Object} toq, should it be transformed to a TOQ-type query?
 * @returns {Array} as [{ dateGte, dateLte }]
 */
export function convertDateObjectToQueryDates(dateObject = {}, toq = false) {
  return queryList('date', dateObject, toq).reduce((acc, val) => assign(acc, val), {});
}

export function convertQueryDatesToDateOption({ dateGte, dateLte }, { returnAsKey = false } = {}) {
  if (isArray(dateGte)) dateGte = dateGte[0];
  if (isArray(dateLte)) dateLte = dateLte[0];
  const lookup = DATE_OPTIONS_LOOKUP_DATES();
  const dateOptionIndex = Object.entries(lookup)
    .findIndex(([_, opt]) => dateGte === opt.dateGte && dateLte === opt.dateLte);
  const dateOptionKey = dateOptionIndex > -1 ? Object.keys(DATE_OPTIONS_LOOKUP)[dateOptionIndex] : 'absolute';

  if (returnAsKey) return dateOptionKey;
  return dateOptionKey === 'absolute'
    ? { type: 'absolute', dateGte, dateLte }
    : DATE_OPTIONS_LOOKUP[dateOptionKey];
}

export function getDates(intervalDays, daysBack = null) {
  const dateLte = format((daysBack ? subDays(new Date(), daysBack) : new Date()), 'yyyy-MM-dd');
  const dateGte = format(subDays(new Date(dateLte), intervalDays), 'yyyy-MM-dd');
  return {
    dateLte,
    dateGte,
  };
}

export function convertDateObjectToAbsoluteDates(dateObject) {
  return Object.assign({}, ...queryList('date', dateObject.date));
}

export function convertToCompareDates({ dateLte, dateGte }) {
  const lte = adjustForUTCOffset(new Date(dateLte));
  const gte = adjustForUTCOffset(new Date(dateGte));
  const [fnsGteYear, fnsGteMonth] = dateGte.split('-');
  const [fnsLteYear, fnsLteMonth] = dateLte.split('-');
  const diff = differenceInMonths(new Date(fnsLteYear, fnsLteMonth), new Date(fnsGteYear, fnsGteMonth)) + 1;

  return {
    dateLte: format(subMonths(lte, diff), 'yyyy-MM-dd'),
    dateGte: format(subMonths(gte, diff), 'yyyy-MM-dd'),
  };
}

export function getKeyByDate(date) {
  if (!date) return '';
  const dateOption = Object.entries(DATE_OPTIONS_LOOKUP)
    .find(([_, value]) => JSON.stringify(date.span) === JSON.stringify(value.span)
      && JSON.stringify(date.offset) === JSON.stringify(value.offset));
  return dateOption?.[0] || '';
}

export function getDatesByDate(date, returnAsArray = true) {
  return queryList('date', date).map((entry) => (returnAsArray ? Object.values(entry)[0] : entry));
}

export const getAbsoluteDatesFromDate = (date) => {
  let { dateGte, dateLte } = date; // ? type === 'absolute'
  if (dateGte === undefined || dateLte === undefined) ([dateGte, dateLte] = getDatesByDate(date)); // ? type === 'relative'
  return { dateGte, dateLte };
};

export function getDateFilterPrev(dateObject) {
  // Get the trend/compare/previous values from date filter
  if (dateObject.date) {
    const date = { ...dateObject.date };
    if (Object.entries(date).length === 0) return dateObject;

    if (date.type === 'absolute') {
      const { dateLte, dateGte } = convertToCompareDates(date);
      date.dateLte = dateLte;
      date.dateGte = dateGte;
      return { date };
    }
    if (date.offset !== null) {
      date.offset = Object.keys(date.offset).map((key) => ({ [key]: date.offset[key] * 2 }))[0];
      return { date };
    } if (date.span !== null) {
      // date.span = Object.keys(date.span).map((key) => ({ [key]: date.span[key] * 2 }))[0];
      date.offset = Object.keys(date.span).map((key) => ({ [key]: date.span[key] }))[0];
      return { date };
    }
  }
  return dateObject;
}

export function dateLabels(useInContext = false) {
  return useInContext
    ? {
      '3m': $pgettext('Date - in context', 'De senaste 3 månaderna'),
      '6m': $pgettext('Date - in context', 'De senaste 6 månaderna'),
      '12m': $pgettext('Date - in context', 'De senaste 12 månaderna'),
      this_year: $pgettext('Date - in context', 'Detta året'),
      last_month: $pgettext('Date - in context', 'Förra månaden'),
      last_quarter: $pgettext('Date - in context', 'Förra kvartalet'),
      last_year: $pgettext('Date - in context', 'Förra året'),
      absolute: $pgettext('Date - in context', 'Eget datum'),
      all_time: $pgettext('Date - in context', 'All feedback'),
    } : {
      '3m': $pgettext('Date', 'De senaste 3 månaderna'),
      '6m': $pgettext('Date', 'De senaste 6 månaderna'),
      '12m': $pgettext('Date', 'De senaste 12 månaderna'),
      this_year: $pgettext('Date', 'Detta året'),
      last_month: $pgettext('Date', 'Förra månaden'),
      last_quarter: $pgettext('Date', 'Förra kvartalet'),
      last_year: $pgettext('Date', 'Förra året'),
      absolute: $pgettext('Date', 'Eget datum'),
      all_time: $pgettext('Date', 'All feedback'),
    };
}

export function relativeDate(date) {
  const key = getKeyByDate(date);
  return dateLabels()[key] || key;
}

export function convertDatesToDateObject(dates) {
  if (!dates) return {};
  if (dates[0] === null) return { dateLte: dates[1] };
  return {
    type: 'absolute',
    dateGte: dates[0],
    dateLte: dates[1],
  };
}

function getPreviousMonth(date, lastDay) {
  const previousMonth = subMonths(date, 1);
  if (!lastDay) return format(previousMonth, 'yyyy-MM-dd');
  const lastDayOfPreviousMonth = endOfMonth(previousMonth);
  return format(lastDayOfPreviousMonth, 'yyyy-MM-dd');
}

export function processRecurringDates(rrule, dates) {
  if (dates.type === 'absolute') return dates;
  if (dates.offset || getKeyByDate(dates) === 'this_year' || dates.span.allTime) return getAbsoluteDatesFromDate(dates);
  // const frequency = getFrequency(rrule);
  const restriction = getRestriction(rrule);
  let [dateGte, dateLte] = getDatesByDate(dates);
  const isLastDay = '-1';
  const rruleEndOfMonth = restriction?.value.includes(isLastDay);
  dateGte = new Date(dateGte);
  dateLte = new Date(dateLte);

  // use current month
  if (rruleEndOfMonth) {
    const restrictionIsLastWeekDay = restriction?.value.includes('BYDAY=MO,TU,WE,TH,FR');
    const dateLteIsWeekday = !isWeekend(dateLte);
    if (!restrictionIsLastWeekDay || dateLteIsWeekday) return getAbsoluteDatesFromDate(dates);
    // if sent on last weekday set to last business day if last day of month is weekend
    if (isSaturday(dateLte)) return { dateGte: format(dateGte, 'yyyy-MM-dd'), dateLte: format(subDays(dateLte, 1), 'yyyy-MM-dd') };
    if (isSunday(dateLte)) return { dateGte: format(dateGte, 'yyyy-MM-dd'), dateLte: format(subDays(dateLte, 2), 'yyyy-MM-dd') };
    return { dateGte: format(dateGte, 'yyyy-MM-dd'), dateLte: format(dateLte, 'yyyy-MM-dd') };
  }
  // else move the dates to last full months
  const dateGtePrevMonth = getPreviousMonth(dateGte, false);
  const dateLtePrevMonth = getPreviousMonth(dateLte, true);
  return { dateGte: dateGtePrevMonth, dateLte: dateLtePrevMonth };
}
