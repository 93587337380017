const getApexChartBase64 = async (chartInstance) => new Promise((resolve, reject) => {
  setTimeout(async () => {
    if (!chartInstance?.chart) return Promise.reject(new Error('Missing chart instance'));
    try {
      const base64 = await chartInstance.dataURI();
      return resolve(base64?.imgURI);
    } catch (err) {
      return reject(err);
    }
  }, 1500);
});

export {
  // eslint-disable-next-line import/prefer-default-export
  getApexChartBase64,
};
