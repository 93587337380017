<template>
  <VDropdown
    theme="tooltip"
    handle-resize
    :popper-triggers="['hover']"
    @apply-show="onShow()"
  >
    <span
      class="pill ml-0.5 cursor-help"
      :class="getCustomerPlanClean(segment.customer.plan, 'class')"
    >{{ getCustomerPlanClean(segment.customer.plan, 'short') }}</span>
    <template #popper>
      <div>
        <span
          v-if="loading"
          class="staff-popover_loading"
        >
          Fetching customer
          <i class="zmdi zmdi-spinner zmdi-hc-spin" />
        </span>
        <span
          v-else-if="error"
          class="tc-color-red"
        >
          Couldn’t get customer. Hover to try again
        </span>
        <template v-else>
          <small>ONLY STAFF CAN SEE THIS</small><br>
          <span
            class="pill my-0.5"
            :class="getCustomerPlanClean(customer.plan, 'class')"
          >{{ getCustomerPlanClean(customer.plan, 'long') }}</span><br>
          <strong class="h5">{{ customer.name }}</strong><br>
          <strong>ID:</strong> {{ customer.id }}<br>
          <strong>Start:</strong> {{ customer.plan.start_date }}<br>
          <span v-if="customer.plan.plan_type === 'free_trial'">
            <strong>End:</strong> {{ customer.plan.end_date }}<br>
            <strong>Time remaining:</strong> {{ customer.plan.time_remaining }}<br>
            <strong>Used up free trial?:</strong> {{ customer.plan.used_free_trial ? 'Yes' : 'No' }}<br>
          </span>
          <strong>Steps:</strong> {{ customer.all_steps.map((s) => translateTerm(s)).join(', ') }}<br>
          <strong>Inactive:</strong> {{ customer.inactive_steps.length
            ? customer.inactive_steps.map((s) => translateTerm(s)).join(', ')
            : '—' }}<br>
          <span
            v-html="Object.entries(customer.used_features)
              .map(([k, v]) => `<strong>${k}?</strong> ${v ? 'Yes ❇️' : 'No ⛔'}`).join('\r\n<br>')"
          />
        </template>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import { ref } from 'vue';
import { translateTerm } from 'Utils/general';
import { store } from '@/store';

const props = defineProps({
  segment: {
    type: Object,
    required: true,
  },
});

const loading = ref(true);
const customer = ref(null);
const error = ref(false);

const onShow = async () => {
  try {
    const c = await store.dispatch('fetchCustomer', props.segment.customer.id);
    customer.value = c;
  } catch (err) {
    error.value = true;
  } finally {
    loading.value = false;
  }
};

const getCustomerPlanClean = (plan = {}, getVersion = 'short') => {
  if (getVersion === 'class') {
    return {
      'pill-purple': plan.plan_type === 'free_trial',
      'pill-teal': plan.plan_type === 'free',
      'pill-purple-hard': plan.plan_type === 'enterprise',
    };
  }
  if (getVersion === 'long') {
    if (plan.plan_type === 'free_trial') return 'Free Trial';
    if (plan.plan_type === 'free') return 'Trustcruit Free';
    if (plan.plan_type === 'standard') return 'Trustcruit Standard';
    if (plan.plan_type === 'enterprise') return 'Trustcruit Enterprise';
  }
  if (plan.plan_type === 'free_trial') return 'Trial';
  if (plan.plan_type === 'free') return 'Free';
  if (plan.plan_type === 'standard') return 'Standard';
  if (plan.plan_type === 'enterprise') return 'Enterprise';
  return plan.plan_type || '';
};
</script>

<style lang="scss" scoped>
.staff-popover_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  .zmdi-spinner {
    margin-top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      height: 10px;
      width: 10px;
    }
  }
}
</style>
