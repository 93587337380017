let theCurrentRoute = false;
export function currentRoute(val) {
  // ? Set or Get current route navigated to
  theCurrentRoute = val !== undefined ? val : theCurrentRoute;
  return theCurrentRoute;
}

/**
 ** validQueryParam - Validate url queries like "?unanswered=1&term=bacon&level=step5&cnps_cat=0&avg_gte=2&avg_lte=8"
 * @param {String} key
 * @param {String} value
 * @param {Object} validator
 * @example
  const validator = {
    search: (value) => typeof value === 'string',
    unanswered: (value) => value === 'true' || value === '1',
    avg_gte: (value) => /[0-9]?/.test(value),
    avg_lte: (value) => /[0-9]?/.test(value),
    page_size: (value) => /[0-9]?/.test(value),
    level: (value) => typeof value === 'string',
    cnps_cat: (value) => typeof value === 'string',
    has_text_answer: (value) => typeof value === 'string',
  };
 * @returns {Boolean} true if valid in validator
 */
export function validQueryParam(key, value, validator = {}) {
  if (key.startsWith('tags__')) return typeof value === 'string';
  return key in validator && validator[key](value);
}
export function validQuery(query, validator = {}) {
  if (typeof query !== 'object') return {};
  return Object.keys(query)
    .filter((key) => validQueryParam(key, query[key], validator))
    .map((key) => ({ [key]: query[key] }))
    .reduce((a, b) => Object.assign(a, b), {});
}

if (!window?.location?.origin) {
  window.location.origin = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
}
export const SITE_URL = window?.location.origin || global.location.origin;
export const BASE_URL = process.env.BASE_URL || 'http://localhost:8000';
