import { baseRequest } from 'Utils/api';

/**
 ** Paginator
 *  For promise-based pagination handling (older variant, not "fresh")
 *  Can't be combined with the other pagination classes currently
 *
 * @param {Promise} promise with Paginator object inside
 *
 * @prop {Object} response
 * @prop {Boolean} loading
 *
 * @method {Boolean} hasNext()
 * @method {Boolean} hasPrevious()
 * @method {Object} next() returns next available page
 * @method {Object} hasPrevious() returns previous available page
 */
export default class {
  constructor(promise) {
    this.initPromise(promise);
  }

  initPromise(promise) {
    this.promise = promise;
    this.loading = true;
    this.response = {
      results: [],
      count: 0,
      next: null,
      previous: null,
    };
    promise.then((response) => {
      this.response = response;
      this.loading = false;
    });
  }

  get results() {
    return this.response.results;
  }

  hasNext() {
    return this.response.next !== null;
  }

  hasPrevious() {
    return this.response.previous !== null;
  }

  next() {
    if (this.hasNext) {
      this.initPromise(baseRequest((request) => request.get(this.response.next)));
    }
    return this;
  }

  previous() {
    if (this.hasPrevious) {
      this.initPromise(baseRequest((request) => request.get(this.response.previous)));
    }
    return this;
  }

  then(...args) {
    return this.promise.then(...args);
  }
}
