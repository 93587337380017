<template>
  <nav
    class="nav-user"
    :aria-label="$pgettext('ARIA - User nav', 'Inställningsmeny & Hjälp')"
  >
    <!-- <sidebar-shortcuts class="sidebar-list" /> -->
    <div class="nav-explore-btn mx-1 mb-1">
      <button
        tabindex
        class="btn btn-thin btn-block badge-activatewall"
        :title="spotlightSurvey?.name ?? ''"
        @click.prevent="openSurveyModal(spotlightSurvey?.step ?? '')"
      >
        <i class="zmdi zmdi-forms-page mr-0.5" />
        <span class="fluid-type-container">
          <span class="fluid-type">
            {{ $pgettext(
              'Sidebar surveys - add spotlightSurvey',
              'Utforska %{surveyName}',
              { surveyName: spotlightSurvey?.name ?? $pgettext('Sidebar surveys - explore surveys plural', 'formulär') }
            ) }}
          </span>
        </span>
      </button>
    </div>
    <ul
      class="sidebar-list block-links-sm mt-0 mb-0.5 mx-1"
      role="menu"
    >
      <li
        v-if="showSidebarPlan"
        role="none"
      >
        <sidebar-plan
          class="block-link-text"
          role="menuitem"
        />
      </li>
      <li
        v-if="store.getters.role === 'admin' || store.getters.segmentCanEdit"
        role="none"
      >
        <router-link
          :to="{ name: 'segmentSettingsInvite' }"
          class="block-link-text block-link-bulleted"
          role="menuitem"
          @click="clickedRoute"
        >
          <i
            class="zmdi zmdi-accounts-alt"
            aria-hidden
          />
          <span>{{ $pgettext('Sidebar button', 'Hantera användare') }}</span>
        </router-link>
      </li>
      <li role="none">
        <a
          rel="noopener noreferrer"
          :href="supportUrl"
          target="_blank"
          class="block-link-text block-link-bulleted"
          role="menuitem"
          :title="$gettext('Öppna i en ny flik')"
        >
          <i
            class="zmdi zmdi-help-outline"
            aria-hidden
          />
          <span>{{ $pgettext('Button — Header link','Support') }}<i class="zmdi zmdi-open-in-new ml-0.5" /></span>
        </a>
      </li>
    </ul>

    <div
      class="tc-avatar-wrapper"
      :class="{ 'active': isOpen }"
      tabindex="0"
      role="menuitem"
      aria-haspopup
      :aria-expanded="isOpen"
      :aria-label="isOpen
        ? $pgettext('ARIA - User nav btn isOpen', 'Stäng Inställningsmeny & Hjälp')
        : $pgettext('ARIA - User nav btn !isOpen', 'Öppna Inställningsmeny & Hjälp')"
      aria-controls="ariaNavUserBtn"
      @keyup.enter.stop.prevent="toggleDropdown"
      @click.stop.prevent="toggleDropdown"
    >
      <span class="tc-avatar-content">
        <!-- <span class="tc-avatar">
          <span class="inner"><i class="zmdi zmdi-account" /></span>
        </span> -->
        <RespondentAvatar
          :id="avatarHash"
          class="tc-avatar"
          mask="circle"
          include-mask
          chosen-mouth="joy"
          :size="32"
          :colors="['#BECCCC']"
        />
        <span
          :title="`${formattedName} — ${customerName}`"
          class="tc-avatar-text"
        >
          <p
            class="mb-0 tc-avatar-title no-wrap"
            data-private
          >{{ formattedName }}</p>
          <p
            v-if="!isError(customerName)"
            class="small-text subtle-text tc-avatar-subtitle no-wrap"
          >{{ customerName }}</p>
        </span>
      </span>
      <span class="tc-avatar-chevron">
        <i class="zmdi zmdi-settings subtle-transparent-text" />
        <i
          class="zmdi zmdi-chevron-right"
          :class="{ 'zmdi-hc-rotate-180': isOpen }"
        />
      </span>
    </div>
  </nav>

  <div
    class="nav-aside-popout tc-dropdown"
    :class="{ 'active': isOpen, 'sidebarToggled': sidebarToggled }"
    :aria-hidden="!isOpen"
    role="none"
    tabindex="-1"
    @click.stop
  >
    <nav
      id="ariaNavUser"
      class="tc-dropdown-action-nav"
      aria-labelledby="ariaNavUserBtn"
    >
      <ul
        class="sidebar-list"
        role="menu"
      >
        <li role="none">
          <router-link
            role="menuitem"
            :to="{ name: 'userProfile' }"
            class="block-link"
            tabindex
            @click="clickedRoute"
          >
            <i class="zmdi zmdi-account" />
            <span>{{ $pgettext('Button — Header dropdown','Min Profil') }}</span>
          </router-link>
          <ul role="none">
            <!-- <li role="none">
              <router-link
                role="menuitem"
                exact-path
                :to="{ name: 'userProfile' }"
                class="block-link"
                tabindex
                @click="clickedRoute"
              >
                <span>{{ $gettext('Användarprofil') }}</span>
              </router-link>
            </li> -->
            <li role="none">
              <router-link
                role="menuitem"
                :to="{ name: 'userPrivileges' }"
                class="block-link"
                tabindex
                @click="clickedRoute"
              >
                <span>{{ $gettext('Privilegier') }}</span>
                <span
                  v-if="store.getters.me?.is_staff"
                  class="badge ml-1"
                >Staff</span>
              </router-link>
            </li>
            <li role="none">
              <router-link
                role="menuitem"
                :to="{ name: 'notifications' }"
                class="block-link"
                tabindex
                @click="clickedRoute"
              >
                <span>{{ $gettext('Aviseringar') }}</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li role="none">
          <router-link
            role="menuitem"
            :to="{
              name: store.getters.role === 'admin'
                ? 'customerSettingsAccount'
                : 'customerSettingsSegment',
              params: { customerId },
            }"
            class="block-link"
            tabindex
            @click="clickedRoute"
          >
            <i
              class="zmdi zmdi-city"
              aria-hidden
            />
            <span>{{ $pgettext('Button — Header dropdown','Mitt företag') }}</span>
          </router-link>
          <ul role="none">
            <!-- <li role="none">
              <router-link
                role="menuitem"
                exact-path
                :to="{
                  name: store.getters.role === 'admin'
                    ? 'customerSettingsAccount'
                    : 'customerSettingsSegment'
                }"
                class="block-link"
                tabindex
                @click="clickedRoute"
              >
                <span>{{ $pgettext('Button — Header dropdown','Inställningar') }}</span>
              </router-link>
            </li> -->
            <li role="none">
              <router-link
                role="menuitem"
                :to="{
                  name: 'customerSettingsReports',
                  params: { customerId },
                }"
                class="block-link"
                tabindex
                @click="clickedRoute"
              >
                <span>{{ $pgettext('Plural - Customer settings navigation', 'Rapporter') }}</span>
              </router-link>
            </li>
            <li role="none">
              <router-link
                role="menuitem"
                :to="{
                  name: 'customerSettingsSegment',
                  params: { customerId },
                }"
                class="block-link"
                tabindex
                @click="clickedRoute"
              >
                <span>{{ $pgettext('Plural - Customer settings navigation', 'Segment') }}</span>
              </router-link>
            </li>
            <li
              v-if="store.getters.role === 'admin'"
              role="none"
            >
              <router-link
                role="menuitem"
                :to="{
                  name: 'customerSettingsUsers',
                  params: { customerId },
                }"
                class="block-link"
                tabindex
                @click="clickedRoute"
              >
                <span>{{ $pgettext('Plural - Customer settings navigation', 'Användare') }}</span>
              </router-link>
            </li>
            <li
              v-if="store.getters.role === 'admin'"
              role="none"
            >
              <router-link
                role="menuitem"
                :to="{
                  name: 'customerSettingsInvite',
                  params: { customerId },
                }"
                class="block-link"
                tabindex
                @click="clickedRoute"
              >
                <span>{{ $gettext('Bjud in') }}</span>
              </router-link>
            </li>
            <li role="none">
              <router-link
                role="menuitem"
                :to="{
                  name: 'customerSettingsImportExport',
                  params: { customerId },
                }"
                class="block-link"
                tabindex
                @click="clickedRoute"
              >
                <span>{{ $pgettext('Navigation — Customer Settings', 'Importera/Exportera') }}</span>
              </router-link>
            </li>
            <li role="none">
              <router-link
                role="menuitem"
                :to="{
                  name: 'customerSettingsSendingLog',
                  params: { customerId },
                }"
                class="block-link"
                tabindex
                @click="clickedRoute"
              >
                <span>{{ $gettext('Utskickslogg') }}</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li role="none">
          <router-link
            role="menuitem"
            :to="{ name: 'aboutHelp' }"
            class="block-link"
            tabindex
            @click="clickedRoute"
          >
            <i
              class="zmdi zmdi-info-outline"
              aria-hidden
            />
            <span>{{ $pgettext('Button — Header dropdown','Kontakt &amp; info') }}</span>
          </router-link>
          <ul role="none">
            <!-- <li role="none">
              <router-link
                role="menuitem"
                exact-path
                :to="{ name: 'aboutHelp' }"
                class="block-link"
                tabindex
                @click="clickedRoute"
              >
                <span>{{ $gettext('Om Trustcruit') }}</span>
              </router-link>
            </li> -->
            <li role="none">
              <router-link
                role="menuitem"
                :to="{ name: 'aboutCNPS' }"
                class="block-link"
                tabindex
                @click="clickedRoute"
              >
                <span>{{ $gettext('Vad är CNPS?') }}</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <hr>
    <nav class="tc-dropdown-action-nav">
      <ul role="none">
        <li role="none">
          <router-link
            role="menuitem"
            to="/logout"
            class="block-link"
            tabindex
          >
            <span class="zmdi-hc-stack tc-color-link-active">
              <i class="zmdi zmdi-crop-din zmdi-hc-stack-1x pr-1" />
              <i
                class="zmdi zmdi-chevron-left zmdi-hc-stack-1x pl-1"
                style="color:white"
              />
              <i class="zmdi zmdi-long-arrow-right zmdi-hc-stack-1x pl-1" />
            </span><span>{{ $pgettext('Button — Header dropdown','Logga ut') }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { isError } from 'lodash-es';
import ga from '@/plugins/googleAnalytics';
import eventBus from 'Utils/eventBus';
import { formatUserDisplayName } from 'Utils/general';
import { localStorageIsAvailable } from 'Utils/storage';
import dynamicImport from 'Utils/dynamicImport';
import useSurveyList from 'Composables/useSurveyList';
import SidebarPlan from 'Components/parts/SidebarPlan';
import RespondentAvatar from 'Components/parts/avatars/RespondentAvatar';
import { store } from '@/store';

const props = defineProps({
  sidebarToggled: {
    type: Boolean,
  },
});
const closeSidebar = () => eventBus.$emit('close-sidebar');

const showSidebarPlan = computed(() => !store.getters.customerPlanHasPaid
    && store.getters.isCustomerUserInCurrentSegment
    && store.getters.role === 'admin'
    && !store.getters.customerIsFetching);

const isOpen = ref(false);
const supportUrl = ref('https://support.trustcruit.com/'); // $gettext('https://support.trustcruit.com/sv/')

const formattedName = computed(() => formatUserDisplayName(store.getters.me));
const customerName = computed(() => store.getters.customerName);
const customerId = computed(() => store.getters.customerId);
const avatarHash = computed(() => store.getters.me?.email || 'sven');

const addCloseHandler = (payload) => store.dispatch('addCloseHandler', payload);
const removeCloseHandler = (payload) => store.dispatch('removeCloseHandler', payload);
const removeAllCloseHandlers = () => store.dispatch('removeAllCloseHandlers');
const closeHandler = () => { isOpen.value = false; };

const openDropdown = () => {
  removeAllCloseHandlers();
  isOpen.value = true;
  addCloseHandler(closeHandler);
};
const closeDropdown = () => {
  isOpen.value = false;
  removeCloseHandler(closeHandler);
};
const toggleDropdown = () => {
  if (isOpen.value) closeDropdown();
  else openDropdown();
};
const clickedRoute = () => {
  closeDropdown();
  closeSidebar();
};

const { hardCodedSurveysSorted, isLoadingForms } = useSurveyList();
const spotlightSurvey = ref({});
const setSpotlightSurvey = () => {
  const availableSurveys = hardCodedSurveysSorted.value
    .filter((survey) => survey?.active === false);
  const amountAvailable = availableSurveys.length - 1; // ? Skips last item "Custom"
  const refreshUnixTimestamp = localStorageIsAvailable && localStorage.getItem('token-refresh') || '0';
  const lastDigit = refreshUnixTimestamp
    ? parseInt(refreshUnixTimestamp[refreshUnixTimestamp.length - 1], 10) % amountAvailable // ? Clamp to availableSurveys.length - 1
    : 0;
  spotlightSurvey.value = availableSurveys?.[lastDigit] || availableSurveys[0];
};
watch(isLoadingForms, (newVal) => {
  if (!newVal) setSpotlightSurvey();
});

const openSurveyModal = (showSurvey = '') => {
  const AddSurveyDetails = dynamicImport(() => import(/* webpackChunkName: "AddSurveyDetails", webpackPrefetch: true */ 'Components/parts/details/AddSurveyDetails'));
  ga('send', {
    hitType: 'event',
    eventCategory: 'Dashboard',
    eventAction: 'open-survey-modal',
    eventLabel: `clicked "Explore ${showSurvey}" in NavUser`,
  });
  store.dispatch('openModal', {
    name: 'AddSurveyDetails',
    component: AddSurveyDetails,
    dataObject: { props: { showSurvey } },
    size: 'xlarge',
  });
};
</script>
