<template>
  <div class="dashboard-wrapper dashboard-wrapper-print">
    <RespondentAvatar
      include-mask
      :include-avatar="false"
      :size="0"
    />
    <print-header />
    <section class="dashboard-main dashboard-main-no-sidebar">
      <main class="main-cols">
        <div
          class="col-main"
          tabindex="-1"
        >
          <router-view />
        </div>
        <print-footer />
      </main>
    </section>
    <!-- <baloo-notifications />
    <modalception /> -->
  </div>
</template>

<script>
import languageAPI from 'API/userLanguage';
import PrintHeader from '../parts/print/PrintHeader';
import PrintFooter from '../parts/print/PrintFooter';
import RespondentAvatar from '../parts/avatars/RespondentAvatar';
// import BalooNotifications from '../parts/notifications/BalooNotifications';
// import Modalception from 'Modal/Modalception';

export default {
  components: {
    PrintHeader,
    PrintFooter,
    RespondentAvatar,
    // BalooNotifications,
    // Modalception,
  },
  beforeRouteEnter(to, from, next) {
    // ? Make sure correct user language is used on print page
    next((vm) => {
      if (process.env.NODE_ENV !== 'test') languageAPI.getLang();
    });
  },
};
</script>
