import { sub } from 'date-fns';
import { format } from 'Utils/dateHelpers';
import { NPS_CATEGORIES } from 'Utils/constants';
/**
 ** random - generate random numbers between min and max
 * @param {Number} min
 * @param {Number} max
 * @param {Boolean} float
 * @returns {Number}
 */
// const random = (min, max, float = false) => {
//   const val = Math.random() * (max - min) + min;

//   if (float) return val;
//   return Math.floor(val);
// };

/**
 ** placeholderSerie
 * @param {String} questionType, a string for different types of preset ranges
 * @param {Number} mod, a modifier for getting differing series
 * @returns Serie, to use in Apexcharts linechart
 */
export const placeholderSerie = (type = 'date') => {
  if (type === 'list' || type === 'cnps') {
    const list3bars = Array.apply(0, Array(3))
      .reduce((acc, val, i) => {
        const key = `Loading ${i + 1}`;
        acc[key] = { answer_str: type === 'cnps' ? NPS_CATEGORIES[i] : 'Loading', score: i + 1, count: i + 1, respondent_count: i + 1 };
        return acc;
      }, {});
    return list3bars;
  }

  if (type === 'yesno') {
    const yesno2bars = Array.apply(0, Array(2))
      .reduce((acc, val, i) => {
        const key = `Loading ${i + 1}`;
        acc[key] = { answer_str: 'Loading', score: i === 0 ? 0 : 1, count: i === 0 ? 2.5 : 7.5, respondent_count: i === 0 ? 2.5 : 7.5 };
        return acc;
      }, {});
    return yesno2bars;
  }
  if (type === 'rating') {
    const rating11bars = Array.apply(0, Array(11))
      .reduce((acc, val, i) => {
        const key = `Loading ${i + 1}`;
        acc[key] = { answer_str: i, score: i === 0 ? 0 : 1, count: i === 0 ? 2.5 : 7.5, respondent_count: i === 0 ? 2.5 : 7.5 }; // eslint-disable-line max-len
        return acc;
      }, {});
    return rating11bars;
  }
  // export const placeholderSerie = (questionType = '', mod = 1) => {
  // let min = 0;
  // let mid = 5;
  // let max = 10;
  // let percentages = false;
  // switch (questionType) {
  //   case 'rating':
  //     break;
  //   case 'cnps':
  //   case 'yesno':
  //   default:
  //     min = 0;
  //     mid = 50;
  //     max = 100;
  //     percentages = true;
  // }

  const last12Months = Array.apply(0, Array(12))
  // .reduce((acc, _, i) => {
    // const lastRando = acc[i - 1] || mid;
    // const { newMin, newMax } = { newMin: lastRando - (mod * 0.75), newMax: lastRando + mod };
    // acc.push(random(
    //   newMin >= min ? newMin : min,
    //   newMax <= max ? newMax : max,
    // ));
  //   return acc;
  // }, [])
    .reduce((acc, val, i, arr) => {
      const key = format(sub(new Date(), { months: arr.length - i - 1 }), 'yyyy-MM');
      // acc[key] = { score: percentages ? val / 100 : val };
      acc[key] = { score: NaN };
      return acc;
    }, {});

  return last12Months; // ex. { ['2021-01']: 30, ['2020-12']: 36, ['2020-11']: 50 };
};

export default {
  placeholderSerie,
};
