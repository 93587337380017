<template>
  <div class="dynamic-import dynamic-import-error">
    <i class="zmdi zmdi-alert-triangle zmdi-hc-2x" />
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: 'DynamicImportLoading',
  data() {
    return {
      message: '',
    };
  },
  mounted() {
    this.message = this.$pgettext('Error — Dynamic import error offline', 'Ett fel uppstod när komponenten skulle laddas in. Prova igen eller ladda om sidan.');
    if (window?.navigator?.onLine === false) {
      this.message = this.$pgettext('Error — Dynamic import error offline', 'Komponenten kunde inte laddas in pga. nätverksproblem.');
      console.log('[TC] CLIENT IS OFFLINE'); // eslint-disable-line no-console
    }
  },
};
</script>
