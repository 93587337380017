import { baseRequest, getUrl } from 'Utils/api';

export const getAllSizes = async () => getUrl('/feedback/api/size/');

export const getAllIndustries = async () => getUrl('/feedback/api/industry/');

export const getAllCountries = async () => getUrl('/feedback/api/country/');

export const getStaticCountries = async (lang) => {
  let url = '/includes/en_US/';
  if (lang === 'sv-se') url = '/includes/sv_SE/';
  return baseRequest((request) => request.get(`${url}country.json`));
};

export default {
  getAllSizes,
  getAllIndustries,
  getAllCountries,
  getStaticCountries,
};
