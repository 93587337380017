<template>
  <div
    class="bar-graph-indicator-wrapper"
    @click="handleResize"
  >
    <div
      v-if="isNaN(data?.[0])"
      class="bar-graph-indicator bar-graph-indicator-nan"
      :class="npsColor"
      :style="`width: ${barWidth}%; ${customStyle}`"
    >
      <p
        v-if="!skeletonLoader"
        ref="data"
        class="bar-graph-data"
        :class="{ outside }"
        v-text="'—'"
      />
    </div>
    <div
      v-else
      ref="indicator"
      class="bar-graph-indicator"
      :class="npsColor"
      :style="`width: ${barWidth}%; ${customStyle}`"
    >
      <p
        v-if="!skeletonLoader"
        ref="data"
        class="bar-graph-data"
        :class="{ outside }"
      >
        {{ data?.[0] || 0 }}%
      </p>
    </div>
    <!-- <div
      v-for="relational in relationals"
      :key="relational.id"
      class="bar-graph-relational"
      :style="`left: ${relational.width}%`"
    >
      <p
        class="bar-graph-data"
        :class="{ 'outside': relational.width < 5}"
      >
        {{ relational.data || 0 }}%
      </p>
    </div> -->
  </div>
</template>

<script>
import { throttle, isNaN } from 'lodash-es';

export default {
  name: 'BarGraphIndicator',
  props: {
    data: Array,
    count: Array,
    width: Array,
    skeletonLoader: {
      type: Boolean,
      default: false,
    },
    npsColor: {
      type: String,
      default: '',
    },
    customStyle: {
      type: String,
      default: '',
    },
  },
  expose: ['handleResize'],
  data() {
    return {
      outside: false,
      throttledResize: () => {},
    };
  },
  computed: {
    barWidth() {
      if (isNaN(this.data?.[0])) return 0;
      return this.width?.[0] || this.data?.[0] || 0;
    },
    // relationals() {
    //   return this.data.reduce((acc, data, i) => {
    //     if (i === 0) return acc;
    //     acc.push({
    //       data,
    //       count: this.count?.[i] || NaN,
    //       width: this.width?.[i] || data,
    //     });
    //     return acc;
    //   }, []);
    // },
  },
  watch: {
    skeletonLoader(newVal, oldVal) {
      if (newVal !== oldVal) this.$nextTick(() => this.throttledResize());
    },
    barWidth(newVal, oldVal) {
      if (newVal !== oldVal) this.$nextTick(() => this.throttledResize());
    },
  },
  mounted() {
    this.throttledResize = throttle(this.handleResize, 300, { leading: true, trailing: true });

    if (typeof window !== 'undefined') window.addEventListener('resize', this.throttledResize);
    this.$nextTick(() => this.throttledResize());
  },
  unmounted() {
    if (typeof window !== 'undefined') window.removeEventListener('resize', this.throttledResize);
  },
  methods: {
    isNaN,
    handleResize() {
      const indicatorRect = this.$refs?.indicator?.getBoundingClientRect();
      const dataRect = this.$refs?.data?.getBoundingClientRect();
      if (indicatorRect && dataRect) {
        this.outside = dataRect.width > (indicatorRect.width - 14);
      } else {
        this.outside = this.barWidth < 5;
      }
    },
  },
};
</script>
