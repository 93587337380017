<template>
  <ul
    ref="boardListEl"
    class="board-list"
    :class="{ 'scroll-shadow': isOverflown }"
    @click="closeSidebar"
    @scroll="handleScroll"
  >
    <template v-if="fetchingBoards || isRemovingBoard">
      <li
        v-for="(board, index) in placeholderBoards"
        :key="index"
        class="sidebar-placeholder-text my-0"
      >
        <span
          class="tc-loading-text tc-loading-text-on-color"
          :class="{
            'inline': !board?.isPlaceholder,
            'size-inherit': !board?.isPlaceholder,
            'size-xsmall': board?.isPlaceholder && index % 2 === 0,
            'size-small': board?.isPlaceholder && index % 2 !== 0,
            'pl-3': board?.is_overview || board?.is_public === false,
          }"
        >{{ board?.name || 'Loading…' }}</span>
      </li>
    </template>
    <li
      v-for="board in sortedBoards"
      v-else
      :key="board.id"
    >
      <router-link
        :to="board?.is_overview
          ? { name: 'overview' }
          : { name: 'board', params: board?.id ? { boardId: board.id, segmentId: segmentId } : {}}"
        tabindex
        class="block-link"
        :class="{
          'block-link-bulleted': board?.is_overview || board?.is_public === false,
          'active': activeBoard == board?.id,
        }"
      >
        <i
          v-if="board?.is_overview"
          class="zmdi zmdi-compass subtle-transparent-text"
        />
        <i
          v-if="!board.is_public"
          class="zmdi zmdi-key subtle-transparent-text"
        />
        <span>{{ board.name }}</span>
      </router-link>
    </li>
    <li>
      <button
        tabindex
        class="btn btn-link strong block-link block-link-bulleted subtle-text"
        @click.prevent="addBoard"
      >
        <i class="zmdi zmdi-plus subtle-text" />
        <span class="subtle-text">{{ $gettext('Lägg till rapport') }}</span>
      </button>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { useResizeObserver } from '@vueuse/core';
import { throttle } from 'lodash-es';
import { sortBoardsFn } from 'API/boards';
import eventBus from 'Utils/eventBus';
import dynamicImport from 'Utils/dynamicImport';

export default {
  data: () => ({
    isRemovingBoard: false,
    isOverflown: false,
    stopObserver: () => {},
  }),
  computed: {
    ...mapGetters([
      'fetchingBoards',
      'segmentId',
      'boards',
    ]),
    placeholderBoards() {
      if (this.fetchingBoards) return Array(3).fill({ isPlaceholder: true, name: 'Loading…' });
      return (this.boards && this.sortedBoards) || [];
    },
    sortedBoards() {
      return [...(this.boards ?? [])].sort(sortBoardsFn);
    },
    activeBoard() {
      if (this.$route.name === 'board') return this.$route?.params?.boardId || '';
      if (this.$route.name === 'overview') return 'overview';
      return '';
    },
  },
  mounted() {
    this.$nextTick(() => {
      eventBus.$on('removing-board', this.removingBoard);
      eventBus.$on('removed-board', this.removedBoard);
    });
    ({ stop: this.stopObserver } = useResizeObserver(this.$refs.boardListEl, this.checkOverflow));
  },
  unmounted() {
    eventBus.$off('removing-board', this.removingBoard);
    eventBus.$off('removed-board', this.removedBoard);
    this.stopObserver();
  },
  methods: {
    ...mapActions([
      'openModal',
      'fetchAllBoardsBySegmentId',
    ]),
    handleScroll: throttle(function () {
      const multiplier = 1.5;
      const currentScroll = (this.$refs.boardListEl.scrollTop)
        / ((this.$refs.boardListEl.scrollHeight - this.$refs.boardListEl.parentElement.offsetHeight));
      this.$refs.boardListEl.style.setProperty('--scrollShadowBeforeOpacity', `${currentScroll * multiplier}`);
      this.$refs.boardListEl.style.setProperty('--scrollShadowAfterOpacity', `${(1 - currentScroll) * multiplier}`);
    }, 16, { leading: true, trailing: true }),
    checkOverflow(event) {
      const { target } = event[0];
      this.isOverflown = target?.scrollHeight > target?.clientHeight;
    },
    removingBoard(boardId) { this.isRemovingBoard = true; },
    async removedBoard(boardId) {
      return this.fetchAllBoardsBySegmentId().then(() => {
        this.isRemovingBoard = false;
      });
    },
    closeSidebar() {
      eventBus.$emit('close-sidebar');
    },
    addBoard() {
      const AddBoardDetails = dynamicImport(() => import(/* webpackChunkName: "AddBoardDetails", webpackPrefetch: true */ 'Components/parts/details/AddBoardDetails'));
      this.openModal({
        name: 'AddBoardDetails',
        component: AddBoardDetails,
        position: 'center',
        callback: (newDataObject) => {
          if (newDataObject?.boardId) {
            this.$router.push(
              { name: 'board', params: { boardId: newDataObject?.boardId, segmentId: newDataObject?.segmentId } },
            ).catch((err) => {});
          }
        },
      });
    },
  },
};
</script>
