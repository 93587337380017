<template>
  <div
    v-if="question.show"
    class="tc-questions-list-show"
  >
    <button
      v-if="!initialToggle"
      class="show-title btn btn-link"
      :title="showTitleDescription"
      @click.stop="toggled = !toggled"
    >
      {{ $gettext('Visningsvillkor') }}
    </button>
    <transition name="fade">
      <div
        v-if="toggled"
        class="show-lists"
        v-html="showString"
      />
    </transition>
  </div>
</template>

<script>
import { processText, translateBaseValue } from 'Utils/general';

export default {
  props: {
    question: Object,
    questions: Array,
    initialToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggled: false,
    };
  },
  computed: {
    showString() {
      return `<ul>${this.checkIf(this.question.show)}</ul>`;
    },
    labels() {
      return {
        not: this.$gettext('Om villkor INTE stämmer'),
        or: this.$gettext('Om NÅGOT villkor stämmer'),
        and: this.$gettext('Om ALLA villkor stämmer'),
        empty: this.$gettext('Tom'),
      };
    },
    showTitleDescription() {
      return this.$gettext('Visningsvillkor: Avgör om en fråga visas för användaren');
    },
  },
  mounted() {
    this.toggled = Boolean(this.initialToggle);
  },
  methods: {
    checkIf(data) {
      let key = Object.keys(data)[0];
      return this[key](data[key]);
    },
    or(data) {
      let result = `<ul class="show-or" data-label="${this.labels.or}">`;
      for (let i = 0; i < data.length; i++) {
        result += this.checkIf(data[i]);
      }
      result += '</ul>';
      return result;
    },
    and(data) {
      let result = `<ul class="show-and" data-label="${this.labels.and}">`;
      for (let i = 0; i < data.length; i++) {
        result += this.checkIf(data[i]);
      }
      result += '</ul>';
      return result;
    },
    equals(data) {
      return `<li class="row">
        <em class="show-question-translation col-sm-8" title="${this.getQuestionTranslation(data.question)}">${this.getQuestionTranslation(data.question)}</em>
        <span class="show-operator col-sm-2"><i class="zmdi zmdi-long-arrow-right"></i></span>
        <span class="show-value-translation col-sm-2">${this.getBaseValueTranslation(data.value, data.question)}</span>
      </li>`;
    },
    not(data) {
      return `<ul class="show-not" data-label="${this.labels.not}">${this.checkIf(data)}</ul>`;
    },
    empty(data) {
      return `<li>
        <em class="show-question-translation col-sm-8" title="${this.getQuestionTranslation(data.question)}">${this.getQuestionTranslation(data.question)}</em>
        <span class="show-operator col-sm-2"><i class="zmdi zmdi-long-arrow-right"></i></span> ${this.labels.empty}
      </li>`;
    },
    getQuestionTranslation(questionId) {
      let question = this.questions.find((elem) => elem.id === questionId);
      return question?.translation?.question
        ? processText(question.translation.question)
        : questionId;
    },
    getBaseValueTranslation(baseValue, questionId) {
      let question = this.questions.find((elem) => elem.id === questionId);
      return question ? translateBaseValue(baseValue, question) : baseValue;
    },
  },
};
</script>
