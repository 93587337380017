import { isArray } from 'lodash-es';
import topicsAPI from 'API/topics';
import { markLabel, labelHit } from 'Utils/tcselectHelpers';

export default {
  state: {
    topics: [],
    fetchingTopics: Promise.resolve(),
  },
  mutations: {
    SET_TOPICS(state, { topics }) {
      state.topics = topics;
    },
    SET_TOPICS_FETCH_PROMISE(state, fetchState) {
      state.fetchingTopics = fetchState;
    },
    CLEAR_TOPICS(state) {
      state.topics = [];
    },
  },
  getters: {
    fetchingTopics: (state) => state.fetchingTopics,
    topics: (state) => state.topics || [],
    topicOptions: (getters) => (term = '') => getters.topics
      .map((topic) => ({
        label: topic.name,
        value: topic.id,
        sidelabel: topic.description,
      }))
      .filter(labelHit(term))
      .map(markLabel(term)),
  },
  actions: {
    reFetchTopics({ dispatch }) {
      dispatch('clearTopics');
      return dispatch('fetchTopics');
    },
    clearTopics({ commit }) { commit('CLEAR_TOPICS'); },
    fetchTopics: async ({ state, commit }) => {
      if (state.topics.length) {
        const resolvedPromise = Promise.resolve(state.topics);
        commit('SET_TOPICS_FETCH_PROMISE', resolvedPromise);
        return resolvedPromise;
      }
      const topicProm = topicsAPI.list().then((topics) => { commit('SET_TOPICS', { topics }); });

      commit('SET_TOPICS_FETCH_PROMISE', topicProm);
      return topicProm;
    },
    getTopics({ state }, topicIds = []) {
      if (isArray(topicIds)) return topicIds.map((tid) => state.topics?.[tid] ?? null);
      return null;
    },
  },
};
