/* eslint-disable no-console */
/**
 * For setting server pings
 * Mainly needs to set ping, successCallback & errorCallback
 */
export default {
  state: {
    pings: [],
  },
  mutations: {
    SET_PING(state, { ping }) {
      state.pings.push(ping);
    },
    CLEAR_PING(state, { ping }) {
      if (typeof ping === 'number') {
        clearTimeout(ping);
        state.pings = state.pings.filter((p) => p !== ping);
      }
    },
  },
  actions: {
    async createPing({ commit, dispatch }, {
      ping = () => Promise.resolve(), // ? A Fn that returns a Promise that is used to ping against/call on
      successCallback = null, // ? Callback Fn for changing what happens on succcess ending
      errorCallback = null, // ? Callback Fn for changing what happens on error ending
      successAssert = (r) => r.status === 'done', // ? Return truthy for pings that should end with success
      errorAssert = (r) => r.status === 'error', // ? Return truthy for pings that should end with error
      interval = 5000, // ? Ping interval in ms
      increasedInterval = 60000, // ? Ping interval in ms that is used after initial minute of trying
      killswitch = 7.2 * 10e6, // ? Killswitch time in ms (7.2 * 10e6 = 2h)
    }) {
      let pingCount = 1;
      let useIncreasedInterval = false;
      if (!successCallback) {
        successCallback = () => dispatch('notify', {
          text: 'Ping ended with successAssert truthy',
          level: 'success',
        });
      }
      if (!errorCallback) {
        errorCallback = () => dispatch('notify', {
          text: 'Ping ended with errorAssert truthy',
          level: 'error',
        });
      }

      // eslint-disable-next-line no-use-before-define
      let pingId = setTimeout(pingFn, useIncreasedInterval ? increasedInterval : interval);

      async function pingFn() {
        let response;
        useIncreasedInterval = pingCount >= (60000 / interval); // If pinged up to a minute

        // eslint-disable-next-line brace-style
        try { response = await ping(); }
        catch (err) { response = new Error(err); }

        if (response instanceof Error) { // If new Error(err)
          commit('CLEAR_PING', { pingId });
        } else if (successAssert(response) || errorAssert(response)) {
          if (successAssert(response)) successCallback(response);
          if (errorAssert(response)) errorCallback(response);
          commit('CLEAR_PING', { pingId });
        } else {
          pingId = setTimeout(pingFn, useIncreasedInterval ? increasedInterval : interval); // ? Recurse
          commit('SET_PING', { pingId });
        }
        pingCount++;

        return Promise.resolve(response);
      }

      // Killswitch after 2h
      if (killswitch) setTimeout(() => commit('CLEAR_PING', { pingId }), killswitch);

      commit('SET_PING', { pingId });
    },
  },
};
