<template>
  <div>
    <div v-if="gettingStats && !stepsWithData.length">
      <board-area-skeleton />
    </div>
    <div
      v-if="stepsWithData"
      class="step-form-list mb-5"
    >
      <step-form-list-single
        v-for="stepData in stepsWithData"
        :key="stepData.step"
        :step-data="stepData"
        :getting-stats="gettingStats"
        :tiny-form="tinyForms[stepData.step]"
      />
    </div>
    <div
      v-if="!isLoadingForms && availableSurveys?.length"
      class="page-content-title activatewall-text"
    >
      <span class="h2">
        <i class="zmdi zmdi-forms-page" />
      </span>
      <span>
        <h2
          class="cursor-pointer"
          @click="openSurveyModal()"
        >{{ $gettext('Tillgängliga formulär') }}</h2>
      </span>
    </div>
    <div class="step-form-list badge-activatewall-outline badge-activatewall-outline--thick">
      <div
        v-for="(step, index) in availableSurveys"
        :key="index"
        class="step-form-list-single justify-space-between"
      >
        <div class="mb-2">
          <p class="link mb-0">
            {{ step.name }}
          </p>
          <p class="subtle-text small-text">
            {{ step.description }}
          </p>
        </div>
        <button
          class="btn btn-primary"
          @click="openSurveyModal(step.step)"
        >
          {{ $gettext('Aktivera Formuläret') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import ga from '@/plugins/googleAnalytics';
import dynamicImport from 'Utils/dynamicImport';
import useSurveyList from 'Composables/useSurveyList';
import BoardAreaSkeleton from 'Components/parts/board/BoardAreaSkeleton';
import { store } from '@/store';
import StepFormListSingle from './StepFormListSingle';

const { hardCodedSurveysSorted, isLoadingForms } = useSurveyList();

const props = defineProps({
  allSteps: {
    type: Array,
    required: true,
  },
  stats: {
    type: Object,
    default: null,
  },
  globalStats: {
    type: Object,
    default: null,
  },
  gettingStats: {
    type: Boolean,
    default: false,
  },
  tinyForms: {
    type: Object,
    default: () => ({}),
  },
});

const availableSurveys = computed(() => hardCodedSurveysSorted.value
  .filter((survey) => survey?.active === false && !props.allSteps.includes(survey.step)));

const openSurveyModal = (showSurvey = '') => {
  const AddSurveyDetails = dynamicImport(() => import(/* webpackChunkName: "AddSurveyDetails", webpackPrefetch: true */ 'Components/parts/details/AddSurveyDetails'));
  ga('send', {
    hitType: 'event',
    eventCategory: 'Dashboard',
    eventAction: 'activate-survey-btn',
    eventLabel: `clicked "Activate survey" about "${showSurvey}" in StepFormList`,
  });
  store.dispatch('openModal', {
    name: 'AddSurveyDetails',
    component: AddSurveyDetails,
    dataObject: { props: { showSurvey } },
    size: 'xlarge',
  });
};

const stepsWithData = computed(() => props.allSteps.map((step) => ({
  step,
  stats: props.stats?.[step],
  globalStats: props.globalStats?.[step],
})));

</script>
