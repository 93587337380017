import { defineAsyncComponent } from 'vue';
import { localStorageIsAvailable } from 'Utils/storage';
import DynamicImportLoading from 'Components/parts/dynamic-import/DynamicImportLoading';
import DynamicImportError from 'Components/parts/dynamic-import/DynamicImportError';

const dynamicImport = (componentImportFn) => defineAsyncComponent({
  loader: componentImportFn, // () => import('path/to/component')
  loadingComponent: DynamicImportLoading,
  delay: 200,
  errorComponent: DynamicImportError,
  timeout: 10000,
  onError: (error, retry, fail, attempts) => {
    if (/error\sloading\sdynamically\simported\smodule/.test(error.name) // "ChunkLoadError" is thrown by webpack, "error loading dynamically imported module" is thrown by vite
      && localStorageIsAvailable
      && !sessionStorage.getItem('chunkErrorPageReloaded')
    ) {
      sessionStorage.setItem('chunkErrorPageReloaded', true);
      if (typeof window !== 'undefined') window.location.reload();
    } else if (attempts < 2) {
      retry();
    } else {
      fail();
    }
  },
});

export default dynamicImport;
