function getHandler(handlers, event) {
  if (event.supressCloseHandlers) {
    return false;
  }
  for (let i = handlers.length - 1; i >= 0; i--) {
    if (handlers[i].event !== event) {
      return handlers[i];
    }
  }
  return false;
}

function close(state, commit, dispatch, event) {
  if (state.closeHandlers?.length > 0) {
    // run last added handler and remove from array
    const handler = getHandler(state.closeHandlers, event);
    if (handler?.handler) {
      handler.handler(dispatch);
      commit('REMOVE_CLOSE_HANDLER', handler);
    }
  }
}

export default {
  state: {
    closeHandlers: [],
    closeHandlersInitiated: false,
  },
  mutations: {
    ADD_CLOSE_HANDLER(state, handler) {
      state.closeHandlers.push(handler);
    },
    REMOVE_CLOSE_HANDLER(state, handler) {
      state.closeHandlers.splice(state.closeHandlers.indexOf(handler), 1);
    },
    REMOVE_LAST_CLOSE_HANDLER(state, handler) {
      state.closeHandlers.splice(state.closeHandlers.length - 1, 1);
    },
    INIT_CLOSE_HANDLERS(state) {
      state.closeHandlersInitiated = true;
    },
    CLEAR_CLOSE_HANDLER(state) {
      state.closeHandlers = [];
    },
  },
  actions: {
    registerMainHandlerOnce({ state, commit, dispatch }) {
      return new Promise((resolve) => {
        if (!state.closeHandlersInitiated) {
          commit('INIT_CLOSE_HANDLERS');
          document.body.addEventListener('click', (e) => {
            close(state, commit, dispatch, e);
          });
          if (typeof window !== 'undefined') {
            window.addEventListener('keyup', (e) => {
              if (e.keyCode === 27) {
                close(state, commit, dispatch, e);
              }
            });
          }
        }
        resolve();
      });
    },
    addCloseHandler({ commit, dispatch }, payload) {
      const handler = payload && typeof payload === 'function' && payload || payload.handler;
      const event = payload?.event || null;
      const stop = event && payload.stop || false;
      return dispatch('registerMainHandlerOnce')
        .then(() => {
          commit('ADD_CLOSE_HANDLER', {
            handler,
            event,
          });
          if (stop) {
            event.stopPropagation();
          }
        });
    },
    removeCloseHandler({ commit, state }, handler) {
      // if closed by someting or someone else than the window handler
      return new Promise((resolve) => {
        const h = state.closeHandlers.filter((obj) => obj.handler === handler);
        if (h) {
          commit('REMOVE_CLOSE_HANDLER', h);
          resolve();
        }
      });
    },
    removeAllCloseHandlers({ commit, state, dispatch }) {
      for (let i = 0; i < state.closeHandlers.length; i++) {
        state.closeHandlers[i].handler(dispatch);
      }
      commit('CLEAR_CLOSE_HANDLER');
    },
  },
};
