<template>
  <div class="container container-narrow">
    <div class="hgroup">
      <h2>{{ $gettext('Medlemmar i segment') }}</h2>
      <h5 v-html="translations.canSeeSegment" />
    </div>
    <user-list user-type="segment" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserList from '../parts/user/UserList';

export default {
  components: {
    UserList,
  },
  computed: {
    ...mapGetters([
      'customerName',
      'segment',
    ]),
    translations() {
      return {
        canSeeSegment: this.$gettext('Kan se segmentet <strong>%{name}</strong> på <strong>%{customer}</strong>', { name: this.segment.name, customer: this.customerName }),
      };
    },
  },
};
</script>
