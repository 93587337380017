<template>
  <div
    ref="el"
    class="top-banner"
  >
    <slot />
  </div>
</template>

<script setup>
import { ref, onUnmounted } from 'vue';
import { useResizeObserver } from '@vueuse/core';

const el = ref(null);

const observer = useResizeObserver(el, (entries) => {
  const entry = entries[0];
  const { clientHeight } = entry.target;
  // Potentially throttle this at some point
  document.documentElement.style.setProperty('--top-banner-height', `${Math.ceil(clientHeight)}px`);
});

onUnmounted(() => {
  observer.stop();
});
</script>

<!-- <style lang="scss">
@import "@/scss/vars/vars.scss";

.page-content-header-fixed {
  $min: map-get($breakpoints, small);

  @media (min-width: 544px) {
    top: var(--top-banner-height) !important;
  }
}

.dashboard-wrapper, .dashboard-sidebar {
  padding-top: var(--top-banner-height) !important;
}

.hamburger-wrapper {
  top: calc(16px + var(--top-banner-height)) !important;
}
</style> -->
