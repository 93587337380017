<template>
  <div ref="indicatorDataEl">
    <p class="goal-bar-data-description">
      {{ props.headline }}
    </p>
    <p class="goal-bar-data-num">
      {{ props.data }}
    </p>
    <p
      v-if="props.remaining !== undefined
        && props.remaining !== null
        && props.remaining !== false
        && props.remaining >= 0"
      data-testid="goalBarDataDescriptionText"
      class="goal-bar-data-description"
      v-text="getGoalCopy()"
    />
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useElementBounding } from '@vueuse/core';
import gettext from '@/gettext';

const { $pgettext } = gettext;

const props = defineProps({
  goalUnit: String,
  current: [Object, Number, String],
  end: [Object, Number, String],
  headline: String,
  data: [String, Number],
  remaining: [Number, Boolean],
  getElementBounding: Boolean,
});

const getGoalCopy = () => {
  // do if only cnps thingy
  if (props.goalUnit === 'cnpsValue' && props.remaining <= 0 && props.current.date < props.end.date) return $pgettext('Description – Goal reached', 'I fas');
  if (props.remaining <= 0) return $pgettext('Description – Goal reached', 'Målet är uppnått! 🎉');
  return $pgettext('Description – Goal remaining', '%{amount} till för att nå målet', { amount: props.remaining });
};

const indicatorDataEl = ref(null);
const elementBounding = props.getElementBounding
  ? reactive(useElementBounding(indicatorDataEl, { immediate: true, windowScroll: false }))
  : null;

defineExpose({ elementBounding });

</script>
