import { createStore as _createStore } from 'vuex';
import { cloneDeep } from 'lodash-es';
import mockStore from '@/test/__mocks__/store';
import * as actions from './actions';
import * as getters from './getters';
import stringTranslations from './modules/stringTranslations';
import closeHandlers from './modules/closeHandlers';
import notifications from './modules/notifications';
import customerProxies from './modules/customerProxies';
import customer from './modules/customer';
import segments from './modules/segments';
import benchmarks from './modules/benchmarks';
import stepForms from './modules/stepForms';
import questions from './modules/questions';
import answers from './modules/answers';
import filter from './modules/filter';
import modals from './modules/modals';
import boards from './modules/boards';
import sections from './modules/sections';
import cards from './modules/cards';
import panes from './modules/panes';
import users from './modules/users';
import tasks from './modules/tasks';
import pings from './modules/pings';
import router from './modules/router';
import tags from './modules/tags';
import topics from './modules/topics';

/* eslint-disable no-param-reassign */
const mutations = {
  SET_ROLE(state, role) {
    state.role = role;
  },
  SET_USER_TYPE(state, userType) {
    state.userType = userType;
  },
  SET_SEGMENT(state, segment) {
    state.currentSegment = segment;
  },
  SET_SEGMENT_BOARD(state, overview_board_id) {
    state.currentSegment = { ...state.currentSegment, overview_board_id };
  },
  SET_ME(state, me) {
    state.me = me;
  },
  SET_LANGUAGE_SETTINGS(state, languageSettings) {
    state.languageSettings = languageSettings;
  },
  ADD_TEXT_FILTER(state, textFilter) {
    state.textFilter = { ...state.textFilter, ...textFilter };
  },
  SET_LOAD_STATE(state, loading) {
    state.loading = loading;
  },
  SET_BACK_HISTORY(state, route) {
    state.backRoute = route;
  },
};
/* eslint-enable no-param-reassign */

const storeOptions = {
  modules: {
    stringTranslations,
    notifications,
    closeHandlers,
    customerProxies,
    customer,
    segments,
    benchmarks,
    stepForms,
    questions,
    answers,
    filter,
    modals,
    boards,
    sections,
    cards,
    panes,
    users,
    tasks,
    pings,
    router,
    tags,
    topics,
    // ? Add new modules to test store as well
  },
  state: () => ({
    role: '',
    userType: '',
    me: {},
    textFilter: {},
    loading: false,
    currentSegment: {},
    languageSettings: {},
    backRoute: null,
  }),
  actions,
  getters,
  mutations,
  strict: process.env.NODE_ENV !== 'production',
};

// ? For use in tests
export const createTestStore = (replaceState = {}) => _createStore({
  modules: {
    stringTranslations: cloneDeep(storeOptions.modules.stringTranslations),
    notifications: cloneDeep(storeOptions.modules.notifications),
    closeHandlers: cloneDeep(storeOptions.modules.closeHandlers),
    customerProxies: cloneDeep(storeOptions.modules.customerProxies),
    customer: cloneDeep(storeOptions.modules.customer),
    segments: cloneDeep(storeOptions.modules.segments),
    benchmarks: cloneDeep(storeOptions.modules.benchmarks),
    stepForms: cloneDeep(storeOptions.modules.stepForms),
    questions: cloneDeep(storeOptions.modules.questions),
    answers: cloneDeep(storeOptions.modules.answers),
    filter: cloneDeep(storeOptions.modules.filter),
    modals: cloneDeep(storeOptions.modules.modals),
    boards: cloneDeep(storeOptions.modules.boards),
    sections: cloneDeep(storeOptions.modules.sections),
    panes: cloneDeep(storeOptions.modules.panes),
    cards: cloneDeep(storeOptions.modules.cards),
    users: cloneDeep(storeOptions.modules.users),
    tasks: cloneDeep(storeOptions.modules.tasks),
    pings: cloneDeep(storeOptions.modules.pings),
    router: cloneDeep(storeOptions.modules.router),
    tags: cloneDeep(storeOptions.modules.tags),
    topics: cloneDeep(storeOptions.modules.topics),
  },
  state: () => ({ ...mockStore.state, ...replaceState }),
  // plugins: not used,
  actions: storeOptions.actions,
  getters: storeOptions.getters,
  mutations: storeOptions.mutations,
  strict: true,
});

export const store = process.env.NODE_ENV === 'test' ? createTestStore() : _createStore(storeOptions);
