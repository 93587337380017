<template>
  <div class="tc-card-takeover tc-card-takeover--lg">
    <div class="tc-card tc-card-primary">
      <div class="tc-card-header p-card">
        <h3>
          {{ $gettext('Trustcruit engångslösenord') }}
        </h3>
      </div>

      <div class="tc-card-body">
        <div class="p-card py-0">
          <div class="button-info mb-3">
            <template v-if="welcomeMessage">
              <h3
                tag="h3"
                class="mb-1"
              >
                {{ $pgettext(
                  "Paragraph - OTP signup 1",
                  "Välkommen till Truscruit!"
                ) }}
              </h3>
              <!--  eslint-disable max-len -->
              <p class="mb-5">
                {{ $pgettext(
                  "Paragraph - OTP signup 2",
                  "Det kan vara osäkert och krångligt att komma ihåg lösenord, därför har vi avlastat er från att komma ihåg ytterligare ett."
                ) }}
              </p>
              <!--  eslint-enable max-len -->
            </template>
            <h1 class="mb-1">
              {{ $pgettext(
                "Paragraph - OTP headline",
                "Kolla efter en kod i din inkorg"
              ) }}
            </h1>
            <p>
              <span>
                {{ $pgettext(
                  "Paragraph - OTP intro 1",
                  "Vi har skickat ett engångslösenord till"
                ) }}
              </span>
              <strong
                data-private="lipsum"
                class="ml-0.5"
              >{{
                emailAddress
                  ? emailAddress
                  : $pgettext('Paragraph - OTP intro 1 email', 'er')
              }}</strong><br>
              <span>
                {{ $pgettext(
                  "Paragraph - OTP intro 2",
                  "Koden löper ut snart, så klistra in den snarast."
                ) }}
              </span>
            </p>
          </div>

          <form
            class="otp-form"
            @submit.prevent="loginOTP"
          >
            <div class="text-center">
              <p>
                <input
                  id="otpInput"
                  ref="otpInput"
                  v-model="otpInput"
                  type="text"
                  class="form-control form-control-filled animated"
                  maxlength="7"
                  autofocus="on"
                  autocorrect="off"
                  autocomplete="one-time-code"
                  autocapitalize="none"
                  spellcheck="false"
                  data-private="lipsum"
                  tabindex="1"
                  :title="$pgettext('Tooltip - OTP input', 'Måste vara 6 bokstäver och/eller nummer')"
                  :disabled="loading"
                  placeholder="A1B 2C3"
                  @paste="pastedOTP"
                >
                <!-- pattern="[a-zA-Z0-9]{6,7}" -->
              </p>
            </div>

            <div class="text-center mt-3">
              <div
                v-if="!gdprInDB"
                class="form-group form-check"
              >
                <input
                  id="gdpr"
                  ref="gdpr"
                  v-model="gdpr"
                  name="gdpr"
                  type="checkbox"
                  class="form-check-input"
                  tabindex="0"
                >
                <label
                  class="form-check-label"
                  for="gdpr"
                >{{ $gettext('Jag har läst och godkänt villkoren i') }}</label> <a
                  class="link underline"
                  tabindex="0"
                  @click.stop="openGDPRModal()"
                  @keypress.enter.stop="openGDPRModal()"
                >{{ $gettext('Trustruits Privacy policy') }}</a>
              </div>
              <router-link
                v-if="!emailAddress"
                class="block validation-message is-invalid py-1 link-inverted"
                tabindex="0"
                to="login"
              >
                {{ $gettext('Saknar emailadress, klicka här för att prova igen.') }}
              </router-link>
              <transition
                name="fade-quick"
                mode="out-in"
              >
                <button
                  v-if="hasValidInput && otpError === '' || hasValidInput && !gdprInDB && emailAddress"
                  type="submit"
                  class="btn btn-block"
                  style="height: 40px"
                  :disabled="(!hasValidInput || !gdprInDB && !gdpr) || loading"
                >
                  <span v-if="!loading">
                    {{ $gettext('Logga in') }}
                  </span>
                  <span v-else>
                    <i class="zmdi zmdi-spinner zmdi-hc-spin" /><span>{{ $gettext('Loggar in') }}</span>
                  </span>
                </button>

                <div
                  v-else
                  class="block help-block validation-message is-invalid p-1 pb-1"
                  style="height: 40px"
                >
                  {{ otpError }}
                </div>
              </transition>
            </div>

            <div class="emailclients my-5">
              <a
                target="_blank"
                class="emailclient"
                href="https://mail.google.com/mail/u/0/"
                rel="noopener noreferrer"
                :title="$pgettext('Tooltip - emailclient', 'Öppna Gmail i en ny tabb')"
              ><img
                 class="mr-1"
                 alt="Gmail icon"
                 srcset="/img/icon-gmail.png, /img/icon-gmail@2x.png 2x"
               ><span>{{ $pgettext("Button - Open emailclient", "Öppna Gmail") }}</span>
                <span :aria-label="$pgettext('Tooltip - emailclient', 'Öppna Gmail i en ny tabb')" />
              </a>
              <a
                target="_blank"
                class="emailclient"
                href="https://outlook.live.com/mail/0/inbox"
                rel="noopener noreferrer"
                :title="$pgettext('Tooltip - emailclient', 'Öppna Outlook i en ny tabb')"
              ><img
                 class="mr-1"
                 alt="Outlook icon"
                 srcset="/img/icon-outlook.png, /img/icon-outlook@2x.png 2x"
               ><span>{{ $pgettext("Button - Open emailclient", "Öppna Outlook") }}</span>
                <span :aria-label="$pgettext('Tooltip - emailclient', 'Öppna Outlook i en ny tabb')" />
              </a>
            </div>
          </form>
        </div>

        <login-footer>
          <p
            class="mb-2"
          >
            {{ $pgettext("Paragraph - OTP footer 1", "Kan du inte hitta ditt engångslösenord?") }}
          </p>
          <ol class="login-footer-list pl-0">
            <li>
              {{ $pgettext("Paragraph - OTP footer step 1", "Kolla din skräpkorg!") }}
            </li>
            <li>
              <a
                tabindex="0"
                :class="{'login-footer-disable-link': !canResend}"
                @click="resendCode()"
              >{{ $pgettext("Paragraph - OTP footer step 2", "Skicka koden igen.") }}</a>
              <span class="subtle-text ml-0.5"> {{ resendCodeStateText }}</span>
            </li>
            <li>
              <a
                href="mailto:support@trustcruit.com"
                class="mr-0.5"
              >{{ $pgettext("Paragraph - OTP footer step 3.1", "Kontakta oss") }}</a>
              <span>{{ $pgettext("Paragraph - OTP footer step 3.2", "för hjälp.") }}</span>
            </li>
          </ol>
        </login-footer>
      </div>
    </div>
    <modalception />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { captureMessage } from '@/plugins/sentry';
import ga from '@/plugins/googleAnalytics';
import loginAPI from 'API/login';
import { localStorageIsAvailable } from 'Utils/storage';
import dynamicImport from 'Utils/dynamicImport';
import Modalception from 'Modal/Modalception';
import LoginFooter from 'Components/parts/LoginFooter';

const SPACE_POS = 3;
const SPACE_CHAR = ' ';
const MAX_OTP_LENGTH = 6;

export default {
  components: {
    LoginFooter,
    Modalception,
  },
  props: [
    'email',
    'next',
    'signup',
  ],
  data() {
    return {
      loading: false,
      otpInput: '',
      otpError: '',
      gdpr: false,
      canResend: true,
      resendCodeStateText: '',
    };
  },
  computed: {
    ...mapState({
      hiddenParams: (state) => state.router.hiddenParams,
    }),

    gdprInDB() {
      const gdprAccepted = localStorageIsAvailable && localStorage.getItem('gdprAccepted');
      return gdprAccepted === 'true';
    },
    welcomeMessage() {
      return this.hiddenParams?.signup;
    },
    nextRoute() {
      return this.hiddenParams?.next !== undefined ? this.hiddenParams.next : '/';
    },
    emailAddress() {
      return this.email || this.hiddenParams?.email || localStorageIsAvailable && localStorage.getItem('emailAddress') || false;
    },
    cleanOTPInput() { // ! Must be used in conjucture with this.$nextTick(() => { this.cleanOTPInput })
      return String(this.otpInput).replace(/\s/g, '');
    },
    hasValidInput() {
      return this.cleanOTPInput.length === MAX_OTP_LENGTH;
    },
  },
  watch: {
    hasValidInput() {
      if (this.cleanOTPInput.length >= 6) {
        if (this.gdpr === true) {
          this.loginOTP(); // ? Try to login with pasted OTP
        } else {
          this.otpError = this.$gettext('Du måste acceptera våra villkor innan du kan logga in.');
          setTimeout(() => this.$refs.gdpr?.focus(), 100);
        }
      }
    },
    otpInput(newVal, oldVal) { // ? Is run twice!
      let string = String(newVal).trim().replace(/[^a-zA-Z0-9\s]/g, '').toUpperCase();
      if ( // ? Handle string addition, add a space at i3
        (
          newVal.length >= 3
          && newVal.length >= oldVal.length
          && newVal.charAt(SPACE_POS) !== SPACE_CHAR)
        || (
          newVal.length > 6
          && newVal.charAt(SPACE_POS) !== SPACE_CHAR)
      ) {
        let cleanVal = newVal.replace(/[^a-zA-Z0-9]/g, '');
        string = `${cleanVal.substring(0, SPACE_POS)}${SPACE_CHAR}${cleanVal.substring(SPACE_POS, MAX_OTP_LENGTH)}`;
      } else if ( // ? Handle string deletion, remove space from i3
        newVal.length === SPACE_POS + 1
        && newVal.length < oldVal.length
        && newVal.charAt(SPACE_POS) === SPACE_CHAR
      ) {
        string = `${newVal.substring(0, SPACE_POS)}`;
      }

      this.otpInput = string;
    },
  },
  mounted() {
    this.gdpr = this.gdprInDB;

    document?.body?.addEventListener('paste', this.pastedOTP);
  },
  beforeUnmount() {
    document?.body?.removeEventListener('paste', this.pastedOTP);
  },
  methods: {
    ...mapActions(['openModal', 'fetchMe', 'setHiddenParams']),
    async resendCode() {
      if (this.emailAddress !== '' && this.canResend) {
        this.loading = true;
        this.canResend = false;
        this.resendCodeStateText = this.$pgettext('State - Resend code', 'Skickar...');
        try {
          await loginAPI.requestLogin(this.emailAddress);
          this.resendCodeStateText = this.$pgettext('State - Resend code', 'Skickat!');
        } catch (err) {
          // Catch any expected error here?
          this.resendCodeStateText = this.$pgettext('State - Resend code', 'Kunde inte skicka');
          this.canResend = true;
          console.error('[TC] resend OTP error', err); // eslint-disable-line no-console
        } finally {
          this.loading = false;
        }
      }
    },
    openGDPRModal() {
      const PrivacyPolicyDetails = dynamicImport(() => import(/* webpackChunkName: "PrivacyPolicyDetails", webpackPrefetch: true */ 'Components/parts/details/PrivacyPolicyDetails'));
      this.openModal({
        name: 'PrivacyPolicyDetails',
        component: PrivacyPolicyDetails,
        size: 'large',
      });
    },
    pastedOTP(evt) {
      evt.preventDefault();
      let string = evt.clipboardData.getData('text') || '';
      string = string.trim().replace(/\s/, '');
      this.otpInput = string;
    },
    async loginOTP() {
      if (this.hasValidInput && this.gdpr === true) {
        this.loading = true;
        this.otpError = '';

        this.$nextTick(async () => { // ? Needs to make sure this.cleanOTPInput is done
          const { AUTH_ERROR } = loginAPI;

          try {
            // gdpr is always true here but whatever...
            await loginAPI.loginEmailOTP(this.emailAddress, this.cleanOTPInput, this.gdpr);

            const me = await this.fetchMe();
            if (me?.segments?.length === 0 && me?.customers?.length === 0) {
              captureMessage('[TC] OTP — User has no connected segments or companies but tried logging in with correct OTP', { user: { id: me.id } });
              throw this.$pgettext('Error — OTP no segments or company', 'Din användare har inte åtkomst till något segment eller företag. Kontakta oss för hjälp.');
            }
            this.$router.push(this.nextRoute)
              .then((resp) => {
                ga('send', {
                  hitType: 'event',
                  eventCategory: 'Dashboard',
                  eventAction: 'Login',
                  eventLabel: 'Logged in with OTP',
                });
                if (localStorageIsAvailable) {
                  localStorage.removeItem('gdprAccepted');
                }
              })
              .catch((err) => {
                captureMessage('[TC] Login routing error', err);
                this.loading = false;
                throw this.$gettext('Inloggning misslyckades, kunde inte navigera till nästa sida');
              });
          } catch (err) {
            switch (err.message) {
              case AUTH_ERROR.INVALID_OTP:
                ga('send', {
                  hitType: 'event',
                  eventCategory: 'Dashboard',
                  eventAction: 'Login Error',
                  eventLabel: `Tried logging in with OTP. ${AUTH_ERROR.INVALID_OTP}`,
                });
                this.otpError = this.$pgettext('Error – OTP failed', 'Det där engångslösenordet var fel, prova igen!');
                this.otpInput = '';
                break;
              default:
                this.otpError = (err?.error || err?.detail) ?? err;
            }

            setTimeout(() => this.$refs.otpInput?.focus(), 100);
            this.loading = false;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-footer-list {
  max-width: 14rem;
  text-align: left;
  margin: 0 auto;
  li {
    margin-bottom: 0.5em;
  }
}

.login-footer-disable-link {
  cursor: inherit;
  text-decoration: none !important;
}
</style>
