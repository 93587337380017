import { h as createElement } from 'vue';
import { isEmpty, isEqual } from 'lodash-es';
import { mapState, mapGetters, mapActions } from 'vuex';
import { set, getDaysInMonth, isSameMonth, isSameYear, sub, differenceInCalendarMonths } from 'date-fns';
import { requestData, formatFiltersForRequest } from 'API/data';
import { filterMerge, prefixNPSValue, roundNumber } from 'Utils/general';
import { NPS_CATEGORIES } from 'Utils/constants';
import { GOAL_GRAPH_TYPES, graphTypeElement, getAllowlistCompiledFilter } from 'Utils/graph';
import { getAbsoluteDatesFromDate } from 'Utils/date';
import { format, nowUTC } from 'Utils/dateHelpers';
import { getGoalTypeFromDate } from 'Utils/goal';
import eventBus from 'Utils/eventBus';
import Card from '../Card';
import GoalResultHeader from './GoalResultHeader';
import GoalResultBarGraph from './GoalResultBarGraph';

const GRAPH_TYPE_COMPONENTS = {
  GoalBar: GoalResultBarGraph,
};

export function wrapGoalVal({ score = null, respondent_count = null, date = null } = {}, goalUnit = null) {
  if (date === 'now') date = format(set(nowUTC, { date: 1 }), 'yyyy-MM');

  let value = null;
  let displayValue = null;
  let val = score;
  if (NPS_CATEGORIES.includes(goalUnit || '')) val = respondent_count;
  if (val !== null && val !== undefined && Number.isFinite(val)) {
    if (goalUnit === 'cnpsValue') {
      value = roundNumber(val * 100);
      displayValue = String(prefixNPSValue(value));
    } else {
      value = roundNumber(val);
      displayValue = String(value);
    }
  }
  if (!Number.isFinite(val)) { displayValue = null; value = null; respondent_count = null; }
  return { displayValue, value, respondent_count, date };
}

const GoalResult = {
  name: 'GoalResult',
  props: {
    card: {
      type: Object,
      required: true,
    },
    board: Object,
    contextFilter: { // i.e. BoardFilter
      type: Object,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    links: Array,
    actions: Array,
    isDraggable: {
      type: Boolean,
      default: false,
    },
    enableOverlay: {
      type: Boolean,
      default: false,
    },
    temporaryCard: {
      type: Boolean,
      default: false,
    },
    isInBoard: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    hydrateProps: null,
  },
  emits: ['update-drag-area', 'loaded', 'toggle', 'update:col-class', 'download-card-png'],
  data() {
    return {
      goalVals: {},
      question: {},
      loading: true,
      hasData: false,
      hydratePropsInternal: null,
      abortToken: Math.random().toString(10).substring(2),
      isRemoving: false,
    };
  },
  computed: {
    ...mapState({
      isOverlayed: (state) => state.boards.isOrganizing,
    }),
    ...mapGetters([
      'customerId',
      'segmentId',
      'segmentFilter',
      'modalCard',
    ]),
    goalUnit() { return this.card?.metadata?.goal?.unit || ''; },
    goalStartDate() {
      let startDate;
      if (this.currentFilter?.date?.type === 'absolute') startDate = this.currentFilter.date.dateGte;
      return startDate
        || this.modalCard?.metadata?.goal?.startDate
        || this.card?.metadata?.goal?.startDate
        || (this.card?.created_at && format(set(new Date(this.card.created_at), { date: 1 }), 'yyyy-MM-dd'))
        || null;
    },
    colClass() { return this.card.metadata.colClass || 'col-xs-12 col-md-6'; },
    graphType() { return this.card.metadata.graphType?.selected; },
    compiledFilter() { return getAllowlistCompiledFilter({ filter: filterMerge(this.segmentFilter, this.contextFilter, this.filter), cardType: 'goal' }); },
    questionId() { return this.card.metadata.question; },
    filter() {
      return {
        ...this.card.metadata.filter,
        level: this.card.metadata.level,
      };
    },
    currentFilter() {
      const formattedCompiledFilter = formatFiltersForRequest(this.compiledFilter) || {};
      const defaultSegment = this.compiledCompare?.key !== 'segment' && this.compiledCompare?.key !== 'customerProxy'
        ? { segment: { segment_id: this.segmentId } } : {};
      const { tags, answers } = formattedCompiledFilter;
      const applicantFiltersUsed = { ...(tags && { tags }), ...(answers && { answers }) };
      const applicantFilter = !isEmpty(applicantFiltersUsed) ? { applicant: applicantFiltersUsed } : {};
      const { customer_proxy, date, segment } = formattedCompiledFilter;
      return {
        ...applicantFilter,
        ...(customer_proxy && { customer_proxy }),
        ...(date && { date }),
        ...((segment && { segment }) || defaultSegment),
        question: { question_id: this.questionId },
      };
    },
    watchedTrigger() {
      return {
        questionId: this.questionId,
        currentFilter: this.currentFilter,
        compiledFilter: this.compiledFilter,
        goalUnit: this.goalUnit,
      };
    },
  },
  watch: {
    watchedTrigger: {
      deep: true,
      handler(newVal, oldVal) {
        let load = false;
        let getQuestion = false;

        if (newVal.questionId && newVal.questionId !== oldVal.questionId) { load = true; getQuestion = true; }
        if (newVal.currentFilter && !isEqual(newVal.currentFilter, oldVal.currentFilter)) load = true;
        if (newVal.compiledFilter && !isEqual(newVal.compiledFilter, oldVal.compiledFilter)) load = true;
        if (newVal.goalUnit && newVal.goalUnit !== oldVal.goalUnit) load = true;

        if (getQuestion) this.getSetQuestion(this.questionId);
        if (load) this.load();
      },
    },
    card: {
      immediate: true,
      handler(card) {
        this.isRemoving = card.isRemoving;
      },
    },
    goalStartDate: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!isEmpty(this.modalCard)) {
          const { startDate } = this.modalCard.metadata.goal;
          if (!startDate && newVal !== oldVal && this.goalStartDate !== undefined) {
            this.setModalCardPath({
              path: 'metadata.goal.startDate',
              value: this.goalStartDate,
            });
          }
        }
      },
    },
  },
  mounted() {
    if (this.questionId) {
      this.getSetQuestion(this.questionId); // .then(() => this.load);
      this.load();
    }
  },
  beforeUnmount() {
    eventBus.$emit(`abortRequest:${this.abortToken}`);
  },
  methods: {
    ...mapActions([
      'getQuestionById',
      'setModalCardPath',
      'updateModalState',
    ]),
    async getSetQuestion(qId) {
      try {
        const question = await this.getQuestionById(qId);
        this.question = question;
      } catch (err) {
        this.question = {};
      }
      return this.question;
    },
    async goalRequest(queries = [], path = '') {
      if (queries.length === 0) return [];
      try {
        const result = await requestData(
          queries,
          path,
          this.abortToken,
          this.$pgettext('Error - Card API data', 'data för card'),
        );
        return result || [];
      } catch (error) {
        return [];
      }
    },
    populateHydrateProps(goalVals, query) {
      this.hydratePropsInternal = {
        [this.graphType]: { [this.goalUnit]: { goalVals, query } },
      };
    },
    checkHasContent(goalVals) {
      if (!goalVals) return false;
      return Object.values(goalVals).filter((val) => val !== null && val !== undefined && !isEmpty(val)).length > 0
        || false;
    },
    content() {
      if (this.isRemoving) {
        return [createElement('div', { class: 'p-card' }, [
          createElement('div', { class: 'alert alert-info mb-0' }, this.$gettext('Tar bort...')),
        ])];
      }

      if (this.card) {
        return [createElement(graphTypeElement(GRAPH_TYPE_COMPONENTS, this.graphType), {
          card: this.card,
          board: this.board,
          question: this.question,
          loading: this.loading || null,
          hasData: this.hasData || null,
          contextFilter: this.contextFilter,
          compiledFilter: this.compiledFilter,
          disableTabindex: this.isOverlayed || null,
          goalVals: this.goalVals,
          previewMode: this.previewMode || null,
          onLoading: (state) => {
            this.loading = state;
            if (!state) this.$emit('loaded', !state);
          },
          'onUpdate-drag-area': () => this.$emit('update-drag-area'),
        })];
      }
      return [createElement('div', { class: 'p-card' }, [
        createElement('div', { class: 'alert alert-info mb-0' }, this.$gettext('Det finns ingen mall för denna typ av graf.')),
      ])];
    },
    prepareQueries({ graphType, goalUnit, goalStartDate, currentFilter } = {}) {
      if (graphType === GOAL_GRAPH_TYPES.GoalBar && goalStartDate && !isEmpty(currentFilter?.date)) {
        const goalType = getGoalTypeFromDate(currentFilter?.date);
        let cnpsCategoryGroupBy = {};
        if (NPS_CATEGORIES.includes(goalUnit)) {
          cnpsCategoryGroupBy = {
            hierarchy: 1,
            groupBy: ['score'],
          };
        }

        const query = {
          filter: { ...currentFilter },
          ...cnpsCategoryGroupBy,
        };

        let trendDateGte;
        let trendDateLte;
        if (goalType === 'oneTime') {
          const { dateGte, dateLte } = getAbsoluteDatesFromDate(query.filter.date);
          const tmpGte = new Date(dateGte);
          const tmpLte = new Date(dateLte);
          if (isSameYear(tmpGte, tmpLte) && isSameMonth(tmpGte, tmpLte)) {
            trendDateGte = sub(tmpGte, { months: 1 });
            trendDateLte = sub(tmpLte, { months: 1 });
          } else {
            trendDateGte = tmpGte;
            trendDateLte = sub(tmpLte, { months: 1 });
          }
        }
        const trendQuery = {
          filter: {
            ...query.filter,
            date: goalType === 'oneTime'
              ? { // 'oneTime'
                type: 'absolute',
                dateGte: format(set(trendDateGte, { date: 1 }), 'yyyy-MM-dd'), // ? Same as absolute current Gte
                dateLte: format(set(trendDateLte, { date: getDaysInMonth(trendDateLte) }), 'yyyy-MM-dd'), // ? Minus one month from absolute current Lte
              } : { // 'rollingAvg'
                ...query.filter.date,
                offset: query.filter.date?.offset?.months !== undefined // ? Always offset trend backwards 1 more month
                  ? { ...query.filter.date.offset, months: query.filter.date.offset.months + 1 }
                  : { ...query.filter.date?.offset, months: 1 },
              },
          },
          ...cnpsCategoryGroupBy,
        };

        let monthsDiffStartCurrent = 0;
        if (goalType === 'rollingAvg') {
          monthsDiffStartCurrent = differenceInCalendarMonths(nowUTC, new Date(goalStartDate));
        }
        const startDateQuery = {
          filter: {
            ...query.filter,
            date: goalType === 'oneTime'
              ? query.filter.date // 'oneTime'
              : { // 'rollingAvg'
                ...query.filter.date,
                offset: query.filter.date?.offset?.months !== undefined // ? Always offset backwards the diffing amount of months
                  ? { ...query.filter.date.offset, months: query.filter.date.offset.months + monthsDiffStartCurrent }
                  : { ...query.filter.date?.offset, months: monthsDiffStartCurrent },
              },
          },
          ...cnpsCategoryGroupBy,
        };

        const startDateFirstTruthyQuery = { // ? Only for finding out when the first available data is from, to set as startDate
          ...startDateQuery,
          hierarchy: 2,
          groupBy: NPS_CATEGORIES.includes(goalUnit)
            ? ['date', 'score']
            : ['date'],
        };

        return [query, trendQuery, startDateFirstTruthyQuery, startDateQuery];
      }
      return [];
    },
    async load() {
      this.hasData = false;
      this.loading = true;
      const query = this.prepareQueries({
        graphType: this.graphType,
        goalUnit: this.goalUnit,
        goalStartDate: this.goalStartDate,
        currentFilter: this.currentFilter,
      });

      const useSavedResponse = isEqual(this.hydrateProps?.[this.graphType]?.[this.goalUnit]?.query, query)
      && !isEmpty(this.hydrateProps?.[this.graphType]?.[this.goalUnit]);

      if (useSavedResponse) {
        this.goalVals = this.hydrateProps[this.graphType][this.goalUnit]?.goalVals || {};
        this.hasData = this.checkHasContent(this.goalVals);
        if (this.hasData && this.previewMode) this.updateModalState({ goalVals: this.goalVals });
        this.loading = false;
      }
      if (!this.hasData) {
        try {
          this.loading = true;
          let response;
          if (query.length !== 0) response = await this.goalRequest(query);

          if (!isEmpty(response)) {
            const [currentValIndex, trendValIndex, startValFirstTruthyIndex, startValIndex] = [0, 1, 2, 3];
            this.goalVals = response?.reduce((acc, resp, i) => { // [{},{},{}] => {}
              if (
                [currentValIndex, trendValIndex, startValIndex].includes(i)
                && NPS_CATEGORIES.includes(this.goalUnit)
              ) resp = resp?.[this.goalUnit] || {}; // Flatten early for other than startValFirstTruthyIndex

              let key = i;
              let date = null;
              if (i === currentValIndex) {
                key = 'currentVal';
                date = 'now';
              }
              if (i === trendValIndex) {
                let currValDB = acc?.currentVal?.value;
                if (currValDB !== undefined && this.goalUnit === 'cnpsValue') currValDB = acc.currentVal.value / 100;
                key = 'trendVal';
                date = format(sub(new Date(acc?.currentVal?.date), { months: 1 }), 'yyyy-MM');
                if (NPS_CATEGORIES.includes(this.goalUnit)) {
                  resp.respondent_count = currValDB !== undefined && resp.respondent_count !== null
                    ? currValDB - resp.respondent_count
                    : resp.respondent_count;
                } else {
                  resp.score = currValDB !== undefined && resp.score !== null
                    ? currValDB - resp.score
                    : resp.score;
                }
              }
              if (i === startValFirstTruthyIndex) {
                key = 'startValFirstTruthy';

                // ? If a response obj comes in with bad sorting, it will screw this up
                const firstTruthyIndex = Object.values(resp).findIndex((respInner) => {
                  // {"2022-10":null,"2022-11":{},...} => null || {}
                  if (respInner === null) return false;
                  if (NPS_CATEGORIES.includes(this.goalUnit)) { // ? support `hierarchy: 2, groupBy: ['date', 'score']`
                    return Number.isFinite(respInner?.[this.goalUnit]?.respondent_count);
                  }
                  return Number.isFinite(respInner?.score); // ? support `hierarchy: 2, groupBy: ['date']
                });

                let firstTruthyResp;
                if (firstTruthyIndex === -1) {
                  firstTruthyResp = resp;
                } else if (NPS_CATEGORIES.includes(this.goalUnit)) {
                  firstTruthyResp = Object.values(resp)?.[firstTruthyIndex]?.[this.goalUnit] ?? {};
                } else {
                  firstTruthyResp = Object.values(resp)?.[firstTruthyIndex] ?? {};
                }

                resp = firstTruthyResp;
                date = firstTruthyResp?.date_str ?? null;
              }
              if (i === startValIndex) {
                key = 'startVal';
                date = format(new Date(this.goalStartDate), 'yyyy-MM');
                if (getGoalTypeFromDate(this.currentFilter.date) === 'oneTime') {
                  date = acc?.startValFirstTruthy?.date ?? date;
                  if (NPS_CATEGORIES.includes(this.goalUnit)) {
                    resp.respondent_count = 0;
                  } else {
                    resp.score = resp.score ?? -1;
                  }
                }
              }

              acc = { ...acc, [key]: wrapGoalVal({ ...resp, date }, this.goalUnit) };
              return acc;
            }, {});
          }
        } catch (err) {
          this.goalVals = {};
        } finally {
          this.loading = false;
          this.hasData = this.checkHasContent(this.goalVals);
          if (this.hasData && this.previewMode) this.updateModalState({ goalVals: this.goalVals });
          if (!this.previewMode) this.populateHydrateProps(this.goalVals, query);
          this.$emit('loaded', true);
        }
      }
    },
  },
  render() {
    return createElement(
      Card,
      {
        loadingSpinner: this.loading || null,
        isDraggable: this.isDraggable || null,
        isOverlayed: this.isOverlayed || null,
        enableOverlay: this.enableOverlay || null,
        colClass: this.colClass || '',
        onToggle: (card) => this.$emit('toggle', card),
        'onUpdate:col-class': (colClass) => this.$emit('update:col-class', colClass),
      },
      {
        header: () => createElement(GoalResultHeader, {
          class: 'p-card',
          question: this.question,
          contextFilter: this.contextFilter,
          compiledFilter: this.compiledFilter,
          name: this.name,
          description: this.description,
          actions: this.actions,
          links: this.links,
          card: this.card,
          board: this.board,
          visible: !this.isOverlayed,
          isInBoard: this.isInBoard,
          previewMode: this.previewMode,
          temporaryCard: this.temporaryCard,
          hydrateProps: this.hydrateProps || this.hydratePropsInternal,
          cardLoading: this.loading,
          'onDownload-card-png': () => this.$emit('download-card-png'),
          'onUpdate-drag-area': () => this.$emit('update-drag-area'),
        }),
        body: () => this.content(createElement),
      },
    );
  },
};

export default GoalResult;
