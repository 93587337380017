import request from 'superagent';
import { BASE_URL, currentRoute } from 'Utils/urls';
import { localStorageIsAvailable, removeCookies } from 'Utils/storage';
import { superagentSetDashboardOriginPage } from 'Utils/apiHelpers';
import { ErrCodeError } from 'Utils/errors';
import { store } from '@/store';

export function clearLoginLocalStorage() {
  if (localStorageIsAvailable) {
    localStorage.removeItem('token');
    localStorage.removeItem('token-refresh');
    localStorage.removeItem('token-refresca');
    localStorage.removeItem('gdprAccepted');
    localStorage.removeItem('emailAddress');
    localStorage.removeItem('currentSegmentId');
    localStorage.removeItem('customerProxy');
  }
}

export function tokenNeedsRefresh(key = 'token') {
  return localStorageIsAvailable && localStorage.getItem(`${key}-refresh`) < Date.now();
}

export function getToken(key = 'token') {
  const route = currentRoute();
  const token = (route?.query?.token)
    || (localStorageIsAvailable && localStorage.getItem(key));
  const refresca = localStorageIsAvailable && localStorage.getItem(`${key}-refresca`) || null; // ! The refresh-token is called 'refresca'
  return {
    token, // ? Access-token lives for 4 hours
    refresca, // ? Refresh-token lives for 60 days
    needsRefresh: tokenNeedsRefresh(key),
    hasToken: token != null,
  };
}

export function setToken(token, key = 'token', refresca = undefined) {
  if (localStorageIsAvailable) {
    localStorage.setItem(key, token);
    if (refresca !== undefined) localStorage.setItem(`${key}-refresca`, refresca);
    const future = 3.5 * 60 * 60 * 1000; // 3.5 hours to ms
    // ? Set to half an hour before expiration (4 hours) to hopefully retrieve a new accessToken before trying requests with a bad token
    localStorage.setItem(`${key}-refresh`, Date.now() + future);
  }
}

export function logout() {
  if (window?.Intercom) window.Intercom('shutdown');
  if (localStorageIsAvailable) {
    localStorage.removeItem('intercom-state');
    localStorage.removeItem('intercom.intercom-state');
  }

  const token = getToken();
  const postObject = token.refresca ? { refresh: token.refresca } : {};
  function _postBaseUrlWithOuterToken(url, data = null) {
    let r = request
      .post(`${BASE_URL}${url}`)
      .use(superagentSetDashboardOriginPage)
      .accept('application/json')
      .on('abort', () => Promise.resolve());
    if (token.token) r = r.set('Authorization', `JWT ${token.token}`);

    if (data !== null) return r.send(data);
    return r;
  }
  const p = _postBaseUrlWithOuterToken('/logout/', postObject);
  removeCookies(['at', 'rt']); // ? Requires a client-side reload to work
  if (localStorageIsAvailable) {
    localStorage.removeItem('token');
    localStorage.removeItem('token-refresh');
    localStorage.removeItem('token-refresca');
  }
  if (!token.hasToken) return false;
  p.then(
    () => {
      if (typeof window !== 'undefined')window.location.reload();
    },
    () => {
      if (typeof window !== 'undefined') window.location.reload();
    },
  );
  return true;
}

export function refreshToken(refresca) {
  return request
    .post(`${BASE_URL}/api-token-refresh/`)
    .send({ refresh: refresca })
    .retry(2)
    .then((response) => response.body.access)
    .then(setToken)
    .then(() => Promise.resolve())
    .catch(() => logout()); // Perhaps return rejected promise too?
}

export function isAuthenticated(key = 'token', forceRefreshToken = false) {
  const token = getToken(key);
  if (token.refresca != null && (token.needsRefresh || !token.hasToken || forceRefreshToken)) {
    // ? Currently we only refreshToken on reroutes, that means that regular API requests that run
    // ? after the accessToken has run out (4hrs), will throw a 401. They can be caught in a catch (handleAuthError),
    // ? but the original request is never re-run after getting a fresh accessToken. Can we solve this?
    // ? Setting the accessToken to half an hour less might be sufficient
    return refreshToken(token.refresca);
  }
  if (token.hasToken) {
    return Promise.resolve(true);
  }
  return Promise.reject();
}

export async function handleAuthError(error) {
  // if (process.env.NODE_ENV === 'test') return console.error('[TC] TEST Caught error:', error);

  if (error.status === 401) { // 401 = Unauthorized
    try {
      await isAuthenticated('token', true);
      store.dispatch('notify', {
        text: 'TM-401-retry',
        level: 'error',
      });
      return error;
    } catch (err) {
      logout();
    }
  }
  throw new ErrCodeError(error);
}
