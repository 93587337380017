export default function debounce(fn, wait) {
  let timeout;

  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => fn.apply(context, args), wait);
  };
}

export function Timer(callback, delay = 200) {
  let timerId;
  let start;
  let remaining = delay;

  this.pause = () => {
    if (typeof window !== 'undefined') {
      window.clearTimeout(timerId);
      remaining -= Date.now() - start;
    }
  };

  this.resume = () => {
    if (typeof window !== 'undefined') {
      start = Date.now();
      window.clearTimeout(timerId);
      timerId = window?.setTimeout(callback, remaining);
    }
  };

  this.resume();
}

/**
 ** ActivityBasedTimer - attach "middleware-callbacks" that will be called when invoking runTimersCheck(), or if foredInterval timer is reached
 * It is good for having an interval check that doesn't try to run network requests when the user isn't interacting with the dashboard
 */
export const ActivityBasedTimer = () => {
  let globalTimerId = 0;
  const timers = new Map();

  const maybeExecuteTimerCallback = ({ timerId, forced = false }) => {
    const timer = timers.get(timerId);

    if (timer === undefined) return;

    const {
      callback,
      interval,
      forcedInterval,
      forcedIntervalId,
      lastExecution,
    } = timer;
    const intervalToCheckFor = forced === true ? forcedInterval : interval;
    const now = Date.now();

    if (now - lastExecution < intervalToCheckFor) return;

    const newTimer = {
      ...timer,
      lastExecution: now,
    };

    if (forcedIntervalId !== undefined) {
      if (typeof window !== 'undefined') {
        window.clearInterval(forcedIntervalId);
        newTimer.forcedIntervalId = window.setInterval(() => {
          maybeExecuteTimerCallback({ timerId, forced: true });
        }, forcedInterval);
      }
    }

    timers.set(timerId, newTimer);
    callback({ forced, timerId });
  };

  const setInterval = ({ callback, interval, forcedInterval } = {}) => {
    const timerId = globalTimerId;

    if (typeof callback !== 'function' || typeof interval !== 'number') return undefined;

    const timer = {
      callback,
      interval,
      lastExecution: Date.now(),
    };

    if (forcedInterval !== undefined && typeof window !== 'undefined') {
      timer.forcedInterval = forcedInterval;
      timer.forcedIntervalId = window.setInterval(() => {
        maybeExecuteTimerCallback({ timerId, forced: true });
      }, forcedInterval);
    }

    timers.set(timerId, timer);
    globalTimerId += 1;
    return timerId;
  };

  const clearInterval = (timerId) => {
    const timer = timers.get(timerId);
    if (timer === undefined) return;

    const { forcedIntervalId } = timer;
    if (forcedIntervalId !== undefined && typeof window !== 'undefined') window.clearInterval(forcedIntervalId);

    timers.delete(timerId);
  };

  const runTimersCheck = () => {
    timers.forEach((_timer, timerId) => {
      maybeExecuteTimerCallback({ timerId });
    });
  };

  return {
    setInterval,
    clearInterval,
    runTimersCheck,
  };
};
