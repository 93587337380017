<template>
  <div class="skeleton-wrapper">
    <div
      v-for="(skel, i) in skeletons"
      :key="i"
      class="skeleton-full skeleton-h-100"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  sectionsAmount: {
    type: Number,
    default: 1,
  },
});
const skeletons = computed(() => [...Array(props.sectionsAmount)]); // TODO: Doesn’t react to changes in sectionsAmount
</script>
