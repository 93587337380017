<template>
  <div class="select-filter-type-key">
    <tc-select
      v-if="filterType === 'tags'"
      ref="filterTypeKeyTags"
      v-model="chosenKey"
      :placeholder="$gettext('Välj tag')"
      :searchable="false"
      :disabled="disabled"
      :on-change="filteredTagKeyOptions"
      :clean-paginator-fn="cleanPaginatorFn"
      :populate-store-fn="populateStoreFn"
      :loading-store-promise="fetchingTagKeys"
      :no-options-text="$gettext('Inga fler tags finns')"
      @select="changeKey"
    />
    <question-filter
      v-else-if="filterType === 'answers'"
      v-model="chosenKey"
      :enable-sidelabel="true"
      :level="filterKeepLevel !== 'all_steps' ? filterKeepLevel : ''"
      :disabled="disabled"
      :filter-out-keys="filterOutKeys"
      @update:model-value="changeKey"
    />
  </div>
</template>

<script>
import { isArray } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import tagsAPI from 'API/tags';
import { questionOption } from 'Utils/question';
import QuestionFilter from 'Components/parts/filters/QuestionFilter';

export default {
  name: 'SelectFilterTypeKey',
  components: {
    QuestionFilter,
  },
  props: {
    modelValue: Array,
    filterType: {
      type: String,
      required: true,
      validator(value) {
        return ['tags', 'answers', 'customerProxies', 'date'].indexOf(value) !== -1;
      },
    },
    customer: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filterOutKeys: {
      type: Array,
      default: () => [],
      validator(value) {
        return value.every((v) => typeof v === 'string' || typeof v === 'number');
      },
    },
    filterOutSegmentTags: {
      type: Boolean,
      default: false,
    },
    filterKeepLevel: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      chosenKey: [],
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'customerId',
      'tagKeys',
      'segmentTagKeyOptions',
      'customerTagKeyOptions',
      'fetchingTagKeys',
      'customerTagKeyVal',
      'segmentTagKeyVal',
    ]),
    filteredTagKeyOptions() {
      return (term) => (
        this.customer
          ? this.customerTagKeyOptions(term)
          : this.segmentTagKeyOptions(term)
      );
    },
  },
  watch: {
    filterType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setupKey(newVal);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setupKey();
    });
  },
  methods: {
    ...mapActions([
      'getAllQuestions',
      'fetchAnswerValues',
      'setTagKeys',
      'customerTagKeyTranslation',

    ]),
    populateStoreFn(keysPaginator) {
      switch (this.filterType) {
        case 'tags':
          return this.setTagKeys({ tagKeysPaginator: keysPaginator, customer: this.customer }); // eslint-disable-line max-len
          // case 'answers':
          //  return this.setAnswerValues({ answerKey: this.filterTypeKey, answerValuesPaginator: valuesPaginator });

        default:
          return () => {};
      }
    },
    cleanPaginatorFn(reqPag, populator) {
      const formatPopulator = (opt) => {
        if (opt?.label) return { key: opt.value, value: opt.label };
        return { key: opt.key, value: opt.value };
      };
      return ({
        ...reqPag._dataset,
        results: populator?.stack.map(formatPopulator) || [],
      });
    },
    filterActiveTagsFn(term) {
      const tagKeys = this.tagKeys(this.customer);
      const keepTheseKeys = [...tagKeys];
      const removeTheseKeys = [...this.filterOutKeys];
      return this.filterOutSegmentTags
        ? keepTheseKeys.indexOf(term) > -1 && removeTheseKeys.indexOf(term) === -1
        : removeTheseKeys.indexOf(term) === -1;
    },
    filterActiveAnswersFn(term) {
      return this.filterOutKeys.indexOf(term) === -1;
    },
    clear() {
      this.chosenKey = [];
    },
    changeKey(key) {
      this.$emit('update:modelValue', isArray(key) ? key : [key]);
    },
    setupKey(filterType = this.filterType, value = this.modelValue) {
      if (value.length !== 0) {
        this.setup(value);
      } else if (filterType === 'tags') {
        const tagKeys = this.tagKeys(this.customer);
        const tagKeysFirst = tagKeys?.filter(this.filterActiveTagsFn)?.[0] || null;
        const translatedValue = this.customer
          ? this.customerTagKeyVal?.[tagKeysFirst]
          : this.segmentTagKeyVal?.[tagKeysFirst];
        if (tagKeysFirst) {
          this.setup([tagsAPI.selectTag({ key: tagKeysFirst, value: translatedValue || tagKeysFirst })]);
        }
      } else if (filterType === 'answers') {
        this.getAllQuestions()
          .then((questions) => { // ? Probably breaks UX a bit if questions aren’t already loaded
            const restrictedQuestions = this.filterKeepLevel && this.filterKeepLevel !== 'all_steps'
              ? questions.filter((q) => q.level === this.filterKeepLevel)
              : questions;
            const answerKeysFirst = restrictedQuestions.find((q) => this.filterActiveAnswersFn(String(q.id))) || null;
            if (answerKeysFirst) {
              const firstQuestionOption = questionOption(answerKeysFirst, true);
              this.setup([firstQuestionOption]);
              this.fetchAnswerValues({ answerKeys: [firstQuestionOption.value], question: answerKeysFirst });
            }
          });
      }
    },
    setup(key) {
      if (key) this.chosenKey = key;
      this.$emit('update:modelValue', this.chosenKey);
    },
  },
};
</script>
