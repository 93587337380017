import { getBaseUrl, putUrl, deleteUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

function list({ customer = null, excludeFromSegment = null, role = null, paginated = false } = {}) {
  return getBaseUrl('/feedback/accounts/api/customeruser/')
    .query({
      ...(customer && { customer }),
      ...(excludeFromSegment && { exclude_from_segment: excludeFromSegment }),
      ...(role && { role }),
    })
    .then((response) => (
      paginated && response.body.next !== undefined ? response.body : response.body.results
    ), handleAuthError)
    .catch(() => { /* fixes Error: connect ECONNREFUSED in tests */ });
}

function me(customer) {
  return getBaseUrl('/feedback/accounts/api/customeruser/me/')
    .query({ customer })
    .then((response) => response.body, handleAuthError);
}

function update(id, role) {
  return putUrl(`/feedback/accounts/api/customeruser/${id}/`, { role });
}

function remove(id) {
  return deleteUrl(`/feedback/accounts/api/customeruser/${id}/`);
}

export default {
  list,
  me,
  update,
  remove,
};
