import Model from 'API/model';
import {
  postUrl,
  patchUrl,
  deleteUrl,
  getBaseUrl,
  createClass,
} from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';
import { striphtml } from 'Utils/general';
import { store } from '@/store';

/**
 ** Type Definitions
 * @typedef {object} IorderAsSection
 * @property {string} IorderAsSection.prevSectionOrder
 * @property {string} IorderAsSection.nextSectionOrder
 * @example { prevSectionOrder, nextSectionOrder }
 */

class Section extends Model {
  constructor(data) {
    super(data);
    this.board = this.board ?? null;
    this.id = this.id ?? null; // sectionId
    this.order = this.order ?? 'n';
    this.name = this.name ?? '';
    this.description = this.description ?? '';
    this.color = this.color ?? '';
  }

  setInfo({ name, description, color }) {
    this.name = name ?? this.name;
    this.description = description ?? this.description;
    this.color = color ?? this.color;
  }

  // remove() comes from Model

  save(thenAssign = true) {
    if (this.id === undefined) {
      return this._api.create(this.board, store.getters.getSectionOrder(this.board), this)
        .then((obj) => Object.assign(this, obj));
    }
    const promise = this._api.update(this.id, this);
    if (thenAssign) return promise.then((obj) => Object.assign(this, obj));
    return promise;
  }
}

export const createSection = createClass?.(Section);

export const getSectionOrderAs = (sectionOrders, section = null) => {
  const orderAs = { prevSectionOrder: '', nextSectionOrder: '' };
  if (section === null) { // ? create new section, add as last
    orderAs.prevSectionOrder = sectionOrders?.[sectionOrders.length - 1] ?? '';
    return orderAs;
  }
  // TODO: add according to section.order
  console.error('add according to section.order'); // eslint-disable-line no-console
  return orderAs;
};

/**
  ** sectionsAPI.create
  * @param {number} boardId
  * @param {IorderAsSection} [orderAs={ prevSectionOrder: '', nextSectionOrder: '' }]
  * @param {object} optionals
  * @param {string} [optionals.name='']
  * @param {string} [optionals.description='']
  * @param {string} [optionals.color='']
  * @returns {Promise<SectionClass>}
 */
function create(
  boardId,
  orderAs = { prevSectionOrder: '', nextSectionOrder: '' },
  { name = '', description = '', color = '' } = {},
) {
  return postUrl('/databoard/api/sections/', {
    board: boardId,
    previous_section_order: orderAs.prevSectionOrder,
    next_section_order: orderAs.nextSectionOrder,
    name: striphtml(name),
    description: striphtml(description),
    color,
  }).then(createSection);
}

/**
  ** sectionsAPI.list
  * @param {number|null} [boardId=null]
  * @returns {Promise<SectionClass[]>}
 */
function list(boardId = null) {
  return getBaseUrl('/databoard/api/sections/').query({ board: boardId })
    .then((response) => response.body.results, handleAuthError) // TODO: Add paginated response support & update JSDOC
    .then(createSection);
}

/**
  ** sectionsAPI.get
  * @param {number} sectionId
  * @returns {Promise<SectionClass>}
 */
function get(sectionId) {
  return getBaseUrl(`/databoard/api/sections/${sectionId}/`)
    .then((response) => response.body, handleAuthError)
    .then(createSection);
}

/**
  ** sectionsAPI.update
  * @param {number} sectionId
  * @param {object} optionals
  * @param {string} [optionals.name=null]
  * @param {string} [optionals.description=null]
  * @param {string} [optionals.color=null]
  * @returns {Promise<SectionClass>}
 */
function update(sectionId, { name = null, description = null, color = null }) {
  return patchUrl(`/databoard/api/sections/${sectionId}/`, {
    ...(name !== null && { name: striphtml(name) }),
    ...(description !== null && { description: striphtml(description) }),
    ...(color !== null && { color }),
  });
  // .then(createSection);
}

/**
  ** sectionsAPI.remove
  * @param {number} sectionId
  * @returns {Promise}
 */
function remove(sectionId) {
  return deleteUrl(`/databoard/api/sections/${sectionId}/`);
}

/**
  ** sectionsAPI.move
  * @param {number} sectionId
  * @param {IorderAsSection} [orderAs={ prevSectionOrder: '', nextSectionOrder: '' }]
  * @returns {Promise}
 */
function move(sectionId, orderAs = { prevSectionOrder: '', nextSectionOrder: '' }) {
  return patchUrl(`/databoard/api/sections/${sectionId}/`, {
    previous_section_order: orderAs.prevSectionOrder,
    next_section_order: orderAs.nextSectionOrder,
  });
}

const _api = {
  get,
  list,
  update,
  remove,
  create,
  move,
};

Section.prototype._api = _api;

export default _api;
