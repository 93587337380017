import { createGettext } from 'vue3-gettext';
import { availableLanguages, defaultLanguage } from 'Utils/languages';
import translations from './translations.json';

// ? Use gettext.$language.current as the safest current language getter - https://github.com/Polyconseil/vue-gettext#configuration
const gettext = createGettext({
  availableLanguages,
  translations,
  defaultLanguage,
  silent: true,
});

// export const { $gettext, $pgettext, $npgettext } = gettext;

export default gettext;
