import { has, get } from 'lodash-es';

export function rowSort(key, desc = true, percentages = false) {
  return (a, b) => {
    if (key === 'label' && a[key]) { // make sure there is a sort-key on the row (e.g. not on the totalrow)
      // sort errored stats below
      if (typeof a.stats.error !== typeof b.stats.error) {
        return a.stats.error === undefined ? -1 : 1;
      }
      // sort anonymized stats below
      if (a.stats.anonymized !== b.stats.anonymized) {
        return a.stats.anonymized ? 1 : -1;
      }
      return desc
        ? a.label.localeCompare(b.label)
        : b.label.localeCompare(a.label);
    }
    let path = percentages ? 'stats.sortValues.percentages' : 'stats.sortValues';
    path += `.${key}`;
    if (!has(a, path)) {
      return 1;
    }
    if (!has(b, path)) {
      return -1;
    }
    if (get(a, path) > get(b, path)) {
      return desc ? -1 : 1;
    }
    if (get(a, path) < get(b, path)) {
      return desc ? 1 : -1;
    }
    return a.label.localeCompare(b.label);
  };
}

export default {
  rowSort,
};
