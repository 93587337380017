// Examples:
// v-tc-badge:center-left.activatewall-outline="show ? 'NEW' : null"
// v-tc-badge:top-right.activatewall="'NEW'"

function createBadge(el, value, arg, modifiers) {
  const badge = document.createElement('span');
  badge.classList.add('badge');
  badge.classList.add(`badge--${arg}`);
  Object.keys(modifiers).forEach((modifier) => {
    badge.classList.add(`badge-${modifier}`);
  });
  badge.innerText = value;
  el.classList.add('relative');
  el.appendChild(badge);
}

const tcBadge = {
  beforeMount(el, { value, arg, modifiers }) {
    if (!value) return;
    createBadge(el, value, arg, modifiers);
  },
  updated(el, { value, arg, modifiers }) {
    const badge = el.querySelector('.badge');
    if (!value && badge) {
      badge.remove();
    } else if (badge) {
      badge.innerText = value;
    } else {
      createBadge(el, value, arg, modifiers);
    }
  },
};

export default tcBadge;
