<template>
  <div class="filter-dropdowns-columns">
    <div class="filter-dropdowns-column date-column">
      <label class="block">Date</label>
      <div class="badge-list">
        <div
          class="badge badge-grid badge-filter strong
          tc-loading-text tc-loading-badge tc-loading-text-on-dark size-inherit"
        >
          <div class="btn-link btn-text">
            <span class="strong">Last 12 months</span>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-dropdowns-column filter-column">
      <label class="block">Filter</label>
      <div class="badge-list">
        <div class="badge-list-btn btn btn-link btn-icon-text hide-print disabled">
          <i class="zmdi zmdi-plus" />
          <span class="ml-0.5">Add</span>
        </div>
      </div>
    </div>
    <div class="filter-dropdowns-column benchmark-column">
      <label class="block">Benchmark</label>
      <div class="badge-list">
        <div
          class="badge badge-filter badge-grid
          tc-loading-text tc-loading-badge tc-loading-text-on-dark size-inherit"
        >
          <span>
            <i class="zmdi zmdi-globe mr-0.5" />
            <strong>Global</strong>
          </span>
        </div>
        <div class="badge-list-btn btn btn-link btn-icon-text hide-print disabled">
          <i class="zmdi zmdi-plus" />
          <span class="ml-0.5">Add</span>
        </div>
      </div>
    </div>
    <div class="filter-dropdowns-column compare-column">
      <label class="block">Compare</label>
      <div class="badge badge-filter tc-loading-text tc-loading-badge tc-loading-text-on-dark size-inherit">
        <span class="badge-compare-key">
          <span class="badge-compare--label">Compare</span>
          <span class="badge-compare--with"> By</span>
        </span>
        <span class="badge-compare-value">Default</span>
      </div>
    </div>
  </div>
</template>
