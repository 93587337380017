/**
 ** v-pulsing-dot="{ active: true, pulse: true }"
 */

const createDot = (el, value) => {
  if (el.querySelector('.pulsing-dot')) return;

  const dot = document.createElement('span');
  dot.className = 'pulsing-dot';
  if (value.pulse !== false) dot.classList.add('pulse');
  else dot.className = dot.classList.remove('pulse');

  el.className += ' pulsing-dot-container';
  el.appendChild(dot);
};

const removeDot = (el) => {
  const dot = el.querySelector('.pulsing-dot');
  dot?.remove?.();
  el.className = el.className.replace('pulsing-dot-container', '');
};

const pulsingDot = {
  mounted(el, { value }) {
    if (value.active === false) return;
    createDot(el, value);
  },

  updated(el, { value }) {
    if (value.active) createDot(el, value);
    else removeDot(el);
  },

  unmounted(el) {
    removeDot(el);
  },
};

export default pulsingDot;
