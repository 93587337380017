<template>
  <div>
    <label
      v-if="label"
      :id="`aria-label-${label}`"
    >{{ label }}</label>
    <tc-select
      ref="TcSelect"
      v-model="selected"
      :aria-labelledby="`aria-label-${label}`"
      :multiselect="multiselect"
      :on-change="change"
      :disabled="disabled"
      :placeholder="placeholder || $gettext('Skriv för att söka')"
      :searchable="searchable"
      :populate-store-fn="populateStoreFn"
      :loading-store-promise="loadingStorePromise"
      :search-is-separate-req="searchIsSeparateReq"
      :clear-after-select="clearAfterSelect"
      :case-sensitive="caseSensitive"
      @update:model-value="update"
    />
  </div>
</template>

<script>
import { isArray } from 'lodash-es';
import gettext from '@/gettext';

const { $gettext } = gettext;

export default {
  name: 'FilterSelect',
  props: {
    label: {
      type: String || Number,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    change: {
      required: true,
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default() {
        return $gettext('Skriv för att söka');
      },
    },
    populateStoreFn: Function,
    loadingStorePromise: Promise,
    searchIsSeparateReq: {
      type: Boolean,
      default: false,
    },
    clearAfterSelect: {
      type: Boolean,
      default: true,
    },
    caseSensitive: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    'update:modelValue': (val) => isArray(val),
  },
  data() {
    return {
      selected: [],
    };
  },
  watch: {
    modelValue: {
      deep: true,
      handler(newValue) {
        this.setup(newValue);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setup(this.modelValue);
    });
  },
  methods: {
    setup(value) {
      this.selected = value;
    },
    update(selected) {
      this.$emit('update:modelValue', selected);
    },
    clear() {
      this.$refs.TcSelect.clear();
      this.update();
    },
    clearPaginator() {
      this.$nextTick(() => {
        if (this.$refs.TcSelect) this.$refs.TcSelect.clearPaginator();
      });
    },
  },
};
</script>
