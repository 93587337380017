import gettext from '@/gettext';

const { $pgettext } = gettext;

export const NPS_CATEGORIES = ['promoter', 'passive', 'detractor'];

export const NPS_NAMES = {
  CNPS: 'CNPS',
  ENPS: 'ENPS',
  NPS: 'NPS',
};

export const stepNPSMap = {
  step1: NPS_NAMES.CNPS,
  step2: NPS_NAMES.CNPS,
  step3: NPS_NAMES.CNPS,
  step4: NPS_NAMES.ENPS,
  step5: NPS_NAMES.CNPS,
  consultantstep1: NPS_NAMES.CNPS,
  exitstep1: NPS_NAMES.ENPS,
  employeestep1: NPS_NAMES.ENPS,
  employeestep2: NPS_NAMES.ENPS,
  kundstep1: NPS_NAMES.NPS,
  kundstep2: NPS_NAMES.NPS,
  kundstep3: NPS_NAMES.NPS,
  customstep1: NPS_NAMES.NPS,
  customstep2: NPS_NAMES.NPS,
  customstep3: NPS_NAMES.NPS,
  'test step': NPS_NAMES.NPS,
  'test step 2': NPS_NAMES.NPS,
};

export const getStepNPSName = (step) => (typeof step === 'string' && stepNPSMap?.[step]) || NPS_NAMES.CNPS;

export const NPS_CONTEXTS = () => ({
  cnps: {
    npsShort: $pgettext('Title — npsShort', 'CNPS'),
    npsLong: $pgettext('Title — npsLong', 'Candidate Net Promoter Score'),
    npsTheExperience: $pgettext('Title — npsTheExperience', 'kandidatupplevelsen'),
    npsSubjects: $pgettext('Title — npsSubjects', 'kandidaternas'),
    npsJourney: $pgettext('Title — npsJourney', 'rekryteringsprocess'),
  },
  enps: {
    npsShort: $pgettext('Title — npsShort', 'ENPS'),
    npsLong: $pgettext('Title — npsLong', 'Employee Net Promoter Score'),
    npsTheExperience: $pgettext('Title — npsTheExperience', 'medarbetarupplevelsen'),
    npsSubjects: $pgettext('Title — npsSubjects', 'medarbetares'),
    npsJourney: $pgettext('Title — npsJourney', 'anställningsprocess'),
  },
  nps: {
    npsShort: $pgettext('Title — npsShort', 'NPS'),
    npsLong: $pgettext('Title — npsLong', 'Net Promoter Score'),
    npsTheExperience: $pgettext('Title — npsTheExperience', 'kundupplevelsen'),
    npsSubjects: $pgettext('Title — npsSubjects', 'kunders'),
    npsJourney: $pgettext('Title — npsJourney', 'process'),
  },
});

export const getStepNPSContext = (step) => NPS_CONTEXTS()?.[getStepNPSName(step ?? '')?.toLowerCase?.()] || NPS_CONTEXTS().CNPS;
