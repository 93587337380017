<template>
  <div class="alert alert-default">
    {{ $gettext('Loggar ut...') }}
  </div>
</template>

<script>
import { logout } from 'Utils/authHelpers';

export default {
  created() {
    if (!logout()) {
      this.$router.push('/login')?.catch((err) => {});
      setTimeout(() => {
        if (window?.location) window.location.reload();
      }, 100);
    }
  },
};
</script>
