/**
 ** FilterPaginator interface
 *
 * @param {Object} paginator a paginator class
 * @param {Function} filterFunction, ex. (item) => array.indexOf(item) > -1
 *
 * @prop {Array} stack returned filtered results
 * @prop {Boolean} loading load state
 *
 * @method hasPrevious ()
 * @method hasNext ()
 * @method async previous ()
 * @method async next ()
*/

import { ref } from 'vue';

export default class FilterPaginator {
  constructor(paginator, filterFunction) {
    this.loading = false;
    this._paginator = paginator;
    this._filterFunction = filterFunction; // ex. (item) => array.indexOf(item) > -1
    this.stack = this._paginator.stack.filter(this._filterFunction);
  }

  updateStack() {
    this.stack = this._paginator.stack.filter(this._filterFunction);
  }

  hasPrevious() {
    return this._paginator.hasPrevious();
  }

  hasNext() {
    return this._paginator.hasNext();
  }

  async previous() {
    this.loading = true;
    try {
      await this._paginator.previous();
      this.stack = this._paginator.stack.filter(this._filterFunction);
    } finally {
      this.loading = false;
    }
  }

  async next() {
    this.loading = true;
    try {
      await this._paginator.next();
      this.stack = this._paginator.stack.filter(this._filterFunction);
    } finally {
      this.loading = false;
    }
  }

  async goToPage(page) {
    this.loading = true;
    try {
      await this._paginator.goToPage(page);
      this.stack = this._paginator.stack.filter(this._filterFunction);
    } finally {
      this.loading = false;
    }
  }
}
