// ! These keys must match POEditor language names exactly (but not sv-se as that is default) !
export const availableLanguages = {
  'en-us': 'English',
  'sv-se': 'Svenska',
};

// const availableLanguages = {
//   'en-us': 'English',
//   'sv-se': 'Swedish',
//   'da-dk': 'Danish',
//   'nb-no': 'Norwegian',
//   'fi-fi': 'Finnish',
//   'de-de': 'German',
//   'es-es': 'Spanish (ES)',
//   'es-mx': 'Spanish (MX)',
//   'fr-fr': 'French (FR)',
//   'fr-ca': 'French (CA)',
//   'nl-nl': 'Dutch',
//   'it-it': 'Italian',
//   'pt-br': 'Portuguese',
//   'et-ee': 'Estonian',
//   'lv-lv': 'Latvian',
//   'lt-lt': 'Lithuanian',
//   'pl-pl': 'Polish',
//   'sk-sk': 'Slovak',
//   'hu-hu': 'Hungarian',
//   'uk-ua': 'Ukranian',
//   'ru-ru': 'Russian',
//   'tr-tr': 'Turkish',
//   'ja-jp': 'Japanese',
//   'ko-kr': 'Korean',
//   'zh-hans': 'Simplified Chinese',
//   'zh-hant': 'Traditional Chinese',
//   'id-id': 'Indonesian',
//   'vi-vn': 'Vietnamese',
//   'th-th': 'Thai',
// }

// ISO-639 Language Codes & ISO-3166 Country Codes: https://www.fincher.org/Utilities/CountryLanguageList.shtml
export const availableQuestionLanguages = {
  'en-US': 'English',
  'sv-SE': 'Svenska',
  'nb-NO': 'Norsk Bokmål',
  'da-DK': 'Dansk',
  'fi-FI': 'Suomi',
  'de-DE': 'Deutsch',
  'es-ES': 'Español (ES)',
  'es-MX': 'Español (MX)',
  'fr-FR': 'Français (FR)',
  'fr-CA': 'Français (CA)',
  'nl-NL': 'Nederlands',
  'it-IT': 'Italiano',
  'pt-BR': 'Português',
  'et-EE': 'Eesti',
  'lv-LV': 'Latvija',
  'lt-LT': 'Lietuva',
  'pl-PL': 'Polski',
  'sk-SK': 'Slovenčina',
  'hu-HU': 'Magyar',
  'uk-UA': 'Українська',
  'ru-RU': 'Русский',
  'tr-TR': 'Türkçe',
  'ja-JP': '日本語',
  'ko-KR': '한국어',
  'zh-Hans': '简体中文', // perhaps use 'zh-CN' instead, unsure
  // 'zh-CN': '简体中文',
  'zh-Hant': '繁體中文', // perhaps use 'zh-TW' instead, unsure
  // 'zh-TW': '繁體中文',
  'id-ID': 'Bahasa Indonesia',
  'vi-VN': 'Tiếng Việt',
  'th-TH': 'ภาษาไทย',
};

export const convertToAvailableLanguage = (lang = 'en-us', availables = availableLanguages) => {
  // if separator is '_' replace with '-' and lowercase else just lowercase
  const lowercaseLang = lang[2] === '_'
    ? lang.replace('_', '-').toLowerCase()
    : lang.toLowerCase();

  const avail = Object.keys(availables ?? {});

  return avail.find((availableLang) => {
    // lowercase availableLang to be able to match lowercaseLang
    availableLang = availableLang.toLowerCase();
    if (lowercaseLang.length > 4 && lowercaseLang[2] === '-') { // ? if lowercaseLang is 'sv-se' or 'sv-FI' syntax
      return lowercaseLang.substring(0, 2) === availableLang.substring(0, 2) || lowercaseLang === availableLang; // ! Doesn't support selecting 'se-fi' if it were to be added to availableLanguages
    }
    if (lowercaseLang.length === 2) { // ? if lowercaseLang is 'sv' syntax
      return lowercaseLang === availableLang.substring(0, 2);
    }
    return false;
  }) || 'en-us';
};

export const windowLang = String(window?.navigator.language || window?.navigator.userLanguage || 'en-us').toLowerCase();
export const defaultLanguage = convertToAvailableLanguage(windowLang) || 'en-us';

export default {
  availableLanguages,
  availableQuestionLanguages,
  convertToAvailableLanguage,
  windowLang,
  defaultLanguage,
};
