function getFirstTabindex(el) {
  if (el?.classList.contains('form-control')) return el;
  if (el?.children) {
    for (let i = 0; i < el.children.length; i++) {
      const result = getFirstTabindex(el.children[i]);
      if (result) return result;
    }
  }
  return null;
}

function sendEvent(el, hasFocus = false) {
  const eventName = hasFocus ? 'field-focused' : 'field-blurred';
  el.dispatchEvent(new CustomEvent(eventName, { detail: hasFocus, bubbles: true }));
}

// ? Use on slotted field elements as v-labelled-field-focused to let <labelled-field-wrapper> get the focus event.
const labelledFieldFocused = {
  beforeMount(el) {
    const theElement = getFirstTabindex(el);
    if (theElement) {
      theElement.addEventListener('focus', () => sendEvent(theElement, true));
      theElement.addEventListener('blur', () => sendEvent(theElement, false));
    }
  },
  beforeUnmount(el) {
    const theElement = getFirstTabindex(el);
    if (theElement) {
      theElement.removeEventListener('focus', () => sendEvent(theElement, true));
      theElement.removeEventListener('blur', () => sendEvent(theElement, false));
    }
  },
};

export default labelledFieldFocused;
