<template>
  <div
    v-tc-loader-pill="loading"
    class="tc-loader-pill-wrapper"
  >
    <user-list-count
      :loading="loading"
      :count="userTotalCount"
      :invite-count="invites.length"
      type="member"
    />
    <ul
      v-if="invites.length > 0 || userPage.length > 0"
      class="tc-list-group"
    >
      <single-invite
        v-for="(invite, index) in invites"
        :key="invite.id"
        :type="userType"
        :is-last="isLastInvite(index)"
        :invite="invite"
        @updated="fetchInvites"
      />
      <single-user
        v-for="(user, index) in userPage"
        :key="user.id"
        :type="userType"
        :user="user"
        :display-name="displayNames[index]"
        @updated="refreshPagination"
      />
    </ul>
    <pagination
      v-if="scopeId !== false"
      ref="pagination"
      v-model:count="userTotalCount"
      v-model:page="userPage"
      :page-size="5"
      :url="usersApiUrl"
      @loading="(status) => { loadingUsers = status }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { list as customerInviteList } from 'API/customerUserInvitation';
import { list as segmentInviteList } from 'API/segmentUserInvitation';
import segmentUserApi from 'API/segmentUser';
import customerUserApi from 'API/customerUser';
import { formatUserDisplayNames } from 'Utils/general';
import Pagination from 'Components/parts/Pagination';
import SingleUser from 'Components/parts/user/UserListSingleUser';
import SingleInvite from 'Components/parts/user/UserListSingleInvite';
import UserListCount from 'Components/parts/user/UserListCount';

export default {
  components: {
    UserListCount,
    SingleUser,
    SingleInvite,
    Pagination,
  },
  props: {
    userType: {
      default: 'segment',
    },
    singleSegmentId: {
      required: false,
      type: Number,
    },
  },
  emits: ['loading'],
  data() {
    return {
      invites: [],
      userPage: [],
      userTotalCount: 0,
      safetyOff: [],
      api: {},
      listInvitesAPI: {},
      scopeId: false,
      loadingUsers: false,
      loadingInvites: false,
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'customerId',
    ]),
    displayNames() {
      return formatUserDisplayNames(this.userPage);
    },
    usersApiUrl() {
      return `/feedback/accounts/api/${this.userType}user/?${this.userType}=${this.scopeId}`;
    },
    loading() {
      return this.loadingUsers || this.loadingInvites;
    },
  },
  watch: {
    segmentId() {
      this.load();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.load();
    });
  },
  methods: {
    isLastInvite(index) {
      return index + 1 === this.invites.length && this.userTotalCount > 0;
    },
    refreshPagination() {
      this.$refs.pagination.refresh();
    },
    fetchInvites() {
      this.loadingInvites = true;
      this.$emit('loading', this.loading);
      this.listInvitesAPI(this.scopeId)
        .then((invites) => {
          this.invites = invites;
        })
        .then(() => {
          this.loadingInvites = false;
          this.$emit('loading', this.loading);
        });
    },
    load() {
      const segmentId = this.singleSegmentId ? this.singleSegmentId : this.segmentId;
      this.scopeId = this.userType === 'segment' ? segmentId : this.customerId;
      this.listInvitesAPI = this.userType === 'segment' ? segmentInviteList : customerInviteList;
      this.api = this.userType === 'segment' ? segmentUserApi : customerUserApi;
      this.fetchInvites();
    },
  },
};
</script>
