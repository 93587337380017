<template>
  <div class="respondent-avatar">
    <div
      v-if="showAPulse && !skipBecauseOfMount"
      class="respondent-avatar-pulse"
    />
    <AvatarBeam
      :name="hash"
      :hash="hash"
      :colors="colors"
      less-random-colors
      :chosen-mouth="chosenMouth"
      mask="squircle"
      v-bind="$attrs"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import md5 from 'Utils/md5';
import AvatarBeam from './AvatarBeam';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  id: {
    type: [String, Number],
    default: '',
  },
  idIsHashed: {
    type: Boolean,
    default: false,
  },
  npsCategory: {
    type: Number,
    default: null,
    validator: (val) => val === null || [-1, 0, 1].includes(val), // -1 = Detractor, 0 = Passive, 1 = Promoter
  },
  isSadface: {
    type: Boolean,
    default: null,
  },
  chosenMouth: {
    type: String,
    default: null,
    validator: (val) => val === null || ['frown', 'smile', 'joy'].includes(val),
  },
  showAPulse: {
    type: Boolean,
    default: false,
  },
});

const hash = computed(() => (props.idIsHashed ? props.id : md5(encodeURIComponent(props.id))));
const chosenMouth = computed(() => {
  if (props.chosenMouth) return props.chosenMouth;
  if (props.npsCategory === -1 || props.isSadface === true) return 'frown';
  if (props.npsCategory === 0) return 'smile';
  if (props.npsCategory === 1) return 'joy';
  if (props.isSadface === false) return 'smile';
  return null;
});
const colors = computed(() => {
  switch (props.npsCategory) {
    case -1:
      return ['#FFF0F1', '#FFD2CC'];
    case 0:
      return ['#FEF4DC', '#FFEDCA'];
    case 1:
      return ['#F0FCF8', '#D9F5EA'];
    default:
      return ['#F0F5F5', '#EBEBEB'];
  }
});

const skipBecauseOfMount = ref(true);
watch(() => props.showAPulse, (newVal, oldVal) => {
  if (newVal && newVal !== oldVal) {
    skipBecauseOfMount.value = false;
  }
});
</script>
