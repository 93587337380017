<template>
  <div
    class="board-area"
    :class="{ 'is-organizing': isOrganizing, 'is-edit-mode': isEditMode }"
  >
    <div
      ref="dragContainerEl"
      class="drag-container"
    />
    <BoardAreaSkeleton
      v-show="!hasOuterGrid || (!hasSections && hasLoadedAllSections)"
      :sections-amount="sectionsLocal.length || 1"
    />
    <div
      v-show="hasSections"
      ref="sectionWrapperEl"
      class="board-section-wrapper"
    >
      <BoardSection
        v-for="(section,index) in sectionsLocal"
        :key="section.id"
        :data-section-id="section.id"
        class="board-section"
        :board="board"
        :section="section"
        :outer-grid="outerGrid"
        :drag-container-el="dragContainerEl"
        :inner-drag-sort="innerDragSort"
        :has-loaded-all-sections="hasLoadedAllSections"
        :disable-drag-outer="disableDragOuter"
        :is-in-pane="isInPane"
        :render-cards-permission="renderCardsPermission[index]"
        @card-grid="addInnerGrid"
        @section-fully-loaded="sectionFullyLoaded"
        @section-refresh-layout="refreshOuterGridLayout"
        @remove-section="removeSection"
      />
    </div>
    <div
      v-if="shouldLetSejdaPrintNow"
      id="allCardsAboveFinishedLoading"
    />
    <div
      v-if="!isPrintView && !isInPane && isEditMode"
      class="board-section-adder hide-print"
    >
      <button
        :disabled="!hasOuterGrid || (!hasSections && hasLoadedAllSections)"
        class="btn btn-secondary"
        type="button"
        @click="createNewSection"
      >
        <i class="zmdi zmdi-plus" />
        <span class="btn-icon-text">{{ $pgettext('Button — create section button', 'Lägg till sektion') }}</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { throttle } from 'lodash-es';
import { klona } from 'klona';
import { useRoute } from 'vue-router';
import { createSection, getSectionOrderAs } from 'API/sections';
import { sortByOrder } from 'Utils/card';
import { useMuuriSection, useInnerGrids } from 'Composables/useMuuri';
import BoardSection from 'Components/parts/board/BoardSection';
import BoardAreaSkeleton from 'Components/parts/board/BoardAreaSkeleton';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext } = gettext;

const props = defineProps({
  board: {
    type: Object,
    required: true,
  },
  dragDisabled: {
    type: Boolean,
    default: false,
  },
  isInPane: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:currentColClass', 'all-cards-loaded', 'allCardsAboveFinishedLoading']);

const isEditMode = computed(() => store.state?.boards?.isEditMode ?? false);
const isOrganizing = computed(() => store.state?.boards?.isOrganizing ?? false);
const skipMuuriAnimation = ref(false);
const dragContainerEl = ref(null);
const sectionWrapperEl = ref(null);
const fullyLoadedSections = ref(0);
const { innerGrids, innerDragSort, addInnerGrid } = useInnerGrids();
const outerGrid = ref(null);
const refreshOuterGridLayout = throttle(() => {
  if (outerGrid.value) outerGrid.value.refreshItems().layout(skipMuuriAnimation.value);
}, 11, { trailing: true, leading: false });

// const sectionsStore = computed(() => store.getters.getSectionsByBoardId(props.board.id));
const sectionsLocal = ref(props.board?.sections && createSection(sortByOrder(props.board.sections)) || []);

// const cardsInBoard = computed(() => klona(store.getters.getCardsByBoardId(props.board.id)) || []);
const renderCardsPermission = ref(new Array(sectionsLocal.value.length).fill(false));
const batchAmount = 3;
const setRenderCardsPermission = (startIndex, times, value) => {
  const newRenderCardsPermission = klona(renderCardsPermission.value);
  for (let i = 0; i < times; i++) { newRenderCardsPermission[startIndex + i] = value; }
  renderCardsPermission.value = newRenderCardsPermission;
};
setRenderCardsPermission(0, batchAmount, true);

const totalCardAmountLoaded = ref(0);
const readyForNextBatch = computed(() => fullyLoadedSections.value % batchAmount === 0);
const sectionFullyLoaded = (batchedCardAmountLoaded) => {
  totalCardAmountLoaded.value += batchedCardAmountLoaded;
  fullyLoadedSections.value++;
  if (readyForNextBatch.value) setRenderCardsPermission(fullyLoadedSections.value, batchAmount, true);
  if (fullyLoadedSections.value === sectionsLocal.value.length) {
    emit('all-cards-loaded', totalCardAmountLoaded.value); // ? Works as long as it's more than 0
  }
};
const isPrintView = computed(() => useRoute()?.name?.indexOf('print-') > -1);
const sectionAmount = computed(() => sectionsLocal.value?.length);
const disableDragOuter = computed(() => props.isInPane || props.dragDisabled || sectionAmount.value <= 1);

const hasOuterGrid = computed(() => outerGrid.value !== null);
const hasSections = computed(() => sectionAmount.value !== 0);
const hasLoadedAllSections = computed(() => {
  if (fullyLoadedSections.value === true) return true;
  if (sectionAmount.value !== 0 && fullyLoadedSections.value === 0) return false;
  if (sectionAmount.value !== 0 && fullyLoadedSections.value >= sectionAmount.value) return true;
  return false;
});

const shouldLetSejdaPrintNow = ref(false);
const timeoutLoadingCardsElementId = ref(null);
function tellSejdaToPrintNow(items, hasLayoutChanged) {
  if (shouldLetSejdaPrintNow.value === true) return;
  setTimeout(() => {
    shouldLetSejdaPrintNow.value = true;
    if (isPrintView.value) console.log('[TC] Considering everything completely loaded and ready for Sejda now', props.board?.id); // eslint-disable-line no-console
  }, 1000);
}
watch(hasLoadedAllSections, (newVal, oldVal) => {
  if (newVal === true && newVal !== oldVal) {
    refreshOuterGridLayout();
    innerGrids.value.forEach((iG, index) => {
      if (index === innerGrids.value.length - 1) {
        if (typeof iG?.refreshItems === 'function') iG.refreshItems().layout(true, tellSejdaToPrintNow());
        else tellSejdaToPrintNow();
      } else if (typeof iG?.refreshItems === 'function') iG.refreshItems().layout(true);
    });
  }
});

const isRunningCreateNewSection = ref(false);
const createNewSection = async () => {
  let newSection;
  if (isRunningCreateNewSection.value) return;
  isRunningCreateNewSection.value = true;
  const orderAs = getSectionOrderAs(sectionsLocal.value.map((sect) => sect.order)) ?? { prevSectionOrder: '', nextSectionOrder: '' };
  try {
    newSection = await store.dispatch('createSection', { boardId: props.board.id, orderAs });
    store.dispatch('notify', {
      text: $gettext('Ny sektion tillagd'),
      level: 'success',
      type: 'pop',
    });
  } catch (error) {
    store.dispatch('notify', {
      text: $gettext('Kunde inte skapa sektion'),
      level: 'warning',
      type: 'slow',
    });
  }
  if (!newSection) return;
  sectionsLocal.value.push(createSection(newSection));
  if (sectionAmount.value !== 1) {
    nextTick(() => {
      const boardSectionEls = Array.from(document.querySelectorAll('.board-section'));
      const sectionToAddEl = boardSectionEls.find(({ classList }) => ![...classList].includes('muuri-item'));
      if (sectionToAddEl && outerGrid.value) outerGrid.value.add(sectionToAddEl);
      isRunningCreateNewSection.value = false;
    });
  }
};

const removeSection = (removedSectionId) => {
  const section = sectionsLocal.value.find((sect) => sect.id === removedSectionId);
  if (section) {
    sectionsLocal.value = sectionsLocal.value.filter((sect) => sect.id !== removedSectionId);
    nextTick(() => {
      if (outerGrid.value) {
        outerGrid.value.refreshItems().layout(skipMuuriAnimation.value);
      }
    });
  }
};

onMounted(() => {
  skipMuuriAnimation.value = isPrintView;
  watch(hasSections, () => {
    if (!hasSections.value) return;
    nextTick(() => {
      outerGrid.value = useMuuriSection(sectionWrapperEl.value, { dragEnabled: true });
    });
  }, { immediate: true });

  // Renders element that triggers Sejda to print after 45s maximum
  const timeoutLoadingCardsElement = (timeoutInSeconds = 60) => {
    if (shouldLetSejdaPrintNow.value === true && fullyLoadedSections.value === true) return;
    timeoutLoadingCardsElementId.value = setTimeout(() => {
      fullyLoadedSections.value = true;
      shouldLetSejdaPrintNow.value = true;
    }, timeoutInSeconds * 1000);
  };
  timeoutLoadingCardsElement(45);
});

onBeforeUnmount(() => {
  if (outerGrid.value) outerGrid.value.destroy();
  if (timeoutLoadingCardsElementId.value) clearTimeout(timeoutLoadingCardsElementId.value);
});

defineExpose({ createNewSection });
</script>
