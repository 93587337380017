<template>
  <div @click="store.getters.isEditMode ? null : store.dispatch('setHintAboutEditMode', true)">
    <label
      class="block"
      v-text="$pgettext('Label — Compare column', 'Jämför')"
    />
    <VMenu
      v-model:shown="isMenuShown"
      auto-hide
      theme="select"
      :triggers="['click', 'touch']"
      :auto-boundary-max-size="false"
      :auto-size="false"
      :disabled="isDisabled"
      :container="false"
      :instant-move="true"
      :flip="!(isCard || isTemporary)"
      :strategy="(isCard || isTemporary) ? 'absolute' : 'fixed'"
      :distance="8"
    >
      <BadgeListItemCompare
        :item="badge"
        :class="{
          'cursor-pointer': !isDisabled,
          'is-default-compare': selectedCompareOption == null,
          'focus-briefly': ['compare', 'compare__tag'].includes(store.state.cards.modalCardOpenedFrom),
        }"

        tabindex="0"
      />

      <template #popper>
        <FilterDropdownsComparePopper
          :selected-compare="selectedCompareOption?.value"
          :dropdown-settings="{ multiselect: false, dropdownFlavor: 'compare' }"
          @selected="handleSelected"
        />
      </template>
    </VMenu>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { hideAllPoppers } from 'floating-vue';
import { equalLiteral } from 'Utils/general';
import BadgeListItemCompare from 'Components/parts/badges/BadgeListItemCompare';
import FilterDropdownsComparePopper from 'Components/parts/filter-dropdowns/FilterDropdownsComparePopper';
import gettext from '@/gettext';
import { store } from '@/store';

const { $gettext, $pgettext } = gettext;

const props = defineProps({
  compareMetadata: {
    type: [Object, String, null],
    default: () => (null),
  },
  isCard: {
    type: Boolean,
    default: false,
  },
  isTemporary: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:compareMetadata']);

const convertOptionToMetadata = (option) => {
  const slug = option?.value || null;
  if (slug === null) return null; // ? = Default
  if (slug === 'date') return {};
  if (slug === 'segment' || slug === 'customerProxy') return { key: slug };

  return { tag: slug };
};

const selectedCompare = computed({
  get: () => props.compareMetadata ?? null, // From top: simple db store value
  set: (option) => { emit('update:compareMetadata', convertOptionToMetadata(option)); }, // From bottom: bigger options object
});

const defaultCompares = [
  {
    value: 'date',
    label: $gettext('Datum'),
    icon: 'zmdi-calendar',
  },
  {
    value: 'segment',
    label: $gettext('Segment'),
    icon: 'zmdi-group-work',
  },
  {
    value: 'customerProxy',
    label: $gettext('Proxies'),
    icon: 'zmdi-input-antenna',
  },
];

const selectedCompareOption = computed(() => {
  if (selectedCompare.value?.tag) {
    const response = store.dispatch('segmentTagKeyTranslation', selectedCompare.value.tag);
    return {
      value: selectedCompare.value.tag,
      label: response?.label ?? selectedCompare.value.tag,
      icon: 'zmdi-labels',
    };
  }
  const finding = defaultCompares.find((opt) => equalLiteral(convertOptionToMetadata(opt), selectedCompare.value));

  return finding ?? null;
});

const badge = computed(() => ({
  slug: selectedCompare.value?.tag ? 'compare__tag' : 'compare',
  label: $pgettext('Badge — Compare', 'Jämför'),
  value: selectedCompareOption.value?.label ?? selectedCompareOption.value?.value ?? $pgettext('Badge — Compare value', 'Default'),
}));

const handleSelected = (selectedOptions = []) => {
  hideAllPoppers();
  let option = null;
  if (selectedOptions?.[0]?.value) option = selectedOptions?.[0] ?? null; // Because multiselect: false
  selectedCompare.value = option;
};

const isMenuShown = ref(false);
const timers = ref([]);
onMounted(() => {
  if ((props.isCard || props.isTemporary) && ['compare', 'compare__tag'].includes(store.state.cards.modalCardOpenedFrom)) {
    isMenuShown.value = true;
    timers.value.push(setTimeout(() => store.dispatch('setModalCardOpenedFrom', null), 2000));
  }
});
onUnmounted(() => {
  timers.value.forEach((timer) => clearTimeout(timer));
});

</script>
