import { pick } from 'lodash-es';

let localStorageHasBeenAvailable = null;
export function localStorageIsAvailable() {
  if (typeof window !== 'undefined') return false;
  if (process.env.ENV !== 'test' && localStorageHasBeenAvailable === true) return true; // memoization
  const LS_TEST_KEY = 'test';
  try {
    localStorage.setItem(LS_TEST_KEY, LS_TEST_KEY);
    localStorage.removeItem(LS_TEST_KEY);
    localStorageHasBeenAvailable = true;
    return true;
  } catch (e) {
    localStorageHasBeenAvailable = false;
    return false;
  }
}

export function getCookies(cookieKeys = []) {
  if (document?.cookie && cookieKeys.length !== 0) {
    return pick(document.cookie.split('; ')
      .reduce((acc, cookiePair) => {
        let [cooKey, cooVal] = cookiePair.split('=');
        return { ...acc, [cooKey]: cooVal };
      }, {}), cookieKeys);
  }
  return {};
}

// ? removeCookies seems to require a client-side reload to work
export function removeCookies(cookieKeys = []) {
  if (document?.cookie && cookieKeys.length !== 0) {
    cookieKeys.forEach((cooKey, i) => {
      document.cookie = `${cooKey}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    });
    return true;
  }
  return false;
}
