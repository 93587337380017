<template>
  <li class="tc-list-group-item">
    <div class="item-row">
      <p class="item-col my-1">
        <strong data-private>{{ displayName }}</strong>
        <small
          v-if="me.id === user.user.id"
          class="ml-0.5"
        >{{ $gettext('(du)') }}</small>
        <span v-if="user.role && type !== 'segment'">
          <span class="badge mx-1">{{ userRoleText }}</span>
        </span>
      </p>
      <div
        v-if="segmentCanEdit"
        class="chip-set chip-set-outline chip-set-sm"
      >
        <chip
          v-if="type !== 'segment'"
          icon="zmdi-account-circle"
          @click="toggleRole"
        >
          {{ roleBtnText }}
        </chip>
        <chip
          v-if="me.id === user.user.id"
          icon="zmdi-close-circle-o"
          @click="leave"
        >
          <span>{{ $gettext('Lämna') }}</span>
        </chip>
        <chip
          v-else
          icon="zmdi-delete"
          @click="remove"
        >
          <span>{{ $gettext('Ta bort') }}</span>
        </chip>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import customerUserApi from 'API/customerUser';
import segmentUserApi from 'API/segmentUser';
import { confirm } from 'Modal/dialogBoxes';
import Chip from 'Components/parts/chips/Chip';

export default {
  components: {
    Chip,
  },
  props: {
    user: {
      required: false,
    },
    displayName: {
      required: false,
    },
    type: {
      default: 'segment',
    },
  },
  emits: ['updated'],
  data() {
    return {
      api: {},
    };
  },
  computed: {
    ...mapGetters([
      'me',
      'role',
      'customerId',
      'customerName',
      'segmentId',
      'segmentName',
      'segmentCanEdit',
    ]),
    userRoleText() {
      return this.user.role === 'admin' ? this.$gettext('Admin') : this.$gettext('Medlem');
    },
    roleBtnText() {
      if (this.user.role === undefined) return false;
      return this.user.role === 'admin' ? this.$gettext('Gör till medlem') : this.$gettext('Gör till admin');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.load();
    });
  },
  methods: {
    ...mapActions([
      'setupRole',
      'fetchSegment',
    ]),
    toggleRole() {
      if (this.user.role === undefined) return false;

      let role = 'member';
      if (role === this.user.role) {
        role = 'admin';
      }
      return this.api.update(this.user.id, role)
        .then(() => {
          if (this.me.id === this.user.user.id) {
            if (this.type === 'segment') {
              return this.fetchSegment(this.segmentId)
                .then(() => {
                  this.$router.push({ name: 'home' }).catch((err) => {});
                });
            }
            return this.setupRole(this.customerId);
          }
          return this;
        })
        .then(() => {
          this.$emit('updated');
        });
    },
    remove() {
      let removeCtx = this.$pgettext('Title — Remove segmentuser', 'denna lista');
      if (this.type === 'segment') {
        removeCtx = this.segmentName || this.$pgettext('Title — Remove segmentuser', 'detta segment');
      } else if (this.type === 'customer') {
        removeCtx = this.customerName || this.$pgettext('Title — Remove customeruser', 'detta företag');
      }
      const title = this.$pgettext('Title — Remove user from ctx', 'Är du säker på att du vill ta bort användaren från %{removeCtx}?', { removeCtx });
      confirm({ title, confirmText: this.$gettext('Ta bort användaren'), dismissText: this.$gettext('Avbryt'), theme: 'danger' })
        .then((confirmed) => {
          if (!confirmed) return Promise.resolve();
          return this.api.remove(this.user.id)
            .then(() => { this.$emit('updated'); });
        });
    },
    leave() {
      this.api.remove(this.user.id)
        .then(() => {
          this.$router.push({ name: 'logout' }).catch(() => {});
        });
    },
    load() {
      this.api = this.type === 'segment' ? segmentUserApi : customerUserApi;
    },
  },
};
</script>
