/**
 * flatten
 * Based on lodash internal baseFlatten
 * https://github.com/lodash/lodash/blob/master/.internal/baseFlatten.js
 *
 * @param {Array} array required The array to flatten
 * @param {Function} next optional Next level down to iterate
 * @param {Array} result optional Result array to mutate and return
 * @param {Function} filter optional Filter function to modify value
 * @returns {Array} The new flattened array
 */
export function flatten(array, next = null, result = [], filter = (f) => f) {
  if (!Array.isArray(array)) {
    return result;
  }

  array.forEach((value) => {
    if (typeof next === 'function' && next(value) !== undefined) {
      return flatten(next(value), next, result, filter);
    }
    if (Array.isArray(value)) {
      return flatten(value, next, result, filter);
    }
    return result.push(filter(value));
  });

  return result;
}

/**
 * isLast
 * Check if element is last in array
 */
export function isLast(item = null, array = []) {
  if (item === null) {
    return false;
  }
  return array.indexOf(item) === array.length - 1;
}

export function unique(label) {
  return (list) => {
    const uniqueList = [];
    return list.filter((row) => {
      if (uniqueList.indexOf(row[label]) === -1) {
        uniqueList.push(row[label]);
        return true;
      }
      return false;
    });
  };
}

export function groupby(label) {
  return (list = []) => {
    let value;
    const group = new Map();
    list?.forEach((row) => {
      value = row[label];
      if (!group.has(value)) {
        group.set(value, []);
      }
      group.get(value).push(row);
    });
    return group;
  };
}
