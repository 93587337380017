import { roundNumber } from 'Utils/general';
import { NPS_CATEGORIES } from 'Utils/constants';
import { FAKE_ABS_REL_DATE_OPTIONS, getKeyByDate } from 'Utils/date';

export const useDecimal = (val, goalUnit = '') => {
  if (!Number.isFinite(val)) return val;
  if (NPS_CATEGORIES.includes(goalUnit)) return roundNumber(val);
  return roundNumber(val * 100);
};

export const getGoalTypeFromDate = (date) => {
  if (date?.type === 'absolute') return 'oneTime';
  if (date?.type === 'relative' && FAKE_ABS_REL_DATE_OPTIONS.includes(getKeyByDate(date))) return 'oneTime';
  return 'rollingAvg'; // date.type === 'relative' || default
};
