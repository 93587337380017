/**
 ** SearchPopulator interface
 *- This is an array that only loads more data like 𝕏 (previously Twitter).
 *  With support for searching and filtering. Replaces FilterPaginator & Populator combo.
 *  It doesn't paginate backwards, only forward.
 *
 * @param {Object} paginator a paginator class
 *
 * @prop {Array} stack current filled results
 * @prop {Boolean} loading load state
 *
 * @method hasMore ()
 * @method async loadMore ()
 * @method async setPaginator ()
*/

export default class SearchPopulator {
  constructor(paginator, filterFunction = (item) => item) {
    this.stack = [];
    this.loading = false;
    this._paginator = paginator;
    this._filterFn = filterFunction;
    this._initialized = false;
    this.filteredStack = this._paginator.stack.filter(this._filterFn);
  }

  filterStack() {
    this.filteredStack = this.stack.filter(this._filterFn);
    // this.stack = this._paginator.stack.filter(this._filterFunction);
  }

  hasMore() {
    return !this._initialized || this._paginator.hasNext();
  }

  async loadMore() {
    // Load more items into stack
    // Returns true if more items have been loaded
    this.loading = true;
    try {
      if (this._initialized) {
        if (!this.hasMore()) {
          return false;
        }
        await this._paginator.next();
      }

      this._paginator.stack.forEach((element) => {
        this.stack.push(element);
      });
      this.filterStack();
      this._initialized = true;
      return true;
    } finally {
      this.loading = false;
    }
  }

  async setPaginator(paginator) {
    while (this.stack.length > 0) {
      this.stack.pop();
    }
    this._paginator = paginator;
    this._initialized = false;
    await this.loadMore();
  }
}
