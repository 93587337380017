import { getBaseUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

function list(customer = null, filter = {}, paginated = false) {
  return getBaseUrl('/feedback/api/customer_proxy/')
    .query({ customer, filter })
    .then((response) => (
      paginated && response.body.next !== undefined ? response.body : response.body.results
    ), handleAuthError);
}

function get(id) {
  return getBaseUrl(`/feedback/api/customer_proxy/${id}/`)
    .then((response) => response.body, handleAuthError);
}

export default {
  get,
  list,
};
