<template>
  <placeholder-card
    class="page-content"
    :placeholder-message="error || $pgettext('Error — Print could’nt export (upper msg)', 'Kunde inte exportera')"
    card-icon="zmdi-alert-octagon"
    :card-message="$pgettext('Error — Print could’nt export (lower msg)', 'Prova igen eller kontakta vår support')"
    :cta="null"
  />
</template>

<script>
import PlaceholderCard from 'Components/parts/PlaceholderCard';

export default {
  components: {
    PlaceholderCard,
  },
  props: ['error'],
};
</script>
