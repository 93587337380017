<template>
  <tc-select
    ref="tcSelect"
    v-model="levels"
    :multiselect="multiselect"
    :disabled="disabled"
    :on-change="segmentLevelOptions"
    :placeholder="placeholder || $gettext('Skriv för att söka bland formulär')"
    :no-options-text="$gettext('Inga fler formulär finns')"
    @update:model-value="update"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { translateTerm, equalLiteral } from 'Utils/general';

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'update:filter'],
  data() {
    return {
      levels: [],
    };
  },
  computed: {
    ...mapGetters([
      'segmentLevels',
      'segmentLevelOptions',
    ]),
  },
  watch: {
    modelValue: {
      deep: true,
      handler(newFilter, oldFilter) {
        if (!equalLiteral(newFilter, oldFilter)) this.setup(newFilter);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$nextTick(() => this.setup(this.modelValue));
    });
  },
  methods: {
    update(levels) {
      this.$emit('update:modelValue', levels.map((levelObject) => levelObject.value));
      this.$emit('update:filter', this.levels);
    },
    clear() {
      this.$refs?.tcSelect?.clear?.();
    },
    async setup(filter) {
      const filterIds = filter.map((entry) => {
        if (entry.value) return entry.value;
        return entry;
      });
      const lvls = await this.segmentLevels;
      this.levels = lvls
        .filter((level) => filterIds.includes(level))
        .map((level) => ({ value: level, label: translateTerm(level) }));
    },
  },
};
</script>
