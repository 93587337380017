<template>
  <small id="print-footer">
    <a href="https://trustcruit.com/?utm_source=trustcruit&utm_medium=pdf">
      {{ titleText }}
    </a>
    <!-- <span id="print-page-numbering" /> -->
    <span v-if="segmentName && !isError(segmentName) && !isError(customerName)">
      {{ customerName }} | {{ $gettext('Segment') }}: {{ segmentName }}
    </span>
  </small>
</template>

<script>
import { isError } from 'lodash-es';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'segmentName',
      'customerName',
    ]),
    titleText() {
      return this.$pgettext('Print — Footer title', 'data från trustcruit.com');
    },
  },
  methods: {
    isError,
  },
};
</script>
