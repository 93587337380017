<template>
  <tc-select
    ref="tcSelect"
    v-model="proxies"
    :multiselect="multiselect"
    :disabled="disabled"
    :on-change="customerProxiesOptions"
    :placeholder="placeholder || $gettext('Skriv för att söka bland proxies')"
    :no-options-text="$gettext('Inga fler proxies finns')"
    @update:model-value="update"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { equalLiteral } from 'Utils/general';

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'update:filter'],
  data() {
    return {
      proxies: [],
    };
  },
  computed: {
    ...mapGetters([
      'fetchingProxies',
      'customerProxies',
      'customerProxiesOptions',
    ]),
  },
  watch: {
    modelValue: {
      deep: true,
      handler(newFilter, oldFilter) {
        if (!equalLiteral(newFilter, oldFilter)) this.setup(newFilter);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$nextTick(() => this.setup(this.modelValue));
    });
  },
  methods: {
    update(proxies) {
      this.$emit('update:modelValue', proxies.map((proxyObject) => proxyObject.value));
      this.$emit('update:filter', this.proxies);
    },
    clear() {
      this.$refs.tcSelect.clear();
    },
    setup(filter = []) {
      const filterIds = filter.map((entry) => entry?.value || entry);
      this.proxies = this.customerProxies
        .filter((proxy) => filterIds.includes(proxy.id))
        .map((proxy) => ({
          value: proxy.id,
          label: proxy.internal_name || proxy.name,
        }));
    },
  },
};
</script>
