<template>
  <div
    ref="wrapperEl"
    class="goal-bar-indicator-wrapper"
    :class="{ 'goal-bar-indicator-small': goalBarIndicatorSmall }"
  >
    <div
      ref="indicatorEl"
      data-testid="indicatorComp"
      class="goal-bar-indicator"
      :class="{ outside, [barColor]: !!barColor, skeletonLoader }"
      :style="calcWidth(barProgressPercentage)"
    >
      <GoalBarIndicatorData
        v-if="!skeletonLoader"
        ref="dataInnerLeftEl"
        class="goal-bar-data goal-bar-data--inner-left"
        :class="{ 'hidden': pushed === false }"
        :goal-unit="goalUnit"
        :end="end"
        :current="current"
        :headline="headline"
        :data="data"
        :remaining="remaining"
        get-element-bounding
      />
      <GoalBarIndicatorData
        v-if="!skeletonLoader"
        ref="dataInnerRightEl"
        class="goal-bar-data goal-bar-data--inner-right"
        :class="{ 'hidden': pushed === false }"
        :goal-unit="goalUnit"
        :end="end"
        :current="current"
        :headline="headline"
        :data="data"
        :remaining="remaining"
        get-element-bounding
      />
    </div>
    <GoalBarIndicatorData
      v-if="!skeletonLoader"
      ref="dataOuterEl"
      class="goal-bar-data"
      :class="{ 'hidden': pushed === true }"
      :goal-unit="goalUnit"
      :end="end"
      :current="current"
      :headline="headline"
      :data="data"
      :remaining="remaining"
      get-element-bounding
    />
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch, nextTick } from 'vue';
import { throttle, isPlainObject } from 'lodash-es';
import { useElementBounding } from '@vueuse/core';
import GoalBarIndicatorData from 'Components/parts/graph/GoalBarIndicatorData';
import gettext from '@/gettext';

const { $pgettext } = gettext;

const props = defineProps({
  goalUnit: String,
  current: [Object, Number, String],
  end: [Object, Number, String],
  remaining: [Number, Boolean],
  barProgressPercentage: Number,
  skeletonLoader: {
    type: Boolean,
    default: false,
  },
  barColor: {
    type: String,
    default: '',
  },
});

// inputs the percentage of a goal returns the width of a bar filled up to the percentage with 1rem at the start /J-* calcsss
const calcWidth = (percentage) => `width: calc(-${percentage / 50}rem + ${percentage}% + 1rem + 1px);`;

const outside = ref(true);
const pushed = ref(false);

const wrapperEl = ref(null);
const indicatorEl = ref(null);

const ebConfig = { immediate: true, windowScroll: false };
const wrapperRect = reactive(useElementBounding(wrapperEl, ebConfig));
const indicatorRect = reactive(useElementBounding(indicatorEl, ebConfig));
const dataInnerRightEl = ref(null);
const dataInnerLeftEl = ref(null);
const dataOuterEl = ref(null);

const data = computed(() => (
  (props.current?.displayValue || props.current) !== undefined
    && props.current?.displayValue || (!isPlainObject(props.current) && props.current)
) || 0);

const headline = $pgettext('Headline — GoalBarIndicator top', 'Just nu');

const handleResize = async (
  indicator = indicatorRect,
  dataOuter = dataOuterEl,
  dataInnerRight = dataInnerRightEl,
  dataInnerLeft = dataInnerLeftEl,
  barWrapper = wrapperRect,
) => {
  await nextTick();
  if (
    indicator?.right !== undefined
    && indicator?.width !== undefined
    && dataOuter?.value?.elementBounding?.left !== undefined
    && dataInnerRight.value?.elementBounding?.right !== undefined
    && dataInnerLeft.value?.elementBounding?.width !== undefined
    && barWrapper?.right !== undefined
  ) {
    dataInnerRight.value.elementBounding.update();
    dataOuter.value.elementBounding.update();
    await nextTick();
    pushed.value = indicator.right > dataOuter.value.elementBounding.left && props.barProgressPercentage < 100;
    outside.value = props.barProgressPercentage < 100
    && barWrapper.right > dataInnerRight.value.elementBounding.right
    || indicator.width < dataInnerLeft.value.elementBounding.width;
  }
};
const throttledResize = throttle(handleResize, 301, { leading: false, trailing: true }); // $transition-timing-long is 300ms
const goalBarIndicatorSmall = ref(false);
watch(
  [() => props.skeletonLoader, () => props.barProgressPercentage, () => wrapperRect.width],
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      if (wrapperRect.width < dataInnerRightEl?.value?.elementBounding?.width || wrapperRect.width < 250) {
        goalBarIndicatorSmall.value = true;
      } else goalBarIndicatorSmall.value = false;
      throttledResize();
    }
  },
  { immediate: true },
);
</script>
