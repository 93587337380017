import { store } from '@/store';

export function handleErrorResponse(errorResponse, capture = false) {
  let errs = errorResponse?.response?.body || {};
  // let status = errorResponse.status

  /**
  * Error responses from django REST framework
  * http://www.django-rest-framework.org/api-guide/exceptions/
  *
  * Eamples:
  * general: {"detail": "Method 'DELETE' not allowed."}
  * form validation errors: {"email": ["Ange en giltig mejladress."], "firstname": ["Lorem ipsum"]}
  */
  Object.keys(errs).forEach((field) => {
    let fields = errs[field];
    // form errors come in [] general errors as ""
    if (typeof errs[field] === 'string') {
      fields = [errs[field]];
    }
    fields.forEach((error) => {
      // omit key in text if it is === 'details'
      let text = field === 'detail' ? error : `${field}: ${error}`;
      store.dispatch('notify', {
        text,
        level: 'info',
      });
    });
  });
  if (capture) {
    return errorResponse;
  }
  throw errorResponse;
}

export default {
  handleErrorResponse,
};
