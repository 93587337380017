const initialFilters = {
  segmentFilter: {
    date: {
      type: 'relative',
      offset: null,
      span: {
        months: 12,
      },
    },
    // benchmark: {
    //   sector: [],
    //   size: [],
    //   location: [],
    //   industry: [],
    // },
  },
};

export default {
  state: {
    segmentFilter: initialFilters.segmentFilter,
    fetchingFilters: false,
    currentBoxRowHash: null, // ? Used to disable other BoxRows when updating a certain BoxRow
  },
  mutations: {
    UPDATE_FILTER(state, { filter, props }) {
      if (props && filter && filter in state) {
        state[filter] = { ...state[filter], ...props };
      }
    },
    CLEAR_FILTER(state, filter) {
      if (filter && filter in state) {
        state[filter] = {};
      }
    },
    RESET_FILTER(state, filter) {
      if (filter && filter in state) {
        if (filter in initialFilters) {
          state[filter] = initialFilters[filter];
        } else {
          state[filter] = {};
        }
      }
    },
    SET_FETCH_FILTER_STATE(state, fetchState) {
      state.fetchingFilters = fetchState;
    },
    SET_CURRENT_BOX_ROW_HASH(state, hash) {
      state.currentBoxRowHash = hash;
    },
  },
  getters: {
    segmentFilter: (state) => state.segmentFilter,
    fetchingFilters: (state) => state.fetchingFilters,
  },
  actions: {
    updateFilter({ commit }, props) {
      if (props && typeof props === 'object') {
        commit('UPDATE_FILTER', {
          filter: 'segmentFilter',
          props,
        });
      }
    },
    clearFilter({ commit }, props) {
      if (props && Array.isArray(props)) {
        commit('UPDATE_FILTER', {
          filter: 'segmentFilter',
          props: props.reduce((resetProps, prop) => {
            resetProps[prop] = null;
            return resetProps;
          }, {}),
        });
      } else {
        commit('CLEAR_FILTER', 'segmentFilter');
      }
    },
    resetFilter({ commit }) {
      commit('RESET_FILTER', 'segmentFilter');
    },
    setFilterFetchingState({ commit }, state) {
      commit('SET_FETCH_FILTER_STATE', state);
    },
    setCurrentBoxRowHash({ commit }, hash = null) {
      commit('SET_CURRENT_BOX_ROW_HASH', hash);
    },
  },
};
