<template>
  <div
    v-tooltip="tooltipWithHoverState"
    class="no-wrap"
    @mouseover="hoverHandler"
    @mouseout="hover = !hover"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HelperTextEllipsis',
  props: {
    overrideEllipsisShow: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tooltipAllowed: false,
      isEllipsed: false,
      hover: false,
    };
  },
  computed: {
    tooltipWithHoverState() {
      return {
        ...this.tooltip,
        shown: this.tooltipAllowed && this.hover,
        triggers: ['hover', 'click', 'focus'],
      };
    },
  },
  watch: {
    isEllipsed() { this.toggleHoverState(); },
  },
  methods: {
    hoverHandler(e) {
      this.hover = !this.hover;
      this.setEllipseState(e);
      if (this.overrideEllipsisShow) this.toggleHoverState();
    },
    setEllipseState({ currentTarget }) {
      const { scrollWidth, offsetWidth } = currentTarget;
      this.isEllipsed = offsetWidth < scrollWidth;
    },
    toggleHoverState() {
      if (this.isEllipsed || this.overrideEllipsisShow || this.isEllipsed && this.overrideEllipsisShow) {
        this.tooltipAllowed = true;
      } else {
        this.tooltipAllowed = false;
      }
    },
  },
};
</script>
