/* This is a performant tooltip doesn’t have to heavily mount/unmount tooltips. Good if used by large tables etc. */
import { createTooltip as floatingCreateTooltip, destroyTooltip as floatingDestroyTooltip, hideAllPoppers } from 'floating-vue';

let tooltips = new WeakMap();

export function createTooltip(el, settings) {
  if (Array.isArray(el)) el = el[0];
  if (!el) return null;
  if (tooltips.has(el)) return tooltips.get(el);
  const tooltip = floatingCreateTooltip(el, {
    triggers: [],
    content: settings?.content ?? settings,
    delay: 0,
    ...settings,
  });
  tooltips.set(el, tooltip);
  tooltip.show();
  return tooltip;
}

export function destroyTooltip(el, transitionTime = 400) {
  if (Array.isArray(el)) el = el[0];
  const tooltip = tooltips.get(el);
  if (!tooltip) return;
  tooltip.hide();
  setTimeout(() => {
    floatingDestroyTooltip(el);
    tooltips.delete(el);
  }, transitionTime);
}

export function destroyAllTooltips() {
  hideAllPoppers();
  tooltips = new WeakMap();
}
