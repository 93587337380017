<template>
  <div class="container container-narrow">
    <div class="hgroup">
      <h2>{{ $gettext('Användare &amp; inbjudningar') }}</h2>
      <h5 v-html="subTitleText" />
    </div>
    <user-list user-type="customer" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserList from '../parts/user/UserList';

export default {
  components: {
    UserList,
  },
  computed: {
    ...mapGetters([
      'customerName',
    ]),
    subTitleText() {
      return this.$gettext('Användare som kan se <em>alla</em> segment på kontot');
    },
  },
};
</script>
