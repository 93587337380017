<template>
  <div>
    <key-metric-result
      v-if="card && card.metadata && card.isKeyMetric() && !loading"
      :card="card"
      @loaded="l => fullyLoadedCard = l"
      @update-drag-area="updateDragArea"
    />
    <query-result
      v-else-if="card && card.metadata && !card.isKeyMetric() && !loading"
      :card="card"
      @loaded="l => fullyLoadedCard = l"
      @update-drag-area="updateDragArea"
    />
    <placeholder-card
      v-else-if="!card && loading || !card && !loading || showError"
      :placeholder-message="couldntLoadCardErrorText"
      card-icon="zmdi-alert-octagon"
      :card-message="tryAgainText"
      :cta="null"
    />
    <div
      v-if="fullyLoadedCard"
      id="allCardsAboveFinishedLoading"
    />
  </div>
</template>

<script>
import cardAPI from 'API/cards';
import QueryResult from 'Components/parts/explore/QueryResult';
import KeyMetricResult from 'Components/parts/explore/KeyMetricResult';
import PlaceholderCard from 'Components/parts/PlaceholderCard';

export default {
  components: {
    QueryResult,
    PlaceholderCard,
    KeyMetricResult,
  },
  props: ['cardId'],
  data() {
    return {
      card: {},
      loading: false,
      showError: false,
      fullyLoadedCard: false,
    };
  },
  computed: {
    couldntLoadCardErrorText() {
      return this.$pgettext(
        'Error — Print card (upper msg)',
        'Kunde inte hitta card (#%{id}) 😕',
        { id: parseInt(this.cardId, 10) },
      );
    },
    tryAgainText() {
      return this.$pgettext('Error — Print card (lower msg)', 'Prova igen eller skapa rapport av ett annat card');
    },
  },
  watch: {
    cardId(id) {
      this.loadCard(id);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadCard(this.cardId && parseInt(this.cardId, 10));
      this.timeoutLoadingCardsElement(45);
    });
  },
  methods: {
    updateDragArea() {
      if (this.$refs.tcDragArea) this.$refs.tcDragArea.updateLayout();
    },
    loadCard(id = '') {
      this.loading = true;
      if (id === '') {
        const card = new cardAPI.Card();
        return Promise.resolve(card).then(this.setup);
      }
      return cardAPI.get(id).then(
        this.setup,
        (error) => {
          this.showError = true;
          console.error(this.couldntLoadCardErrorText); // eslint-disable-line no-console
          // throw error;
        },
      );
    },
    setup(card) {
      if (card) {
        this.card = card;
        // this.updateDragArea();
        this.loading = false;
        // this.card.metadata = card.metadata;
        return Promise.resolve(card);
      }
      this.loading = false;
      return Promise.reject(new Error(this.$gettext('Kunde inte konfigurera cardet')));
    },
    timeoutLoadingCardsElement(timeoutInSeconds = 60) {
      setTimeout(() => {
        this.fullyLoadedCard = true;
      }, timeoutInSeconds * 1000);
    },
  },
};
</script>
