<template>
  <div class="dropdown-select-list tc-select-options-list tc-select-sidelabel--overlap">
    <template v-if="groups.length > 0">
      <template v-for="group in groups">
        <label
          v-if="group.groupSlug"
          :key="`${group.groupSlug}-title`"
        >
          {{ group.groupTitle }}
        </label>
        <template v-if="group.options.length > 0">
          <DropdownSelectListItem
            v-for="option in group.options"
            :key="option.value"
            :option="option"
            :class="group.groupSlug ? `group-${group.groupSlug}` : ''"
            :title="option.label"
            tabindex="0"
            @selected="selected"
          />
        </template>
        <template v-else-if="group.loading">
          <div
            :key="`${group.groupSlug}-loading`"
            class="tc-select-options-list-item tc-select-no-options"
          >
            <i class="zmdi zmdi-hc-fw zmdi-spinner zmdi-hc-spin" />
            <span v-text="$gettext('Laddar…')" />
          </div>
        </template>
        <div
          v-else
          :key="`${group.groupSlug}-no-options`"
          class="tc-select-options-list-item tc-select-no-options"
          v-text="$gettext('Inga fler val finns')"
        />
        <div
          v-if="group?.hasMore"
          :key="`${group.groupSlug}-load-more`"
          class="tc-select-options-list-item tc-select-load-more"
          :aria-disabled="group.loading"
          @click="emit('load-more', group.groupSlug)"
        >
          <i
            v-if="group.loading"
            class="zmdi zmdi-hc-fw zmdi-spinner zmdi-hc-spin"
          />
          <span v-text="group.loading ? $gettext('Laddar…') : $gettext('Ladda fler')" />
        </div>
      </template>
    </template>
    <div
      v-else
      class="tc-select-options-list-item tc-select-no-options"
      v-text="$gettext('Inga fler val finns')"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { isArray } from 'lodash-es';
import DropdownSelectListItem from './DropdownSelectListItem';

const props = defineProps({
  groups: {
    type: Array,
    default: () => ([]),
    validator: (groups) => groups.every((group) => {
      const validOption = (option) => {
        const { label, value, icon, tooltip, disabled, marked, selected, sublist } = option;
        let warnings = [];
        if (typeof label !== 'string') warnings.push('label is not a string', label); // Required
        if (typeof value !== 'string' && typeof value !== 'number') warnings.push('value is not a string or number', value); // Required
        if (icon != null && typeof icon !== 'string') warnings.push('icon is not a string', icon);
        if (icon != null && !icon?.startsWith('zmdi-')) warnings.push('icon doesn’t start with "zmdi-"', icon);
        if (tooltip != null && typeof tooltip !== 'string') warnings.push('tooltip is not a string', tooltip);
        if (disabled != null && typeof disabled !== 'boolean') warnings.push('disabled is not a boolean', disabled);
        if (marked != null && typeof marked !== 'string') warnings.push('marked is not a string', marked);
        if (selected != null && typeof selected !== 'boolean') warnings.push('selected is not a boolean', selected);
        if (sublist != null && typeof sublist !== 'function') warnings.push('sublist is not a function', sublist);
        if (warnings.length > 0) {
          console.warn('[TC] Invalid option:', value, warnings.join(', ')); // eslint-disable-line no-console
          return false;
        }
        return true;
      };

      const validGroup = (grp) => {
        const { groupSlug, groupTitle, options, loading } = grp;
        let warnings = [];
        if (typeof groupSlug !== 'string') warnings.push('groupSlug not a string', typeof groupSlug); // Required
        if (typeof groupTitle !== 'string') warnings.push('groupSlug not a string', typeof groupTitle); // Required
        if (typeof loading !== 'boolean') warnings.push('loading not a boolean', typeof loading); // Required
        if (!isArray(options)) warnings.push('options not a array', typeof options); // Required
        if (!options.every((o) => validOption(o))) warnings.push('options not valid');
        if (warnings.length > 0) {
          console.warn('[TC] Invalid group:', groupSlug, warnings.join(', ')); // eslint-disable-line no-console
          return false;
        }
        return true;
      };

      if (group?.groupSlug != null) return validGroup(group);
      return false;
    }),
  },
  multiselect: {
    type: Boolean,
    default: false,
  },
  canDeselect: {
    type: Boolean,
    default: true,
  },
  dropdownFlavor: {
    type: String,
    default: '',
  },
});

const groups = computed(() => props.groups);

const emit = defineEmits(['selected', 'load-more']);

const isSelected = (option, selectedOption) => {
  const isSingleSelect = option?.multiselect === false;
  const optionIsSelectedOption = option.value === selectedOption;
  if (isSingleSelect) return !!optionIsSelectedOption;
  if (optionIsSelectedOption) return props.canDeselect ? !option.selected : true;
  return props.multiselect ? option.selected : false;
};

const selectedOptions = ref([]);
watch(groups, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    selectedOptions.value = newVal.flatMap((group) => group.options.filter((option) => option.selected));
  }
});
const allOptionsFlat = computed(
  () => groups.value
    .flatMap((group) => (group.loading
      ? []
      : [
        ...group.options.map((option) => ({
          ...option,
          type: group?.boxRowType ?? null,
          group: group.groupSlug || option.value,
          selected: !!selectedOptions.value.find((opt) => opt.value === option.value),
        })),
      ]
    )),
);

const selected = (selectedOption, isSublist = false) => {
  selectedOptions.value = allOptionsFlat.value.reduce((acc, option) => {
    // !refactor rename useWithBoxRow to something relevant
    const isBenchmark = props.dropdownFlavor === 'benchmark';
    if (isSelected(option, selectedOption)) acc.push({ ...option, selected: true, isSublist });
    else if (isBenchmark && option.selected && props.canDeselect) acc.push({ ...option, selected: false, isSublist }); // eslint-disable-line max-len
    return acc;
  }, []);

  if (selectedOptions.value.length === 0 && props.dropdownFlavor === 'filter') {
    const option = allOptionsFlat.value.find((opt) => opt.value === selectedOption);
    selectedOptions.value = option?.group && !option?.type ? [{ group: option.group, type: null, selected: false, isSublist }] : [];
    return emit('selected', selectedOptions.value);
  }
  return emit('selected', selectedOptions.value);
};
</script>
