/**
 * For caching of user requests
 * updates will remove cache and get user again
 * components need to watch getters.user
 *
 * Pagination is currently not supported. As that would require tons of work with dual userAPIs.
 * Could be easily implemented as `store/modules/tags.js` if it was only one userAPI for them both.
 */
import { isEmpty } from 'lodash-es';
import customerUserApi from 'API/customerUser';
import segmentUserApi from 'API/segmentUser';
import { markLabel, labelHit } from 'Utils/tcselectHelpers';

const userEmailOption = (u) => ({ value: u?.user?.id, label: u?.user?.email });

export default {
  state: {
    customerUsers: {}, // UNPAGINATED customer_user under customerId
    segmentUsers: {}, // UNPAGINATED segment_user under customerId
    fetchingUsers: false,
  },
  mutations: {
    SET_CUSTOMER_USERS(state, { customerId, customerUsers }) {
      state.customerUsers[customerId] = customerUsers;
    },
    SET_SEGMENT_USERS(state, { customerId, segmentUsers }) {
      state.segmentUsers[customerId] = segmentUsers;
    },
    CLEAR_USERS(state, customerId) {
      if (typeof customerId === 'number') {
        state.customerUsers[customerId] = [];
        state.segmentUsers[customerId] = [];
      }
    },
    SET_USERS_FETCH_STATE(state, fetchState) {
      state.fetchingUsers = fetchState;
    },
  },
  getters: {
    fetchingUsers: (state) => state.fetchingUsers,
    users: (state, getters) => {
      if (
        isEmpty(state.customerUsers?.[getters.customerId] ?? {})
        && isEmpty(state.segmentUsers?.[getters.customerId] ?? {})
      ) return [];
      return [
        ...state.customerUsers[getters.customerId],
        ...state.segmentUsers[getters.customerId],
      ];
    },
    getUserById: (state, getters) => (id) => getters.users.find((u) => u?.user?.id === id),
    usersEmailOptions: (state, getters) => (term) => Promise.resolve(getters.users
      .filter((u) => !!u.user.email) // ? Require users’ emails
      .map(userEmailOption).filter(labelHit(term)).map(markLabel(term))),
  },
  actions: {
    async fetchUsers({ dispatch, getters }) {
      const segmentId = getters.segmentId;
      return dispatch('fetchUsersBySegmentId', segmentId);
    },
    async fetchUsersBySegmentId({ getters, commit }, segmentId) {
      const customerId = getters.customerId;
      commit('SET_USERS_FETCH_STATE', true);
      commit('CLEAR_USERS', customerId);
      const su = segmentUserApi.list({ segmentId, paginated: false });
      const cu = customerUserApi.list({ customer: customerId, excludeFromSegment: segmentId, paginated: false });
      return Promise.all([su, cu])
        .then(([segmentUsers, customerUsers]) => {
          commit('SET_SEGMENT_USERS', { customerId, segmentUsers });
          commit('SET_CUSTOMER_USERS', { customerId, customerUsers });
          commit('SET_USERS_FETCH_STATE', false);
        });
    },
  },
};
