import { getBaseUrl, putUrl, getUrl, deleteUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

function list({ segmentId = null, paginated = false } = {}) {
  return getBaseUrl('/feedback/accounts/api/segmentuser/')
    .query({ ...(segmentId && { segment: segmentId }) })
    .then((response) => (
      paginated && response.body.next !== undefined ? response.body : response.body.results
    ), handleAuthError)
    .catch((error) => { /* fixes Error: connect ECONNREFUSED in tests */ });
}

function get(id) {
  return getUrl(`/feedback/accounts/api/segmentuser/${id}/`);
}

function update(id, role) {
  return putUrl(`/feedback/accounts/api/segmentuser/${id}/`, { role });
}

function remove(id) {
  return deleteUrl(`/feedback/accounts/api/segmentuser/${id}/`);
}

export default {
  list,
  get,
  update,
  remove,
};
