<template>
  <div
    v-tc-loader-bar="usingPlaceholder"
    class="query-result-bar-graph tc-loader-bar-wrapper"
    :class="{ 'query-result-no-graph': !hasData && !loading,
              'query-result-bar-graph--drillable': !isTopicable(questionId) }"
  >
    <div
      v-if="!hasData && !loading"
      class="p-card"
    >
      <p class="alert alert-info">
        <i class="zmdi zmdi-info" />
        <span>{{ $gettext('Det finns ingen data. Prova att ändra filter.') }}</span>
        <br>
        <span>
          {{ $pgettext("Error - Bar graph empty", "Eller så finns det inte tillräckligt med svar ännu.") }}
        </span>
      </p>
    </div>
    <template v-else>
      <p
        v-if="isPrintViewRoute || show('count')"
        class="query-result-totalcount subtle-text"
      >
        <span>
          {{ $pgettext("Title - totalCount", "Antal svar:") }}
        </span> {{ totalCount.segment }}
      </p>
      <bar-graph
        :question-type="questionType"
        :rows="rows"
        :categories="[segmentName, customerName, 'Benchmark']"
        :skeleton-loader="usingPlaceholder"
        :total-count="totalCount"
        :card-id="card.id"
      />
      <p
        v-if="loadingBaseValues"
        class="query-result-loading-more small-text super-subtle-text"
      >
        <i class="zmdi zmdi-spinner zmdi-hc-spin" />
        <span>{{ $gettext('Laddar rader utan svar…') }}</span>
      </p>
      <p
        v-if="questionType === 'text'"
        class="query-result-totalcount subtle-text"
      >
        <i class="zmdi zmdi-info-outline mr-0.5" /><span>{{ $pgettext(
          "Title - Bar graph help note",
          "Visar andelen sökande som har svarat på något relaterat till en textkategori",
        ) }}</span>
      </p>
    </template>
  </div>
</template>

<script>
import { klona } from 'klona';
import { mapGetters, mapActions } from 'vuex';
import { isEmpty, forOwn } from 'lodash-es';
import { isTopicable } from 'API/topics';
import { translateBaseValue, roundNumber } from 'Utils/general';
import { NPS_NAMES, questionTypesTooltip } from 'Utils/question';
import { show, GRAPH_TYPES } from 'Utils/graph';
import { placeholderSerie } from 'Utils/generate';
import eventBus from 'Utils/eventBus';
import BarGraph from 'Components/parts/graph/BarGraph';
import { destroyAllTooltips } from 'Components/parts/widgets/PerformantTooltip';

export default {
  name: 'QueryResultBarGraph',
  components: {
    BarGraph,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    hasData: Boolean,
    question: Object,
    contextFilter: Object,
    compiledFilter: Object,
    compiledBenchmark: Object,
    toqResponse: Array,
    toqGlobalResponse: Array,
  },
  emits: ['update-drag-area'],
  data() {
    return {
      loadingBaseValues: null,
      baseValuesResponse: {},
      abortToken: Math.random().toString(10).substring(2),
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'segmentName',
      'customerName',
    ]),
    sortBy() {
      return ['yesno', 'list', 'listmany', 'text'].includes(this.questionType) ? 'respondent_count' : 'answer_str';
    },
    isPrintViewRoute() { return this.$route?.name?.indexOf('print-') > -1; },
    questionId() { return this.card.metadata.question; },
    questionType() { return this.question?.options?.cnps ? 'cnps' : this.question.question_type; },
    usingPlaceholder() { return this.loading || this.toqResponse?.[0] === undefined; },
    shouldShowHiddenRows() { return this.show('erroredRows'); },
    totalCount() {
      return {
        segment: this.toqResponse?.[2]?.respondent_count || null,
        customer: this.toqResponse?.[3]?.respondent_count || null,
      };
    },
    rows() {
      // !refactor, this is probably an unnecessary type of looping, combine chartData, serie and rows
      // ? From [segment, customer, global] to rows [1: [s,c,g], 2: [s,c,g] etc...]
      if (!this.chartData.series?.[0] || !this.questionId) return [];
      const rows = this.chartData.series?.[0]?.data?.reduce((rowsAcc, segmentData, i) => { // Loop through data
        const row = this.chartData.series.reduce((rowAcc, serie) => { // Loop through series
          rowAcc = {
            drilldownData: {
              value: this.chartData.labels[i] === 'Loading' ? ['Loading'] : [this.chartData.labels[i]],
            },
            label: this.chartData.labels[i] === 'Loading'
              ? 'Loading'
              : translateBaseValue(this.chartData.labels[i], this.question),
            data: [...rowAcc.data, serie?.data?.[i] || NaN],
            count: [...rowAcc.count, serie?.count?.[i] || NaN],
          };
          if (isTopicable(this.questionId)) rowAcc.topic = serie?.topic?.[i] || '';
          return rowAcc;
        }, { data: [], count: [] });
        let drilldownData = { value: [row.label] };

        // ? BarGraphs can currently be grouped by topics or answers. Or Company/Segment/Benchmark for ResponseRates, but not relevant to drilldown on rn.
        if (isTopicable(this.questionId)) {
          // drilldownData = {
          //   ...row.drilldownData,
          //   slug: 'topics',
          //   label: 'Textkategori',
          //   boxRowValue: row.topic,
          //   boxRowKey: 'topics',
          //   boxRowType: null,
          // };
        } else {
          drilldownData = {
            ...row.drilldownData,
            slug: `answers__${this.questionId}`,
            label: this.questionId,
            boxRowValue: row.drilldownData.value,
            boxRowKey: this.questionId,
            boxRowType: 'answers',
            needsTranslation: true,
          };
          row.drilldownData = drilldownData;
        }

        // row.drilldownData = drilldownData;

        rowsAcc.push(row);
        return rowsAcc;
      }, []) || [];

      return rows;
    },
    chartData() {
      const { segmentResponse, companyResponse } = this.matchObjectKeys(
        this.loadingBaseValues === false && !isEmpty(this.baseValuesResponse)
          ? { ...this.baseValuesResponse, ...this.toqResponse[0] }
          : this.toqResponse[0],
        this.toqResponse[1],
      );

      const segmentSerie = this.usingPlaceholder
        ? this.serie(placeholderSerie(this.questionType), 10, 'Segment')
        : this.serie(
          segmentResponse,
          this.totalCount.segment ?? 0,
          this.segmentName,
        );
      const companySerie = this.usingPlaceholder
        ? null
        : this.serie(
          companyResponse,
          this.totalCount.customer ?? 0,
          this.customerName,
          segmentSerie.label,
        );

      const series = [segmentSerie, companySerie];
      const labels = segmentSerie?.label || companySerie?.label || [];

      return { series, labels };
    },
  },
  watch: {
    async usingPlaceholder() {
      await this.$nextTick();
      this.$emit('update-drag-area');
    },
    loading(newVal) {
      if (newVal) this.$emit('update-drag-area');
    },
    question(newVal, oldVal) {
      if (newVal !== oldVal) this.getSetBaseValues(newVal);
      if (newVal?.question_type === 'yesno') this.$emit('update-drag-area');
    },
    shouldShowHiddenRows(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getSetBaseValues();
    },
    // segmentId(newId, oldId) {
    //   if (newId !== oldId) {
    //     this.baseValuesResponse = {};
    //   }
    // },
  },
  beforeUnmount() {
    eventBus.$emit(`abortRequest:${this.abortToken}`);
  },
  unmounted() {
    destroyAllTooltips();
  },
  mounted() {
    this.getSetBaseValues();
  },
  methods: {
    ...mapActions([
      'fetchStepFormsBySegmentId',
    ]),
    questionTypesTooltip,
    isTopicable,
    findKeyDiff(obj1, obj2) {
      if (!obj1 && !obj2) return [];
      if (obj1.length > obj2.length) return obj1.filter((value) => !obj2.includes(value));
      return obj2.filter((value) => !obj1.includes(value));
    },
    matchObjectKeys(segmentResponse, companyResponse) {
      let segmentResponseKeys = [];
      let clonedSegmentResponse = {};
      let clonedCompanyResponse = {};
      let companyResponseKeys = [];
      if (segmentResponse) {
        clonedSegmentResponse = klona(segmentResponse);
        segmentResponseKeys = Object.keys(clonedSegmentResponse);
      }
      if (!segmentResponse && !companyResponse) return {};
      if (companyResponse) {
        clonedCompanyResponse = klona(companyResponse);
        companyResponseKeys = Object.keys(clonedCompanyResponse);
      }
      const keysToRemove = this.findKeyDiff(segmentResponseKeys, companyResponseKeys);
      if (keysToRemove.length) {
        keysToRemove.forEach((key) => {
          if (segmentResponseKeys.length > companyResponseKeys.length) delete clonedSegmentResponse?.[key];
          else delete clonedCompanyResponse?.[key];
        });
      }
      return { segmentResponse: clonedSegmentResponse, companyResponse: clonedCompanyResponse };
    },
    copyOrder(values, order) {
      const isCnps = this.questionType === 'cnps';
      const orderAsc = this.card?.metadata?.show?.sort?.orderAsc === true;
      if (!orderAsc) {
        if (isCnps) return values.sort((a, b) => order.indexOf(NPS_NAMES()[a?.answer_str]) - order.indexOf(NPS_NAMES()[b?.answer_str])); // eslint-disable-line max-len
        return values.sort((a, b) => order.indexOf(a?.answer_str) - order.indexOf(b?.answer_str));
      }
      if (isCnps) return values.sort((a, b) => order.indexOf(NPS_NAMES()[b?.answer_str]) - order.indexOf(NPS_NAMES()[a?.answer_str])); // eslint-disable-line max-len
      return values.sort((a, b) => order.indexOf(b?.answer_str) - order.indexOf(a?.answer_str));
    },
    sortOrder(response, copyOrder, sortBy = 'answer_str') { // sortMethod count/label
      const values = Object.values(response);
      const orderAsc = this.card?.metadata?.show?.sort?.orderAsc === true;
      if (!isEmpty(copyOrder)) return this.copyOrder(values, copyOrder);
      return values
        .sort((a, b) => {
          if (
            (sortBy === 'respondent_count')
            || (sortBy === 'answer_str' && this.questionType === 'rating')
          ) {
            return orderAsc
              ? (a?.[sortBy] || 0) - (b?.[sortBy] || 0)
              : (b?.[sortBy] || 0) - (a?.[sortBy] || 0);
          }
          return orderAsc
            ? (a?.[sortBy] || '').localeCompare(b?.[sortBy] || '')
            : (b?.[sortBy] || '').localeCompare(a?.[sortBy] || '');
        });
    },
    serie(response, totalCount, name, copyOrder = []) {
      const count = [];
      const data = [];
      const label = [];
      const topic = [];

      if (this.questionType === 'cnps') copyOrder = Object.values(NPS_NAMES());
      const sorted = this.sortOrder(response, copyOrder, this.sortBy);
      forOwn(sorted, (val, key) => {
        const percentageValue = val != null && val.respondent_count != null && totalCount > 0
          ? roundNumber((val.respondent_count / totalCount) * 100, 1)
          : null;
        data.push(percentageValue);
        count.push(val?.respondent_count || null);
        if (isTopicable(this.questionId)) topic.push(val?.answer_topic);
        if (this.questionType === 'cnps') label.push(NPS_NAMES()[val?.answer_str] || val?.answer_str || val?.date_str || key);
        else label.push(val?.answer_str || val?.date_str || key);
      });

      return {
        usesPercentages: true,
        count,
        data,
        label,
        name,
        ...((isTopicable(this.questionId)) && { topic }),
      };
    },
    async getSetBaseValues(useQuestion = this.question) {
      if (!this.shouldShowHiddenRows || !isEmpty(this.baseValuesResponse)) {
        this.loadingBaseValues = false;
      } else {
        this.loadingBaseValues = true;
        const { question, values } = this.getBaseValuesByQuestion(useQuestion);
        this.baseValuesResponse = {
          question,
          values: values.reduce((acc, str) => {
            acc[str] = { answer_str: str, score: 0, count: 0, respondent_count: 0 };
            return acc;
          }, {}),
        };
        this.loadingBaseValues = false;
        this.$emit('update-drag-area');
      }
    },
    getBaseValuesByQuestion(question) {
      if (question.question_type === undefined) return { question, values: [] };
      if (question.question_type === 'yesno') return { question, values: ['ja', 'nej'] };
      return {
        question,
        values: question?.options?.base_values || [],
      };
    },
    show(key) {
      return show(key, this.question, GRAPH_TYPES.barGraph, {
        ...this.card.metadata.show,
        ...this.card.metadata.show.columns,
        ...this.card.metadata.show.rows,
      });
    },
  },
};
</script>
