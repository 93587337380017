<template>
  <div class="dropdown-select tc-select-options">
    <!-- eslint-disable vue/attributes-order, vue/max-attributes-per-line -->
    <svg style="display: none">
      <defs>
        <symbol viewBox="0 0 12 12" id="fluent-comment-checkmark-12-regular"><path fill="currentColor" d="M12 3.5a3.5 3.5 0 1 0-7 0a3.5 3.5 0 0 0 7 0Zm-1.646-1.354a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708L8 3.793l1.646-1.647a.5.5 0 0 1 .708 0ZM3 2h1.256c.126-.356.295-.691.502-1H3a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2v1.5a.5.5 0 0 0 .777.416L6.651 9H9a2 2 0 0 0 1.984-1.747a4.475 4.475 0 0 1-1.557.651A.996.996 0 0 1 9 8H6.5a.5.5 0 0 0-.277.084L4 9.566V8.5a.5.5 0 0 0-.5-.5H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1Z" /></symbol>
        <symbol viewBox="0 0 16 16" id="fluent-comment-multiple-checkmark-16-regular"><path fill="currentColor" d="M3.05 3A2.5 2.5 0 0 1 5.5 1H12a3 3 0 0 1 3 3v3.337a5.533 5.533 0 0 0-1-1.08V4a2 2 0 0 0-2-2H5.5a1.5 1.5 0 0 0-1.415 1H11a2 2 0 0 1 2 2v.6a5.465 5.465 0 0 0-1-.393V5a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h1v2.028l1.558-1.112c.148.302.322.588.521.856l-1.498 1.07A1 1 0 0 1 3 14.028V13a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h.05ZM15 10.5a4.5 4.5 0 1 0-9 0a4.5 4.5 0 0 0 9 0Zm-2.146-1.854a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1-1a.5.5 0 0 1 .708-.708l.646.647l2.646-2.647a.5.5 0 0 1 .708 0Z" /></symbol>
      </defs>
    </svg>
    <!-- eslint-disable vue/attributes-order, vue/max-attributes-per-line -->
    <div
      v-if="dropdownSettings?.searchable"
      class="sticky px-2 tc-bg-white pt-1 mb-1"
    >
      <input
        ref="searchField"
        v-model="searchTerm"
        class="form-control"
        :placeholder="dropdownSettings?.searchPlaceholder"
        type="text"
      >
    </div>
    <DropdownSelectList
      :groups="groups"
      v-bind="dropdownSettings ?? {}"
      @load-more="emit('load-more', $event)"
      @selected="(selectedOptions) => emit('selected', selectedOptions)"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import gettext from '@/gettext';
import DropdownSelectList from './DropdownSelectList';

const { $gettext } = gettext;

const props = defineProps({
  groups: {
    type: Array,
    default: () => ([]),
  },
  dropdownSettings: Object,
});

const groups = computed(() => props.groups);

const defaultDropdownSettings = {
  multiselect: false,
  canDeselect: true,
  dropdownFlavor: '',
  searchable: true,
  searchPlaceholder: $gettext('Skriv för att söka'),
};
const dropdownSettings = computed(() => ({
  ...defaultDropdownSettings,
  ...props.dropdownSettings,
}));

const emit = defineEmits(['selected', 'load-more', 'update:search-term']);

const searchTerm = ref('');
watch(searchTerm, (val) => { emit('update:search-term', val); });

</script>
