let current = null;

export const app = () => current;
export const vm = () => current?.config.globalProperties; // ? Sugar-syntax for `app()?.config.globalProperties`
export const setVM = (newApp) => { current = newApp; };

export default {
  app,
  vm,
  setVM,
};
