import { isEmpty } from 'lodash-es';
import { getStaticCountries } from 'API/benchmarks';
import { vm } from '@/vm';
import gettext from '@/gettext';

const { $gettext, $pgettext } = gettext;

export default {
  state: {
    translations: {},
    customTranslations: {},
  },
  mutations: {
    ADD_STRING_TRANSLATIONS(state, translations) {
      Object.keys(translations).forEach((key) => {
        state.translations[key] = translations[key];
      });
    },
    ADD_CUSTOM_STRING_TRANSLATIONS(state, translations) {
      Object.keys(translations).forEach((key) => {
        state.customTranslations[key] = translations[key];
      });
    },
    RESET_CUSTOM_STRING_TRANSLATIONS(state) {
      state.customTranslations = {};
    },
  },
  getters: {
    stringTranslations(state) {
      return state.translations;
    },
    customStringTranslations(state) {
      return state.customTranslations;
    },
  },
  actions: {
    addStringTranslations({ commit }, { translations, customTranslations = false }) {
      if (typeof translations !== 'object') {
        throw new Error('”translations” is not an object');
      }
      if (customTranslations) return commit('ADD_CUSTOM_STRING_TRANSLATIONS', translations);
      return commit('ADD_STRING_TRANSLATIONS', translations);
    },
    setBaseTranslations({ dispatch, getters }) {
      let translations = {
        cnps: $gettext('CNPS'),
        cnps5: $gettext('CNPS'),
        rating: $gettext('Rating'),
        yesno: $pgettext('questionType', 'Ja/Nej'),
        text: $pgettext('questionType', 'Fritext'),
        list: $pgettext('questionType', 'Envalsfråga'),
        listmany: $pgettext('questionType', 'Flervalsfråga'),
        technicalErrors: $gettext('Tekniska fel'),
        all_steps: $pgettext('Level', 'Alla'),
        step1: $gettext('Ansökan'),
        step2: $gettext('Intervju'),
        step3: $gettext('Anställd'),
        step4: $gettext('Onboarding'),
        step5: $gettext('Avslag'),
        kundstep1: $gettext('Kundanpassat'),
        consultantstep1: $gettext('Onboarding konsulter'),
        hiringmanagerstep1: $gettext('Chefsenkät'),
        employeestep1: $gettext('Medarbetare'),
        other: $gettext('Annat'),
        table: $gettext('Tabell'),
        lineGraph: $gettext('Linjediagram'),
        barGraph: $gettext('Stapeldiagram'),
        nej: $gettext('Nej'),
        ja: $gettext('Ja'),
        small: $gettext('Liten'),
        medium: $gettext('Medium'),
        large: $gettext('Stor'),
        location: $gettext('Plats'),
        size: $gettext('Storlek'),
        industry: $gettext('Bransch'),
        sector: $gettext('Sektor'),
        governmental: $pgettext('Sector', 'Offentlig verksamhet'),
        privately_held: $pgettext('Sector', 'Privat'),
        recruitment: $pgettext('Sector', 'Rekrytering & Bemanning'),
        'Employee count above 1000.': $gettext('Fler än 1000 anställda'),
        'Employee count below 100.': $gettext('Färre än 100 anställda'),
        'Employee count between 100 and 1000.': $gettext('100 - 1000 anställda'),
      };
      dispatch('addStringTranslations', { translations });
      dispatch('addStringTranslations', { translations, customTranslations: true });
      if (getters.customerProxies) dispatch('addStepDisplayNames', getters.customerProxies);
    },
    // ? Sets the base steps that can be overwritten by custom step_display_names, used throughout via translateTerm
    async addStepDisplayNames({ dispatch, state }, customerProxies = []) {
      const LANG = vm()?.$language?.current || state.languageSettings?.language || 'en-us';
      let translations = { _countries: await getStaticCountries(LANG) };
      if (Array.isArray(customerProxies) && customerProxies.length > 0) {
        const customStepDisplayNames = customerProxies.reduce((acc, proxy) => {
          if (proxy?.customer?.step_display_names && !isEmpty(proxy.customer.step_display_names)) {
            return {
              ...acc,
              ...Object.entries(proxy.customer.step_display_names)
                .reduce((cumulator, [key, val]) => ({ ...cumulator, [key]: val }), {}),
            };
          }
          return acc;
        }, {});
        if (customStepDisplayNames) {
          const mergedTranslations = { ...translations, ...customStepDisplayNames };
          dispatch('addStringTranslations', { translations: mergedTranslations, customTranslations: true });
        }
      }
    },
  },
};
