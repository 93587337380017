<template>
  <div>
    <div
      v-tc-loader-bar="loading"
      class="query-result-donut-graph p-card"
    >
      <div
        v-if="!hasData"
        class="mx-5"
      >
        <p class="alert alert-info">
          <i class="zmdi zmdi-info" />
          <span>{{ infoMessage }}</span>
        </p>
      </div>
      <template v-else>
        <p class="query-result-totalcount subtle-text">
          <span>
            {{ $pgettext("Title - totalCount", "Antal svar:") }}
          </span> {{ totalSegmentCount }}
        </p>
        <apexchart
          v-if="!apexImg"
          ref="apex"
          class="tc-donut-graph"
          height="300"
          width="100%"
          type="donut"
          :options="chartOptions"
          :series="chartData.series"
        />
        <img
          v-if="apexImg"
          :src="apexImg"
        >
        <div class="apexcharts-legend tc-chip-container tc-chip-container--column-chips flex-wrap">
          <div
            v-for="(item,index) in legends.showable"
            :key="item.charIndex"
            class="apexcharts-legend-series"
            tabindex="0"
            @mouseover="handleMouseEnter(item.index)"
            @mouseleave="handleMouseLeave(item.index)"
            @click="handleClick(index)"
            @keyup.enter="handleClick(index)"
          >
            <span
              class="apexcharts-legend-marker"
              :style="{ backgroundColor: item.color }"
            />
            <div class="apexcharts-legend-text">
              <span class="strong">{{ item.charIndex.toUpperCase() }} - {{ item.label }}</span>
              <span>
                {{ item.percentage }}%
                ({{ item.count }} / {{ totalSegmentCount }} svar)
              </span>
            </div>
          </div>
          <div
            v-if="legends.hidden.length"
            v-tooltip="{
              content: hiddenLegendsTooltip,
              html: true,
            }"
            class="apexcharts-legend-series"
            tabindex="0"
            @mouseover="handleMouseEnter(null, true)"
            @mouseleave="handleMouseLeave(null, true)"
          >
            <div class="apexcharts-legend-text pl-2">
              <span class="strong"> {{ $pgettext('Other', 'Övriga') }}</span>
              <span>
                {{ `${legends.hidden.length} ${$gettext('dolda')}` }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue';
import Apexchart from 'vue3-apexcharts';
import { merge } from 'lodash-es';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { topicColorsArrayed } from 'Utils/chartColors';
import { getApexChartBase64 } from 'Utils/apexchart';
import { translateBaseValue } from 'Utils/general';
import gettext from '@/gettext';

const fontFamily = "'Trim-Regularr', sans-serif";

const store = useStore();
const { $pgettext } = gettext;
const apex = ref(null);
const apexImg = ref(null);
const route = useRoute();

const props = defineProps({
  card: Object,
  compiledFilter: Object,
  hasData: Boolean,
  loading: Boolean,
  toqResponse: Array,
  toqGlobalResponse: Array,
  question: Object,
});

const generateApexPng = async (chartInstance) => {
  apexImg.value = await getApexChartBase64(chartInstance);
};
const hasDataAndcardIsVisible = computed(
  () => props.hasData && store._state.data.cards.shownCardIds.includes(props.card.id) || false,
);
watch(
  () => hasDataAndcardIsVisible.value,
  async (newVal) => {
    await nextTick();
    if (route?.name?.indexOf('print-') > -1 && newVal && apex.value) generateApexPng(apex.value);
  },
  { immediate: true, deep: true },
);

const infoMessage = computed(() => {
  if (props.loading) {
    return $pgettext('Info - DonutGraph', 'Laddar data…');
  }
  return $pgettext(
    'Info - DonutGraph',
    'Det finns ingen data. Prova att ändra filter.',
  );
});

const emit = defineEmits(['update-drag-area']);

watch(
  () => props.loading,
  (newVal, oldVal) => {
    if (newVal !== oldVal) emit('update-drag-area');
  },
);

const totalSegmentCount = computed(() => props.toqResponse?.[2]?.respondent_count || null);
const chartData = computed(() => {
  const segmentResponse = props.toqResponse?.[0] || [];
  const series = Object.values(segmentResponse).map((item) => item.respondent_count) || [];
  const labels = Object.values(segmentResponse).map(
    (item) => translateBaseValue(item.answer_str, props.question),
  ) || [];

  return {
    series,
    labels,
  };
});

const numToChar = (num) => {
  const alphabet = [...'abcdefghijklmnopqrstuvwxyz'];
  if (num < alphabet.length) {
    return alphabet[num];
  }
  return alphabet[Math.floor(num / alphabet.length) - 1] + alphabet[num % alphabet.length];
};

const overwriteChartData = ref({});
const colors = topicColorsArrayed[topicColorsArrayed.length - 1];
const getColor = (index) => colors[index % colors.length];
const chartOptions = computed(() => merge(
  {},
  overwriteChartData.value,
  {
    labels: chartData.value.labels,
    colors,
    chart: {
      fontFamily,
      foreColor: '#769BA3', // text color,
      animations: {
        enabled: route?.name?.indexOf('print-') === -1, // disable animations in print
      },
    },
    stroke: {
      width: 2,
    },
    tooltip: {
      fillSeriesColor: false,
      style: {
        fontFamily,
      },
      marker: {
        show: true,
      },
      y: {
        formatter: (value) => `${((value / totalSegmentCount.value) * 100).toFixed(1)}% (${value} / ${totalSegmentCount.value} svar)`,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      expandOnClick: false,
      pie: {
        expandOnClick: false,
        customScale: 0.8,
        dataLabels: {
          offset: 0,
        },
        donut: {
          // size: '50%',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (value, { seriesIndex }) => `${numToChar(seriesIndex).toUpperCase()}.`,
      style: {
        fontSize: '12px',
        fontFamily,
        colors: ['#fff'],
      },
      background: {
        enabled: true,
        foreColor: '#000',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#000',
        opacity: 0.9,
      },

      dropShadow: {
        enabled: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.15,
        },
      },
      active: {
        filter: {
          type: 'lighten',
          value: 0.15,
        },
      },
    },
  },
));

const showablePercentage = 0.02;
const legends = computed(() => {
  const series = chartData.value.series;
  const labels = chartData.value.labels;
  return series.reduce((acc, item, index) => {
    if (item / totalSegmentCount.value < showablePercentage) {
      acc.hidden.push({
        index,
        charIndex: numToChar(index),
        label: labels[index],
        count: item,
        percentage: ((item / totalSegmentCount.value) * 100).toFixed(1),
        color: getColor(index),
      });
      return acc;
    }
    acc.showable.push({
      index,
      charIndex: numToChar(index),
      label: labels[index],
      count: item,
      percentage: ((item / totalSegmentCount.value) * 100).toFixed(1),
      color: getColor(index),
    });

    return acc;
  }, { hidden: [], showable: [] });
});

const hiddenLegendsTooltip = computed(() => legends.value.hidden.map((item) => {
  const marker = `<span class="apexcharts-legend-marker" style="background-color: ${item.color}"></span>`;
  return `<div class="donut-legend-tooltip">${marker} ${item.label}: <span class="bold">${item.percentage}% (${item.count} / ${totalSegmentCount.value} svar)</span></div>`;
}).join(''));

const getAllPieSlicesPathEls = () => apex.value?.chart.series.w.globals.dom.baseEl.querySelectorAll('.apexcharts-pie-series path') || null;
const handleMouseEnter = (index, isHiddenLegends) => {
  const slices = getAllPieSlicesPathEls();

  if (!slices) return;
  slices.forEach((slice, i) => {
    if (isHiddenLegends) {
      if (!legends.value.hidden.map((item) => item.index).includes(i)) {
        slice.style.opacity = 0.3;
      }
    } else if (index !== i) {
      slice.style.opacity = 0.3;
    }
  });
};
const handleMouseLeave = () => {
  const slices = getAllPieSlicesPathEls();
  if (!slices) return;
  slices.forEach((slice, i) => { slice.style.opacity = null; });
};

const handleClick = (index) => { apex.value.toggleSeries(chartData.value.labels[index]); };

</script>
