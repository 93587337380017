<template>
  <ul
    v-if="questions.length > 0"
    class="tc-questions-list"
  >
    <li
      v-if="responseRate"
      class="tc-questions-item"
    >
      <key-metric-result
        :actions="['editButton','downloadPng']"
        :context-filter="ctxMetadata.filter"
        :context-benchmark="ctxMetadata.benchmark"
        :context-compare="ctxMetadata.compare"
        :card="responseRate.card"
        is-in-board
        temporary-card
      />
    </li>
    <li
      v-for="(question, index) in questions"
      :id="`questionId-${question.id}`"
      :key="question.id"
      class="tc-questions-item"
    >
      <show-block
        :class="showClasses(question, index)"
        :question="question"
        :questions="questions"
      />
      <query-result
        v-if="cards[question.id] !== undefined"
        v-model:card="cards[question.id]"
        :context-filter="ctxMetadata.filter"
        :context-benchmark="ctxMetadata.benchmark"
        :context-compare="ctxMetadata.compare"
        :actions="['editButton','downloadPng']"
        is-in-board
        temporary-card
        :data-download-card="question.id"
        @download-card-png="setupDownloadCardOpts(question.id)"
      />
    </li>
  </ul>
  <BoardAreaSkeleton
    v-else-if="loading"
    :sections-amount="3"
  />
</template>

<script setup>
import * as htmlToImage from 'html-to-image';
import { captureException } from '@/plugins/sentry';
import { CUSTOMER_PLAN_UPGRADE_REASONS } from 'API/customer';
import { questionInShow } from 'Utils/general';
import dynamicImport from 'Utils/dynamicImport';
import QueryResult from 'Components/parts/explore/QueryResult';
import ShowBlock from 'Components/parts/step-form/ShowBlock';
import BoardAreaSkeleton from 'Components/parts/board/BoardAreaSkeleton';
import KeyMetricResult from 'Components/parts/explore/KeyMetricResult';
import gettext from '@/gettext';
import { store } from '@/store';

const { $gettext } = gettext;

const props = defineProps({
  questions: Array,
  responseRate: {
    type: Object,
    default: () => null,
  },
  ctxMetadata: Object,
  cards: Object,
  loading: Boolean,
});

const openPaywall = () => {
  // openPaywall() {
  const GeneralUpgradeDetails = dynamicImport(() => import(/* webpackChunkName: "GeneralUpgradeDetails", webpackPrefetch: true */ 'Components/parts/details/GeneralUpgradeDetails'));
  return store.dispatch('openModal', {
    name: 'GeneralUpgradeDetails',
    component: GeneralUpgradeDetails,
    dataObject: { props: {
      upgradeReason: CUSTOMER_PLAN_UPGRADE_REASONS.unlimited_downloads,
      canStartFreeTrial: store.getters.customerPlanCanStartFreeTrial,
    } },
    size: 'xlarge',
    position: 'center',
  });
};

const showClasses = (question, index) => {
  if (question.show && props.questions[index - 1]) {
    return {
      'has-show-to-previous': questionInShow(
        props.questions[index - 1].id,
        question.show,
      ),
    };
  }
  return false;
};

const excludeClassesFilter = (node) => {
  const exclusionClasses = ['hide-print'];
  return !exclusionClasses.some((classname) => node.classList?.contains(classname));
};

const downloadCard = async (node, options, id) => {
  try {
    const dataUrl = await htmlToImage.toPng(node, options);
    let fileName = props.cards[id].metadata.name || props.cards[id].metadata.graphType.selected || 'chart';
    fileName += `-${id ?? ''}`;
    const link = document.createElement('a');
    link.download = `${fileName}.png`;
    link.href = dataUrl;
    link.click();
    link.remove();
  } catch (error) {
    captureException('[TC] Couldn’t download card:', error);
    store.dispatch('notify', {
      type: 'slow',
      level: 'error',
      text: $gettext('Kunde inte ladda ner cardet.'),
    });
  } finally { store.dispatch('removeDownloadingCard', id); }
};

const setupDownloadCardOpts = (id) => {
  if (props.showPaywalls) {
    store.dispatch('removeDownloadingCard', id ?? null);
    openPaywall();
    return;
  }
  const nodes = document.querySelectorAll(`[data-download-card="${id}"`);
  const node = nodes[nodes.length - 1]; // ? Because every pane adds below in the DOM, so the last one is the one we want
  const options = { filter: excludeClassesFilter, pixelRatio: 2 };
  downloadCard(node, options, id);
};

</script>

<style lang="scss" scoped>

</style>
