import { getBaseUrl, patchUrl, handleServerError } from 'Utils/api';
import { superagentFilter } from 'Utils/apiHelpers';
import { handleAuthError } from 'Utils/authHelpers';

export function get(segment, page_size = 10, filter = {}) {
  return getBaseUrl('/feedback/api/flaggedrespondent/')
    .use(superagentFilter(filter))
    .query({ segment, page_size })
    .then((response) => response.body, handleServerError);
}

export function patch(id, { handled, note } = {}) {
  return patchUrl(`/feedback/api/flaggedrespondent/${id}/`, { handled, note })
    .catch(handleAuthError);
}

export default {
  get,
  patch,
};
