import { has, isEmpty } from 'lodash-es';
import { setupSentryContext } from '@/plugins/sentry';
import apiCustomerUser from 'API/customerUser';
import apiSegment from 'API/segment';
import apiBoards from 'API/boards';
import apiUser from 'API/user';
import { equalLiteral } from 'Utils/general';
import { localStorageIsAvailable } from 'Utils/storage';
import gettext from '@/gettext';

const { $pgettext } = gettext;

// function superagentSource(state) {
//   return (req) => {
//     let request = req;
//     if (has(state.currentSegment, 'id') && state.currentSegment.id > 0) {
//       request = request.query({ segment: state.currentSegment.id });
//     } else if (has(state.customer, 'id') && state.customer.id > 0) {
//       request = request.query({ applicant__customer: state.customer.id });
//     }
//     return request;
//   };
// }

export function setupIntercomContext({ getters }, { segment = {} } = {}) {
  if (window.Intercom) {
    const segmentInfo = segment ? {
      segment_name: segment?.name,
      segment_id: segment?.id,
      ...(has(segment, 'customer') && {
        company: {
          company_id: segment.customer?.id,
          name: segment.customer?.name,
          plan: segment.customer?.plan.plan_type,
        },
      }),
    } : {};

    const formatIntercomName = (user) => {
      if (user.first_name && user.last_name) return `${user.first_name} ${user.last_name}`;
      if (user.first_name) return user.first_name;
      if (user.last_name) return user.last_name;
      return user.email || null;
    };

    let settings = {
      // role: 'user',
      user_id: getters.me.id,
      name: formatIntercomName(getters.me), // Full name
      email: getters.me.email, // Email address
      username: getters.me.username,
      user_type: getters.userType,
      user_role: getters.role,
      is_staff: getters.me.is_staff,
      language_override: document.documentElement.lang || 'en-US',
      ...segmentInfo,
    };

    let filteredSettings = (obj) => {
      let filteredObj = {};
      Object.keys(obj).forEach((prop) => {
        if (obj[prop]) { filteredObj[prop] = obj[prop]; }
      });
      return filteredObj;
    };

    window.intercomSettings = {
      ...window.intercomSettings,
      ...filteredSettings(settings),
    }; // Will 'boot' Intercom in router.afterEach

    if (window.intercomStatus === 1) {
      window.Intercom('update', window.intercomSettings);
      window.intercomStatus = 2; // 2 = sent first metadata
    } else if (window.intercomStatus === 2 && window.intercomSettings.user_type) {
      window.Intercom('update', window.intercomSettings);
      window.intercomStatus = 3; // 3 = sent all late metadata, including userType
    } else if (window.intercomStatus === 3 && window.intercomSettings.segment_id) {
      window.Intercom('update', window.intercomSettings);
      window.intercomStatus = 4; // 4 = made sure segment_id is sent, so routes work properly
    }
  }
}

export const setLoadState = ({ commit }, loading) => {
  commit('SET_LOAD_STATE', loading);
};

export const setBackHistory = ({ commit }, route) => {
  commit('SET_BACK_HISTORY', route);
};

export const setLanguageSettings = ({ commit, dispatch, state }, languageSettings) => {
  if (!isEmpty(state.languageSettings) && !equalLiteral(languageSettings, state.languageSettings)) { // ? Check before setting
    commit('SET_LANGUAGE_SETTINGS', languageSettings);
    dispatch('setBaseTranslations');
  } else {
    commit('SET_LANGUAGE_SETTINGS', languageSettings);
  }
};

export const setCustomerNameTextFilter = ({ commit }, customerName) => {
  commit('ADD_TEXT_FILTER', { customerName });
};

export const fetchMe = ({ commit, dispatch }) => {
  let req = apiUser.me()
    .then((me) => {
      commit('SET_ME', me);
      dispatch('setupIntercomContext', { me });
      return me;
    });
  return req;
};

export const setVisualPreferences = ({ commit }, keyValues) => {
  let req = apiUser.patchVisualPreferences(keyValues)
    .then((me) => {
      if (me) commit('SET_ME', me);
      return me;
    });
  return req;
};
export const setNotificationPreferences = ({ commit }, { prefType, keyValues } = {}) => {
  let req = apiUser.patchNotificationPreferences(prefType, keyValues)
    .then((me) => {
      if (me) commit('SET_ME', me);
      return me;
    });
  return req;
};

export const setupRole = ({ commit, dispatch }, customerId) => {
  let req = apiCustomerUser.me(customerId)
    .then((me) => {
      commit('SET_ROLE', me.role);
      commit('SET_USER_TYPE', 'customerUser');
      dispatch('setupIntercomContext');
    })
    .catch((err) => {
      commit('SET_ROLE', 'member');
      if (err.response?.body?.detail === 'Not found.') commit('SET_USER_TYPE', 'segmentUser');
      dispatch('setupIntercomContext');
    }); // ? Unhandled "planned" 404's for segmentUsers
  return req;
};

/**
 ** setupSegment - If we remove currentSegment, use this
*/
export const setupSegment = ({ dispatch }, router) => {
  dispatch('resetSegment')
    .then(() => dispatch('fetchSegment'))
    .then((segment) => router?.push({ name: 'home', params: { segmentId: segment.id } })
      .catch((err) => {}));
};

export const resetSegment = ({ commit }) => {
  commit('SET_SEGMENT', {});
};

export const setSegment = ({ commit, dispatch }, segment) => {
  commit('RESET_CUSTOM_STRING_TRANSLATIONS');
  commit('RESET_FILTER');
  dispatch('fetchCustomer', segment.customer.id); // ? Sets benchmark options
  dispatch('setupRole', segment.customer.id); // ? Sets me.role before fetchMe
  setupSentryContext(segment);
  if (localStorageIsAvailable) localStorage.setItem('currentSegmentId', segment.id);
  dispatch('setCustomerNameTextFilter', segment.customer.name);
  commit('SET_SEGMENT', segment);
  dispatch('getAllProxies'); // ? CustomerSettingsImportExport depends on this
  // dispatch('fetchTagKeys', true); // ? Heavy load, be sure of it's necessity. Only used in PaneSettingsSegment for customer settings
  dispatch('fetchTagKeys', false);
  dispatch('fetchTopics');
  dispatch('setBaseTranslations');
  dispatch('fetchAllBoardsBySegmentId');
  return segment;
};
export const setSegmentBoard = ({ commit }, overview_board_id) => {
  commit('SET_SEGMENT_BOARD', overview_board_id);
  return overview_board_id;
};

/**
 ** getSegment - Memoized version of fetchSegment
 * @param {number} segmentId
 * @returns {promise} segment
 */
export const getSegment = ({ getters, state, dispatch }, segmentId) => {
  if (typeof segmentId === 'number') {
    if (segmentId === getters.segmentId && !isEmpty(state.currentSegment)) {
      return getters.segment;
    }
    return dispatch('fetchSegment', segmentId);
  }
  return Promise.reject();
};

export const fetchSegment = ({ dispatch }, segmentId) => {
  const useDashboardLanguage = true;
  let p = typeof segmentId === 'number' ? apiSegment.get(segmentId, useDashboardLanguage) : Promise.reject();
  return p.catch(apiSegment.getFirst).then(
    (segment) => {
      if (segment?.customer) {
        dispatch('setSegment', segment);
        return segment;
      }
      throw new Error($pgettext('Error — fetchSegment getFirst', 'Din användare har inte åtkomst till något segment'));
    },
    (err) => Promise.reject(new Error($pgettext('Error — Fetch segment action', 'Kunde inte hämta segment'), err)),
  );
};

export const fetchSegmentById = ({ _ }, segmentId) => {
  let p = typeof segmentId === 'number' ? apiSegment.get(segmentId) : Promise.reject();
  return p.catch(apiSegment.getFirst).then(
    (segment) => segment,
    (err) => Promise.reject(new Error($pgettext('Error — Fetch segment by ID action', 'Kunde inte hämta segment från ID'), err)),
  );
};

export const fetchSegmentByBoardId = ({ dispatch }, boardId) => {
  let p = typeof boardId === 'number' ? apiBoards.get(boardId) : Promise.reject();
  return p
    .then((board) => dispatch('getSegment', parseInt(board.segment, 10)))
    .catch((err) => Promise.reject(new Error($pgettext('Error — Fetch segment by Board action', 'Kunde inte hämta segment från boardID'), err)));
};

// export const fetchSegmentByCardId = ({ commit, dispatch }, cardId) => {
//   let p = typeof cardId === 'number' ? apiCards.get(cardId) : Promise.reject();
//   return p
//      TODO: fetchSegmentBySectionId if we will need this rework it to work by getting it thru SectionId instead
//     .then((card) => dispatch('fetchSegmentByBoardId', parseInt(card.board, 10)))
//     .catch((err) => Promise.reject(new Error($pgettext('Error — Fetch segment by Board action', 'Kunde inte hämta segment från cardID'), err)));
// };
