<template>
  <i
    v-if="active"
    class="zmdi"
    :class="classes"
  />
</template>

<script>
export default {
  props: {
    desc: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'zmdi-caret-up': this.desc,
        'zmdi-caret-down': !this.desc,
      };
    },
  },
};
</script>
