import { markRaw } from 'vue';
import { confirm } from 'Modal/dialogBoxes';
import gettext from '@/gettext';

const { $pgettext } = gettext;

/**
 * @typedef ModalObject
 * @type {object}
 * @prop {string} name – name of modal/component
 * @prop {function|VueComponent} component – a function/import that returns a Vue component
 * @prop {object} [dataObject] – options for the component
 * @prop {string} [dataObject.props] – passed props
 * @prop {string} [dataObject.on] – passed listeners
 * @prop {string} [size] – size of modal. ex: undefined, 'large', 'xlarge'
 * @prop {string} [position] – position of modal. ex: undefined, 'center'
 * @prop {boolean} [confirmExit] – should the user be prompted to confirm exit?
 * @prop {function} [callback] – callback function that is run after closing the modal, returns the popped dataObject
 */
export default {
  state: {
    /** @type {ModalObject[]|[]} */
    openModals: [], // ? An array of open modals as <ModalObject>
    // closedInitialModal: null, // ? A promise that resolves when the initial modal is closed
    modalState: {}, // ? A place for extra information to be passed between modal child components, without the need for props
    hasUnsavedChanges: false, // ? A flag to indicate if there are unsaved changes in the current modal, which will be checked beforeRouteLeave
  },
  getters: {
    activeModal: (state) => (state.openModals.length > 0 ? state.openModals[state.openModals.length - 1] : null),
    allOpenModals: (state) => state.openModals,
    hasUnsavedChanges: (state) => state.hasUnsavedChanges,
  },
  mutations: {
    UPDATE_MODAL_STATE: (state, payload) => { state.modalState = markRaw({ ...state.modalState, ...payload }); },
    RESET_MODAL_STATE: (state) => { state.modalState = {}; },
    OPEN_MODAL: (state, payload) => {
      document.body.style.overflow = 'hidden';
      state.openModals.push(markRaw(payload));
    },
    UPDATE_MODAL: (state, payload) => { state.openModals[state.openModals.length - 1] = markRaw(payload); },
    UPDATE_MODAL_CONFIRMEXIT: (state, { name, confirmExit }) => {
      const modalIndex = state.openModals.findIndex((modal) => modal.name === name);
      if (modalIndex > -1) state.openModals[modalIndex].confirmExit = confirmExit;
    },
    REPLACE_MODAL: (state, payload) => { state.openModals = [markRaw(payload)]; }, // Replaces all open modals
    CLOSE_MODAL: (state, callbackData) => {
      document.body.style.overflow = '';
      const poppedModal = state.openModals.pop();
      if (poppedModal?.callback && typeof poppedModal.callback === 'function') {
        poppedModal.callback({ ...poppedModal.dataObject, ...callbackData });
      }
    },
    CLOSE_ALL_MODALS: (state) => {
      document.body.style.overflow = '';
      state.openModals = [];
    },
    SET_UNSAVED_CHANGES: (state, payload) => { state.hasUnsavedChanges = payload; },
  },
  actions: {
    updateModalState: ({ commit }, payload) => commit('UPDATE_MODAL_STATE', payload),
    resetModalState: ({ commit }) => commit('RESET_MODAL_STATE'),
    openModal: ({ commit }, payload) => commit('OPEN_MODAL', payload),
    updateModal: ({ commit }, payload) => commit('UPDATE_MODAL', payload),
    updateConfirmExit: ({ commit }, { name = '', confirmExit = false }) => commit('UPDATE_MODAL_CONFIRMEXIT', { name, confirmExit }),
    replaceModal: ({ commit }, payload) => commit('REPLACE_MODAL', payload),
    // prevModal: ({ commit }, payload) => commit('PREV_MODAL', payload), // ? Thought for a modal-wizard or so
    // nextModal: ({ commit }, payload) => commit('NEXT_MODAL', payload), // ? Thought for a modal-wizard or so
    closeModal: async ({ commit, state }, { confirmExit = false, callbackData = {} } = {}) => {
      if (confirmExit === true) {
        const title = $pgettext('confirmExit — Leave modal title', 'Lämna utan att spara?');
        const confirmText = $pgettext('confirmExit — Leave modal confirm', 'Ja, lämna utan att spara');
        const dismissText = $pgettext('confirmExit — Leave modal cancel', 'Stanna');
        const confirmed = await confirm({ title, confirmText, dismissText, theme: 'danger' });
        if (confirmed) {
          const closedModal = commit('CLOSE_MODAL', callbackData);
          if (state.openModals.length === 0) commit('RESET_MODAL_STATE');
          return closedModal;
        }
        return null;
      }
      const closedModal = commit('CLOSE_MODAL', callbackData);
      if (state.openModals.length === 0) commit('RESET_MODAL_STATE');
      return closedModal;
    },
    closeAllModals: ({ commit }) => {
      commit('RESET_MODAL_STATE');
      commit('CLOSE_ALL_MODALS');
    },
    setUnsavedChanges: ({ commit }, payload) => {
      commit('SET_UNSAVED_CHANGES', payload);
    },
  },
};
