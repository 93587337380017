import { translateTerm } from 'Utils/general';
import gettext from '@/gettext';

const { $gettext, $pgettext } = gettext;

export const RECRUITMENT_ONLY_STEPS = ['kundstep1'];

export const customStep = () => ({
  name: $pgettext('Name — Step', 'Anpassad'),
  description: $pgettext('Add survey modal - survey description', 'Vill du skicka enkäter till en annan målgrupp? Du kan skicka en anpassad undersökning till dina kunder, konsulter eller vilken annan grupp som helst.'),
  preview_url: 'https://api.trustcruit.com/shinyform/test/2473/134f27df072fa69632f0/',
  step: 'customstep1',
  popular: false,
});

export const hardCodedSurveys = () => [
  {
    name: $gettext('Ansökan'),
    description: $pgettext('Add survey modal - survey description', 'Ta reda på vad dina kandidater tycker om din ansökningsprocess.'),
    preview_url: 'https://api.trustcruit.com/shinyform/test/2463/41dbd82c1d3399e3ee0f/',
    step: 'step1',
    stepDiff: false,
    popular: false,
  },
  {
    name: $gettext('Employer branding'),
    description: $pgettext('Add survey modal - survey description', 'Detta mäter hur ditt varumärke uppfattas av sökande och vad du bör fokusera på inom ditt arbetsgivarvarumärke.'),
    preview_url: 'https://api.trustcruit.com/shinyform/test/2471/2899eebe44e3e6083fcc/',
    step: 'step1',
    stepDiff: 'employerbrandingstep1',
    popular: true,
  },
  {
    name: $gettext('Intervju'),
    description: $pgettext('Add survey modal - survey description', 'Vill du förbättra din intervjuprocess? Ta reda på vad dina kandidater tycker.'),
    preview_url: 'https://api.trustcruit.com/shinyform/test/2464/ed12d4755a2b066048ce/',
    step: 'step2',
    stepDiff: false,
    popular: false,
  },
  {
    name: $gettext('Anställd'),
    description: $pgettext('Add survey modal - survey description', 'Vad tycker de kandidater som får jobbet om din rekryteringsprocess?'),
    preview_url: 'https://api.trustcruit.com/shinyform/test/2467/5e1fcf0500037b47b10b/',
    step: 'step3',
    stepDiff: false,
    popular: false,
  },
  {
    name: $gettext('Onboarding'),
    description: $pgettext('Add survey modal - survey description', 'En bra introduktionsprocess är avgörande för nya medarbetare. Hur upplevde dina senaste anställda sin introduktion?'),
    preview_url: 'https://api.trustcruit.com/shinyform/test/2465/5f0debb02003fef51c6f/',
    step: 'step4',
    stepDiff: false,
    popular: true,
  },
  {
    name: $gettext('Avslag'),
    description: $pgettext('Add survey modal - survey description', 'Vad tycker avvisade kandidater om din rekryteringsprocess?'),
    preview_url: 'https://api.trustcruit.com/shinyform/test/2466/59f7b951b8052c0c13d6/',
    step: 'step5',
    stepDiff: false,
    popular: true,
  },
  {
    name: $gettext('Avslag efter intervju'),
    description: $pgettext('Add survey modal - survey description', 'Vad tycker avvisade kandidater som deltog i minst en intervju om din rekryteringsprocess?'),
    preview_url: 'https://api.trustcruit.com/shinyform/test/2472/d75bcce58df37ffc94d7/',
    step: 'step5',
    stepDiff: 'step5.1',
    popular: false,
  },
  {
    name: $gettext('Hiring manager'),
    description: $pgettext('Add survey modal - survey description', 'Samarbetet mellan rekryteringsteamet och rekryterande chef är viktigt. Ta reda på vad rekryterande cheferna tycker ni kan förbättra.'),
    preview_url: 'https://api.trustcruit.com/shinyform/test/2470/f28887e7a97da8f84fbb/',
    step: 'hiringmanagerstep1',
    stepDiff: false,
    popular: false,
  },
  {
    name: 'Quality of hire',
    description: $pgettext('Add survey modal - survey description', 'Detta mäter kvalitén av en rekrytering genom att ta reda på hur den nyanställde uppfyller kraven för tex erfarenhet och kompetens, anpassningsbarhet till företagskulturen och teamet samt hur väl den nyanställde presterar i sin roll.'),
    preview_url: 'https://api.trustcruit.com/shinyform/test/2776/16cb412f06d37369f3d2/',
    step: 'qualityofhirestep1',
    stepDiff: false,
    popular: false,
  },
  {
    name: 'Exit',
    description: $pgettext('Add survey modal - survey description', 'Det gör ont att se dina anställda lämna. Varför slutade de och kan du förbättra något för att förhindra att andra också slutar?'),
    preview_url: 'https://api.trustcruit.com/shinyform/test/2468/6848177831776df6e6e8/',
    step: 'exitstep1',
    stepDiff: false,
    popular: false,
  },
  {
    name: $gettext('Medarbetare'),
    description: $pgettext('Add survey modal - survey description', 'Du bryr dig om dina anställda. Vet du hur de känner om dig?'),
    preview_url: 'https://api.trustcruit.com/shinyform/test/2469/65f4ee7772cb87ed9886/',
    step: 'employeestep1',
    stepDiff: false,
    popular: false,
  },
  {
    name: $gettext('Kundnöjdhet'),
    description: $pgettext('Add survey modal - survey description', 'Du har anställt personal åt en kund. Är de nöjda med din insats?'),
    preview_url: 'https://app.trustcruit.com/shinyform/test/2518/00664d8d5ca2f53ebee9/',
    step: 'kundstep1',
    stepDiff: false,
    popular: false,
  },
  customStep(),
];

const sortSurveys = (surveys) => surveys.sort((a, b) => {
  const aIndex = hardCodedSurveys().findIndex((survey) => survey.step === a);
  const bIndex = hardCodedSurveys().findIndex((survey) => survey.step === b);
  if (aIndex === -1 && bIndex === -1 || a === b) return translateTerm(a).localeCompare(translateTerm(b));
  if (aIndex === -1) return 1;
  if (bIndex === -1) return -1;
  return aIndex - bIndex;
});

export const getSurveysInOrder = (surveys) => {
  const surveysInHardCoded = surveys.filter(
    (survey) => hardCodedSurveys().find((hardCodedSurvey) => hardCodedSurvey.step === survey),
  );
  const surveysNotInHardCoded = surveys.filter(
    (survey) => !hardCodedSurveys().find((hardCodedSurvey) => hardCodedSurvey.step === survey),
  );

  return [...sortSurveys(surveysInHardCoded), ...sortSurveys(surveysNotInHardCoded)];
};
