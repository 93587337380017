<template>
  <nav
    id="ariaNavSegmentSelect"
    class="nav-segment-select"
    :aria-label="$pgettext('ARIA - Segment nav', 'Segmentväljare')"
  >
    <div
      v-if="segmentAmount > 1"
      class="inner"
    >
      <p class="mb-0">
        {{ $pgettext('Label — SegmentSelect choosable', 'Visa data för') }}
      </p>
      <button
        id="ariaNavSegmentSelectBtn"
        v-tooltip.right="{
          container: 'body',
          strategy: 'fixed',
          content: () => $gettext('Välj segment'),
        }"
        class="tc-select-control tc-select--fake cursor-pointer btn-block"
        tabindex="0"
        :title="`${customerName} — ${segmentName}`"
        aria-haspopup
        :aria-expanded="isOpen"
        :aria-label="isOpen
          ? $pgettext('ARIA - SegmentSelect Btn isOpen', 'Stäng segmentväljaren')
          : $pgettext('ARIA - SegmentSelect Btn !isOpen', 'Öppna segmentväljaren')"
        aria-controls="ariaNavSegmentSelectPopup"
        @keydown.left="selectListEl?.paginatePrev()"
        @keydown.right="selectListEl?.paginateNext()"
        @keyup.enter="openDropdown"
        @click.stop="toggleDropdown"
      >
        <span class="segment-name">{{ segmentName }}</span>
        <i class="zmdi zmdi-unfold-more" />
      </button>
    </div>
    <div
      v-else
      class="inner readonly"
    >
      <p class="mb-0">
        {{ $pgettext('Label — SegmentSelect disabled', 'Visar data för') }}
      </p>
      <h3
        v-tooltip.right="{
          container: 'body',
          strategy: 'fixed',
          content: () => $pgettext('Tooltip - Segment select', 'Du har bara ett segment att välja bland'),
        }"
        class="readonly"
      >
        {{ segmentName }}
      </h3>
    </div>
  </nav>

  <Teleport
    v-if="isOpen"
    to="#teleport-outer-space"
  >
    <div
      id="ariaNavSegmentSelectPopup"
      ref="dropdownEl"
      class="nav-aside-popout-top tc-dropdown"
      :class="{ 'active': isOpen, 'sidebarToggled': sidebarToggled }"
      role="region"
      aria-labelledby="ariaNavSegmentSelect"
      tabindex="0"
      @keydown.right.stop="selectListEl?.paginateNext()"
      @keydown.left.stop="selectListEl?.paginatePrev()"
      @click.stop
    >
      <SegmentSelectList
        ref="selectListEl"
        :dropdown-is-open="isOpen"
        @update:segment="closeDropdown"
      />
    </div>
  </Teleport>
</template>

<script setup>
import { computed, ref, nextTick } from 'vue';
import SegmentSelectList from 'Components/parts/segment/SegmentSelectList';
import { store } from '@/store';

const props = defineProps({
  sidebarToggled: {
    type: Boolean,
  },
});

const dropdownEl = ref(null);
const selectListEl = ref(null);

const isOpen = ref(false);

const customerName = computed(() => store.getters.customerName);
const segmentName = computed(() => store.getters.segmentName);
const segmentAmount = computed(() => store.getters.me.segments?.length ?? NaN);

const getSize = () => {
  if (!dropdownEl.value) return;
  if (store.getters.me?.visual_preferences?.segmentSelectWidth != null) { // possible: 205 – 612 || null
    dropdownEl.value.style.width = `${store.getters.me?.visual_preferences.segmentSelectWidth}px`;
  }
};
const setSize = (segmentsLength = segmentAmount.value) => {
  // ! Fails if setting size when segmentsCount still isn’t available
  if (!dropdownEl.value) return;
  if (store.getters.me?.visual_preferences?.segmentSelectWidth == null
    && !Number.isNaN(segmentsLength)
    && segmentsLength <= 15) {
    store.dispatch('setVisualPreferences', { segmentSelectWidth: (dropdownEl.value.clientWidth / 2) + 1 });
  } else {
    store.dispatch('setVisualPreferences', { segmentSelectWidth: dropdownEl.value.clientWidth + 1 });
  }
};

const addCloseHandler = (payload) => store.dispatch('addCloseHandler', payload);
const removeCloseHandler = (payload) => store.dispatch('removeCloseHandler', payload);
const removeAllCloseHandlers = () => store.dispatch('removeAllCloseHandlers');
const closeHandler = () => {
  isOpen.value = false;
  if (dropdownEl.value) setSize();
};

const openDropdown = () => {
  removeAllCloseHandlers();
  isOpen.value = true;
  nextTick(() => {
    if (dropdownEl.value) {
      getSize();
      setSize();
    }
  });
  addCloseHandler(closeHandler);
};
const closeDropdown = () => {
  isOpen.value = false;
  removeCloseHandler(closeHandler);
};
const toggleDropdown = () => {
  if (isOpen.value) closeDropdown();
  else openDropdown();
};
</script>
