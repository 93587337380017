<template>
  <thead class="tc-table-head">
    <tr>
      <th
        v-for="(header, index) in headers"
        :key="header"
        scope="col"
        :class="header.sortFn ? 'cursor-pointer' : ''"
        tabindex="0"
        role="columnheader"
        :aria-sort="getAriaSort(index)"
        @click="header.sortFn && sortColumn(header.sortFn, index)"
        @keydown.enter="header.sortFn && sortColumn(header.sortFn)"
        @keydown.space="header.sortFn && sortColumn(header.sortFn)"
      >
        {{ header.label }}
        <span v-if="sortConfig.column === index">
          <sort-icon
            :active="sortConfig.column === index"
            :desc="sortConfig.order === 'desc'"
          />
        </span>
      </th>
    </tr>
  </thead>
</template>

<script setup>
import SortIcon from 'Components/parts/icons/SortIcon';

const props = defineProps({
  headers: Array,
  rows: Array,
  sortConfig: Object,
});
const emit = defineEmits(['update:sort']);

const sortColumn = (sortable, index) => {
  if (!sortable) return;
  if (props.sortConfig.column === index) {
    const order = props.sortConfig.order === 'asc' ? 'desc' : null;
    const column = order ? index : null;
    emit('update:sort', {
      column,
      order,
    });
  } else {
    emit('update:sort', {
      column: index,
      order: 'asc',
    });
  }
};

const getAriaSort = (index) => {
  if (props.sortConfig.column === index) {
    return props.sortConfig.order === 'asc' ? 'ascending' : 'descending';
  }
  return 'none';
};
</script>
