<template>
  <ul
    v-if="badgeList.length > 0"
    class="badge-list plain-list"
  >
    <template v-if="type === 'filter'">
      <badge-list-item-compare
        v-for="(item, index) in shortenedBadgeListCompare"
        :key="item.slug+index"
        :item="item"
        @click="$emit('clicked-badge-list', item?.slug || null)"
      />
      <badge-list-item-filter
        v-for="(item, index) in shortenedBadgeListFilter"
        :key="item.slug+index"
        :item="item"
        :removeable="checkIfRemovable(item)"
        :force-tooltips="forceTooltips"
        @click="$emit('clicked-badge-list', item?.slug || null)"
      />
      <badge-list-item-benchmark
        v-for="(item, index) in shortenedBadgeListBenchmarks"
        :key="item.slug+index"
        :item="item"
        :force-tooltips="forceTooltips"
        @click="$emit('clicked-badge-list', item?.slug || null)"
      />
    </template>
    <template v-else-if="type === 'tag'">
      <badge-list-item-tag
        v-for="(item, index) in shortenedBadgeList"
        :key="item.slug+index"
        :item="item"
        :clickable="$route.name === 'applicants'"
        :force-tooltips="forceTooltips"
        @click="$emit('clicked-badge-list', item?.slug || null)"
      /><!--<li class="badge badge-tag badge-grid">
        <span class="badge-tag-more">Mer...</span>
        <!- Idea, make the "Mer..." into the first three letters of the upcoming tagKey instead ->
      </li>-->
    </template>
    <template v-else-if="type === 'benchmark'">
      <badge-list-item-benchmark
        v-for="(item, index) in shortenedBadgeList"
        :key="item.slug+index"
        :item="item"
        :force-tooltips="forceTooltips"
        @click="$emit('clicked-badge-list', item?.slug || null)"
      />
    </template>
  </ul>
</template>

<script>
import { isEmpty, isEqual } from 'lodash-es';
import { mapGetters } from 'vuex';
import { KEY_METRIC_ALLOWLIST_LEVELS } from '@/utils/graph';
import BadgeListItemCompare from './BadgeListItemCompare';
import BadgeListItemFilter from './BadgeListItemFilter';
import BadgeListItemTag from './BadgeListItemTag';
import BadgeListItemBenchmark from './BadgeListItemBenchmark';

export default {
  name: 'BadgeList',
  components: {
    BadgeListItemCompare,
    BadgeListItemFilter,
    BadgeListItemTag,
    BadgeListItemBenchmark,
  },
  props: {
    badgeList: {
      type: Array,
      default: () => ([]),
    },
    forceTooltips: {
      type: Boolean,
      default: false,
    },
    sortFn: {
      type: [Function, Boolean],
      default: false,
      validator: (s) => s !== true,
    },
    shortlist: {
      type: [Boolean, String, Number],
      default: false,
    },
    type: {
      type: String,
      default: 'filter',
      validator: (value) => ['filter', 'tag', 'benchmark'].includes(value),
    },
  },
  emits: ['clicked-badge-list'],
  data() {
    return {
      resolvedBadgeList: [],
    };
  },
  computed: {
    ...mapGetters([
      'fetchingFilters',
      'modalCard',
    ]),
    sortedBadgeList() {
      if (this.sortFn === false) return this.resolvedBadgeList;
      return [...this.resolvedBadgeList].sort((a, b) => this.sortFn(a.label, b.label));
    },
    shortenedBadgeList() {
      return this.sortedBadgeList.filter((a, i) => (this.shortlist === false ? true : i < this.shortlist));
    },
    shortenedBadgeListCompare() {
      const compareBadge = this.shortenedBadgeList.find((item) => item.slug === 'compare' || item.slug === 'compare__tag') || {};
      return isEmpty(compareBadge) ? [] : [compareBadge];
    },
    shortenedBadgeListFilter() {
      return this.shortenedBadgeList.filter((item) => item.slug.split('__')[0] !== 'benchmark' && item.slug !== 'compare' && item.slug !== 'compare__tag');
    },
    shortenedBadgeListBenchmarks() {
      return this.shortenedBadgeList.filter((item) => item.slug.split('__')[0] === 'benchmark');
    },
  },
  watch: {
    badgeList: {
      immediate: true,
      deep: true,
      async handler(newVal, oldVal) {
        if (newVal !== undefined && !isEqual(newVal, oldVal)) {
          this.resolvedBadgeList = await this.resolveBadgeList(newVal);
        }
      },
    },
  },
  methods: {
    checkIfRemovable(item) {
      if (isEmpty(this.modalCard)) return true;
      if (item.slug === 'step' && KEY_METRIC_ALLOWLIST_LEVELS?.[this.modalCard.metadata.graphType.selected]?.stepRequired) return false;
      return true;
    },
    async resolveBadgeList(badgeList = this.badgeList) {
      return Promise.all(badgeList.map(async (badge) => badge));
    },
  },
};
</script>
