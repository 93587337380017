<template>
  <div class="tc-card-wrapper">
    <div
      v-tc-loader-bar="loading"
      class="tc-card"
      :tabindex="isDraggable ? 0 : null"
      :class="{ folded: false, 'is-draggable': (isEditMode && isDraggable), 'tc-card-overlayable': colClass }"
    >
      <div
        v-if="$slots.header"
        class="tc-card-header"
        :class="headerClasses && customCardClass"
      >
        <div
          v-if="isEditMode && isDraggable"
          class="draghandle-wrapper"
        >
          <div class="draghandle" />
        </div>
        <slot name="header" />
        <slot name="actions" />
      </div>
      <div
        class="tc-card-body relative"
        :class="{ bodyClass }"
      >
        <slot name="body">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </slot>
        <transition name="fade">
          <div
            v-if="enableOverlay"
            v-show="isOverlayed"
            v-tc-loader-spinner="loadingSpinner"
            class="tc-card-overlay hide-print"
          >
            <card-size-buttons
              :col-class="colClass"
              @update:col-class="changeColClass"
            />
          </div>
        </transition>
      </div>
      <footer class="tc-card-footer tc-card-flex">
        <slot
          name="footer"
        />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CardSizeButtons from './board/CardSizeButtons';

export default {
  name: 'Card',
  components: {
    CardSizeButtons,
  },
  props: {
    customCardClass: String,
    colClass: {
      default: '',
      type: String,
    },
    foldedOnload: {
      type: Boolean,
      default: false,
    },
    foldable: Boolean,
    loadingSpinner: Boolean,
    loading: Boolean,
    bodyClass: String,
    isDraggable: Boolean,
    isOverlayed: Boolean,
    enableOverlay: Boolean,
  },
  emits: ['toggle', 'update:col-class'],
  data() {
    return {
      folded: this.foldedOnload,
    };
  },
  computed: {
    ...mapGetters(['isEditMode']),
    headerClasses() {
      return {
        'tc-card-header-clickable': this.foldable,
        'tc-card-header-has-actions': this.hasActions,
      };
    },
    hasActions() {
      return this.$slots.actions !== undefined;
    },
  },
  methods: {
    toggleFold() {
      this.folded = !this.folded;
      this.$emit('toggle', this.folded);
    },
    changeColClass(colClass) {
      this.$emit('update:col-class', colClass);
    },
  },
};
</script>
