import { generateJSON, generateHTML } from '@tiptap/core';
import { captureException } from '@/plugins/sentry';
import config from 'Components/parts/widgets/TipTap/config';
import plugins from 'Components/parts/widgets/TipTap/plugins';

const escaper = {
  escape: (str) => str.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;')
    .replaceAll("'", '&#039;'),
  unescape: (str) => str.replaceAll('&amp;', '&').replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&quot;', '"')
    .replaceAll('&#039;', "'"),
};

/**
 * Takes a TipTap JSON doc and sanitizes/unsanitizes all potential dangerous chars in text content
 * @param {*} doc - TipTap document
 * @param {('escape' | 'unescape')} op - Operation
 * @returns {*} - The sanitized TipTap document
 */
const sanitizer = (doc, op) => {
  try {
    const html = generateHTML(doc, [
      ...config.extensions,
      plugins.TCLink(null), // pass null as we dont need the routing functionality
    ]);

    const nodes = new DOMParser().parseFromString(html, 'text/html').body.childNodes;

    // Finds and sanitizes text nodes by recursive traversal
    const traverseAndSanitizeNodes = (_nodes) => _nodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) node.textContent = escaper[op](node.textContent);
      else if (node.childNodes) traverseAndSanitizeNodes(node.childNodes);
    });

    traverseAndSanitizeNodes(nodes);

    const sanitizedHTML = [...nodes].map((n) => n.outerHTML).join('');

    return generateJSON(sanitizedHTML, [...config.extensions, plugins.TCLink(null)]);
  } catch (err) {
    console.error(err);
    captureException(new Error(`[TipTap/utils]: sanitization error on ${op}`));
    // If anything fails, just return original content
    // (if dangerous chars are included, unsanitized payload will probably fail in BE validation anyway)
    return doc;
  }
};

const sanitizeDoc = (doc) => sanitizer(doc, 'escape');
const unSanitizeDoc = (doc) => sanitizer(doc, 'unescape');

export default {
  sanitizeDoc,
  unSanitizeDoc,
};
