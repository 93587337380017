<template>
  <div class="tc-datepicker-block">
    <tc-date-picker
      v-if="dateObjectExists"
      ref="tcDatePicker"
      v-model:dateObject="selectedDate"
      :exclude-predefined="excludePredefined"
      :disable-future-dates="disableFutureDates"
      :empty-allowed="emptyAllowed"
      :disabled="disabled"
      @update:date-object="update"
      @update:date-span="update"
    />
    <!-- :popper-settings="{ strategy: 'absolute' }" -->
    <span class="tc-select-arrow-wrapper cursor-pointer datepicker-block-arrow">
      <i class="zmdi zmdi-chevron-down" />
    </span>
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es';
import { equalLiteral } from 'Utils/general';
import TcDatePicker from 'Components/parts/TcDatePicker';

export default {
  name: 'DateFilter',
  components: {
    TcDatePicker,
  },
  props: {
    excludePredefined: {
      type: Array,
      default() {
        return [];
      },
    },
    dateObject: {
      type: Object,
      default() {
        return {};
      },
    },
    emptyAllowed: {
      type: Boolean,
      default: false,
    },
    disableFutureDates: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open', 'update:date-object', 'update:date-span'],
  data() {
    return {
      selectedDate: {},
      dateObjectExists: false,
    };
  },
  watch: {
    dateObject: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (!equalLiteral(newVal, oldVal)) {
          this.setup(newVal);
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$nextTick(() => this.setup(this.dateObject));
    });
  },
  methods: {
    closeDatePicker() {
      if (this.$refs?.tcDatePicker) this.$refs.tcDatePicker.closeDatePicker();
    },
    update(payload) {
      const emitType = payload.startDate ? 'date-span' : 'date-object';
      if (!isEmpty(payload)) this.$emit(`update:${emitType}`, payload);
    },
    setup(dateObject) {
      this.selectedDate = dateObject;
      this.dateObjectExists = !!this.dateObject;
    },
  },
};
</script>
