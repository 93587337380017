<template>
  <div class="page-content">
    <h5
      v-if="placeholderMessage"
      class="page-content-message"
      :class="{ 'page-content-message-sm': placeholderSize === 'sm' }"
    >
      <slot name="placeholder">
        {{ placeholderMessage === true ? '&nbsp;' : placeholderMessage }}
      </slot>
    </h5>
    <router-link
      v-if="cardType !== 'hide'"
      v-slot="{ navigate, href }"
      custom
      :to="cta && cta.routerLinkTo || {}"
    >
      <component
        :is="cta && cta.routerLinkTo ? 'a' : 'div'"
        :role="cta && cta.routerLinkTo ? 'link' : null"
        :title="cta && cta.title || cta && cta.headline || null"
        :tabindex="cta && cta.routerLinkTo || cta && cta.action ? 0 : -1"
        class="tc-card-wrapper"
        :class="{ 'cursor-pointer': cta && cta.action }"
        :href="cta && cta.routerLinkTo ? href : null"
        @click.prevent.stop="cta.action ? cta.action($event) : navigate()"
        @keypress.enter="cta.action ? cta.action($event) : navigate()"
      >
        <div
          class="tc-card"
          :class="`placeholder-${cardType}`"
        >
          <slot name="card">
            <i
              v-if="cardIcon"
              class="zmdi zmdi-hc-2x"
              :class="cardIcon"
            />
            <h5
              v-if="cardMessage"
              class="message medium-text"
              :class="{ 'mb-2': !cta }"
            >
              {{ cardMessage }}
            </h5>
            <div
              v-if="cta"
              class="btn-group"
            >
              <button class="btn btn-toolbar btn-icon-text">
                <i
                  class="zmdi"
                  :class="cta.icon"
                />
                <span>{{ cta.headline }}</span>
              </button>
            </div>
          </slot>
        </div>
      </component>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    placeholderMessage: {
      type: String,
      default: 'Default placeholder-message',
    },
    placeholderSize: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'sm'].indexOf(value) !== -1;
      },
    },
    cardType: {
      type: String,
      default: 'card',
      validator(value) {
        return ['hide', 'card', 'row'].indexOf(value) !== -1;
      },
    },
    cardIcon: {
      type: String,
      default: 'zmdi-alert-circle-o',
    },
    cardMessage: {
      type: String,
      default: 'Default card-message',
    },
    cta: {
      type: [Object, Boolean],
      default() {
        return {
          icon: 'zmdi-plus',
          headline: 'Default cta.headline',
          title: 'Default cta.title',
          routerLinkTo: false, // router-link object. Ex: { name: 'explore', params: { returnToBoard: board.id } }
          action: false, // Function instead of link. Ex: prompt('Title', 'Confirm');
        };
      },
      validator(cta) {
        if (cta === false) return true; // Hide cta
        if (!cta.routerLinkTo && !cta.action) return true;
        if (typeof cta.action === 'function') return true;
        if (typeof cta.routerLinkTo === 'object') return true;
        return false;
      },
    },
  },
};
</script>
