import { getAllSizes, getAllIndustries, getAllCountries } from 'API/benchmarks';
import { wrapPropsIntoLabelValues } from 'Utils/tcselectHelpers';
import gettext from '@/gettext';

const { $pgettext } = gettext;

/** @enum
 * @property {string} size
 * @property {string} location
 * @property {string} industry
*/
export const BENCHMARK_ICONS = {
  sector: 'zmdi-chart-donut',
  size: 'zmdi-company-size',
  industry: 'zmdi-industry',
  location: 'zmdi-pin',
  global: 'zmdi-globe',
  company: 'zmdi-city',
};

export const BENCHMARK_SECTORS = () => ({
  governmental: $pgettext('Sector', 'Offentlig verksamhet'),
  privately_held: $pgettext('Sector', 'Privat'),
  recruitment: $pgettext('Sector', 'Rekrytering & Bemanning'),
});

export const BENCHMARK_DIMENSIONS = {
  sector: 'sectors',
  size: 'sizes',
  industry: 'industries',
  location: 'locations',
};

export const BENCHMARK_DIMENSIONS_FETCH_FN = {
  sectors: async () => wrapPropsIntoLabelValues(BENCHMARK_SECTORS()),
  sizes: getAllSizes,
  industries: getAllIndustries,
  locations: getAllCountries,
};

export default {
  BENCHMARK_ICONS,
  BENCHMARK_SECTORS,
  BENCHMARK_DIMENSIONS,
  BENCHMARK_DIMENSIONS_FETCH_FN,
};
