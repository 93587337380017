<template>
  <div>
    <div class="page-content-title">
      <span class="h2">
        <i class="zmdi zmdi-forms-page" />
      </span>
      <span>
        <h2>{{ $gettext('Alla formulär') }}</h2>
      </span>
    </div>
    <hr>
    <div class="flex flex-apart">
      <compare-date-filter
        class="my-3"
        :date-label="dateLabel"
        :date-icon="null"
      />
      <TcToggleIcon
        :model-value="currentView"
        :states="{
          card: 'zmdi-view-module',
          table: 'zmdi-view-list',
        }"
        :tooltips="{
          card: $gettext('Visa som kort'),
          table: $gettext('Visa som lista'),
        }"
        @update:model-value="setCurrentView"
      />
    </div>

    <div
      v-if="isTable"
      class="form-group"
    >
      <label
        for="hasStatus"
      >Status</label>
      <chip-set
        v-model="showStepByStatus"
        type="choice"
        :outline="true"
      >
        <chip
          key="0"
          value="0"
        >
          <span>{{ $gettext('Alla') }}</span>
        </chip>
        <chip
          key="1"
          value="1"
        >
          <span>{{ $gettext('Visa endast aktiva') }}</span>
        </chip>
      </chip-set>
    </div>

    <placeholder-card
      v-if="store.getters.customerAllSteps?.length === 0 && !customerAndFormsLoading"
      :placeholder-message="$pgettext(
        'Message — Stepforms placeholder msg',
        'Det finns ingen data här än'
      )"
      placeholder-size="sm"
      card-icon="zmdi-help-outline"
      :card-message="$pgettext(
        'Message — Stepforms card msg',
        'Vänta tills ni fått svar på något formulär eller byt segment'
      )"
      :cta="false"
    />
    <StepFormTable
      v-else-if="isTable"
      :hide-inactive-steps="showStepByStatus === '1'"
      :all-steps="store.getters.customerAllSteps"
      :getting-stats="gettingStats"
      :stats="stats"
      :global-stats="globalStats"
      :proxy-stats="proxyStats"
      :tiny-forms="tinyForms"
    />
    <step-form-list
      v-else
      class="tc-loader-bar-wrapper"
      :all-steps="store.getters.customerAllSteps"
      :getting-stats="gettingStats"
      :stats="stats"
      :global-stats="globalStats"
      :tiny-forms="tinyForms"
      :tiny-page-forms-page-size="tinyPageFormsPageSize"
    />
  </div>
</template>

<script setup>
import { watch, computed, ref } from 'vue';
import { isArray } from 'lodash-es';
import { requestData } from 'API/data';
import { getKeyByDate } from 'Utils/date';
import useSurveyList from 'Composables/useSurveyList';
import PlaceholderCard from 'Components/parts/PlaceholderCard';
import CompareDateFilter from 'Components/parts/filters/CompareDateFilter';
import StepFormTable from 'Components/parts/step-form/StepFormTable';
import TcToggleIcon from 'Components/parts/TcToggleIcon';
import StepFormList from 'Components/parts/step-form/StepFormList';
import Chip from 'Components/parts/chips/Chip';
import ChipSet from 'Components/parts/chips/ChipSet';
import { store } from '@/store';
import gettext from '@/gettext';

const {
  hardCodedSurveysSorted,
  isLoadingForms,
  allSurveysGroupedPerLevel,
} = useSurveyList();

const availableSurveys = computed(() => {
  const filteredSurveys = hardCodedSurveysSorted.value
    .filter((survey) => store.getters.customerAllSteps.includes(survey.step))
    .map((survey) => survey.step);

  const missingSteps = store.getters.customerAllSteps
    .filter((step) => !hardCodedSurveysSorted.value.some((survey) => survey.step === step))
    .map((step) => step);

  return [...filteredSurveys, ...missingSteps];
});

const { $gettext, $pgettext } = gettext;

const currentView = ref(store.getters.me?.visual_preferences?.surveyPageView ?? 'table'); // possible: table || card
const setCurrentView = (view) => {
  currentView.value = view;
  store.dispatch('setVisualPreferences', { surveyPageView: view });
};
const isTable = computed(() => currentView.value === 'table');

const showStepByStatus = ref('1');

const dateLabel = computed(() => {
  const { date } = store.getters.segmentFilter;
  if (getKeyByDate(date) === 'this_year' || getKeyByDate(date) === 'all_time') return $pgettext('dateLabel (this_year & all_time) — StepForms', 'Visa data för');
  if (date.type === 'relative') return $pgettext('dateLabel (type: relative) — StepForms', 'Visa data för');
  return $pgettext('dateLabel (type: absolute)', 'Visa data för');
});

const stats = ref(null);
const globalStats = ref(null);
const gettingStats = ref(true);

const getGlobalQuery = (step) => {
  const { date } = store.getters.segmentFilter;
  return [{
    filter: {
      step: { name: step },
      date,
    },
  }];
};
const getQuery = (step) => {
  const { date } = store.getters.segmentFilter;
  return [{ filter: {
    step: { name: step },
    date,
    segment: { segment_id: store.getters.segmentId },
    customer_proxy: { customer_id: store.getters.customerId },
  } }];
};

const responseGlobalRequest = (step) => requestData(getGlobalQuery(step), 'response/global/').then((r) => r?.[0] || []);
const responseRequest = (step) => requestData(getQuery(step), 'response/').then((r) => r?.[0] || []);

const proxyStats = ref({});
const getProxyPayload = () => {
  const { date } = store.getters.segmentFilter;
  return Object.values(allSurveysGroupedPerLevel.value).flatMap((level) => level.map((survey) => ({
    filter: {
      date,
      customer_proxy: { customer_proxy_id: survey.customer_proxy_id },
      segment: { segment_id: store.getters.segmentId },
      step: {
        name: survey.level,
      },
    },
  })));
};
const getStatsProxies = async () => {
  const payload = getProxyPayload();
  try {
    const response = await requestData(payload, 'response/');
    payload.forEach((f, index) => {
      if (response.length) {
        const proxyId = f.filter.customer_proxy.customer_proxy_id;
        const stepName = f.filter.step.name;
        proxyStats.value = {
          ...proxyStats.value,
          [proxyId]: {
            ...proxyStats.value[proxyId],
            [stepName]: response[index] || {},
          },
        };
      }
    });
  } catch (error) {
    console.error(`[TC] ${error}`); // eslint-disable-line no-console
  }
};
const tinyPageFormsPageSize = 10;
const tinyForms = ref({});
const getTinyForms = async (active) => {
  try {
    let promiseAll = store.getters.customerAllSteps.map((step) => store.dispatch('fetchFormsByLevel', { level: step, tiny: true, pageSize: tinyPageFormsPageSize, ...(active && { active: true }) }).then((r) => {
      tinyForms.value = {
        ...tinyForms.value,
        [step]: r,
      };
    }));
    await Promise.all(promiseAll);
  } catch (error) {
    console.error(`[TC] ${error}`); // eslint-disable-line no-console
  }
};

const getStats = async (filter, step) => {
  if (store.getters.segmentId && step) {
    try {
      let promises = [];
      promises.push(responseRequest(step));
      promises.push(responseGlobalRequest(step));
      return await Promise.all(promises);
    } catch (error) {
      console.error(`[TC] ${error}`); // eslint-disable-line no-console
    }
  }
  return Promise.resolve();
};
const setStats = (stat, step) => {
  if (!stats.value?.[step]) stats.value = { ...stats.value, [step]: {} };
  if (!globalStats.value?.[step]) globalStats.value = { ...globalStats.value, [step]: {} };
  stats.value[step] = stat?.[0] || null;
  globalStats.value[step] = stat?.[1] && !isArray(stat[1]) ? stat[1] : null;
  return stat;
};

const go = async (filter) => {
  stats.value = null;
  gettingStats.value = true;
  try {
    let promiseAll = availableSurveys.value
      .map((survey) => getStats(filter, survey)
        .then((stat) => setStats(stat, survey)));
    await Promise.all([promiseAll, getStatsProxies(), getTinyForms(showStepByStatus.value === '1')]);
  } catch (error) {
    console.error(`[TC] ${error}`); // eslint-disable-line no-console
  } finally {
    gettingStats.value = false;
  }
};

const initialRequest = ref(true);
const customerAndFormsLoading = computed(() => store.state.customer.fetchingCustomer || isLoadingForms.value);
if (customerAndFormsLoading.value) {
  watch(() => customerAndFormsLoading.value, (newVal, oldVal) => {
    if (!initialRequest.value) return;
    if (!newVal) {
      go(store.getters.segmentFilter);
      initialRequest.value = false;
    }
  });
} else {
  go(store.getters.segmentFilter);
  initialRequest.value = false;
}

watch(() => store.getters.segmentFilter, (newVal, oldVal) => {
  if (!initialRequest.value && (newVal.date !== oldVal.date || newVal.segmentId !== oldVal.segmentId)) go(newVal);
});

watch(() => showStepByStatus.value, (newVal, oldVal) => {
  if (newVal === oldVal) return;
  if (!initialRequest.value) getTinyForms(newVal === '1');
});

</script>
