<template>
  <div>
    <header
      ref="formHeaderEl"
      v-element-size="onHeaderResize"
      class="page-content-header page-content-header-fixed"
      :class="{ shadowed: hasScrolled }"
    >
      <div class="hgroup mt-1 mb-0">
        <h2>
          <i class="zmdi zmdi-forms-page-single mr-0.5" />
          <span v-html="translateTerm(level)" />
        </h2>
      </div>
      <board-filters
        :context-metadata="ctxMetadata"
        @update:metadata="ctxMetadata = $event"
      />
    </header>
    <hr
      ref="formHeaderOffsetEl"
      style="margin-top: var(--header-offset-height, 66px)"
    >
    <StepFormBoardArea
      :questions
      :response-rate
      :ctx-metadata
      :cards
      :loading
    />
    <BabushkaPanes :loading="savingDrilldownBoard">
      <template #default="slotProps">
        <BabushkaPaneDrilldown
          :pane-props="slotProps.paneProps"
          :drilldown-board-metadata="mergePaneFiltersOntoDrilldownMetadata(ctxMetadata)"
          @loading="(loading) => savingDrilldownBoard = loading"
        >
          <template #default="{pane}">
            <StepFormBoardArea
              :key="`${segmentId}-${pane?.name}`"
              :questions
              :ctx-metadata="mergePaneFiltersOntoDrilldownMetadata(ctxMetadata)"
              :cards
              :loading
            />
          </template>
        </BabushkaPaneDrilldown>
      </template>
    </BabushkaPanes>
  </div>
</template>

<script>

import { klona } from 'klona';
import { mapActions, mapGetters } from 'vuex';
import { useWindowScroll, onKeyStroke } from '@vueuse/core';
import { vElementSize } from '@vueuse/components'; // eslint-disable-line import/no-extraneous-dependencies
import { isEmpty } from 'lodash-es';
import { cardFromKeyMetric, cardFromQuestion } from 'API/cards';
import { mergeFilterBoxRow } from 'Utils/filterBox';
import eventBus from 'Utils/eventBus';
import { translateTerm, filterOutUnanswerableQuestion } from 'Utils/general';
import { KEY_METRIC_GRAPH_TYPES } from 'Utils/graph';
import BoardFilters from 'Components/parts/filters/BoardFilters';
import { destroyAllTooltips } from 'Components/parts/widgets/PerformantTooltip';
import StepFormBoardArea from 'Components/parts/step-form/StepFormBoardArea';
import BabushkaPanes from 'Components/parts/babushka-pane/BabushkaPanes';
import BabushkaPaneDrilldown from 'Components/parts/babushka-pane/BabushkaPaneDrilldown';
import setSiteTitle from '@/router/siteTitle';
import gettext from '@/gettext';

const { $pgettext } = gettext;

export default {
  name: 'StepForm',
  components: {
    StepFormBoardArea,
    BoardFilters,
    BabushkaPanes,
    BabushkaPaneDrilldown,
  },
  directives: {
    elementSize: vElementSize,
  },
  beforeRouteEnter(to, from, next) {
    if (to?.params?.level) {
      setSiteTitle({ meta: {
        siteTitle: () => $pgettext('siteTitle — forms/:level dynamic', '%{level} – Formulär', { level: translateTerm(to.params.level) }),
      } });
    }
    next();
  },
  props: {
    level: String,
  },
  setup() {
    const { y: windowScrollY } = useWindowScroll();
    return { windowScrollY };
  },
  data() {
    return {
      ctxMetadata: {
        filter: {
          date: { type: 'relative', offset: null, span: { months: 12 } },
        },
        level: this.level,
        benchmark: {},
        compare: null,
      },
      cards: {},
      questions: [],
      stepForms: [],
      loading: false,
      responseRate: {
        name: this.$pgettext('Card.js - card name: ResponseRate', 'Svarsfrekvens'),
        card: null,
      },
      savingDrilldownBoard: false,
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'allOpenPanes',
    ]),
    hasScrolled() { return this.windowScrollY >= 10; },
    query() {
      return this.$route.query;
    },
  },
  watch: {
    level(level) {
      this.go(level).catch(() => { });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.go(this.level).catch(() => { });
    });
    onKeyStroke('Escape', (e) => this.closePane());
  },
  created() {
    if (this.query.proxy) {
      this.ctxMetadata.filter.customerProxies = [parseInt(this.query.proxy, 10)];
    }
    eventBus.$on('update:temporary-card-metadata', ({ metadata }) => {
      if (metadata.name === this.responseRate.name) {
        this.responseRate.card.metadata = {
          ...this.responseRate.card.metadata, ...metadata,
        };
      } else {
        this.cards[metadata?.question].metadata = {
          ...this.cards?.[metadata?.question]?.metadata, ...metadata,
        };
      }
    });
  },
  beforeUnmount() {
    eventBus.$off('update:temporary-card-metadata');
    destroyAllTooltips();
  },
  methods: {
    translateTerm,
    ...mapActions([
      'getQuestionsByLevel',
      'fetchStepFormsBySegmentId',
      'closePane',
    ]),

    setQuestions(questions) {
      const filteredQuestions = questions
        .filter((question) => filterOutUnanswerableQuestion(question, this.level, this.stepForms));

      this.cards = filteredQuestions.reduce((acc, question) => {
        const obj = { [question.id]: cardFromQuestion(question, this.level) };
        return { ...acc, ...obj };
      }, {});
      this.questions = filteredQuestions;
      return filteredQuestions;
    },
    go(level) {
      if (level === undefined) {
        return Promise.resolve();
      }

      this.responseRate.card = cardFromKeyMetric({
        metadata: {
          name: this.responseRate.name,
        },
        graphType: {
          selected: KEY_METRIC_GRAPH_TYPES.ResponseRate,
          settings: { [KEY_METRIC_GRAPH_TYPES.ResponseRate]: { type: 'funnelGraph' } },
        },
        filter: { step: [this.level] },
        colClass: 'col-md-6',
      });

      this.ctxMetadata.level = level;
      this.loading = true;
      this.questions = [];
      return this.getQuestionsByLevel(level)
        .then(async (data) => {
          const forms = await this.fetchStepFormsBySegmentId({ segmentId: this.segmentId })
            .catch(() => { });
          this.stepForms = forms;
          return data;
        })
        .then(this.setQuestions)
        .then(() => {
          this.loading = false;
        })
        .catch(() => { });
    },
    onHeaderResize({ width, height }) {
      let currentWidth = window?.document?.body?.getBoundingClientRect?.()?.width ?? width;
      let currentHeight = height || 90;
      let offsetPx = 16;
      if (currentWidth > 992) { // Breakpoint large, desktop view
        offsetPx = 24;
      }

      document.documentElement.style.setProperty('--header-offset-height', `${Math.ceil(currentHeight - offsetPx)}px`);
      if (this.$refs?.formHeaderEl) { // ? Set header height so popper can calculate correct position
        const formHeaderContainerRect = this.$refs.formHeaderEl.getBoundingClientRect();
        document.documentElement.style.setProperty('--header-height', `${Math.ceil(formHeaderContainerRect.height)}px`);
      }
    },
    mergePaneFiltersOntoDrilldownMetadata(metadata) {
      if (metadata?.filter == null || isEmpty(metadata.filter)) return metadata;

      const newDrilldownMetadata = klona(metadata);
      const newMetadata = this.allOpenPanes.reduce((acc, pane) => { // ? Using allOpenPanes to get all filters, not just the current one.
        const drilldownDataAsBoxRow = {
          type: pane.drilldownData?.boxRowType ?? pane.drilldownData?.slug ?? '',
          key: pane.drilldownData?.boxRowKey ?? pane.drilldownData?.slug ?? '',
          value: pane.drilldownData?.boxRowValue ?? pane.drilldownData?.value ?? [],
        };
        return mergeFilterBoxRow(acc, drilldownDataAsBoxRow);
      }, newDrilldownMetadata.filter);

      newDrilldownMetadata.filter = newMetadata;
      return newDrilldownMetadata;
    },
  },
};
</script>
