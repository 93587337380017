import { isEmpty } from 'lodash-es';
import dynamicImport from 'Utils/dynamicImport';
import { store } from '@/store';

export const openCardEditModal = ({ boardId, card, isGoal = false, callback, emit }) => {
  const CardEditDetails = dynamicImport(() => import(/* webpackChunkName: "CardEditDetails", webpackPrefetch: true */ 'Components/parts/filters/CardEditDetails'));
  if (store.getters?.activeModal != null) return;
  store.dispatch('openModal', {
    name: 'CardEditDetails',
    component: CardEditDetails,
    size: 'xlarge',
    dataObject: {
      props: {
        board: { id: boardId },
        card,
        isGoal,
      },
    },
    callback: callback || ((newDataObject) => {
      if (!isEmpty(newDataObject?.card)) emit('add:cards', [newDataObject.card]);
    }),
  });
};

export const openAddQuestionsModal = ({ boardId, sectionId, isGoal = false, callback, emit }) => {
  const AddQuestionsDetails = dynamicImport(() => import(/* webpackChunkName: "AddQuestionsDetails", webpackPrefetch: true */ 'Components/parts/details/AddQuestionsDetails'));
  if (store.getters?.activeModal != null) return;
  store.dispatch('openModal', {
    name: 'AddQuestionsDetails',
    component: AddQuestionsDetails,
    dataObject: {
      props: {
        boardId,
        sectionId,
        isGoal,
      },
    },
    size: 'xlarge',
    callback: callback || ((newDataObject) => {
      if (newDataObject?.cards) {
        if (isGoal) openCardEditModal({ boardId, card: newDataObject.cards?.[0], isGoal, emit });
        else emit('add:cards', newDataObject.cards);
      }
    }),
  });
};

export const openKeyMetricModal = ({ boardId, sectionId, callback, emit }) => {
  const KeyMetricDetails = dynamicImport(() => import(/* webpackChunkName: "KeyMetricDetails", webpackPrefetch: true */ 'Components/parts/details/KeyMetricDetails'));
  if (store.getters?.activeModal != null) return;
  store.dispatch('openModal', {
    name: 'KeyMetricDetails',
    component: KeyMetricDetails,
    dataObject: {
      props: {
        boardId,
        sectionId,
      },
    },
    size: 'large',
    callback: callback || ((newDataObject) => {
      if (newDataObject.cards) emit('add:cards', newDataObject.cards);
    }),
  });
};

export const openAddTextCardModal = ({ boardId, sectionId, emit }) => {
  if (store.getters?.activeModal != null) return;
  const AddTextDetails = dynamicImport(() => import(/* webpackChunkName: "AddTextDetails", webpackPrefetch: true */ 'Components/parts/details/AddTextDetails'));

  store.dispatch('openModal', {
    name: 'AddTextDetails',
    component: AddTextDetails,
    dataObject: {
      props: {
        boardId,
        sectionId,
      },
    },
    size: 'large',
    callback: ({ cards }) => {
      if (cards) emit('add:cards', cards);
    },
  });
};

export function useCardModals({ boardId, sectionId, emit = () => {} }) {
  /* eslint-disable max-len */
  return {
    openCardEditModal: ({ card, callback, isGoal = false }) => openCardEditModal({ boardId, card, isGoal, callback, emit }),
    openAddQuestionsModal: ({ callback, isGoal = false }) => openAddQuestionsModal({ boardId, sectionId, isGoal, callback, emit }),
    openKeyMetricModal: ({ callback }) => openKeyMetricModal({ boardId, sectionId, callback, emit }),
    openAddTextCardModal: ({ callback }) => openAddTextCardModal({ boardId, sectionId, callback, emit }),
  };
  /* eslint-enable max-len */
}
