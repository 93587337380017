import { ref, computed } from 'vue';
import { translateTerm } from 'Utils/general';
import { hardCodedSurveys, RECRUITMENT_ONLY_STEPS /* customStep */ } from 'Utils/survey';
import gettext from '@/gettext';
import { store } from '@/store';

const { $gettext } = gettext;

/**
 ** useSurveyList
 * @returns {{hardCodedSurveysSorted: ComputedRef<{name: string, description: string, preview_url: string, step: string, active: boolean, popular: boolean}[]>, allSurveys: Ref<unknown[]>, activeSurveysGroupedPerLevel: ComputedRef<{}>, activeSurveys: ComputedRef<{}>}
 */
export default function useSurveyList() {
  const customerIsInRecruitment = computed(() => store.getters.currentCustomer?.sectors?.includes('recruitment') ?? false);

  const isLoadingForms = ref(true);
  const allSurveys = ref([]);
  const setAllSurveys = async () => {
    if (allSurveys.value.length) return;
    allSurveys.value = await store.dispatch('fetchForms', { tiny: true }).catch(() => []);
    isLoadingForms.value = false;
  };
  const allSurveysGroupedPerLevel = computed(() => allSurveys.value.reduce((acc, surveyProxies) => {
    surveyProxies.forEach((survey) => {
      if (!acc[survey.level]) acc[survey.level] = [];
      acc[survey.level].push(survey);
    });
    return acc;
  }, {}));

  const activeSurveysGroupedPerLevel = computed(() => allSurveys.value.reduce((acc, surveyProxies) => {
    surveyProxies.forEach((survey) => {
      if (survey.active) {
        if (!acc[survey.level]) acc[survey.level] = [];
        acc[survey.level].push(survey);
      }
    });
    return acc;
  }, {}));

  const activeSurveys = computed(() => allSurveys.value.reduce((acc, surveys) => {
    surveys.forEach((survey) => {
      if (survey.active && !acc.find((s) => s.step === survey.level)) { // ? Likely missing "Employer Branding" step1 survey here
        acc.push({
          label: translateTerm(survey.level, store.getters.stringTranslations || []),
          step: survey.level,
          preview_url: survey?.test_url,
          active: true,
        });
      }
    });
    return acc;
  }, []));

  const hardCodedSurveysSorted = computed(
    () => hardCodedSurveys().reduce((acc, survey) => {
      // If we use a survey with step5, we don't want to add another one
      if (survey.step === 'step5' && acc.find((e) => e.step === 'step5' && e.active)) return acc;
      // If the survey is one of RECRUITMENT_ONLY_STEPS and the customer has "recruitment" section in the store, we don't want to add it
      if (RECRUITMENT_ONLY_STEPS.includes(survey.step) && !customerIsInRecruitment.value) return acc;
      // if step1 active always show "Application" in used and "Employer branding" in unused
      if (survey.step === 'step1') {
        const accHasStep1 = acc.find((e) => e.step === 'step1');
        survey.active = accHasStep1?.name === $gettext('Employer branding') ? false : !!activeSurveys.value.find((aSurvey) => aSurvey.label === survey.name);
        // set active and popular properties
      } else if (survey.step === 'kundstep1') { // Step may have wildly different labels
        survey.active = !!activeSurveys.value.find((aSurvey) => aSurvey.step === survey.step);
      } else {
        survey.active = !!activeSurveys.value.find((aSurvey) => aSurvey.label === survey.name);
      }

      if (survey?.popular) acc.unshift(survey); // if survey is popular, add it to the beginning of the array
      else acc.push(survey); // else add it to the end

      return acc;
    }, []),
  );

  setAllSurveys();

  return {
    isLoadingForms,
    hardCodedSurveysSorted,
    allSurveys,
    activeSurveysGroupedPerLevel,
    activeSurveys,
    allSurveysGroupedPerLevel,
  };
}
