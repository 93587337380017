<template>
  <div ref="me">
    <form
      v-if="showSearchInput"
      class="tc-search-wrapper tc-search-wrapper-small px-1"
      @submit.prevent="submitSearch"
    >
      <div class="form-control-wrap">
        <input
          ref="segmentSearchInput"
          v-model.lazy="searchTerm"
          v-debounce="800"
          class="form-control"
          type="search"
          autofocus
          :tabindex="dropdownIsOpen ? 0 : -1"
          :placeholder="$pgettext('Placeholder - SegmentSelect search', 'Sök bland segment och företag')"
          :aria-disabled="loading"
          @keydown.down.prevent.stop="focusOn(0)"
          @keydown.up.prevent.stop="focusOn(-1)"
          @keydown.enter.prevent.stop="searchTerm = $event.target.value"
        >
        <button
          class="form-control-clear-beside"
          :title="$pgettext('Tooltip — Clear beside search', 'Töm sökningen')"
          @click="clearSearch"
        >
          <i class="zmdi zmdi-close-circle zmdi-hc-lg" />
        </button>
      </div>
    </form>
    <div
      v-tc-loader-pill="loading"
      class="tc-segment-select-list tc-loader-pill-wrapper"
    >
      <pagination
        ref="paginator"
        v-model:count="count"
        v-model:page="segments"
        v-model:loading="loading"
        class="tc-pagination--expanded"
        :prefetch-next="true"
        :url="baseUrl"
        :search-query="searchQuery"
        :page-size="pageSize"
        :prefill-state="lastSearchState"
        @pagination-state="saveSearchState"
      />
      <nav class="tc-dropdown-action-nav">
        <div
          v-if="!count"
          class="page-content-message text-center"
        >
          {{ translations.emptyList }}
          <span v-if="searchTerm"><br>{{ translations.emptySearchList }}</span>
        </div>
        <ul
          class="segment-list"
          :style="markedSegments.length < 2 ? 'column-fill:auto;' : ''"
        >
          <li
            v-for="(segment, i) in markedSegments"
            :key="segment.id"
          >
            <div
              v-if="i < 1 || segment.customer.id !== markedSegments[i - 1].customer.id"
              class="small-text subtle-text nav-header"
              :title="segment.customer.name"
            >
              <span v-html="segment.customer.marked || segment.customer.name" />
              <staff-popover
                v-if="me.is_staff"
                :segment="segment"
              />
            </div>
            <button
              ref="button"
              type="button"
              class="btn btn-link block-link no-wrap"
              :class="{'active': segment.id === segmentId}"
              :title="`${segment.name} — ID: ${segment.id}`"
              @click.prevent="checkForUnsavedChangesThenSelectSegment(segment)"
              @keydown.up.stop.prevent="focusOn(i > 0 ? i - 1 : -1)"
              @keydown.down.stop.prevent="focusOn(i < markedSegments.length - 1 ? i + 1 : 0)"
            >
              <i
                v-if="segment.id === segmentId"
                class="zmdi zmdi-check mr-2"
                :aria-label="$pgettext('ARIA - Checkmark segment select', 'Aktivt segment:')"
              />
              <span v-html="segment.marked || segment.name" />
            </button>
          </li>
        </ul>
      </nav>
      <p
        v-if="initialSegmentsCount && !loading && searchTerm"
        class="tc-dropdown-action-metadata small-text subtle-text mx-1"
      >
        {{ countOfinitialSegmentsCount }}
      </p>
      <i
        v-tooltip="$gettext('Dra för att ändra storlek')"
        class="tc-dropdown-action-draghandle zmdi zmdi-code"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { isArray, deburr } from 'lodash-es';
import debounce from 'Directives/tc-debounce';
import { striphtml, markHits } from 'Utils/general';
import eventBus from 'Utils/eventBus';
import { confirmLeaveUnsavedChanges } from 'Components/modal/dialogBoxes';
import Pagination from '../Pagination';
import StaffPopover from './StaffPopover';

// <mark></mark> hits in item.customer.name or item.name
export function markName(term) {
  return (item) => {
    if (typeof term !== 'string' || typeof item.customer.name !== 'string' || typeof item.name !== 'string' || term.length === 0) {
      return item;
    }

    const normalizedTerm = deburr(term.trim().toLowerCase());
    const normalizedCustomerName = deburr(striphtml(item.customer.name).toLowerCase());
    if (normalizedCustomerName.indexOf(normalizedTerm) !== -1) {
      return {
        ...item,
        customer: {
          ...item.customer,
          marked: markHits(
            item.customer.name,
            [normalizedCustomerName.indexOf(normalizedTerm)],
            normalizedTerm.length,
          ),
        },
      };
    }

    const normalizedName = deburr(striphtml(item.name).toLowerCase());
    if (normalizedName.indexOf(normalizedTerm) !== -1) {
      return {
        ...item,
        marked: markHits(
          item.name,
          [normalizedName.indexOf(normalizedTerm)],
          normalizedTerm.length,
        ),
      };
    }
    return item;
  };
}

export default {
  components: {
    Pagination,
    StaffPopover,
  },
  directives: {
    debounce,
  },
  props: {
    dropdownIsOpen: Boolean,
  },
  emits: ['update:segment'],
  data() {
    return {
      searchTerm: '',
      searchQuery: '', // Only affect this via searchTerm
      searchHits: [],
      segments: [],
      pageSize: 30,
      count: 0,
      baseUrl: '/feedback/api/segment/',
      loading: null,
      switchedPage: false,
      initialSegmentsCount: null,
    };
  },
  computed: {
    ...mapState({
      lastSearchTerm: (state) => state.segments.lastSearchTerm,
      lastSearchState: (state) => state.segments.lastSearchState,
      hasUnsavedChanges: (state) => state.modals.hasUnsavedChanges,
    }),
    ...mapGetters([
      'me',
      'role',
      'segmentName',
      'segmentId',
    ]),
    countOfinitialSegmentsCount() {
      return this.$npgettext(
        'Table Metadata — Amount of questions shown',
        'Sökningen hittade %{segmentCount} av %{initialCount} tillgängliga segment',
        'Sökningen hittade %{segmentCount} av %{initialCount} tillgängliga segment',
        this.initialSegmentsCount,
        { segmentCount: this.count || 0, initialCount: this.initialSegmentsCount || 0 },
      );
    },
    translations() {
      return {
        emptyList: this.$pgettext('Error — Empty segment select', 'Kunde inte hitta några segment.'),
        emptySearchList: this.$gettextInterpolate(
          this.$pgettext('Error — Empty segment select bc search', 'Prova att utöka sökningen från "%{searchTerm}".'),
          { searchTerm: this.searchTerm },
        ),
      };
    },
    markedSegments() {
      if (isArray(this.segments)) return this.segments.map(markName(this.searchTerm));
      return [];
    },
  },
  watch: {
    count(count) {
      if (this.initialSegmentsCount === null) this.initialSegmentsCount = count || 0;
      // this.$emit('update:segments-count', count || NaN);
    },
    searchTerm(newTerm) {
      if (newTerm !== this.lastSearchTerm) this.search(newTerm ?? '');
      this.$nextTick(() => this.setLastSearchTerm(newTerm ?? ''));
    },
    dropdownIsOpen: {
      immediate: true,
      handler(isOpen) {
        if (isOpen) this.focusOn(null);
      },
    },
    loading(loading) {
      if (!loading && this.switchedPage) {
        this.$nextTick(() => this.focusOn(0));
        this.switchedPage = false;
      }
    },
  },
  beforeMount() {
    this.prefillSetup();
  },
  methods: {
    ...mapActions([
      'setSegment',
      'setLastSearchTerm',
      'setLastSearchState',
      'setUnsavedChanges',
    ]),
    closeSidebar() { eventBus.$emit('close-sidebar'); },
    saveSearchState(payload) {
      this.setLastSearchState({ initialSegmentsCount: this.initialSegmentsCount, ...payload });
    },
    prefillSetup() {
      if (this.searchTerm && this.lastSearchTerm === '') return;
      this.initialSegmentsCount = this.lastSearchState?.initialSegmentsCount || null;
      this.searchTerm = this.lastSearchTerm;
      this.searchQuery = this.searchTerm;
    },
    showSearchInput() { return this.initialSegmentsCount > this.pageSize; },
    // focus(el = this.$refs.segmentSearchInput) {
    //   if (el) {
    //     setTimeout(() => {
    //       el.focus();
    //     }, 1);
    //   }
    // },
    focusOn(fIndex = 0) {
      if (fIndex === null) { // ? Focus on segmentSearchInput
        setTimeout(() => this.$refs?.segmentSearchInput.focus?.(), 50);
      } else if (this.markedSegments.length >= 1) {
        let i = 0;
        if (fIndex === -1) i = this.markedSegments.length - 1; // ? Focus last option if '-1'
        else if (fIndex >= this.markedSegments.length) i = this.markedSegments.length - 1; // ? Focus last option if at the end
        else i = fIndex;

        if (this.$refs?.button?.[i]) this.$refs.button[i].focus();
      }
    },
    clearSearch() { this.searchTerm = ''; },
    submitSearch(evt) { this.search(); },
    search(term = this.searchTerm) {
      this.searchQuery = term;
    },
    getRouteOptions(segment) {
      // ? Use if we want to imp// lement staying on current page when switching segments
      // let reRoute = this.$route.name;
      // let params = { segmentId: segment.id || null };
      // if (reRoute === 'board') {
      //   reRoute = 'overview';
      //   params = {};
      // }
      // if (reRoute === 'form') reRoute = 'forms';
      // return { name: reRoute, params };
      // return { name: 'overview', params: {} };
      return { name: 'overview', params: { segmentId: segment.id || null } };
    },
    async checkForUnsavedChangesThenSelectSegment(segment) {
      if (this.hasUnsavedChanges) {
        const answer = await confirmLeaveUnsavedChanges();
        if (answer) {
          this.setUnsavedChanges(false);
          this.selectSegment(segment);
        }
      } else this.selectSegment(segment);
    },
    selectSegment(segment) {
      const routeOptions = this.getRouteOptions(segment);
      this.setSegment(segment).then(() => {
        this.$emit('update:segment', segment);
        this.$router.push(routeOptions).catch((err) => {});
        this.closeSidebar();
      });
      return true;
    },
    paginateNext() {
      if (document.activeElement !== this.$refs.segmentSearchInput) {
        if (this.$refs.paginator) this.$refs.paginator.next();
        this.switchedPage = true;
      }
    },
    paginatePrev() {
      if (document.activeElement !== this.$refs.segmentSearchInput) {
        if (this.$refs.paginator) this.$refs.paginator.prev();
        this.switchedPage = true;
      }
    },
  },
};
</script>
