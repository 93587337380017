import { handleAuthError } from 'Utils/authHelpers';
import { getBaseUrl, baseRequest } from 'Utils/api';
import { superagentFilter } from 'Utils/apiHelpers';
import BulkFetcher from 'Utils/bulkFetcher';

class AutoBulkFetcher {
  constructor(baseUrl, urlKey = 'id__in', listIn = null, idIn = 'question', useAllPaginations = false) {
    if (baseUrl === undefined) {
      throw new Error('baseUrl is not defined.');
    }
    if (typeof baseUrl !== 'string') {
      throw new Error('baseUrl is not a string.');
    }
    this.bf = new BulkFetcher(baseUrl, urlKey);
    this.idIn = idIn;
    this.listIn = listIn;
    this.timeout = null;
    this.segment = null;
    this.filter = null;
    this.useAllPaginations = useAllPaginations;
  }

  add(id, segment = null, filter = null) {
    if (id === undefined) {
      throw new Error('ID is not defined.');
    }
    // if this is new filter/segment combination run fetch and restart
    if (this.segment !== segment || this.filter !== filter) {
      this.done();
      this.filter = filter;
      this.segment = segment;
    }
    if (this.timeout === null) {
      this.timeout = setTimeout(() => {
        this.done();
      }, 500);
    }
    return this.bf.add(id);
  }

  async fetchAllNextPages(resp, mergedResults = []) {
    if (resp?.next) {
      const nextResponse = await baseRequest((request) => request.get(resp.next));
      mergedResults.push(...nextResponse.results);
      if (nextResponse.next) await this.fetchAllNextPages(nextResponse, mergedResults);
    }
    resp.results = mergedResults;
    return resp;
  }

  fetch() {
    // run on all acumulated promises and reset promises
    // so they wont collide with add():s during the fetch()
    let p = this.bf.promises;
    if (Object.keys(p).join(',').length === 0) {
      return Promise.resolve('No ids in store');
    }
    this.bf.promises = {};
    let req = getBaseUrl(this.bf.baseUrl)
      .query({ [this.bf.urlKey]: Object.keys(p).join(',') })
      .use(superagentFilter(this.filter));
    if (this.segment) {
      req.query({ segment: this.segment });
    }
    return req.then(async (response) => {
      if (this.useAllPaginations && response.body.next) {
        return this.fetchAllNextPages(response.body, response.body.results);
      }
      return response.body;
    }).catch(handleAuthError)
      .then((results) => (this.listIn && results[this.listIn]) || results)
      .catch(() => [])
      .then((results) => {
        // resolve promises in result
        results?.forEach?.((question) => {
          p[question[this.idIn]].resolve(question);
          delete p[question[this.idIn]];
        });
        // reject all promises left
        Object.keys(p).forEach((id) => {
          p[id].reject(new Error(`Item(${id}) not found.`));
        });
        return results;
      });
  }

  done() {
    if (this.timeout !== null) {
      this.fetch();
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}

export default AutoBulkFetcher;
