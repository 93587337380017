<template>
  <div class="dynamic-import dynamic-import-loading">
    <div class="preloader pls-green">
      <svg
        class="pl-circular"
        viewBox="25 25 50 50"
      >
        <circle
          class="plc-path"
          cx="50"
          cy="50"
          r="20"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DynamicImportLoading',
};
</script>
