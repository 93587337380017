<template>
  <div class="question-filter">
    <tc-select
      v-model="selected"
      class="tc-select--wrap tc-select-sidelabel--full-width"
      :on-change="getQuestions"
      :disabled="disabled"
      :placeholder="$gettext('Skriv för att söka bland frågor')"
      :no-options-text="$gettext('Inga fler frågor finns')"
      @update:model-value="update"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { questionOption } from 'Utils/question';
import { labelHit, markLabel } from 'Utils/tcselectHelpers';

export default {
  name: 'QuestionFilter',
  props: {
    modelValue: Array,
    level: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    enableSidelabel: {
      type: Boolean,
      default: false,
    },
    filterOutKeys: {
      type: Array,
      default: () => [],
      validator(value) {
        return value.every((v) => typeof v === 'string' || typeof v === 'number');
      },
    },
    filterOutQuestionTypes: {
      type: Array,
      default: () => ['text'],
      validator(value) {
        return value.every((v) => typeof v === 'string');
      },
    },
  },
  emits: ['update:modelValue'],

  data() {
    return {
      selected: this.modelValue,
    };
  },
  computed: {
    ...mapState({
      questions: (state) => state.questions.questions,
    }),
  },
  watch: {
    async modelValue(newVal) {
      if (newVal?.[0] && newVal?.[0]?.value === undefined && this.questions?.[newVal[0]]) {
        this.selected = [questionOption(await this.questions[newVal[0]], this.enableSidelabel)];
      } else {
        this.selected = newVal;
      }
    },
  },
  methods: {
    ...mapActions([
      'getAllQuestions',
    ]),
    update(selected) {
      this.$emit('update:modelValue', selected);
      // this.$emit('update:selected', selected.map((q) => q.value));
    },
    filterOutUnusableFn(questions) {
      const removeTheseKeys = [
        ...Object.values(this.selected).map((opt) => opt.value.toString()),
        ...this.filterOutKeys,
      ];
      return questions.filter((q) => {
        let keep = true;
        if (this.filterOutQuestionTypes.includes(q.question_type)) keep = false; // ! Removes text questions ¡
        else if (this.level) keep = q.level === this.level;
        return keep && removeTheseKeys.includes(q.id.toString()) === false;
      });
    },
    getQuestions(term) {
      return this.getAllQuestions()
        .then(this.filterOutUnusableFn)
        .then((questions) => questions
          .map((q) => (questionOption(q, this.enableSidelabel)))
          .filter(labelHit(term))
          .map(markLabel(term))).catch((err) => {});
    },
  },
};
</script>
