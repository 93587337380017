<template>
  <span v-if="useFilterBoxStyling">
    <badge-list
      :badge-list="filterBadges"
      :force-tooltips="true"
      type="filter"
      @clicked-badge-list="openEditModal"
    />
    <badge-list
      :badge-list="benchmarkBadges"
      :force-tooltips="true"
      type="benchmark"
      @clicked-badge-list="openEditModal"
    />
  </span>
  <div v-else-if="hasBenchmark || hasCustomFilter">
    <div
      v-if="hasCustomFilter"
      class="tc-card-header-filter-badges"
    >
      <span
        class="tc-card-header-filter-title"
        @click="openEditModal(null)"
      >
        <i
          v-tooltip="{
            content: () => $pgettext('Tooltip — Card filters icon', 'Detta card filtreras på'),
            distance: 12,
          }"
          class="zmdi zmdi-filter-list"
        />
      </span>
      <badge-list
        class="inline-list"
        :badge-list="filterAndCompareBadges"
        type="filter"
        @clicked-badge-list="openEditModal"
      />
    </div>
    <div
      v-if="hasBenchmark"
      class="tc-card-header-filter-badges"
    >
      <span
        class="tc-card-header-filter-title"
        @click="openEditModal(null)"
      >
        <i
          v-tooltip="{
            content: () => $pgettext('Tooltip — Benchmark filters icon', 'Detta card jämför med'),
            distance: 12,
          }"
          class="zmdi zmdi-globe"
        />
      </span>
      <badge-list
        class="inline-list"
        :badge-list="benchmarkBadges"
        :force-tooltips="true"
        type="benchmark"
        @clicked-badge-list="openEditModal"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { forOwn, isEmpty, isArray } from 'lodash-es';
import { translateTerm, translateBenchmark, nameCase } from 'Utils/general';
import { BENCHMARK_ICONS } from 'Utils/benchmarks';
import useBadges from 'Composables/useBadges';
import BadgeList from 'Components/parts/badges/BadgeList';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext, $pgettext } = gettext;

const props = defineProps({
  cardMetadata: {
    type: Object,
    default: () => ({}),
  },
  compiledFilter: {
    type: Object,
    default: () => ({}),
  },
  compiledBenchmark: {
    type: Object,
    default: () => ({}),
  },
  contextBenchmark: {
    type: Object,
    default: () => ({}),
  },
  compiledCompare: [Object, null],
  useFilterBoxStyling: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['open-edit-modal']);
const openEditModal = (itemSlug) => {
  store.dispatch('setModalCardOpenedFrom', itemSlug ?? null);
  emit('open-edit-modal');
};

onMounted(() => { store.dispatch('fetchAllBenchmark', 'industries').catch(() => {}); });

const hasCustomFilter = computed(() => !!Object.keys(props.compiledFilter).find((key) => {
  if (key === 'level' || key === 'benchmark') return false;
  return !isEmpty(props.compiledFilter[key]);
}));

const hasBenchmark = computed(() => !isEmpty(props.compiledBenchmark));
const benchmarkBadges = computed(() => {
  const badges = [];
  forOwn(props.compiledBenchmark, (values, dimension) => {
    let usedDim = { slug: dimension, label: nameCase(translateTerm(dimension)), value: dimension };
    if (dimension === 'general') {
      usedDim = {
        slug: `benchmark__${dimension}`,
        label: values[0] === 'company' ? (store.getters.customerName ?? $gettext('Mitt företag')) : nameCase(translateTerm(values[0])),
        value: values[0],
      };
    }

    badges.push({
      ...(
        !isEmpty(props.cardMetadata.benchmark)
          && dimension in props.cardMetadata.benchmark
          && {
            isCardBenchmark: true,
          }
      ),
      slug: usedDim.slug,
      icon: BENCHMARK_ICONS[usedDim.value] || null,
      label: usedDim.label,
      value: translateBenchmark(usedDim.label, values),
    });
  });
  return badges;
});

const contextMetadata = computed(() => ({
  compare: props.compiledCompare,
  filter: props.compiledFilter,
  benchmark: props.contextBenchmark,
}));
const cardMetadata = computed(() => ({
  compare: props.cardMetadata.compare,
  filter: props.cardMetadata.filter,
  benchmark: props.cardMetadata.benchmark,
  graphType: props.cardMetadata.graphType,
  question: props.cardMetadata.question,
}));
const { filterBadges, compareBadges } = useBadges(contextMetadata, cardMetadata);

const filterAndCompareBadges = computed(() => [
  ...filterBadges.value,
  ...(isArray(compareBadges.value) && !isEmpty(compareBadges.value) ? compareBadges.value : []),
]);
</script>
