<template>
  <nav class="tc-history-navigation">
    <ul>
      <li>
        <a
          class="big-text subtle-text"
          href="/"
          @click.prevent="onClick"
        ><i class="zmdi zmdi-long-arrow-left" /> <span>{{ $gettext('Tillbaka') }}</span></a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'HistoryNavigation',
  computed: {
    ...mapState({
      backRoute: (state) => state.backRoute,
    }),
  },
  methods: {
    ...mapActions([
      'setBackHistory',
    ]),
    onClick() {
      if (this.backRoute != null) {
        const goToRoute = this.backRoute;
        this.setBackHistory(null);
        this.$router.push(goToRoute);
      } else {
        this.$router.push({ name: 'home' });
      }
    },
  },
};
</script>
