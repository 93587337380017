import { debounce } from 'lodash-es';

// Use as v-tc-stick-to="'#scrollParent'"
const tcStickTo = {
  observer: null,
  resizer: null,
  mounted: async (el, binding) => {
    if (binding?.value == null) return;

    // Fixes sticky header toggled on mount
    await new Promise((resolve) => setTimeout(resolve, 1)); // eslint-disable-line no-promise-executor-return

    // Fixes some wonky stuff if parent (in practice .main-col) has padding
    const scrollParent = document.querySelector(binding.value);
    if (scrollParent == null) return;
    const scrollParentPaddingPx = getComputedStyle(scrollParent).getPropertyValue('padding-top');
    el.style = `--top: -${scrollParentPaddingPx}; --sticky-height: ${el.clientHeight}px;`;

    tcStickTo.resizer = debounce(() => {
      el.style = `--top: -${scrollParentPaddingPx}; --sticky-height: ${el.clientHeight}px;`;
    }, 1000, { leading: false, trailing: true });

    // Just toggles a class if element is sticking
    tcStickTo.observer = new IntersectionObserver(
      ([e]) => {
        if (binding.modifiers?.invert) {
          e.target.classList.toggle('is-sticky', e.intersectionRatio === 1);
        } else {
          e.target.classList.toggle('is-sticky', e.intersectionRatio < 1);
        }
      },
      {
        root: scrollParent,
        rootMargin: '-1px 0px 0px 0px',
        threshold: [1],
      },
    );

    tcStickTo.observer.observe(el);
    window.addEventListener('resize', tcStickTo.resizer);
  },
  unmounted: (el) => {
    tcStickTo.observer?.unobserve?.(el);
    if (tcStickTo?.resizer) {
      tcStickTo.resizer.cancel();
      window.removeEventListener('resize', tcStickTo.resizer);
    }
  },
};

export default tcStickTo;
