import { striphtml } from 'Utils/general';
import { getUrl, getBaseUrl, postUrl, putUrl, patchUrl, deleteUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

function create(customer, name, props = null) {
  return postUrl('/feedback/api/segment/', {
    name: striphtml(name),
    customer,
    ...props,
  });
}

function remove(id) {
  return deleteUrl(`/feedback/api/segment/${id}/`);
}

function list(customer = null, filter = {}, paginated = false) {
  let req = getBaseUrl('/feedback/api/segment/');
  if (customer) {
    req.query({ customer, can_edit: filter.canEdit });
  }
  return req.then((response) => (
    paginated && response.body.next !== undefined ? response.body : response.body.results
  ), handleAuthError);
}

function get(id, useDashboardLanguage = false) {
  return getUrl(`/feedback/api/segment/${id}/`, useDashboardLanguage)
    .catch(handleAuthError);
}

function update(id, details) {
  return putUrl(`/feedback/api/segment/${id}/`, details).catch(handleAuthError);
}

function patch(id, {
  name, tags, query, overview_board_id,
}) {
  return patchUrl(`/feedback/api/segment/${id}/`, {
    ...(name && { name: striphtml(name) }),
    ...(tags && { tags }),
    ...(query && { query }),
    ...(overview_board_id && { overview_board_id }),
  });
}

function getFirst() {
  return getBaseUrl('/feedback/api/segment/')
    .query({ page_size: 1, page: 1 })
    .then((response) => {
      if (response.body.count === 0) {
        throw new Error('Inga segment finns för användaren.');
      }
      return response.body.results[0];
    }, handleAuthError);
}

export default {
  list,
  get,
  getFirst,
  update,
  patch,
  create,
  remove,
};
