<template>
  <card>
    <template #header>
      <h3 class="p-card">
        {{ title || $gettext('Är du säker?') }}
      </h3>
    </template>
    <template #body>
      <div class="p-card modal-dialog-buttons">
        <div class="btn-text-group">
          <button
            ref="dismiss"
            tabindex
            type="button"
            class="btn btn-text"
            :class="{ 'btn-link-bw': theme === 'danger' }"
            @keydown.shift.tab.prevent="$refs.confirm && $refs.confirm.focus()"
            @click.prevent="dismiss()"
          >
            {{ dismissText || $gettext('Avfärda') }}
          </button>
          <button
            ref="confirm"
            tabindex
            type="button"
            class="btn"
            :class="{ 'btn-failure': theme === 'danger' }"
            @keydown.tab.prevent="$refs.dismiss && $refs.dismiss.focus()"
            @click.prevent="dismiss(true)"
          >
            {{ confirmText || $gettext('Bekräfta') }}
          </button>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
export default {
  props: {
    title: String,
    dismissText: String,
    confirmText: String,
    theme: String, // 'primary' || 'danger'
  },
  emits: ['dismiss'],
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.confirm) this.$refs.confirm.focus();
    });
  },
  methods: {
    dismiss(value = false) {
      this.$emit('dismiss', value);
    },
  },
};
</script>
