<template>
  <div class="pagination">
    <button
      class="btn btn-link btn-chevron btn-ghost"
      type="button"
      :disabled="data === 1"
      @click="changePage(data - 1)"
    >
      <i class="zmdi zmdi-chevron-left" />
    </button>
    <template v-for="page in transformedRange">
      <span
        v-if="page.type === 'page'"
        :key="page.value"
        :class="{ active: page.value === data }"
        @click="changePage(page.value)"
      >{{ page.value }}</span>
      <span
        v-else
        :key="page.type"
      >  &#8230;</span>
    </template>

    <button
      :disabled="data === totalPages"
      class="btn btn-link btn-chevron btn-ghost"
      type="button"
      @click="changePage(data + 1)"
    >
      <i class="zmdi zmdi-chevron-right" />
    </button>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useVModel } from '@vueuse/core';
import { getRange, transform } from './utils';

const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
  },
  totalItems: {
    type: Number,
    required: true,
    default: 20,
  },
  pageSize: {
    type: Number,
    default: 10,
  },
  siblingCount: {
    type: Number,
    default: 4,
  },
  showEdges: {
    type: Boolean,
    default: true,
  },
});

const totalPages = computed(() => Math.ceil(props.totalItems / props.pageSize));

const emit = defineEmits(['update:currentPage']);

const data = useVModel(props, 'currentPage', emit);

const transformedRange = computed(() => transform(
  getRange(
    data.value,
    totalPages.value,
    props.siblingCount, // props.siblingCount.value
    props.showEdges, // props.showEdges.value
  ),
));

const changePage = (page) => {
  if (page > 0 && page <= totalPages.value) {
    data.value = page;
    emit('update:currentPage', page);
  }
};

watch(() => props.totalItems, (newVal, oldVal) => {
  console.log('newVal, oldVal:', newVal, oldVal);
  if (newVal !== oldVal) changePage(1); // reset to first page if totalItems changes
});
</script>

<style scoped>
.pagination {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

button {
  padding: 0.5rem;
}

span {
  cursor: pointer;
}

span.active {
  font-weight: bold;
  text-decoration: underline;
}
</style>
