import { isEmpty, isArray, difference } from 'lodash-es';
import gettext from '@/gettext';

const { $pgettext } = gettext;

/*
 * RRULE standard from https://tools.ietf.org/html/rfc5545#section-3.3.10
*/

function parseIndividualNumber(value) {
  if (/^[+-]?\d+$/.test(value)) return Number(value);
  return value;
}

function parseNumber(value) {
  if (value.indexOf(',') !== -1) return value.split(',').map(parseIndividualNumber);
  return parseIndividualNumber(value);
}

// parseRrule original Fn from https://github.com/jakubroztocil/rrule/blob/master/src/parsestring.ts
/**
 * @function parseRrule
 * @param {String} line
 */
function parseRrule(line) {
  const options = {};
  const attrs = line.replace(/^(?:RRULE|EXRULE):/i, '').split(';');

  attrs.forEach((attr) => {
    const [key, value] = attr.split('=');
    switch (key.toUpperCase()) {
      case 'FREQ':
        options.freq = value.toUpperCase();
        break;
      case 'WKST':
        options.wkst = value.toUpperCase();
        break;
      case 'COUNT':
      case 'INTERVAL':
      case 'BYSETPOS':
      case 'BYMONTH':
      case 'BYMONTHDAY':
      case 'BYYEARDAY':
      case 'BYWEEKNO':
      case 'BYHOUR':
      case 'BYMINUTE':
      case 'BYSECOND':
        options[key.toLowerCase()] = parseNumber(value);
        break;
      case 'BYWEEKDAY': // NO SUPPORT YET
      case 'BYDAY':
        options.byday = value;
        break;
      case 'DTSTART':
        options.dtstart = value; // NO SUPPORT YET
        break;
      case 'TZID':
        options.tzid = value; // NO SUPPORT YET
        break;
      case 'UNTIL':
        options.until = value; // NO SUPPORT YET
        break;
      case 'BYEASTER':
        options.byeaster = Number(value);
        break;
      default:
        throw new Error(`Unknown RRULE property '${key}'`);
    }
  });

  return options;
}

// function validateRrule(parsedRrule) {
//   if (
//     (parsedRrule.freq === 'MONTHLY' && !parsedRrule.byyearday)
//     || (parsedRrule.freq === 'YEARLY' && !parsedRrule.bymonthday)
//   ) return false;
//   return true;
// }

const PERIODICITIES = (deprecatedWeeklyInUse = false) => [
  ...(deprecatedWeeklyInUse ? [$pgettext('Option — Recurrence rule weekly periodicity', 'Veckovis')] : []),
  $pgettext('Option — Recurrence rule monthly periodicity', 'Månadsvis'),
  $pgettext('Option — Recurrence rule quarterly periodicity', 'Kvartalsvis'),
  $pgettext('Option — Recurrence rule quarterly periodicity', 'Halvårsvis'),
  $pgettext('Option — Recurrence rule yearly periodicity', 'Årsvis'),
];

const FREQUENCIES = (deprecatedWeeklyInUse = false) => [
  ...(deprecatedWeeklyInUse ? [{
    label: $pgettext('Option — Recurrence rule weekly', 'Vecka'),
    value: 'FREQ=WEEKLY',
  }] : []), {
    label: $pgettext('Option — Recurrence rule monthly', 'Månad'),
    value: 'FREQ=MONTHLY',
  }, {
    label: $pgettext('Option — Recurrence rule quarterly', 'Kvartal'),
    value: 'FREQ=MONTHLY;BYMONTH=1,4,7,10', // INTERVAL=3;
  }, {
    label: $pgettext('Option — Recurrence rule quarterly', 'Halvår'),
    value: 'FREQ=MONTHLY;INTERVAL=6',
  }, {
    label: $pgettext('Option — Recurrence rule yearly', 'År'),
    value: 'FREQ=YEARLY',
  }];
const FREQUENCIES_LOOKUP = (deprecatedWeeklyInUse = false) => FREQUENCIES(deprecatedWeeklyInUse).map((opt) => parseRrule(opt.value)); // eslint-disable-line max-len
const FREQUENCIES_PERIODICITY = (deprecatedWeeklyInUse = false) => FREQUENCIES(deprecatedWeeklyInUse).map((opt, i) => {
  opt.label = PERIODICITIES(deprecatedWeeklyInUse)[i];
  return opt;
});

/**
 * Returns different because of complying to the RFC 5545 standard
 * @param {String} freq, value of the 'FREQ=' part of the rrule
 */
const RESTRICTIONS = ({ freq, deprecatedWeeklyInUse } = {}) => {
  const yearly = [{
    label: $pgettext('Option — Recurrence rule first weekday', 'den första måndagen'),
    value: 'BYDAY=MO;BYSETPOS=1',
  }, {
    label: $pgettext('Option — Recurrence rule first day', 'den första dagen'),
    value: 'BYYEARDAY=1',
  }, {
    label: $pgettext('Option — Recurrence rule first weekday', 'den första veckodagen'),
    value: 'BYDAY=MO,TU,WE,TH,FR;BYSETPOS=1',
  }, {
    label: $pgettext('Option — Recurrence rule last day', 'den sista dagen'),
    value: 'BYYEARDAY=-1',
  }, {
    label: $pgettext('Option — Recurrence rule last weekday', 'den sista veckodagen'),
    value: 'BYDAY=MO,TU,WE,TH,FR;BYSETPOS=-1',
  }];
  const monthly = [{
    label: $pgettext('Option — Recurrence rule first weekday', 'den första måndagen'),
    value: 'BYDAY=MO;BYSETPOS=1',
  }, {
    label: $pgettext('Option — Recurrence rule first day', 'den första dagen'),
    value: 'BYMONTHDAY=1',
  }, {
    label: $pgettext('Option — Recurrence rule first weekday', 'den första veckodagen'),
    value: 'BYDAY=MO,TU,WE,TH,FR;BYSETPOS=1',
  }, {
    label: $pgettext('Option — Recurrence rule last day', 'den sista dagen'),
    value: 'BYMONTHDAY=-1',
  }, {
    label: $pgettext('Option — Recurrence rule last weekday', 'den sista veckodagen'),
    value: 'BYDAY=MO,TU,WE,TH,FR;BYSETPOS=-1',
  }];
  const weekly = [{
    label: $pgettext('Option — Recurrence rule mondays', 'måndagar'),
    value: 'BYDAY=MO',
  }, {
    label: $pgettext('Option — Recurrence rule tuesdays', 'tisdagar'),
    value: 'BYDAY=TU',
  }, {
    label: $pgettext('Option — Recurrence rule wednesdays', 'onsdagar'),
    value: 'BYDAY=WE',
  }, {
    label: $pgettext('Option — Recurrence rule thursdays', 'torsdagar'),
    value: 'BYDAY=TH',
  }, {
    label: $pgettext('Option — Recurrence rule fridays', 'fredagar'),
    value: 'BYDAY=FR',
  }, {
    label: $pgettext('Option — Recurrence rule saturdays', 'lördagar'),
    value: 'BYDAY=SA',
  }, {
    label: $pgettext('Option — Recurrence rule sundays', 'söndagar'),
    value: 'BYDAY=SU',
  }];
  switch (freq) {
    case 'YEARLY':
      return yearly;
    case 'MONTHLY':
      return monthly;
    case 'WEEKLY':
      return weekly;

    default:
      return [
        ...(deprecatedWeeklyInUse ? weekly : []),
        ...monthly,
        ...yearly];
  }
};
const RESTRICTIONS_LOOKUP = ({ freq, deprecatedWeeklyInUse } = {}) => RESTRICTIONS({ freq, deprecatedWeeklyInUse }).map((opt) => parseRrule(opt.value)); // eslint-disable-line max-len

function findLookup(parsedRrule, lookupArray) {
  return lookupArray.reduce((match, lookup, index) => {
    const thisMatchesSoFar = Object.entries(lookup).every(([k, v]) => {
      if (isArray(v) && isArray(parsedRrule[k])) return isEmpty(difference(parsedRrule[k], v));
      if (parsedRrule[k] === v) return true;
      return false;
    });

    if (thisMatchesSoFar) match = index;
    return match;
  }, null);
}

function getFrequency(rrule) {
  if (rrule.value) return rrule;
  const parsedRrule = parseRrule(rrule);
  const deprecatedWeeklyInUse = parsedRrule.freq === 'WEEKLY';
  const lookupMatch = findLookup(parsedRrule, FREQUENCIES_LOOKUP(deprecatedWeeklyInUse));
  if (lookupMatch != null) return FREQUENCIES(deprecatedWeeklyInUse)[lookupMatch];
  // eslint-disable-next-line no-console
  console.error(new Error(`[TC] Could not find frequency in FREQUENCIES array ${rrule}`));
  return { label: '', value: '' };
}

function getRestriction(rrule) {
  if (rrule.value) return rrule;
  const parsedRrule = parseRrule(rrule);
  const deprecatedWeeklyInUse = parsedRrule.freq === 'WEEKLY';

  const lookupMatch = findLookup(parsedRrule, RESTRICTIONS_LOOKUP({ deprecatedWeeklyInUse }));
  if (lookupMatch != null) return RESTRICTIONS({ deprecatedWeeklyInUse })[lookupMatch];
  if (RESTRICTIONS({ freq: parsedRrule.freq, deprecatedWeeklyInUse })[0]) {
    return RESTRICTIONS({ freq: parsedRrule.freq, deprecatedWeeklyInUse })[0];
  }
  // eslint-disable-next-line no-console
  console.error(new Error(`[TC] Could not find restriction in RESTRICTIONS array ${rrule}`));
  return { label: '', value: '' };
}

function rruleText(rrule, fallbacks) {
  fallbacks = typeof fallbacks !== 'undefined' ? fallbacks : {
    freq: FREQUENCIES()[0].label,
    restr: RESTRICTIONS()[0].label,
  };

  if (rrule) {
    const freq = getFrequency(rrule);
    const restr = getRestriction(rrule);
    return $pgettext(
      'Description — Recurrence rule text',
      'varje %{frequency} på %{restriction}',
      {
        frequency: freq && String(freq.label).toLowerCase() || fallbacks.freq.toLowerCase(),
        restriction: restr && String(restr.label) || fallbacks.restr,
      },
    );
  }
  return '';
}

export {
  FREQUENCIES,
  FREQUENCIES_PERIODICITY,
  RESTRICTIONS,
  getFrequency,
  getRestriction,
  rruleText,
  parseRrule,
};
