/* eslint-disable no-bitwise */
/* Stolen and modified from Boring Avatars & https://github.com/cmgriffing/boringer-avatars/blob/main/packages/lib-vue3/src/avatar.utils.js */
export const hashCode = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    let character = name.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash &= hash;
  }
  return Math.abs(hash);
};
export const getModulus = (num, max) => num % max;
export const getDigit = (number, ntn) => Math.floor(number / 10 ** ntn % 10);
export const getBoolean = (number, ntn) => !(getDigit(number, ntn) % 2);
export const getAngle = (x, y) => Math.atan2(y, x) * 180 / Math.PI;
export const getUnit = (number, range, index) => {
  let value = number % range;
  if (index && getDigit(number, index) % 2 === 0) {
    return -value;
  } return value;
};
export const getRandomColor = (number, colors, range) => colors[number % range];
export const getContrast = (hexcolor) => {
  if (!hexcolor) {
    return '#FFFFFF';
  }
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1);
  }
  let r = parseInt(hexcolor.substr(0, 2), 16);
  let g = parseInt(hexcolor.substr(2, 2), 16);
  let b = parseInt(hexcolor.substr(4, 2), 16);
  let yiq = (r * 299 + g * 587 + b * 114) / 1e3;
  return yiq >= 128 ? '#000000' : '#FFFFFF';
};

export const SIZE = 36;

export function generateData(name, colors) {
  const numFromName = hashCode(name);
  const range = colors && colors.length;
  const wrapperColor = getRandomColor(numFromName, colors, range);
  const preTranslateX = getUnit(numFromName, 10, 1);
  const wrapperTranslateX = preTranslateX < 5 ? preTranslateX + SIZE / 9 : preTranslateX;
  const preTranslateY = getUnit(numFromName, 10, 2);
  const wrapperTranslateY = preTranslateY < 5 ? preTranslateY + SIZE / 9 : preTranslateY;
  const data = {
    wrapperColor,
    faceColor: getContrast(wrapperColor),
    backgroundColor: getRandomColor(numFromName + 13, colors, range),
    lessRandom: {
      wrapperColor: colors[1],
      backgroundColor: colors[0],
    },
    wrapperTranslateX,
    wrapperTranslateY,
    wrapperRotate: getUnit(numFromName, 360),
    wrapperScale: 1 + getUnit(numFromName, SIZE / 12) / 10,
    isMouthOpen: getBoolean(numFromName, 2),
    isCircle: getBoolean(numFromName, 1),
    eyeSpread: getUnit(numFromName, 5),
    mouthSpread: getUnit(numFromName, 3),
    faceRotate: getUnit(numFromName, 10, 3),
    faceTranslateX: wrapperTranslateX > SIZE / 6 ? wrapperTranslateX / 2 : getUnit(numFromName, 8, 1),
    faceTranslateY: wrapperTranslateY > SIZE / 6 ? wrapperTranslateY / 2 : getUnit(numFromName, 7, 2),
  };
  return data;
}
