<template>
  <div :class="barColor">
    <GoalBarIndicator
      :bar-color="barColor"
      :current="current"
      :end="end"
      :remaining="remaining"
      :bar-progress-percentage="barProgressPercentage"
      :goal-unit="goalUnit"
      :skeleton-loader="skeletonLoader || null"
    />
    <div class="goal-relationals">
      <!-- eslint-disable vue/singleline-html-element-content-newline -->
      <div
        class="goal-relational goal-start"
        :class="{ 'hide': isStartMoreThanEnd }"
      >
        <div
          class="goal-pill goal-pill--start strong"
          :class="{ 'tc-loading-text tc-loading-text-inherit size-inherit': skeletonLoader }"
        >
          {{ start?.value !== undefined && start?.displayValue || '' }}
        </div>
        <p
          class="strong"
          :class="{ 'tc-loading-text tc-loading-text-inherit tc-loading-text-on-dark size-xsmall': skeletonLoader }"
        >
          {{ $pgettext('Legend — Goal', 'Start') }}
        </p>
        <p v-if="start?.date">{{ start.date }}</p>
      </div>
      <span
        v-show="!isStartMoreThanEnd && !skeletonLoader"
        class="goal-relational-arrow"
      >
        <i class="zmdi zmdi-long-arrow-right" />
      </span>
      <div
        v-if="end?.value !== undefined"
        class="goal-relational goal-end"
      >
        <div
          class="goal-pill goal-pill--end strong"
          :class="{ 'tc-loading-text tc-loading-text-inherit size-inherit': skeletonLoader }"
        >
          {{ end?.displayValue }}
        </div>
        <p
          class="strong"
          :class="{ 'tc-loading-text tc-loading-text-inherit tc-loading-text-on-dark size-xsmall': skeletonLoader }"
        >
          {{ $pgettext('Legend — Goal', 'Mål') }}
        </p>
        <p v-if="end?.date">{{ end.date }}</p>
      </div>
      <!-- eslint-enable vue/singleline-html-element-content-newline -->
    </div>
  </div>
</template>

<script>
import GoalBarIndicator from 'Components/parts/graph/GoalBarIndicator';

export default {
  name: 'GoalBar',
  components: {
    GoalBarIndicator,
  },
  props: {
    current: Object,
    start: Object,
    end: Object,
    goalUnit: String,
    skeletonLoader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isStartMoreThanEnd() {
      return this.start?.value !== undefined
        && this.end?.value !== undefined
        && this.start.value > this.end.value;
    },
    remaining() {
      if (this.end?.value === undefined || this.current?.value === undefined) return false;
      const diff = this.end.value - this.current.value;
      return diff < 0 ? 0 : diff;
    },
    startCurrentDiff() {
      if (this.start?.value === undefined || this.current?.value === undefined) return false;
      return this.current.value - this.start.value;
    },
    barProgressPercentage() { // ? return 0-100 percentage as number
      if (this.startCurrentDiff === false || typeof (this.end?.value ?? true) === 'boolean') return 0;
      if (this.startCurrentDiff < 0) return 0; // `0` Bottoms out to 1rem with CSS

      const range = this.end.value - this.start.value;
      if (range === 0) return 100;
      if (range < 0) return 105; // Tops out because it's beyond the endVal
      const barProgressPercentage = (this.startCurrentDiff * 100) / range;
      return barProgressPercentage;
    },
    barColor() {
      if (this.startCurrentDiff < 0) return 'goal-bar-indicator-negative';
      if (this.barProgressPercentage >= 100) return 'goal-bar-indicator-positive';
      return '';
    },
  },
};
</script>
