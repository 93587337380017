import { getUrl, patchUrl, patchBaseUrl, postUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

function list(id) {
  return getUrl('/feedback/api/customer/')
    .catch(handleAuthError);
}

async function get(id) {
  return getUrl(`/feedback/api/customer/${id}/`)
    .catch(handleAuthError);
}

function update(id, details) {
  return patchUrl(`/feedback/api/customer/${id}/`, details)
    .catch(handleAuthError);
}

function updateLogo(id, formDataFiles) {
  return patchBaseUrl(`/feedback/api/customer/${id}/change_logo_image/`)
    .send(formDataFiles)
    .catch(handleAuthError);
}

// ? Customer Plan
export const CUSTOMER_PLAN_UPGRADE_REASONS = {
  unlimited_users: 'unlimited_users',
  unlimited_reports: 'unlimited_reports',
  unlimited_downloads: 'unlimited_downloads',
  general: 'general',
};

function startFreeTrial(id, reason = CUSTOMER_PLAN_UPGRADE_REASONS.general) {
  return postUrl(`/feedback/api/customer/${id}/start_free_trial/`, { reason })
    .catch(handleAuthError);
}

function upgradePlan(
  id,
  { plan_type = 'standard', end_date = 'YYYY-MM-DD' } = {},
  reason = CUSTOMER_PLAN_UPGRADE_REASONS.general,
) {
  return postUrl(`/feedback/api/customer/${id}/upgrade_plan/`, { plan_type, reason })
    .catch(handleAuthError);
}

export default {
  list,
  get,
  update,
  updateLogo,
  startFreeTrial,
  upgradePlan,
};
