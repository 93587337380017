import { useMemoize } from '@vueuse/core';
import { pickBy, identity, isArray } from 'lodash-es';
import { postBaseUrl } from 'Utils/api';
import { TOQFilter, filterQueryKeys } from 'Utils/apiHelpers';
import eventBus from 'Utils/eventBus';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext, $pgettext } = gettext;

/**
 ** Type Definitions
 * @typedef {object} IFilter
 * @example { applicant, question, segment, customer_proxy, date }
 *
 * @typedef {object} ICompareToFilter
 * @example { applicant, question, segment, customer_proxy, date }
 *
 * @typedef {array} IGroupBy
 * @example [ 'question', 'segment', 'customerproxy', 'date', 'year', 'quarter', 'month',]
 *
 * @typedef {(IFilter|ICompareToFilter|IGroupBy)} IAnalysisQuery
 * @typedef {IAnalysisQuery[]} IAnalysisQueries

 * @typedef {object} IConfidenceResponse
 * @example { question_id, step, mean, confidence_interval_percentage, lower_bound, upper_bound }
 * @example {
    question_id: 397,
    step: 'step1',
    mean: 1.337,
    confidence_interval_percentage: 9,
    lower_bound: 1.336,
    upper_bound: 1.338,
  }
 */

/**
 ** requestSWOT - only supports single query
 * @param  {IAnalysisQuery} query , A single instance of a IAnalysisQuery, which contains `filter` & `compare_to_filter`
 * @example { filter, compareToFilter }
 *
 * @param  {(string|number)} [abortToken=null] , A hash token to use for identifying the request, so that it can be aborted via superagent
 * @param  {string} errorMessage , A translated errorMessage for the error notification
 *
 * @return {(undefined|object)} response , The response from the server
 */
export async function requestSWOT({
  filter,
  compareToFilter,
} = {}, abortToken = null, errorMessage = '') {
  let response;
  const cleanedQuery = pickBy({
    filter: filter !== null ? TOQFilter(filter) : null,
    [filterQueryKeys.compareToFilter]: TOQFilter(compareToFilter), // removes props with empty objects
  }, identity); // filters out nulls
  if (!process.env.DISABLE_ANALYSIS) {
    try {
      // if (Object.keys(cleanedQuery.filter).length === 1 && Object.keys(cleanedQuery.filter).includes('date')) return {}; // ? We require either segment or proxy
      const analysisRequest = postBaseUrl('/analysis/swot/', cleanedQuery);

      if (abortToken) {
        eventBus.$once(`abortRequest:${abortToken}`, () => {
          if (analysisRequest.xhr?.status === 0) analysisRequest.abort();
        });
      }

      const analysisResponse = await analysisRequest;
      response = analysisResponse.body;
    } catch (err) {
      if (err.code !== 'ABORTED') {
        store.dispatch('notify', {
          text: $gettext('Kunde inte hämta %{msg}', { msg: errorMessage || $pgettext('Error - SWOT API', 'data för Styrkor och Svagheter') }),
          level: 'warning',
          type: 'slow',
        });
        // handleServerError(err);
      }
    }
  }
  return response;
}

/**
 ** requestPerformance - only supports single query
 * @param  {IAnalysisQueries} queries , An array of IAnalysisQueries, which contains multiple IAnalysisQuery's, an IAnalysisQuery is made up of `filter` & `compare_to_filter`
 * @example
 * [{ filter, compareToFilter }, { filter, compareToFilter }]
 *
 * @param  {(string|number)} [abortToken=null] , A hash token to use for identifying the request, so that it can be aborted via superagent
 * @param  {string} errorMessage , A translated errorMessage for the error notification
 *
 * @return {(undefined|object)} response , The response from the server
 */
export async function requestPerformance({
  groupBy,
  filter,
  compareToFilter,
} = {}, abortToken = null, errorMessage = '') {
  let response;
  const cleanedQuery = pickBy({
    [filterQueryKeys.groupBy]: groupBy !== null ? groupBy : null,
    filter: filter !== null ? TOQFilter(filter) : null,
    [filterQueryKeys.compareToFilter]: TOQFilter(compareToFilter), // removes props with empty objects
  }, identity); // filters out nulls
  if (!process.env.DISABLE_ANALYSIS) {
    try {
      const analysisRequest = postBaseUrl('/analysis/performance/', cleanedQuery);

      if (abortToken) {
        eventBus.$once(`abortRequest:${abortToken}`, () => {
          if (analysisRequest.xhr?.status === 0) analysisRequest.abort();
        });
      }

      const analysisResponse = await analysisRequest;
      response = analysisResponse.body;
    } catch (err) {
      if (err.code !== 'ABORTED') {
        store.dispatch('notify', {
          text: $gettext('Kunde inte hämta %{msg}', { msg: errorMessage || $pgettext('Error - Performance API', 'data för Performance') }),
          level: 'warning',
          type: 'slow',
        });
        // handleServerError(err);
      }
    }
  }
  return response;
}

/**
 ** requestRanking - supports multiple queries
 *
 * @param  {IAnalysisQueries} queries , An array of IAnalysisQueries, which contains multiple IAnalysisQuery's, an IAnalysisQuery is made up of `filter` & `compare_to_filter`
 * @example
 * [{ filter, compareToFilter }, { filter, compareToFilter }]
 *
 * @param  {(string|number)} [abortToken=null] , A hash token to use for identifying the request, so that it can be aborted via superagent
 * @param  {string} errorMessage , A translated errorMessage for the error notification
 *
 * @return {(undefined|object)} response , The response from the server
 */

export async function requestRanking({
  hierarchy = null,
  groupBy,
  filter,
  compareToFilter,
} = {}, abortToken = null, errorMessage = '') {
  let response;
  const cleanedQuery = pickBy({
    hierarchy,
    [filterQueryKeys.groupBy]: groupBy !== null ? groupBy : null,
    filter: filter !== null ? TOQFilter(filter) : null,
    [filterQueryKeys.compareToFilter]: TOQFilter(compareToFilter), // removes props with empty objects
  }, identity); // filters out nulls
  if (!process.env.DISABLE_ANALYSIS) {
    try {
      const analysisRequest = postBaseUrl('/analysis/ranking/', cleanedQuery);

      if (abortToken) {
        eventBus.$once(`abortRequest:${abortToken}`, () => {
          if (analysisRequest.xhr?.status === 0) analysisRequest.abort();
        });
      }

      const failed = {
        comment: $pgettext('Gauge - no data message', 'Inga svar har samlats in för den angivna perioden'),
        ranking: null,
        position: null,
        excluded: true,
        failedResponse: true,
      };
      const failedResponse = hierarchy === 1 ? { failed } : failed;

      const analysisResponse = await analysisRequest;
      response = isArray(analysisResponse.body) && analysisResponse.body.length === 0
        ? failedResponse
        : analysisResponse.body;
    } catch (err) {
      if (err.code !== 'ABORTED') {
        store.dispatch('notify', {
          text: $gettext('Kunde inte hämta %{msg}', { msg: errorMessage || $pgettext('Error - Ranking API', 'data för Ranking') }),
          level: 'warning',
          type: 'slow',
        });
        // handleServerError(err);
        return { comment: 'skumt', ranking: null, excluded: true }; // TODO: ett annat sätt tack...
      }
    }
  }
  return response;
}

/**
   ** requestAnalysis - supports multiple queries
   *
   * @param  {IAnalysisQueries} queries {An array of IAnalysisQueries, which contains multiple IAnalysisQuery's, an IAnalysisQuery is made up of `filter`, `compare_to_filter`}
   * @example
   * [{ filter, compareToFilter }, { filter, compareToFilter }]
   *
   * @param  {(string|number)} abortToken {A hash token to use for identifying the request, so that it can be aborted via superagent}
   * @param  {string} errorMessage {A translated errorMessage for the error notification}
   *
   * @return {(undefined|object)} response {The response from the server}
   */
export async function requestAnalysis(queries = [], abortToken = null, errorMessage = '') {
  let response;
  // ? Return early if any query's filter is unusable or empty
  if (
    !(queries.length > 0)
    || queries.some((q) => (Object.keys(q.filter).length === 1 && Object.keys(q.filter).includes('date')))
  ) return {};

  const cleanedQueries = queries.map((q) => pickBy({
    filter: q.filter !== null ? TOQFilter(q.filter) : null,
    [filterQueryKeys.compareToFilter]: TOQFilter(q.compareToFilter), // removes props with empty objects
  }, identity)); // filters out nulls

  if (!process.env.DISABLE_ANALYSIS) {
    try {
      const analysisRequest = postBaseUrl('/analysis/swot/', cleanedQueries);

      if (abortToken) {
        eventBus.$once(`abortRequest:${abortToken}`, () => {
          if (analysisRequest.xhr?.status === 0) analysisRequest.abort();
        });
      }

      const analysisResponse = await analysisRequest;
      response = analysisResponse.body;
    } catch (err) {
      if (err.code !== 'ABORTED') {
        store.dispatch('notify', {
          text: $gettext('Kunde inte hämta %{msg}', { msg: errorMessage || $pgettext('Error - Analysis API', 'data') }),
          level: 'warning',
          type: 'slow',
        });
        // handleServerError(err);
      }
    }
  }
  return response;
}

/**
   ** requestConfidence - only supports single query
 * @param  {IAnalysisQuery} query , A single instance of a IAnalysisQuery, which contains `filter` & `compare_to_filter`
 * @example { filter }
 *
 * @param  {(string|number)} [abortToken=null] , A hash token to use for identifying the request, so that it can be aborted via superagent
 * @param  {string} errorMessage , A translated errorMessage for the error notification
 *
 * @return {IConfidenceResponse} response, The response from the server
 */
export const requestConfidence = useMemoize(async ({
  filter,
  confidenceInterval = null,
} = {}, abortToken = null, errorMessage = '') => {
  let response;
  const cleanedQuery = pickBy({
    filter: filter !== null ? TOQFilter(filter) : null,
  }, identity); // filters out nulls
  if (!process.env.DISABLE_ANALYSIS) {
    const url = confidenceInterval !== null
      ? `/analysis/confidence-interval/${confidenceInterval}/`
      : '/analysis/confidence-interval/';

    try {
      const analysisRequest = postBaseUrl(url, cleanedQuery);

      if (abortToken) {
        eventBus.$once(`abortRequest:${abortToken}`, () => {
          if (analysisRequest.xhr?.status === 0) analysisRequest.abort();
        });
      }

      const analysisResponse = await analysisRequest;
      response = analysisResponse.body;
    } catch (err) {
      if (err.code !== 'ABORTED') {
        store.dispatch('notify', {
          text: $gettext('Kunde inte hämta %{msg}', { msg: errorMessage || $pgettext('Error - Confidence API', 'data för förtroende') }),
          level: 'warning',
          type: 'slow',
        });
        // handleServerError(err);
      }
    }
  }
  return response;
});
