import gettext from '@/gettext';

const { $gettext } = gettext;
export class ErrCodeError extends Error {
  constructor(error) {
    super(
      error?.response?.body?.reason
        || error?.response?.body?.detail
        || error?.response?.body
        || $gettext(
          'Ett fel uppstod vid hämtning av data, prova igen senare eller kontakta oss.',
        ),
    );
    this.errCode = error?.response?.statusCode || error?.statusCode || error?.status;
    this.responseBody = error?.response?.body || null;
  }
}

export default {
  ErrCodeError,
};
