import { has, isEmpty } from 'lodash-es';
import { markLabel, labelHit } from 'Utils/tcselectHelpers';

export const validSegment = (state) => has(state.currentSegment, 'id');

export function textFilter(state) {
  return state.textFilter;
}

export function loading(state) {
  return state.loading;
}

export function role(state) {
  if (has(state, 'role')) {
    return state.role;
  }
  return new Error('[TC] Couldn’t get role');
}

export function userType(state) {
  if (has(state, 'userType')) {
    return state.userType;
  }
  return new Error('[TC] Couldn’t get userType');
}

export function me(state) {
  if (has(state, 'me.id')) {
    return state.me;
  }
  return new Error('[TC] Couldn’t get me');
}

export function segment(state) {
  if (has(state, 'currentSegment')) {
    return state.currentSegment;
  }
  return new Error('[TC] Couldn’t get segment');
}

export function segmentName(state) {
  if (validSegment && has(state.currentSegment, 'name')) {
    return state.currentSegment.name;
  }
  return new Error('[TC] Couldn’t get segmentName');
}

export function segmentId(state) {
  if (has(state.currentSegment, 'id')) {
    return state.currentSegment.id;
  }
  return new Error('[TC] Couldn’t get segmentId');
}

export function segmentCanEdit(state) {
  if (has(state.currentSegment, 'permissions')) {
    return state.currentSegment.permissions.can_edit;
  }
  return new Error('[TC] Couldn’t get segmentCanEdit');
}

/**
 ** segmentShowsAllData
 *  @returns {(boolean | error)} if current segment is showing 'All data' or not
 */
export function segmentShowsAllData(state) {
  if (has(state.currentSegment, 'query')) {
    return isEmpty(state.currentSegment.query);
  }
  return new Error('[TC] Couldn’t get segmentShowsAllData');
}

/**
 ** segmentShowsOneProxy
 *  @returns {(number | boolean | error)} if current segment is showing a singular proxy or not
 */
export function segmentShowsOneProxy(state) {
  if (has(state.currentSegment, 'query')) {
    return (state.currentSegment.query?.customer_proxy_id__in?.length === 1
      && state.currentSegment.query?.customer_proxy_id__in?.[0])
      ?? false;
  }
  return new Error('[TC] Couldn’t get segmentShowsOneProxy');
}

export function customerName(state) {
  if (has(state.currentSegment.customer, 'name')) {
    return state.currentSegment.customer.name;
  }
  return new Error('[TC] Couldn’t get customerName');
}

export function customerId(state) {
  if (has(state.currentSegment.customer, 'id')) {
    return state.currentSegment.customer.id;
  }
  return 0;
}

export function customerSegments(state) {
  if (has(state, 'me.segments')) {
    return state.me.segments.filter((seg) => seg.customer === state.currentSegment.customer.id);
  }
  return [];
}

export function segmentOptions(state, getters) {
  return (term = '') => {
    if (has(getters, 'customerSegments')) {
      return getters.customerSegments
        .map((seg) => (seg != null ? { value: seg.id, label: seg.name } : { value: null, label: null }))
        .filter(labelHit(term))
        .map(markLabel(term));
    }
    return [];
  };
}

export function firstAvailableSegment(state) {
  if (has(state, 'me.segments') && has(state, 'currentSegment.customer')) {
    return state.me.segments.find((seg) => seg.customer === state.currentSegment.customer.id) || null;
  }
  return new Error('[TC] Couldn’t get firstAvailableSegment');
}
export function isCustomerUserInCurrentSegment(state) {
  if (has(state, 'me.customers') && has(state, 'currentSegment.customer')) {
    return state.me.customers && state.me.customers.length !== 0
    && !!state.me.customers.find((c) => state.currentSegment.customer.id === c.id) || state.me?.is_staff;
  }
  return false;
}
