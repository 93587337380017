<template>
  <aside
    class="dashboard-sidebar"
    :class="{ toggled }"
    role="region"
    :aria-label="$pgettext('ARIA - Sidebar', 'Navigeringsmeny')"
  >
    <div class="dashboard-sidebar-inner">
      <NavSegmentSelect :sidebar-toggled="toggled" />
      <NavMain />
    </div>
    <NavUser :sidebar-toggled="toggled" />
  </aside>
</template>

<script>
import NavSegmentSelect from 'Components/parts/navigation/NavSegmentSelect';
import NavMain from 'Components/parts/navigation/NavMain';
import NavUser from 'Components/parts/navigation/NavUser';

export default {
  components: {
    NavSegmentSelect,
    NavMain,
    NavUser,
  },
  props: {
    toggled: Boolean,
  },
};
</script>
