import { isEmpty, isArray } from 'lodash-es';
import { BENCHMARK_DIMENSIONS, BENCHMARK_DIMENSIONS_FETCH_FN } from 'Utils/benchmarks';
import { vm } from '@/vm';

export default {
  state: {
    allBenchmark: {
      sectors: [],
      sizes: [],
      industries: [],
      locations: [],
    },
    fetchingAllBenchmark: {
      sectors: false,
      sizes: false,
      industries: false,
      locations: false,
    },
  },
  mutations: {
    SET_ALL_BENCHMARK_DIMENSION(state, { dimension, response }) {
      state.allBenchmark[dimension] = response;
    },
    SET_ALL_BENCHMARK_FETCH_STATE(state, { dimension, fetchState }) {
      state.fetchingAllBenchmark[dimension] = fetchState;
    },
    CLEAR_ALL_BENCHMARK_CACHE(state) {
      state.allBenchmark = {
        sectors: [],
        sizes: [],
        industries: [],
        locations: [],
      };
    },
  },
  getters: {
    allBenchmarkIsFetching: (state) => (dimension) => state.fetchingAllBenchmark?.[dimension] || false,
    // ? Getters for allBenchmark dimensions are in store/customer.js
  },
  actions: {
    clearAllBenchmarkCache({ commit }) {
      commit('CLEAR_ALL_BENCHMARK_CACHE');
    },
    async fetchAllBenchmark({ dispatch }, dimension = '') {
      if (
        ![...Object.keys(BENCHMARK_DIMENSIONS), ...Object.values(BENCHMARK_DIMENSIONS), 'all'].includes(dimension)
      ) {
        console.error('[TC] passed dimension must be valid'); // eslint-disable-line no-console
      }
      if (dimension === 'all') {
        const runAllPromises = Object.values(BENCHMARK_DIMENSIONS).map((dim) => dispatch('_runFetch', dim));
        return Promise.all(runAllPromises);
      }
      // ? Enable passing in singular dimension and use plural instead
      const dim = BENCHMARK_DIMENSIONS?.[
        Object.keys(BENCHMARK_DIMENSIONS).find((dimSingular) => dimSingular === dimension)
      ] || dimension;
      return dispatch('_runFetch', dim);
    },
    async _runFetch({ state, commit, dispatch }, dimension) {
      if (!isEmpty(state.allBenchmark?.[dimension]) || state.fetchingAllBenchmark?.[dimension] === true) {
        return state.allBenchmark?.[dimension]; // ? memoization returns cache
      }
      commit('SET_ALL_BENCHMARK_FETCH_STATE', { dimension, fetchState: true });
      const fetchFn = BENCHMARK_DIMENSIONS_FETCH_FN?.[dimension] || (() => Promise.resolve([]));
      return fetchFn()
        .then((response) => {
          if (dimension === 'industries') {
            const LANG = vm()?.$language?.current || 'en-us';
            const translations = isArray(response) && response.reduce((acc, item) => {
              acc[item.name] = item.translations.find(({ language }) => language === LANG)?.translation || item.name;
              return acc;
            }, {});
            dispatch('addStringTranslations', { translations });
            dispatch('addStringTranslations', { translations, customTranslations: true });
          }

          commit('SET_ALL_BENCHMARK_DIMENSION', { dimension, response });
          commit('SET_ALL_BENCHMARK_FETCH_STATE', { dimension, fetchState: false });
          return response;
        });
    },
  },
};
