import { getBaseUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';
import { store } from '@/store';

// filter tags by segment.tags
const filterTags = (tags) => tags.filter((tag) => store.getters.tagKeys(false)?.indexOf(tag.key) > -1);

// turn tag object to TCSelect object
function selectTag({ key, value, translation }) {
  const icon = 'zmdi-label';
  const label = translation || value;
  return { icon, label, value: key || value };
}

export function tagsPreferredOrderSort(a, b) {
  const preferredOrder = [
    'City',
    'RecruitingManager',
    'ResponsibleRecruiter',
    'Role',
    'Division',
    'Section',
    'BusinessUnit',
    'Job ad title',
    'AdvertId',
  ]; // TODO: Let the user perhaps set this dynamically somehow
  // TODO: Make into .toUpperCase() matching
  const hasA = preferredOrder.includes(a);
  const hasB = preferredOrder.includes(b);
  if (hasA && !hasB) return -1;
  if (!hasA && hasB) return 1;
  if (hasA && hasB) return preferredOrder.indexOf(a) - preferredOrder.indexOf(b);
  return 0;
}

/**
 ** tagsAPI.list() - Lists all available tagkeys in a paginated list with filter support.
 *
 * @param {Object} listArgs - the object for listArgs
 * @param {Integer} [listArgs.segment=null] - the segmentId, for getting tagKeys based on segment level
 * @param {Integer} [listArgs.customer=null] - the customerId, for getting tagKeys based on customer level
 * @param {Boolean} [listArgs.paginated=false] - for getting paginated response or not
 * @returns {Promise} (un-)paginated response from `/feedback/api/v2/tags/`
*/
function list({
  segment = null,
  customer = null,
  paginated = false,
} = {}) {
  const tagsUrl = customer
    ? '/feedback/api/v2/tags/'
    : `/feedback/api/segment/${segment}/tags/`;

  const params = customer ? { customer } : {};

  return getBaseUrl(tagsUrl)
    .query(params)
    .then((response) => (
      paginated && response.body.next !== undefined
        ? response.body
        : response.body.results
    ), handleAuthError);
}

/**
 ** tagsAPI.get() - Gets a specific tagkey in a paginated list with filter support.
 *
 * @param {Object} listArgs - the object for listArgs
 * @param {Integer} [listArgs.segment=null] - the segmentId, for getting tagKeys based on segment level
 * @param {Integer} [listArgs.customer=null] - the customerId, for getting tagKeys based on customer level
 * @param {Boolean} [listArgs.paginated=false] - for getting paginated response or not
 * @param {String} [listArgs.tagKey=''] - the tagKey to get
 *
 * @returns {Promise} (un-)paginated response from `/feedback/api/v2/tags/`
*/
function get({
  segment = null,
  customer = null,
  tagKey = '',
} = {}) {
  const tagsUrl = customer
    ? `/feedback/api/v2/tags/${tagKey}/`
    : `/feedback/api/segment/${segment}/tags/${tagKey}/`;
  const params = customer ? { customer } : {};

  return getBaseUrl(tagsUrl)
    .query(params)
    .then((response) => (response.body), handleAuthError);
}

export default {
  get,
  list,
  selectTag,
  filterTags,
};
