<template>
  <div class="tc-card-takeover">
    <div class="tc-card">
      <div class="tc-card-header p-card">
        <h3>{{ $gettext('Något har gått fel') }}</h3>
      </div>
      <div class="tc-card-body p-card">
        <div class="instructions">
          <!-- eslint-disable max-len -->
          <div v-if="error">
            <p class="alert alert-error">
              {{ error }}
            </p>
            <hr>
          </div>
          <h2>{{ $gettext('Koppla din användare') }}</h2>
          <h3>{{ $gettext('Om du är admin') }}</h3>
          <ol>
            <li>
              {{ $gettext('Klicka logga ut på denna sida och logga in med ditt gamla konto (användarnamn och lösenord)') }}
            </li>
            <li>
              {{ $gettext('Gå till fliken medlemmar och bjud in dig själv') }}
            </li>
            <li>
              {{ $gettext('Klicka på länken i mejlet och acceptera den inbjudan') }}
            </li>
          </ol>
          <h3>
            {{ $gettext('Om du är inte admin') }}
          </h3>
          <ol>
            <li>
              {{ $gettext('Be din administratör om en inbjudan') }}
            </li>
            <li>
              {{ $gettext('Acceptera inbjudan som kommer till din mejl') }}
            </li>
          </ol>
          <h3>
            {{ $gettext('Om du har aldrig loggat in tidigare') }}
          </h3>
          <ol>
            <li v-html="translations.askAdminInvite" />
            <li>
              {{ $gettext('Acceptera inbjudan som kommer till din mejl') }}
            </li>
          </ol>
          <p v-html="translations.contactInfo" />
          <p>
            {{ $gettext('/Teamet på Trustcruit') }}
          </p>
          <router-link
            v-slot="{ navigate }"
            custom
            to="/logout"
          >
            <button
              type="submit"
              class="btn btn-block"
              @click="navigate"
            >
              {{ $gettext('Logga ut') }}
            </button>
          </router-link>
          <!-- eslint-enable max-len -->
        </div>
        <login-footer class="bleed" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoginFooter from '../parts/LoginFooter';

export default {
  components: {
    LoginFooter,
  },
  data() {
    return {
      error: '',
    };
  },
  computed: {
    ...mapState({
      hiddenParams: (state) => state.router.hiddenParams,
    }),
    translations() {
      return {
        contactInfo: this.$gettext(`Upplever du problem eller hjälper ovan inte dig, kontakta
            <a href="mailto:support@trustcruit.com">support@trustcruit.com</a>
            så hjälper vi dig!`.replace(/\s+/g, ' ')),
        askAdminInvite: this.$gettext(`Be din administratör om en inbjudan (är din arbetsgivare inte kund idag,
              <a href="mailto:support@trustcruit.com">kontakta oss</a>
              så hjälper vi er komma igång)`.replace(/\s+/g, ' ')),
      };
    },
  },
  created() {
    this.error = this.$route?.params?.error || this.hiddenParams?.error || '';
  },
};
</script>
