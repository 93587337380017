<template>
  <div class="page-loader active">
    <div class="preloader pls-green">
      <svg
        class="pl-circular"
        viewBox="25 25 50 50"
      >
        <circle
          class="plc-path"
          cx="50"
          cy="50"
          r="20"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { localStorageIsAvailable } from 'Utils/storage';
import { store } from '@/store';
import gettext from '@/gettext';

const { $pgettext } = gettext;

export default {
  async beforeRouteEnter(to, from, next) {
    try {
      const me = await store.dispatch('fetchMe');
      if (me?.segments?.length === 0 && me?.customers?.length === 0) {
        throw new Error($pgettext('Error — MainPage', 'Din användare har inte åtkomst till något segment eller företag'));
      }
      const currSegParamOrLocal = Number(to.params.segmentId) || store.state.router.hiddenParams?.segmentId || localStorageIsAvailable && Number(localStorage.getItem('currentSegmentId')) || null;
      const segment = await store.dispatch('fetchSegment', currSegParamOrLocal);
      next({ name: 'overview', params: { ...to.params, segmentId: segment.id } });
    } catch (error) {
      store.dispatch('setHiddenParams', { error });
      next({ name: 'login' });
    }
  },
};
</script>
