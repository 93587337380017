import {
  // labelToDateRange,
  getPercentages,
  getCNPSObject,
  getAverage,
  // getCNPS,
  getSum,
} from 'Utils/graph';
// import { superagentFilter, getBaseUrl } from 'Utils/api';
// import { store } from '@/store';

/**
 * Stat
 * Wrap stat object in class with calculated
 * percentages and averages etc.
 */
class Stat {
  constructor(stat) {
    this.label = stat.label;
    if (stat.error) {
      this.error = stat.error;
    } else {
      this.stats = stat.y || stat.stats;
      this.count = stat.count || getSum(this.stats);
      this.cnps = getCNPSObject(this.stats);
      this.percentages = getPercentages(this.stats);
      this.average = stat.average || getAverage(this.stats);
      this.displayValues = this.getDisplayValues();
      this.sortValues = this.getSortValues();
      this.empty = Object.keys(this.stats).length === 0;
      this.anonymized = this.count > 0 && this.empty;
    }
    if (stat.x) {
      this.x = stat.x;
    }
  }

  mapPercentages(from = 'displayValue') {
    let percentages = {};
    Object.keys(this.percentages).forEach((key) => {
      percentages[key] = this.percentages[key][from];
    });
    return percentages;
  }

  // getCompareStats(field = 'global') {
  //   const promiseKey = `_${field}Promise`;
  //   if (this[promiseKey] instanceof Promise) {
  //     return this[promiseKey];
  //   }
  //   if (this.question === undefined) {
  //     return Promise.reject(new Error('Stat object has no question.'));
  //   }
  //   if (['customer', 'global'].indexOf(field) === -1) {
  //     return Promise.reject(new Error(`${field} is not a valid stat type.`));
  //   }
  //   const dateRange = labelToDateRange(this.label);
  //   const filter = { ...this.filter };
  //   if (field === 'customer') {
  //     filter.customer = store.getters.customerId;
  //   }
  //   if (dateRange) {
  //     filter.date = {
  //       type: 'absolute',
  //       dateGte: dateRange[0],
  //       dateLte: dateRange[1],
  //     };
  //   }
  //   this[promiseKey] = getBaseUrl('/shinyform/api/answers/stats_global/')
  //     .query({ question: this.question })
  //     .use(superagentFilter(filter))
  //     .then((response) => response.body)
  //     .then((rows) => {
  //       if (rows.length <= 0) return new Stat({ error: 'Stat object have no rows.' });
  //       return new Stat(rows[0]);
  //     })
  //     .then((cstats) => {
  //       vm.$set(this, field, cstats);
  //     });
  //   return this[promiseKey];
  // }

  // getSorted() {
  //   let sorted = new Map();
  //   Object.keys(this.stats).sort()
  //     .forEach((stat) => {
  //       sorted.set(stat, this.stats[stat]);
  //     });
  //   return sorted;
  // }

  getSortValues() {
    return {
      ...this.stats,
      count: this.count,
      cnps: this.cnps.value,
      average: this.average,
      percentages: this.mapPercentages('value'),
    };
  }

  getDisplayValues() {
    return {
      ...this.stats,
      count: this.count.toString(),
      cnps: this.cnps.displayValue,
      average: this.average ? this.average.toFixed(2) : null,
      percentages: this.mapPercentages(),
    };
  }
}

/**
 * Create Stat instance of list or
 * single stat object from API
 */
export function processStats(question /* filter = null */) {
  return (rows = []) => {
    if (!Array.isArray(rows)) {
      let stat = new Stat(rows);
      stat.question = question;
      // stat.filter = filter;
      return stat;
    }
    return rows.map((row) => {
      let stat = new Stat(row);
      stat.question = question;
      // stat.filter = filter;
      return stat;
    });
  };
}

export default Stat;
