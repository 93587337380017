/* eslint-disable import/no-unresolved, import/first, import/newline-after-import, import/order */
// import { localStorageIsAvailable } from 'Utils/storage';
import gettext from '@/gettext';

const { $pgettext } = gettext;

// No scope
import MainPage from 'Components/pages/MainPage';
import MainPagePlaceholder from 'Components/pages/MainPagePlaceholder';
import MainPrintPage from 'Components/pages/MainPrintPage';

// About
const AboutPage = () => import('Components/pages/AboutPage');
const AboutHelpPage = () => import('Components/pages/AboutHelp');
const AboutCNPSPage = () => import('Components/pages/AboutCNPS');

// Login/logout
import LoginPage from 'Components/pages/LoginPage';
import OTPAuthPage from 'Components/pages/OTPAuthPage';
import Logout from 'Components/pages/Logout';
import LogoutNoticePage from 'Components/pages/LogoutNoticePage';
import AcceptInvitePage from 'Components/pages/AcceptInvitePage';
import AcceptTermsPage from 'Components/pages/AcceptTermsPage';
const CreateNewAccount = () => import('Components/pages/CreateNewAccount');

// User scope
const UserSettingsPage = () => import('Components/pages/UserSettings');
const UserSettingsProfilePage = () => import('Components/pages/UserSettingsProfile');
const UserSettingsPrivilegesPage = () => import('Components/pages/UserSettingsPrivileges');
const UserSettingsNotificationsPage = () => import('Components/pages/UserSettingsNotifications');

// Customer scope
import CustomerSettingsPage from 'Components/pages/CustomerSettings';
import CustomerSettingsUsersPage from 'Components/pages/CustomerSettingsUsers';
import CustomerSettingsAccountPage from 'Components/pages/CustomerSettingsAccount';
import CustomerSettingsSegmentPage from 'Components/pages/CustomerSettingsSegment';
import CustomerSettingsInvitePage from 'Components/pages/CustomerSettingsInvite';
const CustomerSettingsReportsPage = () => import('Components/pages/CustomerSettingsReports');
const CustomerSettingsImportExportPage = () => import('Components/pages/CustomerSettingsImportExport');
const CustomerSettingsSendingLogPage = () => import('Components/pages/CustomerSettingsSendingLog');

// Segment scope
import SegmentOverviewPage from 'Components/pages/SegmentOverview';
import StepFormPage from 'Components/pages/StepForm';
import StepFormsPage from 'Components/pages/StepForms';
import FollowUpPage from 'Components/pages/FollowUp';
const SegmentNote = () => import('Components/pages/SegmentNotes');
const SegmentNotesOverview = () => import('Components/pages/SegmentNotesOverview');
import SegmentBoardPage from 'Components/pages/SegmentBoard';
import SegmentSettingsPage from 'Components/pages/SegmentSettings';
import SegmentSettingsMembersPage from 'Components/pages/SegmentSettingsMembers';
import SegmentSettingsInvitePage from 'Components/pages/SegmentSettingsInvite';
const SegmentApplicantsPage = () => import('Components/pages/SegmentApplicants');

// Print-specific
import PrintCard from 'Components/pages/PrintCard';
import PrintEmpty from 'Components/pages/PrintEmpty';

/* eslint-enable import/no-unresolved, import/first, import/newline-after-import */

const routes = [
  {
    path: '/segment/:segmentId(\\d+)?', // ? Requires segmentId
    component: MainPage,
    name: 'segment',
    meta: {
      auth: true,
      needSegment: true,
    },
    children: [
      {
        path: 'overview',
        component: SegmentOverviewPage,
        meta: {
          auth: true,
          needSegment: true,
          intercomTour: 'dashboard',
          siteTitle: () => $pgettext('siteTitle — overview', 'Översikt'),
        },
        name: 'overview',
      },
      {
        path: 'notes',
        component: SegmentNotesOverview,
        meta: {
          auth: true,
          needSegment: true,
          gaUrl: '/segment/notes',
          siteTitle: () => $pgettext('siteTitle — notes', 'Anteckningar'),
        },
        name: 'notes',
      },
      {
        path: 'notes/:noteId',
        component: SegmentNote,
        meta: {
          auth: true,
          needSegment: true,
          gaUrl: '/segment/notes',
          // TODO: Set sitetitle to note title?
          siteTitle: () => $pgettext('siteTitle — notes', 'Anteckningar'),
        },
        name: 'note',
      },
      {
        path: 'follow-up/:applicantId?',
        component: FollowUpPage,
        props: true,
        meta: {
          auth: true,
          needSegment: true,
          gaUrl: '/segment/follow-up',
          siteTitle: () => $pgettext('siteTitle — follow-up', 'Follow Up'),
        },
        name: 'follow-up',
      },
      {
        path: 'applicants/:applicantId?',
        component: SegmentApplicantsPage,
        meta: {
          auth: true,
          needSegment: true,
          gaUrl: '/segment/applicants',
          siteTitle: () => $pgettext('siteTitle — applicants', 'Feedback'),
        },
        name: 'applicants',
      },
      {
        path: 'forms',
        component: StepFormsPage,
        props: true,
        meta: {
          auth: true,
          needSegment: true,
          gaUrl: '/segment/forms',
          siteTitle: () => $pgettext('siteTitle — forms', 'Formulär'),
        },
        name: 'forms',
      },
      {
        path: 'forms/:level',
        component: StepFormPage,
        props: true,
        meta: {
          auth: true,
          needSegment: true,
          gaUrl: '/segment/forms',
          siteTitle: () => $pgettext('siteTitle — forms/:level', 'Formulär'),
        },
        name: 'form',
      },
      {
        path: 'board/:boardId',
        component: SegmentBoardPage,
        props: true,
        meta: {
          auth: true,
          needSegment: true,
          gaUrl: '/segment/board',
          siteTitle: () => $pgettext('siteTitle — board', 'Laddar rapport…'),
        },
        name: 'board',
      },
      {
        path: 'members',
        component: SegmentSettingsPage,
        meta: {
          auth: true,
          needSegment: true,
        },
        children: [
          {
            path: '',
            component: SegmentSettingsMembersPage,
            meta: {
              auth: true,
              needSegment: true,
              siteTitle: () => $pgettext('siteTitle — segmentSettingsMembers', 'Medlemmar'),
            },
            name: 'segmentSettingsMembers',
          },
          {
            path: 'invite',
            component: SegmentSettingsInvitePage,
            props: true,
            meta: {
              auth: true,
              needSegment: true,
              siteTitle: () => $pgettext('siteTitle — segmentSettingsInvite', 'Bjud in medlemmar'),
            },
            name: 'segmentSettingsInvite',
          },
        ],
      },
    ],
  },
  {
    path: '/print',
    component: MainPrintPage,
    children: [
      {
        path: 'board/:boardId',
        component: SegmentBoardPage,
        props: true,
        meta: {
          printAuth: true,
          needSegment: true,
          gaUrl: '/print/board',
        },
        name: 'print-board',
      },
      {
        path: 'card/:cardId',
        component: PrintCard,
        props: true,
        meta: {
          printAuth: true,
          needSegment: true,
          gaUrl: '/print/card',
        },
        name: 'print-card',
      },
      {
        path: '',
        component: PrintEmpty,
        name: 'print-error',
        props: true,
      },
    ],
  },
  {
    path: '/customer/:customerId(\\d+)?/settings',
    component: MainPage,
    meta: {
      auth: true,
      needSegment: true,
      backHistory: true,
      needCustomer: true,
    },
    children: [
      {
        name: 'customerSettings',
        path: '',
        component: CustomerSettingsPage,
        meta: {
          auth: true,
          needSegment: true,
          segmentHidden: true,
        },
        children: [
          {
            path: '',
            component: CustomerSettingsAccountPage,
            meta: {
              auth: true,
              needSegment: true,
              segmentHidden: true,
              siteTitle: () => $pgettext('siteTitle — customerSettingsAccount', 'Mitt företag'),
            },
            name: 'customerSettingsAccount',
          },
          {
            path: 'reports',
            component: CustomerSettingsReportsPage,
            meta: {
              auth: true,
              needSegment: true,
              segmentHidden: true,
              siteTitle: () => $pgettext('siteTitle — customerSettingsReports', 'Rapporter'),
            },
            name: 'customerSettingsReports',
          },
          {
            path: 'segment',
            component: CustomerSettingsSegmentPage,
            meta: {
              auth: true,
              needSegment: true,
              segmentHidden: true,
              siteTitle: () => $pgettext('siteTitle — customerSettingsSegment', 'Segment'),
            },
            name: 'customerSettingsSegment',
          },
          {
            path: 'users',
            component: CustomerSettingsUsersPage,
            meta: {
              auth: true,
              needSegment: true,
              segmentHidden: true,
              siteTitle: () => $pgettext('siteTitle — customerSettingsUsers', 'Användare'),
            },
            name: 'customerSettingsUsers',
          },
          {
            path: 'invite',
            component: CustomerSettingsInvitePage,
            meta: {
              auth: true,
              needSegment: true,
              segmentHidden: true,
              siteTitle: () => $pgettext('siteTitle — customerSettingsInvite', 'Bjud in användare'),
            },
            name: 'customerSettingsInvite',
          },
          {
            path: 'import-export',
            component: CustomerSettingsImportExportPage,
            meta: {
              auth: true,
              needSegment: true,
              segmentHidden: true,
              siteTitle: () => $pgettext('siteTitle — customerSettingsImportExport', 'Importera/Exportera'),
            },
            name: 'customerSettingsImportExport',
          },
          {
            path: 'sending-log',
            component: CustomerSettingsSendingLogPage,
            meta: {
              auth: true,
              needSegment: true,
              segmentHidden: true,
              siteTitle: () => $pgettext('siteTitle — customerSettingsSendingLog', 'Utskickslogg'),
            },
            name: 'customerSettingsSendingLog',
          },
        ],
      },
    ],
  },
  {
    path: '/user/settings',
    component: MainPage,
    meta: {
      auth: true,
      needSegment: true,
      segmentHidden: true,
      backHistory: true,
    },
    children: [
      {
        name: 'userSettings',
        path: '',
        component: UserSettingsPage,
        meta: {
          auth: true,
          needSegment: true,
          segmentHidden: true,
        },
        children: [
          {
            path: '',
            component: UserSettingsProfilePage,
            name: 'userProfile',
            meta: {
              auth: true,
              needSegment: true,
              segmentHidden: true,
              siteTitle: () => $pgettext('siteTitle — userProfile', 'Användarprofil'),
            },
          },
          {
            path: 'privileges',
            component: UserSettingsPrivilegesPage,
            name: 'userPrivileges',
            meta: {
              auth: true,
              needSegment: true,
              segmentHidden: true,
              siteTitle: () => $pgettext('siteTitle — userPrivileges', 'Privilegier'),
            },
          },
          {
            path: 'notifications',
            component: UserSettingsNotificationsPage,
            name: 'notifications',
            meta: {
              auth: true,
              needSegment: true,
              segmentHidden: true,
              siteTitle: () => $pgettext('siteTitle — notifications', 'Aviseringar'),
            },
          },
        ],
      },
    ],
  },
  {
    path: '/dashboard',
    component: MainPage,
    meta: {
      auth: true,
      needSegment: true,
      backHistory: true,
    },
    children: [
      {
        path: '/about',
        component: AboutPage,
        children: [
          {
            path: '',
            component: AboutHelpPage,
            name: 'aboutHelp',
            meta: {
              auth: true,
              needSegment: true,
              segmentHidden: true,
              siteTitle: () => $pgettext('siteTitle — aboutHelp', 'Om Trustcruit'),
            },
          },
          {
            path: 'cnps',
            component: AboutCNPSPage,
            name: 'aboutCNPS',
            meta: {
              auth: true,
              needSegment: true,
              segmentHidden: true,
              siteTitle: () => $pgettext('siteTitle — aboutCNPS', 'CNPS'),
            },
          },
        ],
      },
    ],
  },
  {
    name: 'new-account',
    path: '/new-account/:id?/:token?',
    props: true,
    component: CreateNewAccount,
    meta: {
      gaUrl: '/new-account',
      siteTitle: () => $pgettext('siteTitle — new-account', 'Nytt konto'),
    },
  },
  {
    name: 'accept-terms',
    path: '/accept-terms',
    props: true,
    component: AcceptTermsPage,
    meta: {
      gaUrl: '/accept-terms',
      siteTitle: () => $pgettext('siteTitle — accept-terms', 'Acceptera villkor'),
    },
  },
  {
    name: 'login',
    path: '/login',
    props: true,
    component: LoginPage,
    meta: {
      siteTitle: () => $pgettext('siteTitle — login', 'Login'),
    },
  },
  { // !refactor, we will remove URL-based tokens in the future for increased security, affects LI- and GDPR-redirected logins
    path: '/login/:token',
    component: LoginPage,
    props: true,
    meta: {
      gaUrl: '/login/token',
    },
  },
  {
    name: 'otp',
    path: '/otp/',
    component: OTPAuthPage,
    props: true,
    meta: {
      gaUrl: '/otp',
      siteTitle: () => $pgettext('siteTitle — otp', 'One-Time Password'),
    },
  },
  {
    name: 'invitation',
    path: '/invitation/:id/:token',
    component: AcceptInvitePage,
    props: true,
    meta: {
      invitationType: 'customerUser',
      gaUrl: '/invitation',
      siteTitle: () => $pgettext('siteTitle — invitation', 'Inbjudan'),
    },
  },
  {
    name: 'segmentInvitation',
    path: '/segment_invitation/:id/:token',
    component: AcceptInvitePage,
    props: true,
    meta: {
      invitationType: 'segmentUser',
      gaUrl: '/segment_invitation',
      siteTitle: () => $pgettext('siteTitle — segmentInvitation', 'Inbjudan'),
    },
  },
  {
    path: '/logout',
    component: Logout,
    name: 'logout',
    meta: {
      siteTitle: () => $pgettext('siteTitle — logout', 'Loggar ut…'),
    },
  },
  {
    path: '/logout-notice',
    component: LogoutNoticePage,
    props: true,
    name: 'logoutNoticePage',
  },
  {
    path: '/test', // ? Only used for tests, let's vuex work
    component: MainPage,
    children: [
      {
        path: '',
        component: PrintEmpty,
        name: 'TEST_PAGE',
        props: { error: 'You’re not really supposed to be here' },
      },
    ],
  },
  {
    path: '/segment/:segmentId(\\d+)?', // ? Accept segment routes and get segmentId in component, then reroute
    component: MainPagePlaceholder,
    name: 'segmentNoId',
    meta: {
      auth: true,
      needSegment: true,
    },
  },
  {
    path: '/',
    name: 'home',
    redirect: () => {
      if (process.env.NODE_ENV === 'test') return { name: 'TEST_PAGE' }; // ? Necessary for avoiding loop in tests
      return { name: 'segmentNoId' }; // ? Will check for isAuthenticated and reroute accordingly
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

export default routes;
