<template>
  <VMenu
    ref="vmenuSelect"
    theme="select"
    :shown="isOpen"
    :triggers="[]"
    :popper-triggers="[]"
    v-bind="popperSettings ?? {}"
  >
    <div
      v-bind="$attrs"
      ref="container"
      v-tooltip="{ content: readonly ? $gettext('Låst fält') : '' }"
      class="tc-select"
      :class="classes"
      @click.stop="addable|| multiselect || searchable ? open() : toggle()"
    >
      <div
        ref="wrapperControl"
        class="tc-select-control"
        data-input="true"
        tabindex="0"
        @keyup.stop.prevent.enter="open"
        @keyup.prevent.esc="onWrapperEscape"
      >
        <div
          v-if="isEmpty && searchTerm.length === 0"
          class="tc-select-placeholder"
        >
          {{ placeholder }}
        </div>
        <div
          class="tc-select-values-wrapper"
          :class="{ 'tc-select-chip-padding': multiselect }"
        >
          <draggable
            v-if="multiselect"
            v-model="selected"
            class="chip-set chip-set-sm"
            item-key="id"
            @change="update"
          >
            <template #item="{element, index}">
              <chip
                v-tooltip="{
                  content: getTooltip(element.label) || null,
                  strategy: 'fixed',
                  container: 'body',
                }"
                type="input"
                class="tc-select-value cursor-move"
                :icon="element?.icon || null"
                :class="optionsClasses.length > index ? optionsClasses[index] : element?.class ?? ''"
                :title="striphtml(
                  `${element?.description || element?.label}${element?.sidelabel ? ` – ${element.sidelabel}`: ''}`
                )"
                @removed="remove(element)"
              >
                {{ striphtml(element?.label) }}
              </chip>
            </template>
          </draggable>
          <template v-else>
            <span
              v-for="(option,index) in selected"
              :key="option?.id || index"
              class="tc-select-value"
              :title="getTitle(option)"
              :data-sidelabel="option?.sidelabel || null"
            >
              <i
                v-if="option.icon"
                :class="`zmdi ${option.icon} pr-1`"
              />
              {{ option.label }}
            </span>
          </template>
          <span
            v-if="searchable || multiselect || addable"
            v-show="isOpen"
            class="tc-select-input"
          >
            <input
              ref="searchField"
              v-model="searchTerm"
              class="form-control"
              type="text"
              :aria-label="!labelledby ? placeholder : ''"
              :aria-labelledby="labelledby ? labelledby: ''"
              @keydown.exact.enter="addable ? inputAdd($event) : null"
              @keydown.exact.tab="tabToOptions && !isLoading && optionRefs
                ? focus(optionRefs[0]) : close()"
              @keyup.exact.down="tabToOptions && !isLoading && optionRefs
                ? focus(optionRefs[0]) : null"
              @keydown.delete="inputDelete"
            >
          </span>
        </div>
        <span
          v-if="!readonly"
          class="tc-select-arrow-wrapper cursor-pointer"
          @click.stop="toggle"
        ><i class="zmdi zmdi-chevron-down" /></span>
      </div>
    </div>

    <template #popper>
      <div
        class="tc-select-options"
        tabindex="-1"
        @click.stop
      >
        <p
          v-if="isLoading"
          class="tc-select-loading-text subtle-text"
        >
          {{ $gettext('Laddar...') }}
        </p>
        <ul
          v-else
          class="tc-select-options-list"
        >
          <li
            v-for="(option, index) in selectable"
            :key="option.id"
            :ref="setItemRef"
            v-tooltip="{
              content: getTooltip(option.label) || null,
              strategy: 'fixed',
              container: 'body',
              placement: 'left',
            }"
            :title="getTitle(option)"
            :data-sidelabel="option.sidelabel || null"
            class="tc-select-options-list-item"
            tabindex="0"
            @keydown.delete="inputDelete"
            @keyup.exact.stop.esc="close"
            @keyup.exact.enter="select(option, optionRefs[index])"
            @keydown.exact.prevent.up="focus(optionRefs[index - 1])"
            @keydown.exact.prevent.down="focus(optionRefs[index + 1])"
            @keyup.exact.shift.prevent.up="focus(optionRefs[index - 1])"
            @keyup.exact.shift.prevent.down="focus(optionRefs[index + 1])"
            @click="select(option)"
          >
            <p>
              <i
                v-if="option.icon"
                :class="`zmdi ${option.icon} pr-1`"
              />
              <span v-html="(option.marked || option.label)" />
            </p>
          </li>
          <li
            v-if="selectable.length === 0"
            class="tc-select-options-list-item tc-select-no-options"
          >
            {{ noOptionsText || $gettext('Inga fler val finns') }}
          </li>
          <li
            v-if="populator"
            v-show="populator.hasMore()"
            class="tc-select-options-list-item tc-select-load-more"
            :disabled="populator.loading || (requestPaginator && !requestPaginator.hasNext()) || null"
            tabindex="0"
            @keyup.exact.enter="addNext"
            @click="addNext"
          >
            {{ loadMoreText }}
            <i
              v-show="populator && populator.loading"
              class="zmdi zmdi-spinner zmdi-hc-spin"
            />
          </li>
        </ul>
      </div>
    </template>
  </VMenu>
</template>

<script>
import Draggable from 'vuedraggable';
import { debounce } from 'lodash-es';
import { mapActions } from 'vuex';
import { striphtml, translateTerm } from 'Utils/general';
import { markLabel, wrapValues, labelHit } from 'Utils/tcselectHelpers';
import RequestPaginator from 'Utils/fresh-paginators/RequestPaginator';
import SearchPopulator from 'Utils/fresh-paginators/SearchPopulator';
import Chip from 'Components/parts/chips/Chip';
import gettext from '@/gettext';

const { $gettext } = gettext;

/**
 ** TCSelect
 *
 * @param {Array} modelValue - An array with labelValue objects
 * @example
 * [ { value: 'ja', label: 'Jaja!' }, { value: 'nej', label: 'Nejnejnej!'} ]
 *
 * @example <caption>Add tooltips by adding a string like this in the label string</caption>
 * `<span data-tooltip="${this.$gettext('Extra info')}"></span>`
 * @example <caption>Add badges by adding a string like this in the label string</caption>
 * `<small class="badge mx-1"><i class="zmdi zmdi-calendar"></i> ${this.$gettext('Header')}</small>`
 *
 */
export default {
  name: 'TCSelect',
  components: {
    Draggable,
    Chip,
  },
  props: {
    modelValue: Array,
    options: Array,
    optionsClasses: {
      type: Array,
      default: () => [], // ? pass ex. ['', 'chip-error'] for marking second chip red
    },
    noOptionsText: {
      type: String,
      default() {
        return $gettext('Inga fler val finns');
      },
    },
    labelledby: String,
    onChange: {
      type: Function,
      default: (term, options, current) => new Promise((resolve, reject) => {
        resolve((options || []).filter(labelHit(term)).map(markLabel(term)));
      }),
    },
    // ? For vuex store populators
    populateStoreFn: {
      type: Function,
      default: (values) => values, // ? Pass a function (vuex action) that can set passed values into the vuex store by replacing them
    },
    cleanPaginatorFn: {
      type: Function,
      default: (reqPag = this.requestPaginator, populator = this.populator) => ({
        ...reqPag._dataset,
        results: populator?.stack.map((option) => {
          if (option.value) return option.value;
          return option;
        }) || [],
      }), // ? Pass a function that can manipulate reqPaginator and populator for keeping something else than label value in store
    },
    // ? For vuex store loading states (promise-based)
    loadingStorePromise: {
      type: Promise,
      default: () => Promise.resolve(), // ? Pass a promise (vuex state perhaps) that will pause the onChange() Fn until fulfilled
    },
    loading: { // ? Use instead of loadingStorePromise
      type: Boolean,
      default: false,
    },
    wrapResults: {
      type: Function,
      default: (response) => response, // ? Pass custom function that re-maps response results if necessary
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    canDeselect: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    searchIsSeparateReq: { // ? Pass true if using another request than the onChange one, when searching. Sorry, the last drop has fallen, this component is a mess as of now.
      type: Boolean,
      default: false,
    },
    addable: { // ? Can option be added on enter or comma-/space-separation?
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default(props) {
        return props?.searchable || props?.multiselect || props?.addable ? $gettext('Skriv för att söka') : $gettext('Välj ett alternativ');
      },
    },
    caseSensitive: { // ? Should the options be filtered with case sentivity?
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    initOpen: {
      type: Boolean,
      default: false,
    },
    addBeforeClosing: {
      type: Boolean,
      default: false,
    },
    tabToOptions: {
      type: Boolean,
      default: true,
    },
    clearAfterSelect: {
      type: Boolean,
      default: true,
    },
    extendLabel: Boolean,
    popperSettings: {
      type: Object,
      default: () => ({
        container: false,
        strategy: 'absolute',
      }),
    },
  },
  emits: ['open', 'close', 'update:options', 'select', 'update:modelValue'],
  data() {
    return {
      uuid: Math.random().toString(10).substring(2),
      selected: [],
      searchTerm: '',
      lastSearchTerm: '',
      filteredOptions: [],
      loadingInternal: false,
      optionRefs: [],
      isOpen: false,
      requestPaginator: null,
      populator: null,
    };
  },
  computed: {
    isEmpty() {
      return this.selected?.length === 0 ?? true;
    },
    isLoading() {
      return this.loading || this.loadingInternal;
    },
    classes() {
      return {
        active: this.isOpen,
        disabled: this.disabled,
        readonly: this.readonly,
        'tc-select-multiselect': this.multiselect,
      };
    },
    dontUsePaginatorCache() {
      return this.populateStoreFn.toString() !== `function _default(values) {
        return values;
      }`; // ? if the populateStoreFn prop is other than default
    },
    selectable() {
      if (this.extendLabel) {
        const labelIsExtended = this.filteredOptions[0].label
          .includes(translateTerm(this.filteredOptions[0].description));
        if (!labelIsExtended) {
          this.filteredOptions.forEach((option) => { option.label = `${option.label} - (${translateTerm(option.description)})`; });
        }
      }
      if (this.multiselect && this.filteredOptions && Array.isArray(this.filteredOptions)) {
        return this.filteredOptions
          .filter((option) => this.selected.findIndex(
            this.caseSensitive
              ? (o) => o.value === option.value
              : (o) => String(o.value).toLowerCase() === String(option.value).toLowerCase(),
          ) === -1);
        // !FIX: May be a bug that it can't use .filter on filteredOptions (not an array??)
      }
      return this.canDeselect ? [{ label: this.$gettextInterpolate(
        '<span class="small-text subtle-text"><i>%{name}</i></span>',
        { name: this.$gettext('Ingen') },
      ),
      value: 'none' }]
        .concat(this.filteredOptions) : this.filteredOptions;
    },
    loadingPlaceholder() {
      return this.isLoading ? this.$gettext('Laddar...') : this.placeholder;
    },
    loadMoreText() {
      return this.populator?.loading
        ? this.$pgettext('Pagination li-button', 'Laddar fler...')
        : this.$pgettext('Pagination li-button', 'Ladda fler');
    },
  },
  watch: {
    selected: {
      deep: true,
      handler(newVal) {
        if (newVal?.[0]?.value === 'none') {
          this.clear();
        }
      },
    },
    searchTerm: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.growInput();
          if (this.searchIsSeparateReq) this.debouncedSearch();
          else this.change();
        }
      },
    },
    modelValue: {
      deep: true,
      handler(newValue) {
        this.setup(newValue, this.options, this.settings);
      },
    },
    options: {
      deep: true,
      handler(newValue) {
        this.setup(this.selected, newValue, this.settings);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setup(this.modelValue, this.options, this.settings);
      this.isOpen = this.initOpen;
    });
  },
  beforeUpdate() {
    this.optionRefs = [];
  },
  methods: {
    striphtml,
    ...mapActions([
      'addCloseHandler',
      'removeCloseHandler',
      'removeAllCloseHandlers',
    ]),
    updatePopperPosition() { this.$refs?.vmenuSelect?.onResize?.(); },
    debouncedSearch: debounce(function debouncedFn() { this.change(); }, 250),
    onWrapperEscape(evt) {
      if (this.isOpen) evt.stopPropagation();
      this.close();
    },
    setItemRef(el) {
      if (el) this.optionRefs.push(el);
    },
    toggle() {
      if (this.isOpen) this.close();
      else this.open();
    },
    open() {
      if (!this.disabled && !this.readonly) {
        if (!this.isOpen) {
          this.isOpen = true;
          this.$emit('open');
          this.removeAllCloseHandlers();
          this.addCloseHandler(this.close);
          this.change();
        }
        this.focus();
      }
    },
    close() {
      if (this.addBeforeClosing) this.inputAdd();
      if (this.$refs.searchField) {
        this.$refs.searchField.blur();
        this.$refs.searchField.value = '';
      }
      this.searchTerm = '';
      this.isOpen = false;
      this.$emit('close');
      this.removeCloseHandler(this.close);
      if (this.$refs.wrapperControl) this.focus(this.$refs.wrapperControl);
    },
    growInput() {
      if (this.$refs.searchField) {
        this.$refs.searchField.style.width = `${this.searchTerm.length + 1}em`;
      }
    },
    inputAdd(e) {
      if (e) e.preventDefault();
      if (this.searchTerm !== '') {
        const terms = wrapValues(this.searchTerm.split(/[ ,]+/));
        this.setup([...this.selected, ...terms], this.options, this.settings);
        this.update();
        this.searchTerm = '';
      }
    },
    inputDelete(e) {
      if (this.searchTerm.length === 0 && !this.disabled) {
        this.remove(this.selected[this.selected.length - 1]);
      }
    },
    filterPaginatorFn(item) {
      // ? Pass custom function used in paginator's stack.filter()
      if (this.searchTerm === '') return true;
      let string = typeof item === 'string' ? striphtml(item) : String(striphtml(item?.label) || '');
      return string.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
    },
    change() {
      this.growInput();
      this.loadingInternal = true;

      this.loadingStorePromise.then(() => {
        if (!this.dontUsePaginatorCache && this.requestPaginator !== null && this.searchTerm === this.lastSearchTerm) {
          // ? If has a paginator.stack already, try using that first.
          // ? Instead of requesting more via the passed onChange() method
          // ? Dont go here if 1. using vuex store cache and 2. is paginated
          return (async () => {
            await this.populator.setPaginator(this.requestPaginator);
            this.filteredOptions = wrapValues(this.populator.filteredStack);
            this.$emit('update:options', this.filteredOptions);
            this.loadingInternal = false;
          })();
        }

        this.lastSearchTerm = this.searchTerm;
        return this.onChange(this.searchTerm, wrapValues(this.options))
          .then((options) => {
            if (options instanceof Error) throw options;
            // If response is a paginated response
            if (options.next !== undefined) {
              this.requestPaginator = new RequestPaginator(options, this.wrapResults);
              this.populator = new SearchPopulator(this.requestPaginator, this.filterPaginatorFn);
              (async () => {
                await this.populator.loadMore();
                this.filteredOptions = wrapValues(this.populator.filteredStack);
              })();
            } else {
              this.filteredOptions = wrapValues(this.wrapResults(options));
            }
            this.$emit('update:options', this.filteredOptions);
            this.loadingInternal = false;
          })
          .catch((err) => {
            console.warn('[TC] TCSelect', err); // eslint-disable-line no-console
            this.loadingInternal = false;
            return err;
          });
      });
    },
    focus(el = this.$refs.searchField) {
      if (el) {
        setTimeout(() => {
          el.focus();
        }, 1);
      }
    },
    clearInput(el = this.$refs.searchField) {
      if (el?.value) {
        el.value = '';
        this.searchTerm = '';
        // this.update(); perhaps we need this for when searchable=true ?
        this.change();
        this.updatePopperPosition();
      }
    },
    async addNext() {
      this.focus();
      if (this.populator.loading === false && (await this.populator.hasMore())) {
        await this.populator.loadMore();
        if (this.searchIsSeparateReq) {
          this.filteredOptions = wrapValues(this.populator.filteredStack)
            .filter(labelHit(this.searchTerm))
            .map(markLabel(this.searchTerm));
          this.$emit('update:options', this.filteredOptions);
        } else {
          const cleanPag = this.cleanPaginatorFn(this.requestPaginator, this.populator);
          this.populateStoreFn(cleanPag);
          this.$nextTick(() => this.change());
        }
      }
    },
    select(option, focusOn = null) {
      if (this.disabled) return;

      if (!this.multiselect) {
        this.selected.forEach((o) => {
          this.remove(o);
        });
      }
      this.selected.push(option);
      if (!this.isLoading && this.tabToOptions && focusOn) {
        this.focus(focusOn);
      } else {
        this.focus();
      }
      if (!this.multiselect) {
        this.close();
      }
      this.update();
      this.$emit('select', option);
      if (this.clearAfterSelect) {
        this.clearInput();
      }
    },
    update() {
      if (!this.disabled) this.$emit('update:modelValue', this.selected);
      this.updatePopperPosition();
    },
    remove(option) {
      // ? Good to know is that this and other emits trigger HTML form actions if `@submit.prevent` is used
      if (this.disabled) return;

      const index = this.selected.findIndex((o) => o.value === option.value);
      this.selected.splice(index, 1);
      this.$emit('update:modelValue', this.selected);
      if (this.isOpen) {
        this.focus();
      }
      this.change();
      this.updatePopperPosition();
    },
    clear() {
      this.$emit('update:modelValue', []);
      this.selected = [];
      this.change();
      this.updatePopperPosition();
    },
    clearPaginator() {
      this.requestPaginator = null;
      this.filteredOptions = [];
      // this.open();
    },
    // !refactor hasTooltip & getTooltip some day. Too basic and not correctly placed. Perhaps add tooltip key to labelValue object?
    hasTooltip(string) {
      return string.indexOf('data-tooltip="');
    },
    getTooltip(string) {
      if (!string) return false;
      const indexOf = this.hasTooltip(string);
      if (indexOf > -1) {
        const startOf = string.substring(indexOf + 'data-tooltip="'.length);
        return startOf.split('"')[0];
      }
      return false;
    },
    getTitle(option) {
      return striphtml(`${option?.description || option.label}${option?.sidelabel && ` – ${option?.sidelabel}` || ''}`);
    },
    setup(value = [], options = this.filteredOptions) {
      this.filteredOptions = wrapValues(options);
      this.selected = wrapValues(value);
    },
  },
};
</script>
