<template>
  <li
    v-if="canShowBadge"
    v-tooltip="{ content: badgeValuesTooltip, distance: 12 }"
    class="badge badge-tag badge-grid"
    :class="{ clickable, 'badge-overlapping': item.isCardFilter }"
    data-testid="badge-tag"
    @click.stop="addToQuery"
  >
    <span>
      <span class="badge-tag-key">{{ segmentTagKeyVal[item.label] || item.label }}</span>
      <span
        class="badge-tag-value"
        :title="tooltip"
      >
        {{ badgeText }}
      </span>
    </span>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isArray } from 'lodash-es';
import { striphtml, objectMap } from 'Utils/general';
import { prefixTag } from 'Utils/api';

export default {
  name: 'BadgeListItemTag',
  props: {
    item: Object, // ? [{ slug: tags__badge, label: Badge, value: ['val']}]
    clickable: {
      type: Boolean,
      default: false,
    },
    forceTooltips: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['segmentTagKeyVal']),
    canShowBadge() { return isArray(this.item.value) && this.item.value.length > 0; },
    valueLength() { return this.item.value?.length; },
    tooManyValues() { return this.valueLength > 2 || false; },
    tooltip() {
      const label = this.segmentTagKeyVal?.[this.item.label] || this.item.label;
      const prefix = this.clickable
        ? this.$gettextInterpolate(
          this.$pgettext('Tooltip — What happens if I click on badge tag?', 'Filtrera på %{label}: '),
          { label },
        )
        : '';
      const labels = this.item.value.filter((item, idx) => idx < 10).join(', ');
      return `${prefix}${labels}${this.valueLength >= 10 ? '…' : ''}`;
    },
    badgeValuesTooltip() {
      if (!this.tooManyValues && !this.forceTooltips) return '';
      return this.tooltip;
    },
    badgeText() {
      if (!this.item.value) return '';
      let label = striphtml(this.item.label);
      if (this.segmentTagKeyVal?.[this.item.label] !== label) {
        label = this.segmentTagKeyVal?.[this.item.label];
      }
      const len = this.valueLength;
      const translationSmall = this.$npgettext(
        'Badgelist item tag interpolation small',
        '%{val1}',
        '%{val1} %{operand} %{val2}',
        len,
      );
      const translationLarge = this.$pgettext(
        'Badgelist item tag interpolation large',
        '%{val1} %{operand} %{len} till',
      );
      return this.$gettextInterpolate(
        this.tooManyValues ? translationLarge : translationSmall,
        {
          label,
          operand: this.$pgettext('Badgelist item tag operand', 'och'),
          val1: striphtml(this.item.value[0]) || '',
          val2: striphtml(this.item.value[1]) || '',
          tooltip: this.tooltip,
          len: len - 1,
        },
      );
    },
  },
  methods: {
    ...mapActions(['closeModal']),
    addToQuery(evt) {
      if (this.clickable && this.$route.name === 'applicants') {
        let mergedWithQueries = false;
        let newQuery = objectMap(this.$route.query, (qVal, qKey) => {
          if (qKey === this.item.slug) {
            // TODO: take care of key match, merge values?
            // this.tags = { ...this.tags, [unprefixTag(qKey)]: query[qKey].split(',') };
            mergedWithQueries = true;
            return qVal;
          }
          return qVal;
        });

        if (!mergedWithQueries) {
          newQuery = { ...newQuery, [prefixTag(this.item.label)]: this.item.value.join(',') };
        }

        if ('applicantId' in this.$route.params) {
          delete this.$route.params.applicantId;
          this.closeModal();
        }
        this.$nextTick(() => this.navigateTo(newQuery));
      }
    },
    navigateTo(query) {
      this.$router.push({ name: this.$route.name, query, params: this.$route.params })
        .catch((err) => {});
    },
  },
};
</script>
