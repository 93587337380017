<template>
  <li
    v-tooltip="{
      content: badgeValuesTooltip,
      html: true,
      distance: 12,
    }"
    :class="{
      'badge-overlapping': item.isCardBenchmark,
      'badge-error': !badgeNewAndEmpty && !canShowBadge,
      'badge-null': badgeNewAndEmpty,
    }"
    class="badge badge-filter badge-grid"
    data-testid="badge-filter"
  >
    <span v-html="badgeText" />
    <div
      v-if="removable"
      v-tooltip="{
        content: () => $pgettext('Tooltip — Remove badge', 'Ta bort'),
        distance: 8,
        container: 'body',
        placement: 'right',
      }"
      class="remove"
      :aria-label="$pgettext('Tooltip — Remove badge', 'Ta bort')"
      @click.prevent="$emit('remove', item)"
    >
      <i class="zmdi zmdi-close" />
    </div>
  </li>
</template>

<script>
import { isArray } from 'lodash-es';
import { hideAllPoppers } from 'floating-vue';
import { striphtml } from 'Utils/general';

export default {
  name: 'BadgeListItemBenchmarks',
  inject: {
    floatingVue: {
      from: '__floating-vue__popper',
      default: '',
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
      validator: (item) => isArray(item.value),
    },
    forceTooltips: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    shouldOpenBadges: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['remove', 'update:shown'],
  computed: {
    floatingVueState() {
      return this.floatingVue?.parentPopper ?? null;
    },
    valueLength() { return this.item.value?.length; },
    canShowBadge() { return isArray(this.item.value) && this.valueLength > 0; },
    badgeNewAndEmpty() {
      if (!this.floatingVueState?.isShown) return false;
      return isArray(this.item?.boxRowValue) && this.item.boxRowValue.length === 0;
    },
    tooManyValues() { return this.valueLength > 2 || false; },
    badgeValuesTooltip() {
      if (!this.tooManyValues && !this.forceTooltips && !this.item.icon && !this.item.isCardBenchmark) return '';
      let labels = this.item.value?.filter((item, idx) => idx < 10).join(', ');
      return `<strong>${this.item.isCardBenchmark ? 'Card ' : ''}Benchmark – ${this.item.label}</strong>: ${labels}${this.valueLength >= 10 ? '…' : ''}`;
    },
    badgeText() {
      if (this.badgeNewAndEmpty) {
        return this.item.icon
          ? this.$pgettext('Empty — Badgelist item', '<i class="zmdi %{icon}"></i> <strong>%{label}</strong> %{operand}', { icon: this.item.icon, label: this.item.label, operand: this.whiteList })
          : this.$pgettext('Empty — Badgelist item', '<strong>%{label}</strong> %{operand}', { label: this.item.label, operand: this.whiteList });
      }
      if (!this.canShowBadge) {
        return this.item.icon
          ? this.$pgettext('Empty — Badgelist item', '<i class="zmdi %{icon}"></i> <strong>%{label}</strong> Saknar värde', { label: this.item.label, icon: this.item.icon })
          : this.$pgettext('Empty — Badgelist item', '<strong>%{label}</strong> Saknar värde', { label: this.item.label });
      }
      if (this.item.label === 'Global') return this.$pgettext('Global — Badgelist item', '<i class="zmdi %{icon}"></i> <strong>%{label}</strong>', { label: this.item.label, icon: this.item.icon });
      if (this.item.slug === 'benchmark__general') return this.$pgettext('Global — Badgelist item', '<i class="zmdi %{icon}"></i> <strong>%{label}</strong>', { label: this.item.label, icon: this.item.icon });
      const len = this.valueLength;
      let translationSmall = this.$npgettext(
        'Badgelist item interpolation small',
        '<strong>%{label}</strong> %{is} <strong>%{val1}</strong>',
        '<strong>%{label}</strong> %{is} <strong>%{val1}</strong> %{operand} <strong>%{val2}</strong>',
        len,
      );
      let translationLarge = this.$pgettext(
        'Badgelist item interpolation large',
        '<strong>%{label}</strong> %{is} <strong>%{val1}</strong> %{operand} <strong>%{len} till</strong>',
      );

      if (this.item.icon) {
        translationSmall = this.$npgettext(
          'Badgelist item interpolation icon small',
          '<i class="zmdi %{icon}"></i> <strong>%{label}</strong> %{is} <strong>%{val1}</strong>',
          '<i class="zmdi %{icon}"></i> <strong>%{label}</strong> %{is} <strong>%{val1}</strong> %{operand} <strong>%{val2}</strong>',
          len,
        );
        translationLarge = this.$pgettext(
          'Badgelist item interpolation icon large',
          '<i class="zmdi %{icon}"></i> <strong>%{label}</strong> %{is} <strong>%{val1}</strong> %{operand} <strong>%{len} till</strong>',
        );
      }

      return this.$gettextInterpolate(
        this.tooManyValues ? translationLarge : translationSmall,
        {
          label: striphtml(this.item.label),
          icon: this.item.icon,
          is: this.whiteList,
          operand: this.item.operand || this.$pgettext('Badgelist item benchmark operand', 'eller'),
          val1: striphtml(this.item.value[0]) || '',
          val2: striphtml(this.item.value[1]) || '',
          len: len - 1,
        },
      );
    },
    whiteList() {
      return this.item.whitelist === false ? this.$pgettext('Badgelist item is not', 'är inte') : this.$pgettext('Badgelist item is', 'är');
    },
  },
  mounted() {
    if (this.shouldOpenBadges && this.item.slug !== 'default') {
      hideAllPoppers();
      this.$emit('update:shown', { item: this.item, shown: true });
    }
  },
};
</script>
