import { Timer } from 'Utils/debounce';

export default {
  state: {
    notifications: [],
  },
  mutations: {
    ADD_NOTIFICATION(state, message) {
      state.notifications.push(message);
    },
    REMOVE_NOTIFICATION(state, message) {
      const i = state.notifications.indexOf(message);
      if (i > -1) state.notifications.splice(i, 1);
    },
    CLEAR_NOTIFICATIONS(state) {
      state.notifications = [];
    },
    PAUSE_TIMER(state, message) {
      const i = state.notifications.indexOf(message);
      if (i > -1) state.notifications[i].timerPaused = true;
    },
    RESUME_TIMER(state, message) {
      const i = state.notifications.indexOf(message);
      if (i > -1) state.notifications[i].timerPaused = false;
    },
  },
  getters: {
    notifications(state) {
      return state.notifications;
    },
  },
  actions: {
    /**
     ** notify - Add BalooNotification to state and show it
     * @param {Object} BalooNotifications_interface
     * Ex.
     * {
     *   title: this.$gettext('Message title'),
     *   text: this.$gettext('Message text'),
     *   level: 'success', ('success' || 'info' || 'warn' || 'error' || null)
     *   type: 'pop', ('pop' || 'slow' || null)
     *   actions: [{
     *      label: this.$gettext('First action label'),
     *      value: (event) => { console.log('First action triggered', event) }
     *      removeAfter: false,
     *   }], (up to two action buttons can be used)
     *   scrub: true, (optional LR scrubbing)
     *   timer: a "Timer" wrapper for enabling .pause() and .resume() when using setTimeout
     *   timerPaused: a boolean that whispers if timer is paused or not
     * }
     */
    notify({ commit }, message) {
      return new Promise((success) => {
        message.id = Math.random().toString(10).substring(2);
        let duration = message.type === 'pop' ? 3000 : 5000;
        if (message.type === 'pop' || message.type === 'slow') {
          const timer = new Timer(() => {
            commit('REMOVE_NOTIFICATION', message);
          }, duration);
          message.timer = timer;
          message.timerPaused = false;
        }
        commit('ADD_NOTIFICATION', message);
        success(message);
      });
    },
    removeNotification({ commit }, message) {
      commit('REMOVE_NOTIFICATION', message);
    },
    clearNotifications({ commit }) {
      commit('CLEAR_NOTIFICATIONS');
    },
    pauseTimer({ commit }, message) {
      if (message.timer) {
        message.timer.pause();
        commit('PAUSE_TIMER', message);
      }
    },
    resumeTimer({ commit }, message) {
      if (message.timer) {
        message.timer.resume();
        commit('RESUME_TIMER', message);
      }
    },
  },
};
