import AutoBulkFetcher from 'Utils/autoBulkFetcher';
// import { getBaseUrl } from 'Utils/api';
// import { handleAuthError } from 'Utils/authHelpers';

let questionBf = new AutoBulkFetcher('/shinyform/api/questions/', 'id__in', 'results', 'id', true);

// ? Gets all questions no matter the customer or segment
// function all() {
//   return getBaseUrl('/shinyform/api/questions/')
//     .then((response) => response.body.results, handleAuthError); // TODO: handle paginated respone, use questionBf instead
// }

function get(question) {
  return questionBf.add(question).catch((error) => error);
}

export default {
  // all,
  get,
};
