import request from 'superagent';
import languageApi from 'API/userLanguage';
import { BASE_URL } from 'Utils/urls';
import { postUrl } from 'Utils/api';
import { setToken } from 'Utils/authHelpers';

const AUTH_TYPE = {
  OTP: 'otp',
  SSO: 'sso',
};

// Expected errors
const AUTH_ERROR = {
  NO_GDPR_FOR_SSO: 'NO_GDPR_FOR_SSO',
  INVALID_OTP: 'INVALID_CODE',
  NO_GDPR_FOR_OTP: 'NO_GDPR_FOR_OTP',
  // General error passed in redirect url from be, probably due to auth failure against SSO provider
  UNABLE_TO_LOGIN: 'UNABLE_TO_LOGIN',
};

/**  @typedef {{ auth_type: "otp", gdpr_accepted: boolean }} RequestOTPSuccess */
/**  @typedef {{ auth_type: "sso", redirect_url: string }} RequestSSOSuccess */

/**
 * @param {string} email User email address
 * @param {boolean=} _gdpr_accepted Acceptance of GDPR policy
 * @returns {Promise<RequestOTPSuccess|RequestSSOSuccess>}
 */
async function requestLogin(email, _gdpr_accepted) {
  try {
    const payload = { email };
    if (_gdpr_accepted) payload.gdpr_accepted = true;

    const { redirect_url, gdpr_accepted } = await postUrl('/login/', payload);

    if (redirect_url) {
      // Customer has SSO, pass redirect_url for auth against SSO provider
      return {
        auth_type: AUTH_TYPE.SSO,
        redirect_url,
      };
    }

    // Customer does not have SSO, OTP should have been sent
    return {
      auth_type: AUTH_TYPE.OTP,
      gdpr_accepted,
    };
  } catch (err) {
    const reason = err?.message || err?.body?.reason; // .message from ErrCodeError, .body.reason from BE
    if (reason) throw new Error(reason, { cause: err }); // expected error defined in AUTH_ERROR
    throw err;
  }
}

async function loginEmailOTP(email, code, gdpr_accepted = false) {
  try {
    const { body: { token, refresh_token } } = await request
      .post(`${BASE_URL}/login/`)
      .send({ email, code, gdpr_accepted });

    setToken(token, 'token', refresh_token);
    languageApi.getLang(true);
  } catch (err) {
    // use err.response here as were using superagent request
    const reason = err.response?.body?.reason;
    if (reason) throw new Error(reason, { cause: err }); // expected error defined in AUTH_ERROR
    throw err;
  }
}

export default {
  requestLogin,
  loginEmailOTP,
  AUTH_ERROR,
  AUTH_TYPE,
};
