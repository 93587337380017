<template>
  <header>
    <div class="flex-row justify-space-between gap-1 mb-2 mx-1">
      <div>
        <button
          class="btn btn-secondary"
          @click="store.dispatch('closePane')"
        >
          <i class="zmdi zmdi-arrow-left" />
          <span v-text="$pgettext('Button — BabushkaPaneDrilldown exit', 'Gå tillbaka')" />
        </button>
        <button
          v-if="store.getters.allOpenPanes && store.getters.allOpenPanes.length > 1"
          class="btn btn-secondary"
          @click="store.dispatch('closeAllPanes')"
        >
          <i class="zmdi zmdi-close" />
          <span v-text="$pgettext('Button — BabushkaPaneDrilldown exit', 'Lämna')" />
        </button>
      </div>
      <div v-if="boardId">
        <button
          class="btn btn-primary"
          @click="saveAsNewReport(drilldownBoardMetadata)"
        >
          <i class="zmdi zmdi-save" />
          <span v-text="$pgettext('Button — BabushkaPaneDrilldown save', 'Spara som ny rapport')" />
        </button>
      </div>
    </div>
    <div class="flex-row gap-2 mx-1">
      <div style="flex: 1;">
        <h3
          class="mb-0.5 h2"
          style="align-self: center;"
          v-text="$pgettext('Header — BabushkaPaneDrilldown drilldown', 'Djupdyker i:')"
        />
        <badge-list
          type="filter"
          class="tc-select-value"
          :badge-list="drilldownBadges(paneProps.idx, store.getters.allOpenPanes)"
        />
      </div>
    </div>
  </header>
  <div
    class="mt-2"
    style="height: 100%"
    :class="{ 'hide-all-scrollbars': savingDrilldownBoard }"
  >
    <slot :pane="paneProps.pane" />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import boardAPI from 'API/boards';
import dynamicImport from 'Utils/dynamicImport';
import BadgeList from 'Components/parts/badges/BadgeList';
import { store } from '@/store';
import gettext from '@/gettext';

const router = useRouter();

const { $gettext } = gettext;
const props = defineProps({
  isRemovingBoard: Boolean,
  drilldownBoardMetadata: Object,
  paneProps: Object,
  boardId: Number,
});

const emit = defineEmits(['loading']);

const savingDrilldownBoard = ref(false);

watch(() => savingDrilldownBoard.value, (value) => {
  emit('loading', value);
});

const drilldownBadges = (idx, allOpenPanes = []) => {
  const badges = allOpenPanes
    .filter((_, i) => i <= idx)
    .map((openPane) => openPane.drilldownData);
  return badges;
};

const addReport = async () => {
  const AddBoardDetails = dynamicImport(() => import(/* webpackChunkName: "AddBoardDetails", webpackPrefetch: true */ 'Components/parts/details/AddBoardDetails'));
  return new Promise((resolve, reject) => {
    try {
      store.dispatch('openModal', {
        name: 'AddBoardDetails',
        component: AddBoardDetails,
        position: 'center',
        dataObject: {
          props: {
            apiCall: false,
          },
        },
        callback: async (newDataObject) => {
          resolve(newDataObject);
        },
      });
    } catch (error) {
      reject(error);
    }
  });
};

const saveAsNewReport = async (drilldownBoardMetadata = {}) => {
  try {
    const { isPublic, label: boardName } = await addReport();
    savingDrilldownBoard.value = true;
    if (!boardName) {
      savingDrilldownBoard.value = false;
      return;
    }
    const clonedBoard = await boardAPI.clone({
      board: props.boardId,
      name: boardName || (store.getters.getBoardById(props.boardId).name + $gettext('kopia')),
      segment: store.getters.segmentId,
      is_public: isPublic,
      is_overview: false,
    });
    const updatedClonedBoard = await boardAPI.updateMetadata(clonedBoard.id, drilldownBoardMetadata);
    await store.dispatch('fetchAllBoardsBySegmentId', { segmentId: store.getters.segmentId, clearBoards: true });
    savingDrilldownBoard.value = false;
    router.push({ name: 'board', params: { boardId: updatedClonedBoard.id, segmentId: store.getters.segmentId } });
    store.dispatch('closeAllPanes');
  } catch (error) {
    savingDrilldownBoard.value = false;
    store.dispatch('notify', {
      type: 'pop',
      level: 'warning',
      text: $gettext('Kunde inte spara: %{msg}', { msg: error.message }),
    });
  }
};

</script>
