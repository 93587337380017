import { isError } from 'lodash-es';
import questionsAPI from 'API/questions';
import { flatten, groupby, unique } from 'Utils/array';
import { filterOutUnanswerableQuestion } from 'Utils/general';

function formatQuestions({ dispatch }, forms) {
  let stepForms = [];
  return new Promise((resolve) => {
    stepForms = forms;
    resolve(forms
      ? forms.map((form) => flatten(
        form.form_prototype.options.questions,
        (q) => q.questions,
        [],
        (f) => ({ ...f, level: form.level }),
      ))
      : []);
  })
    .then((questionListList) => [].concat(...questionListList))
    .then(unique('question'))
    .then((questions) => {
      let promises = [];
      questions.forEach((q) => {
        if (filterOutUnanswerableQuestion(q, q.level, stepForms)) {
          promises.push(dispatch('getQuestionById', q.question)
            .then((question) => {
              if (isError(question)) {
                return {
                  id: q.question,
                  level: q.level,
                  error: question,
                };
              }
              question.show = q.show;
              question.required = q.required;
              question.level = q.level;
              return question;
            }));
        }
      });
      return Promise.all(promises);
    });
}

export default {
  state: {
    questions: {},
  },
  getters: {
    // allQuestions: // ? Use the response from the action `getAllQuestions` instead
    fetchingAllQuestions: (state) => Promise.allSettled(Object.values(state.questions)),
  },
  mutations: {
    ADD_QUESTION(state, { question, promise }) {
      state.questions[question] = promise;
    },
    CLEAR_QUESTION_CACHE(state) {
      state.questions = {};
    },
  },
  actions: {
    getQuestionById({ commit, state }, questionId) {
      if (!questionId) return Promise.resolve();
      if (state.questions[questionId] && state.questions[questionId] instanceof Promise) {
        return state.questions[questionId];
      }
      const promise = questionsAPI.get(questionId);
      commit('ADD_QUESTION', { question: questionId, promise });
      return promise;
    },
    getQuestionsById({ dispatch }, questionIds) {
      let promises = [];
      questionIds.forEach((questionId) => {
        promises.push(dispatch('getQuestionById', questionId));
      });
      return Promise.all(promises);
    },
    getQuestionsByLevel({ dispatch, getters }, level) {
      if (level === undefined) return Promise.reject(new Error(`Cannot get questions by level ”${level}”`));

      return dispatch('fetchStepFormsBySegmentId', { segmentId: getters.segmentId })
        .then(groupby('level'))
        .then((formMap) => formMap.get(level))
        .then((forms) => formatQuestions({ dispatch }, forms))
        .catch((err) => Promise.reject(new Error(`Couldn’t get questions by level ”${level}”. ${err}`)));
    },
    getAllQuestions({ dispatch, getters }) {
      return dispatch('fetchStepFormsBySegmentId', { segmentId: getters.segmentId })
        .then((forms) => formatQuestions({ dispatch }, forms))
        .catch((err) => Promise.reject(new Error(`Couldn’t get all questions. ${err}`)));
    },
    clearQuestionCache({ commit }) {
      commit('CLEAR_QUESTION_CACHE');
    },
  },
};
