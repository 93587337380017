import { pickBy, identity } from 'lodash-es';
import {
  putUrl,
  postUrl,
  deleteUrl,
  getBaseUrl,
} from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

/**
 ** Interface for subscriptionTask-objects
 * send →
 * Ex:
 {
    params: {
      dateGte: ['2018-12-04'],
      dateLte: ['2019-12-04']
    },
    default_language: 'sv-se',
    to_emails: ['sven@trustcruit.com', 'gi@nni.no'],
    to_users: [328, 1],
    recurrence_rule: 'FREQ=MONTHLY;INTERVAL=3;BYDAY=MO,TU,WE,TH,FR;BYMONTHPOS=1', // monthly/quarterly/yearly as RRULE in RFC5545 standard
    next_datetime: '2020-03-01T09:00:00+02:00' || null, // null = today, UTC time
    board: 1970,
    card: null,
    name: 'Nej tack kommentarer',
  }
 *
 ** Interface for subscriptionTask-responses
 * receive <-
 * Ex:
  {
    id: 29,
    url: 'http://localhost:8000/divoc/api/subscriptions/29/',
    to_emails: ['sven@example.invalid'],
    to_users: [{
      id: 328,
      first_name: '',
      last_name: '',
      username: 'sven',
      email: 'sven@trustcruit.com',
      url: 'http://localhost:8000/feedback/accounts/api/user/328/',
    }],
    owner: {
      id: 1066, first_name: 'Janne', last_name: 'Parker', username: 'Sven', email: '', url: 'http://localhost:8000/feedback/accounts/api/user/1066/',
    },
    default_language: 'sv-se',
    next_datetime: '2020-03-01T09:00:00+02:00',
    recurrence_rule: 'FREQ=MONTHLY;INTERVAL=3;BYMONTH=1,4,7,10;BYMONTHPOS=1;BYDAY=MO,TU,WE,TH,FR',
    board: 479, // Will be complete board here later
    params: { dateLte: ['2020-03-31'], dateGte: ['2019-04-01'] },
    name: 'Subscription testdata',
    upcoming_dates: [
      '2020-07-01T12:00:00Z',
      '2020-10-01T12:00:00Z',
      '2021-01-01T12:00:00Z',
    ],
    },
 */

// ? You probably want to use the vuex (`store/modules/tasks.js`) instance instead of this API
const subscriptionsAPI = {
  create({
    recurrence_rule,
    next_datetime,
    params,
    default_language,
    default_question_language,
    to_emails,
    to_users,
    board,
    card,
    name,
    owner,
  }) {
    return postUrl('/divoc/api/subscriptions/', pickBy({
      recurrence_rule,
      next_datetime,
      params,
      default_language,
      default_question_language,
      to_emails,
      to_users,
      board,
      card,
      name,
      owner,
    }, identity));
  },

  /**
   ** subscriptionsAPI.list()
   * @param {Object} filter?, date / ordering / page_size / page
   * @param {Boolean} paginated?, return paginated response or not
   */
  list({ filter = null } = {}, paginated = true) {
    return getBaseUrl('/divoc/api/subscriptions/')
      .query({ ...filter })
      .then((response) => (
        paginated && response.body.next !== undefined ? response.body : response.body.results
      ), handleAuthError);
  },

  /**
   ** subscriptionsAPI.get()
   * @param {Number} id, The subscriptionId for what subscription to get
   */
  get(id) {
    return getBaseUrl(`/divoc/api/subscriptions/${id}/`)
      .then((response) => response.body, handleAuthError);
  },

  update(id, {
    recurrence_rule,
    next_datetime,
    params,
    default_language,
    default_question_language,
    to_emails,
    to_users,
    board,
    card,
    name,
    owner,
  }) {
    return putUrl(`/divoc/api/subscriptions/${id}/`, {
      recurrence_rule,
      next_datetime,
      params,
      default_language,
      default_question_language,
      to_emails,
      to_users,
      board,
      card,
      name,
      owner,
    });
  },

  /**
   ** subscriptionsAPI.remove()
   * @param {Number} id, The subscriptionId for what subscription to delete
   */
  remove(id) {
    return deleteUrl(`/divoc/api/subscriptions/${id}/`);
  },
};

export default subscriptionsAPI;
