import { pickBy } from 'lodash-es';
import { getBaseUrl, postUrl, deleteUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

export function list(segmentId) {
  return getBaseUrl('/feedback/accounts/api/segmentuserinvitation/')
    .query({ segment: segmentId })
    .then((response) => response.body.results, handleAuthError);
}

export function createUser({
  first_name, last_name = '', token, id, gdpr,
}) {
  const postObject = pickBy({
    first_name, last_name, token, gdpr_accepted: gdpr,
  }, (val) => val !== '');
  return postUrl(`/feedback/accounts/api/segmentuserinvitation/${id}/create_user/`, postObject);
}
export function create(segmentId, email, admin) {
  return postUrl(
    '/feedback/accounts/api/segmentuserinvitation/',
    { segment: segmentId, email, role: admin ? 'admin' : 'member' },
  );
}

export function remove(id) {
  return deleteUrl(`/feedback/accounts/api/segmentuserinvitation/${id}/`);
}

export function check(id, token) {
  return postUrl(`/feedback/accounts/api/segmentuserinvitation/${id}/check/`, { token });
}

export function accept(id, token) { // Search for `acceptFunction`
  return postUrl(`/feedback/accounts/api/segmentuserinvitation/${id}/accept/`, { token });
}

export default {
  list,
  createUser,
  create,
  remove,
  check,
  accept,
};
