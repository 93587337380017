import { isEqual } from 'lodash-es';
import cardAPI, { createCard } from 'API/cards';
import dynamicImport from 'Utils/dynamicImport';
import { striphtml, omit } from 'Utils/general';
import eventBus from 'Utils/eventBus';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext } = gettext;

/**
 ** getCardOrderAs
 * @param {string[]} cardOrders, array of lexicographical card.order
 * @param {object} [card=null], card class, if null, assume it as last cardOrder
 * @returns {IorderAsCard} orderAS
 */
export const getCardOrderAs = (cardOrders, card = null) => {
  const orderAs = { prevCardOrder: '', nextCardOrder: '' };
  if (card === null) { // ? create new card, add as last
    orderAs.prevCardOrder = cardOrders?.[cardOrders.length - 1] ?? '';
    return orderAs;
  }
  // TODO: add according to card.order
  return orderAs;
};
// export const sortAlphabetically = (items, sortByProp) => items.sort((a, b) => a[sortByProp].localeCompare(b[sortByProp]));
export const sortByOrder = (items) => items?.sort((a, b) => a?.order.localeCompare(b.order) ?? 0);

function clone(newCard, oldCard = {}) {
  const addCopyToName = newCard.metadata.name === oldCard?.metadata?.name;
  const name = newCard.metadata?.name || newCard?.getKeyMetricName() || '';
  if (newCard.id) {
    cardAPI.create(
      newCard.section,
      store.getters.getCardOrderAsInSection(newCard.section),
      { metadata: {
        ...newCard.metadata,
        ...(name && { name: `${striphtml(name)}${addCopyToName ? ` (${$gettext('kopia')})` : ''}` }),
      } },
    )
      .then((cardResponse) => {
        const card = createCard(cardResponse);
        store.dispatch('createCard', {
          card, // Must be a Card Class instance
          apiCall: false,
        });
        eventBus.$emit('add-card', card);
        store.dispatch('closeModal');
      }, (request) => {
        store.dispatch('notify', {
          type: 'pop',
          level: 'warning',
          text: $gettext('Kunde inte duplicera: %{msg}', { msg: request.response.body.detail }),
        });
        store.dispatch('closeModal');
      });
  }
}

function openReportModal({ card = {}, props = {}, on = {}, callback = null } = {}) {
  if (store.getters.showPaywalls) {
    const ReportPaywallDetails = dynamicImport(() => import(/* webpackChunkName: "ReportPaywallDetails", webpackPrefetch: true */ 'Components/parts/paywalls/ReportPaywallDetails'));
    store.dispatch('openModal', {
      name: 'ReportPaywallDetails',
      component: ReportPaywallDetails,
    });
  } else {
    const ReportDetails = dynamicImport(() => import(/* webpackChunkName: "ReportDetails", webpackPrefetch: true */ 'Components/parts/details/ReportDetails'));
    store.dispatch('openModal', {
      name: 'ReportDetails',
      component: ReportDetails,
      dataObject: {
        props: {
          ...(card?.id && { cardId: card.id }),
          ...(card?.metadata?.name && { label: card.metadata.name }),
          ...props,
        },
        ...on,
      },
      ...(callback && { callback }),
    });
  }
}

function equalMetadata(newVal, oldVal, unwatchTheseKeys = []) {
  const unwatchedKeys = ['name', 'description', 'colClass', 'order', ...unwatchTheseKeys];
  return isEqual(omit(newVal, unwatchedKeys), omit(oldVal, unwatchedKeys));
}

export {
  clone,
  openReportModal,
  equalMetadata,
};
