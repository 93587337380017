import ga from '@/plugins/googleAnalytics';
import { isNewerSPAVersionAvailable } from 'API/versioning';
import { ActivityBasedTimer } from 'Utils/debounce';
import { store } from '@/store';
import gettext from '@/gettext';

const { $pgettext } = gettext;

/* Check if a new version of the dashboard is available without hogging requests and then notify the user if true */
export default function versioning() {
  isNewerSPAVersionAvailable(true); // ? init
  const versioningTimer = ActivityBasedTimer();
  versioningTimer.setInterval({
    async callback() {
      const newVersionAvailable = await isNewerSPAVersionAvailable();
      const hasUpdateNotification = store.getters.notifications.find((msg) => msg.name === 'updateDashboard');

      if (!newVersionAvailable || hasUpdateNotification) return; // eslint-disable-line no-useless-return
      console.log('[TC] new dashboard version available:', newVersionAvailable); // eslint-disable-line no-console
      ga('send', {
        hitType: 'event',
        eventCategory: 'Dashboard',
        eventAction: 'update available',
        eventLabel: `currently on v${JSON.parse(unescape(process.env.VUE_APP_VERSION))})`,
      });

      store.dispatch('notify', {
        name: 'updateDashboard',
        level: 'success',
        text: $pgettext('Notification – New version available', 'Det finns en ny version av dashboarden, spara dina ändringar och uppdatera sidan för att få de senaste funktionerna'),
        actions: [{
          label: $pgettext('Notification – Action update', 'Uppdatera sidan'),
          value: (event) => window?.location.reload(),
        }],
      });
    },
    interval: 1000 * 60 * 10, // ? Normal interval is once every 10 minutes
    forcedInterval: 1000 * 60 * 60 * 24, // ? Forced interval is once per day
  });

  return versioningTimer;
}
