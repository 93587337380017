import { pickBy } from 'lodash-es';
import { getBaseUrl, postUrl, deleteUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';

export function list(customerId) {
  return getBaseUrl('/feedback/accounts/api/customeruserinvitation/')
    .query({ customer: customerId })
    .then((response) => response.body.results, handleAuthError);
}

export function createUser({
  first_name, last_name = '', token, id, gdpr,
}) {
  const postObject = pickBy({
    first_name, last_name, token, gdpr_accepted: gdpr,
  }, (val) => val !== '');
  return postUrl(`/feedback/accounts/api/customeruserinvitation/${id}/create_user/`, postObject);
}
export function create(customerId, email, admin) {
  return postUrl(
    '/feedback/accounts/api/customeruserinvitation/',
    { customer: customerId, email, role: admin ? 'admin' : 'member' },
  );
}

export function remove(id) {
  return deleteUrl(`/feedback/accounts/api/customeruserinvitation/${id}/`);
}

export function check(id, token) {
  return postUrl(`/feedback/accounts/api/customeruserinvitation/${id}/check/`, { token });
}

export function accept(id, token) { // Search for `acceptFunction`
  return postUrl(`/feedback/accounts/api/customeruserinvitation/${id}/accept/`, { token });
}

export default {
  list,
  createUser,
  create,
  remove,
  check,
  accept,
};
