<template>
  <segment-board
    v-if="segment?.overview_board_id"
    :board-id="segment.overview_board_id"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import SegmentBoard from 'Components/pages/SegmentBoard';

export default {
  name: 'SegmentOverview',
  components: {
    SegmentBoard,
  },
  computed: {
    ...mapGetters(['segment']),
  },
};
</script>
