// Yeah, we know
export default function konami() {
  const konamiCode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a', 'Enter'];
  let konamiCodePosition = 0;
  document.addEventListener('keyup', (e) => {
    const requiredKey = konamiCode[konamiCodePosition];
    if (e.key === requiredKey) {
      konamiCodePosition++;
      if (konamiCodePosition === konamiCode.length) {
        document.designMode = 'on';
        console.log('DESIGN MODE ACTIVATED, press "esc" to deactivate'); // eslint-disable-line no-console
        document.addEventListener('keyup', (evt) => {
          if (evt.key === 'Escape') {
            document.designMode = 'off';
            console.log('DESIGN MODE DEACTIVATED'); // eslint-disable-line no-console
          }
        });
        konamiCodePosition = 0;
      }
    } else {
      konamiCodePosition = 0;
    }
  });
}
