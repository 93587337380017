<template>
  <div
    class="container container-narrow"
    :class="{ 'paywall-underlay': showPaywalls }"
  >
    <invite-paywall v-if="showPaywalls" />
    <div class="hgroup">
      <h2>{{ $gettext('Bjud in användare') }}</h2>
      <h5 v-html="subTitleText" />
    </div>
    <invite-form
      type="customer"
      :disabled-form="showPaywalls"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dynamicImport from 'Utils/dynamicImport';
import InviteForm from 'Components/parts/user/UserInviteForm';

export default {
  components: {
    InviteForm,
    InvitePaywall: dynamicImport(() => import(/* webpackChunkName: "InvitePaywall", webpackPrefetch: true */'Components/parts/paywalls/InvitePaywall')),
  },
  computed: {
    ...mapGetters([
      'customerName',
      'showPaywalls',
    ]),
    subTitleText() {
      return this.$gettext('Användare på kontot kan se <em>alla</em> kontots segment');
    },
  },
};
</script>
