<template>
  <div class="follow-up-wrapper">
    <div class="page-content-title">
      <span class="h2">
        <i class="zmdi zmdi-comments" />
      </span>
      <span>
        <h2>Follow Up</h2>
        <p class="subtle-text my-0 mr-1">
          {{ $gettext('En lista över dina kandidater som vill kontaktas.') }}
          <router-link
            class="link-inverted nowrap"
            tabindex
            to="/user/settings/notifications"
          >
            <span> {{ $gettext('Ändra Follow Up-aviseringar') }}</span> <i class="zmdi zmdi-long-arrow-right" />
          </router-link>
        </p>
      </span>
    </div>
    <hr>
    <follow-up-filter />
    <template v-if="showActivatewall">
      <button
        class="btn btn-link activatewall-link"
        :class="{ clicked: clickedActivate }"
        @click="openIntercom"
      >
        {{ $gettext('Aktivera denna tjänst (ingår i Standard)') }} <i class="zmdi zmdi-long-arrow-right pl-1" />
      </button>
      <follow-up-table
        :demo-data="true"
        class="activatewall"
      />
      <p
        class="subtle-text small-text mx-2 mt-1"
      >
        {{ $gettext('Dessa kandidater är påhittade för att visa hur det kan se ut') }}
      </p>
    </template>
    <follow-up-table
      v-else
      ref="followUpTable"
      @send-page-amount="setAmountOfPages"
      @previous-index="prevIndex"
      @next-index="nextIndex"
      @reset-index="resetIndex"
    />
    <div
      class="tc-pagination my-2"
    >
      <template v-if="!pageAmount">
        <span class="page-counter valign-middle"><strong>1</strong>&nbsp;/&nbsp;1</span>
      </template>
      <template v-else>
        <button
          v-if="!previousButtonDisabled"
          class="btn-link btn valign-middle"
          data-testid="previousPageBtn"
          @click="prev"
        >
          <i
            class="zmdi zmdi-chevron-left"
          />
        </button>
        <span class="page-counter valign-middle">
          <strong>{{ currentIndex }}</strong>&nbsp;/&nbsp;{{ pageAmount || 1 }}
        </span>
        <button
          v-if="pageAmount !== currentIndex"
          class="btn-link btn valign-middle"
          data-testid="nextPageBtn"
          @click="next"
        >
          <i
            class="zmdi zmdi-chevron-right"
          />
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FollowUpFilter from 'Components/parts/follow-up/FollowUpFilter';
import FollowUpTable from 'Components/parts/follow-up/FollowUpTable';

export default {
  name: 'FollowUp',
  components: {
    FollowUpFilter,
    FollowUpTable,
  },
  data() {
    return {
      currentIndex: 1,
      pageAmount: null,
      loading: false,
      dateFilter: null,
      clickedActivate: false,
    };
  },
  computed: {
    ...mapGetters(['customerUsedFeatures']),
    showActivatewall() {
      return !this.customerUsedFeatures?.followup || false;
    },
    previousButtonDisabled() {
      return this.currentIndex === 1;
    },
  },
  methods: {
    setAmountOfPages(payload) {
      this.pageAmount = payload;
    },
    prevIndex() {
      if (this.currentIndex !== 1) this.currentIndex -= 1;
    },
    nextIndex() {
      this.currentIndex += 1;
    },
    resetIndex() {
      this.currentIndex = 1;
    },
    next() {
      this.$refs.followUpTable.next();
    },
    prev() {
      this.$refs.followUpTable.previous();
    },
    openIntercom() {
      this.clickedActivate = true;
      if (window?.Intercom) window.Intercom('showNewMessage', this.$pgettext('Intercom - activate chat', 'Hej! Jag skulle vilja börja använda Follow Up. '));
    },
  },
};
</script>
