<template>
  <div class="container container-narrow">
    <div class="hgroup">
      <h2>{{ titleText }}</h2>
    </div>
    <p translate-context="Description — Manage segments">
      {{ $pgettext(
        'Description — Manage segments',
        'Använd segment för att ge användare tillgång till viss del av kontots data.'
      ) }}
      <br>
      {{ $pgettext(
        'Description — Manage segments',
        'Använd rapporter för att skapa en uppsättning cards med utvalda data.'
      ) }}
    </p>
    <form @submit.prevent>
      <search-box
        v-model:term="searchTerm"
        :placeholder="$gettext('Sök på segment')"
      />
    </form>
    <segment-list-update :term="searchTerm" />
  </div>
</template>

<script>
import { unescape } from 'lodash-es';
import { mapGetters } from 'vuex';
import SegmentListUpdate from 'Components/parts/segment/SegmentListUpdate';
import SearchBox from 'Components/parts/SearchBox';

export default {
  components: {
    SegmentListUpdate,
    SearchBox,
  },
  data() {
    return {
      searchTerm: '',
    };
  },
  computed: {
    ...mapGetters([
      'customerName',
      'segment',
    ]),
    titleText() {
      if (this.customerName === 'Error') {
        return this.$gettext('Laddar in...');
      }
      return unescape(this.$pgettext(
        'Title - Segment manager',
        'Segment, Rapporter och Användare i %{customerName}',
        { customerName: this.customerName },
      ));
    },
  },
};
</script>
