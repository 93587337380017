// https://github.com/vuejs-tips/v-debounce
import debounce from 'Utils/debounce';

export default function directive(el, binding) {
  if (binding.value !== binding.oldValue) {
    el.oninput = directive.debounce((evt) => {
      let event;
      if (typeof Event === 'function') event = new Event('change');
      else {
        event = document.createEvent('Event');
        event.initEvent('change', true, true);
      }
      el.dispatchEvent(event);
    }, parseInt(binding.value, 10) || 500);
  }
}

directive.debounce = debounce;
