import request from 'superagent';
import { isEmpty } from 'lodash-es';
// import { getBaseUrl } from 'Utils/api';
import { defaultLanguage, convertToAvailableLanguage, availableQuestionLanguages } from 'Utils/languages';
import { isAuthenticated, handleAuthError, getToken, setToken } from 'Utils/authHelpers';
import { superagentSetDashboardOriginPage, superagentLanguage } from 'Utils/apiHelpers';
import { BASE_URL } from 'Utils/urls';
import { store } from '@/store';
import gettext from '@/gettext';

/**
 ** Type definitions
 *
 * @typedef {string} ILang - Is a string of 2-5 chars in mixed upper-lowercase that represents a language
 * @example ("sv-se"|"sv-FI"|"fr"|"en")
 *
 * @typedef {object} ILangSettings
 * @property {ILang} language - Indicates the user's dashboard app language
 * @property {ILang} browser_language - Indicates the first set browser language
 * @property {ILang} question_language - Indicates the user's question title language
 *  {
 *   "language": "",
 *   "browser_language": "en-us"
 *   "question_language": ""
 *  }
 * @example
 *  {
 *   "language": "sv-se",
 *   "browser_language": "en-us"
 *   "question_language": "fr-fr"
 *  }
 *
 * @typedef {Object} ILangSettingsAndDidSetLanguage
 * @property {ILangSettings} langSettings
 * @property {boolean} didSetLang - true if langSettings was manipulated by browser_language
 */

// ? [Circular dependency fixes]
export function _getBaseUrl(url) {
  const token = getToken().token;
  const r = request
    .get(`${BASE_URL}${url}`)
    .use(superagentLanguage)
    .use(superagentSetDashboardOriginPage)
    .accept('application/json');
  if (token) return r.set('Authorization', `JWT ${token}`);
  return r;
}
function _postBaseUrl(url, data = null) {
  const token = getToken().token;
  let r = request
    .post(`${BASE_URL}${url}`)
    .use(superagentSetDashboardOriginPage)
    .accept('application/json')
    .on('abort', () => Promise.resolve());
  if (token) r = r.set('Authorization', `JWT ${token}`);

  if (data !== null) return r.send(data);
  return r;
}
function _postUrl(url, data = null) {
  return _postBaseUrl(url, data)
    .then((response) => response.body, handleAuthError)
    .catch((error) => {
      if (error.status >= 500) {
        store.dispatch('notify', {
          text: `${error.status} (${error.message})`,
          level: 'error',
        });
      }
      throw error;
    });
}
// ? [/Circular dependency fixes]

function updateLanguage({ language = defaultLanguage, question_language = defaultLanguage } = {}) {
  if (window?.intercomSettings) {
    window.intercomSettings.language_override = language;
    window.Intercom('update', window.intercomSettings);
  }
  return _postUrl('/user_language/api/user_language/', {
    language,
    question_language,
  }, true);
}

/**
 * * convertToAvailableLangSettings - Checks if lanugage is missing and sets defaults for all keys
 * @param {ILangSettings} langSettings
 *
 * @returns {ILangSettingsAndDidSetLanguage}, but all empty langSettings-keys have values
 */
const convertToAvailableLangSettings = (langSettings) => {
  langSettings = {
    language: convertToAvailableLanguage(langSettings.language),
    browser_language: langSettings.browser_language,
    question_language: convertToAvailableLanguage(langSettings.question_language, availableQuestionLanguages),
  };
  return langSettings;
};

/**
 * * getLangDefaults - Checks if lanugage is missing and sets defaults for all keys
 * @param {ILangSettings} langSettings
 *
 * @returns {ILangSettingsAndDidSetLanguage}, but all empty langSettings-keys have values
 */
const getLangDefaults = (langSettings) => {
  const goingToSetLanguage = !langSettings.language;
  let newLangSettings = langSettings;

  if (goingToSetLanguage) {
    const browserLang = langSettings.browser_language ? langSettings.browser_language : defaultLanguage;
    const questionLang = langSettings.question_language ? langSettings.question_language : browserLang;

    newLangSettings = convertToAvailableLangSettings({
      language: browserLang,
      browser_language: browserLang,
      question_language: questionLang,
    });
  }

  return { langSettings: newLangSettings, didSetLanguage: goingToSetLanguage };
};

/**
 * * saveLanguage - Saves languages asynchronously if Authenticated
 * @param {ILangSettings} langSettings - ex. below
 *
 * @returns {ILangSettings}, but all empty langSettings-keys have values
 */
const saveLanguage = (langSettings) => {
  isAuthenticated().then(() => updateLanguage({ ...langSettings })).catch((err) => {});
  return langSettings;
};

/**
 * * setAppLangSettings
 * @param {ILangSettings} langSettings
 *
 * @returns {ILangSettings}
 */
const setAppLangSettings = (langSettings) => {
  if (langSettings.language && gettext) gettext.current = langSettings.language; // ? else defaultLanguage is used
  return langSettings;
};

/**
 * * setQuestionLangSettings
 * @param {ILangSettings} langSettings
 *
 * @returns {ILangSettings}
 */
const setQuestionLangSettings = (langSettings) => {
  const lS = {
    ...langSettings,
    question_language: langSettings.question_language ? langSettings.question_language : defaultLanguage,
  };

  if (gettext) {
    gettext.currentQuestionLanguage = lS.question_language;
  }
  return lS;
};

/**
 * * storeLangSettings - save the langSettings to store state
 * @param {ILangSettings} langSettings
 *
 * @returns {ILangSettings}
 */
const storeLangSettings = (langSettings) => {
  if (store) store.dispatch('setLanguageSettings', langSettings);
  return langSettings;
};

/**
 * * convertLangSettingsToLang
 * @param {ILangSettings} langSettings
 *
 * @returns {ILang} - ex. "sv-se"
 */
const convertLangSettingsToLang = (langSettings) => {
  const lang = langSettings.language === ''
    ? langSettings.browser_language
    : langSettings.language;
  return lang; // ? Do not .split('-')[0] here
};

/**
 * * setAppLang
 * @param {ILang} lang - ex. "sv-se"
 *
 * @returns {ILang}
 */
const setAppLang = (lang) => {
  // splitLang by '-' or '_'
  const splitLang = lang.split(/[-_]/);
  document.documentElement.lang = `${splitLang[0]}-${String(splitLang[1]).toUpperCase()}`; // ? Use this as currentLang outside of JS-world (Sejda)
  return lang;
};

function getLang(setupVueConfigLanguage = true) {
  // ? Sets token from '?token=eyJ...' or language from '?lang=sv-se'
  if (document?.URL) {
    const url = new URL(document.URL);
    const params = url.searchParams;
    if (params.has('token')) setToken(params.get('token'));
    if (params.has('lang') || params.has('q_lang')) {
      const langParam = params.get('lang') || defaultLanguage;
      const questionLangParam = params.get('q_lang') || langParam;
      return Promise.resolve({
        language: langParam,
        browser_language: langParam,
        question_language: questionLangParam,
      })
        .then((langSettings) => setAppLangSettings(langSettings))
        .then((langSettings) => setQuestionLangSettings(langSettings))
        .then((langSettings) => storeLangSettings(langSettings))
        .then(convertLangSettingsToLang)
        .then(setAppLang);
      // .then((lang) => {
      //   if (setupVueConfigLanguage) Vue.config.language = lang;
      //   return lang;
      // });
    }
  }

  return _getBaseUrl('/user_language/api/user_language/')
    .then((response) => {
      // get the user's language preferences. If nothing is set or the request fails, it defaults to the default language.
      if (response.status === 200 && !response.body) {
        return ({ language: defaultLanguage, browser_language: defaultLanguage, question_language: defaultLanguage });
      }
      const { language, browser_language, question_language } = response.body;
      return ({
        language: language || defaultLanguage,
        browser_language: browser_language || defaultLanguage,
        question_language: question_language || defaultLanguage,
      });
    })
    .catch(() => ({
      language: defaultLanguage,
      browser_language: defaultLanguage,
      question_language: defaultLanguage,
    }))
    .then((langSettings) => convertToAvailableLangSettings(langSettings))
    .then((langSettings) => getLangDefaults(langSettings))
    .then(({ langSettings, didSetLanguage }) => (didSetLanguage ? saveLanguage(langSettings) : langSettings))
    .then((langSettings) => setAppLangSettings(langSettings))
    .then((langSettings) => setQuestionLangSettings(langSettings))
    .then((langSettings) => storeLangSettings(langSettings))
    .then(convertLangSettingsToLang)
    .then(setAppLang);
  // .then((lang) => {
  //   // ? `Vue.config.language` sets what poeditor translation to use https://github.com/Polyconseil/vue-gettext#vueconfiglanguage
  //   if (setupVueConfigLanguage) Vue.config.language = lang; // ? Make sure this matches Poeditor needs two-letter lang string
  //   return lang;
  // });
}

function getLangIfNotInStore(setupVueConfigLanguage) {
  if (!store?.state.languageSettings || isEmpty(store?.state?.languageSettings)) {
    return getLang(setupVueConfigLanguage);
  }
  return Promise.resolve();
}

export default {
  getLang,
  getLangIfNotInStore,
  setAppLang,
  setAppLangSettings,
  setQuestionLangSettings,
  storeLangSettings,
  convertLangSettingsToLang,
  updateLanguage,
};
