import {
  forOwn, isEmpty, pick, mergeWith, isObject, isArray, omitBy, isBoolean,
} from 'lodash-es';
import { captureException } from '@/plugins/sentry';
import { store } from '@/store';

/*
function documentOffset(el) {
  let rect = el.getBoundingClientRect();
  let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    x: rect.left + scrollLeft,
    y: rect.top + scrollTop,
  };
}
*/

function striphtml(value) {
  let div = document.createElement('div');
  div.innerHTML = value;
  return div.textContent || div.innerText || '';
}

/**
 ** markHits — process string and <mark>hit</mark>
 * @param text    String   The string with potential hits
 * @param hits    Array    ex. [{hitIndex}, {hitIndex2}, ...]
 * @param hitLen  Number   Length of hit text
 * @returns {string}
 */
function markHits(text, hits, hitLen) {
  const template = '<mark></mark>';
  return hits.reduce((acc, hit, i) => {
    const from = hit + (i * template.length);
    const to = from + hitLen;
    let chunk = template.split('');
    let result = acc.split('');
    chunk.splice(6, 0, acc.substring(from, to));
    result.splice(from, hitLen, chunk.join(''));
    return result.join('');
  }, striphtml(text));
}

/**
 ** trimString — trim string and add `...` if string is over `length`
 * @param {string} string, string to trim.
 * @param {number} length, length to trim to. Weirdly only works correctly with even numbers.
 * @param {number} center, index of where to middle-out the trimming from.
 * @returns {string}
*/
function trimString(string, length = 50, center = 0) {
  if (center < 0) return string;
  if (string.length <= length) return string;

  let offset = Math.floor(length / 2);
  let indexes = [center - offset, center + offset];

  if (indexes[0] < 0) {
    let move = Math.abs(indexes[0]);
    indexes = indexes.map((index) => index + move);
  }

  if (indexes[1] > string.length) {
    let move = indexes[1] - string.length;
    indexes = indexes.map((index) => index - move);
  }

  const prefix = indexes[0] !== 0 ? '…' : '';
  const suffix = indexes[1] < string.length ? '…' : '';
  return `${prefix}${string.substring(indexes[0], indexes[1])}${suffix}`;
}

/**
 ** Vacuum — Returns object without falsy values (or custom validator)
 * @param {object} object, Object to be cleaned.
 * @param {function} validator, Optional function to use on every key-value pair.
 * @returns {object}
*/
function vacuum(object, validator = null) {
  let vacuumed = {};
  Object.keys(object).filter((key) => {
    if (validator && typeof validator === 'function') {
      return validator(object[key]);
    }
    return !!object[key];
  }).forEach((key) => {
    vacuumed[key] = object[key];
  });
  return vacuumed;
}

function createCacheKey(values) {
  return values.map(JSON.stringify).join('');
}

function processText(text, nameMap) {
  if (text === undefined) return text;
  return String(text).replace(/{{\s*(\w+)\s*}}/g, (all, key) => {
    const value = nameMap?.[key] || store.getters.textFilter[key];
    if (value === undefined) {
      console.error(`[TC] processText error: couldn't find key ${all} in "${text}"`);// eslint-disable-line no-console
      return all;
    }
    return value;
  });
}

// ? question.translation includes all possible answers across companies.
// ? question.options.base_values includes only possible answers for relevant company/stepform.
function translateBaseValue(baseValue, question) {
  if (question.question_type === 'rating' || question.question_type === 'text') return baseValue;
  if (question.question_type === 'yesno') return store.getters.customStringTranslations?.[baseValue] || baseValue; // TODO: If BE adds support for translated yesno options, add support here
  if (question.translation === undefined) return question;
  if (question.translation.options?.values?.[baseValue] === undefined) {
    if (
      typeof baseValue === 'number'
      || (typeof baseValue === 'string' && baseValue == Number(baseValue)) // eslint-disable-line eqeqeq
    ) return baseValue;
    const text = `Could not translate baseValue: "${baseValue}" — for question id: ${question.id}`;
    captureException(new Error(text), { question });
    return processText(baseValue);
  }
  return processText(question.translation.options.values[baseValue]);
}

function translateTerm(term, nameMap) {
  let map = nameMap || store.getters.customStringTranslations;
  if (map === undefined || map[term] === undefined) {
    return term;
  }
  return map[term] || `${map.other || ''} (<small>${term}</small>)`;
}

export const translateTermOption = (term) => ({
  label: translateTerm(term),
  value: term,
});

/* eslint-disable function-paren-newline */
export const translateCountryCode = (countryCode) => translateTerm(
  countryCode, store.getters.customStringTranslations?._countries || [],
);
/* eslint-enable function-paren-newline */

function nameCase(str) {
  const charIsALetter = (char) => char.length === 1
    && (char.toUpperCase() !== char.toLowerCase() || char.codePointAt(0) > 127);
  if (typeof str === 'string' && str.length > 1 && charIsALetter(str[0])) return str[0].toUpperCase() + str.substr(1);
  return str;
}

/**
 ** wrapValueLabel
 *  converts from value into object with `label` & `value` keys
 *
 *  @param {String, Number} value, value is set to `value`-prop.
 *  @param {String, Number} label, label is set to `label-prop.
 *  @example
 *  'governmental', 'Public sector'
 *
 *  @returns {Object} labelValueObject
 *  @example
 *  { value: 'governmental', label: 'Public sector' }
 */
export function wrapValueLabel(value, label = value.toString()) {
  if (!value) return value;
  if (value && value.label !== undefined) return value;
  return { value, label };
}

function translateBenchmark(dimension, values, wrap = false) { // Make sure you have run fetchAllBenchmark('industries') first
  return isArray(values) ? values.map((value) => {
    let returnValue;
    if (dimension === 'location') returnValue = translateCountryCode(value);
    else returnValue = nameCase(translateTerm(value));

    return wrap ? wrapValueLabel(value, returnValue) : returnValue;
  }) : values;
}

function toggleClass(element, classname) {
  if (element.classList.contains(classname)) {
    element.classList.remove(classname);
  } else {
    element.classList.add(classname);
  }
}

function questionInShow(questionId, show) {
  if (show.question === questionId) return true;
  if (typeof show === 'object') return questionInShow(questionId, show[Object.keys(show)[0]]);
  return false;
}

function hydrateMissingStatsKeys(statsObject) {
  // ? yesno: if one answer amounts to 100%, the other one needs to be hydrated in again.
  if (Object.keys(statsObject).length === 0) return { ja: null, nej: null };
  if (statsObject?.ja === 100) return { ...statsObject, nej: 0 };
  if (statsObject?.nej === 100) return { ja: 0, ...statsObject };
  // TODO: Add more support for list/listmany/rating questions?
  return statsObject;
}

function getNPSCatFromRating(value) {
  if (value == null || value === '') return null;
  const val = Number(value);
  if (val > 8 && val <= 10) return 1;
  if (val > 6 && val <= 8) return 0;
  if (val <= 6) return -1;
  return null;
}

function getNPSColorClass(value, lightBackground = false) {
  let val = Number(String(value).replace(/\+|±/, ''));
  if (val > 0) return 'tc-color-green';
  if (val === 0 && lightBackground) return 'tc-color-yellow-dark'; // tc-color-yellow-dark for light bg's
  if (val === 0 && !lightBackground) return 'tc-color-yellow'; // tc-color-dark for dark bg's
  return val < 0 ? 'tc-color-red' : '';
}

function prefixNPSValue(value) {
  if (value === '–') return value;// &ndash;
  if (value > 0) return `+${value}`;
  if (value === 0) return `±${value}`;
  return `${value < 0 ? value : ''}`;
}

/**
 ** getAttribute — wrapper for browser compability
 * from https://github.com/jrudenstam/helper-js/blob/master/helper.js
 * @param {HTMLElement} ele
 * @param {String} attribute
 * Source: http://stackoverflow.com/questions/3755227/cross-browser-javascript-getattribute-method
 */
// function getAttribute(ele, attr) {
//   let result = (ele.getAttribute && ele.getAttribute(attr)) || null;
//   if (!result) {
//     const length = ele.attributes && ele.attributes.length || 0;
//     for (let i = 0; i < length; i++) {
//       if (attr[i] !== undefined) {
//         if (attr[i].nodeName === attr) {
//           result = attr[i].nodeValue;
//         }
//       }
//     }
//   }
//   return result;
// }

const registerEventHandler = (() => {
  if (document.addEventListener) {
    return (node, type, callback) => {
      if (node) node.addEventListener(type, callback, false);
    };
  } if (document.attachEvent) {
    return (node, type, callback) => {
      if (node) {
        node.attachEvent(`on${type}`, (event) => {
          callback.apply(node, [event]);
        });
      }
    };
  }
  return false;
})();

const unregisterEventHandler = (() => {
  if (document.addEventListener) {
    return (node, type, callback) => {
      if (node) node.removeEventListener(type, callback, false);
    };
  } if (document.attachEvent) {
    return (node, type, callback) => {
      if (node) node.detachEvent(`on${type}`, callback);
    };
  }
  return false;
})();

function normaliseEvent(event) {
  if (!event.stopPropagation) {
    event.stopPropagation = () => { this.cancelBubble = true; };
    event.preventDefault = () => { this.returnValue = false; };
  }
  if (!event.stop) {
    event.stop = function stop() {
      this.stopPropagation();
      this.preventDefault();
    };
  }
  if (event.srcElement && !event.target) {
    event.target = event.srcElement;
  }
  return event;
}

/**
 * Event wrapper for browser compability
 * from https://github.com/jrudenstam/helper-js/blob/master/helper.js
 */
function addEvent(node, type, callback, sender) {
  function wrapCallback(event) {
    callback.apply(this, [normaliseEvent(event || window?.event || {}), sender]);
  }

  registerEventHandler(node, type, wrapCallback);

  // Return object to make event handler easy to detach
  return {
    node,
    type,
    callback: wrapCallback,
  };
}

function removeEvent({ node, type, callback }) {
  unregisterEventHandler(node, type, callback);
}

/**
 ** omit - Works pretty much the same as lodash omit but so much quicker
 * @param {Object} obj
 * @param {Array} props, list of stringed keys to remove from obj
 * @returns {Object} objWithoutListedProps
 */
export const omit = (obj, props) => {
  obj = { ...obj };
  props.forEach((prop) => delete obj[prop]);
  return obj;
};

/**
 ** uniqBy — Simplified implementation of https://youmightnotneed.com/lodash#unionBy.
 * This method is like _.uniq except that it accepts iteratee which is invoked for each element in array to generate the criterion by which uniqueness is computed
 * @param {Array} arr
 * @param {String|Function} iteratee String for iterating on props, Function for custom expression per look
 * @returns {Array} filtered on iteratee
 */
export const uniqBy = (arr, iteratee) => {
  if (typeof iteratee === 'string') {
    const prop = iteratee;
    iteratee = (item) => item[prop];
  }
  return arr.filter((x, i, self) => i === self.findIndex((y) => iteratee(x) === iteratee(y)));
};

export function equalLiteral(a = {}, b = {}) {
  return JSON.stringify(a) === JSON.stringify(b);
}

// Very basic implementation, will not work everywhere
function copyToClipboard(text = '') {
  return navigator?.clipboard?.writeText?.(text);
}

// ? Get the position of the caret inside input/textarea fields
function getCaretPosition(inputElem) {
  const canGet = inputElem.selectionStart || inputElem.selectionStart === '0';
  return {
    start: canGet ? inputElem.selectionStart : 0,
    end: canGet ? inputElem.selectionEnd : 0,
  };
}

// ? Set the position of the caret inside input/textarea fields
function setCaretPosition(inputElem, start, end) {
  if (inputElem.createTextRange) {
    const range = inputElem.createTextRange();
    range.collapse(true);
    range.moveEnd('character', end);
    range.moveStart('character', start);
    range.select();
  }
}

function globalTuplets(benchmark, reverseStats = null) {
  const score = typeof benchmark === 'number' ? benchmark : benchmark?.score;
  if (score && isBoolean(reverseStats)) {
    let ja = reverseStats ? 1 - score : score;
    ja *= 100;
    let nej = 100 - ja;
    return { ja, nej };
  }
  return {};
}

/**
 ** filterMerge - Filter shallow merge utility. Omits empty props!
 * Merges passed in objects in ascending order unless a different order is passed.
 * Empty properties overwrites former set properties. (shallow merge)
 * @param  {...object} filters filter objects e.g segmentFilter, contextFilter and cardFilter
 *
 * @returns {object} compiledFilter, A merged object wihout empty props
 */
function filterMerge(...filters) {
  const customizer = (objValue, srcValue, key) => {
    if (key === 'benchmark' && isObject(srcValue)) { // ? Perform a deeper clearing of empty values
      return omitBy({ ...objValue, ...srcValue }, isEmpty);
    }
    if (key === 'date' && isObject(srcValue) && srcValue.type) { // ? Perform better date.type merges, omitting unused values
      const pickThese = srcValue.type === 'absolute'
        ? ['allTime', 'dateGte', 'dateLte', 'quarter', 'type', 'unprocessedDate']
        : ['offset', 'span', 'type', 'unprocessedDate'];
      return pick({ ...objValue, ...srcValue }, pickThese);
    }
    if (typeof srcValue === 'string' || typeof srcValue === 'number') return srcValue;
    if (isArray(srcValue)) {
      if (isEmpty(srcValue)) return objValue;
      return srcValue;
    }
    if (isObject(srcValue) && isEmpty(srcValue)) {
      const newObj = { ...objValue };
      delete newObj[key];
      return newObj;
    }
    return { ...objValue, ...srcValue };
  };

  return filters.reduce((acc, filter) => omitBy(mergeWith(acc, filter, customizer), isEmpty), {});
}

/**
 ** maslovMerge - Filter merge utility.
 * Merges passed in objects in ascending order unless a different order is passed.
 * Empty properties does not overwrite former set properties. (deep merge)
 * @param {array} order Array of strings deciding what order the filters should be applied default is ['segment', 'board', 'card']
 * @param  {...object} filters filter objects e.g segmentFilter, contextFilter and cardFilter
 *
 * @returns {object} compiledFilter An object
 */
function maslovMerge(order = ['segment', 'board', 'card'], ...filters) {
  const [segment, board, card] = filters;
  const filterObject = { segment, board, card };
  filters = [filterObject[order[0]], filterObject[order[1]], filterObject[order[2]]];
  const compiledFilter = filters.reduce((acc, filter, i) => {
    let obj = {};
    let nonEmptyValues;
    forOwn(filter, (v, k) => {
      if (k === 'benchmark') { // ? Perform a deeper clearing of empty values
        nonEmptyValues = Object.keys(v).reduce((acc2, vKey) => (
          isEmpty(v[vKey]) ? acc2 : { ...acc2, [vKey]: v[vKey] }
        ), {});
      }
      obj = typeof v === 'object' && isEmpty(v) ? obj : { ...obj, [k]: nonEmptyValues ?? v };
    });
    return { ...acc, ...obj };
  }, {});
  return compiledFilter;
}

/**
 ** Custom serializing for URL get parameters
 * @param queryObject The object in which to transform into the query string. ex. { dateGte: "2018" }
 *
 * @returns {string} serialized query EXCLUDING QUESTION-MARK ex. "dateGte=2019-02-10&dateLte=2020-02-10"
 */
function serializeQuery(queryObject) {
  return Object.keys(queryObject).map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(queryObject[k])}`).join('&');
}

/**
 ** Returns false to those follow-up-questions that aren't answerable
 * @param {question} object
 * @param {level} string
 * @param {stepForms} array
 */
function filterOutUnanswerableQuestion(question, level, stepForms) {
  if (question?.show?.equals?.question !== undefined) {
    // ? If baseValue isn't found in original question, hide it
    const { questions } = stepForms.find((sf) => sf.level === level);
    const rootQuestion = questions.find((q) => q.id === question.show.equals.question);
    if (rootQuestion?.question_type === 'list' || rootQuestion?.question_type === 'listmany') {
      const baseValues = rootQuestion?.options?.base_values || [];
      const baseValueToCheck = question.show.equals.value;
      return baseValues.indexOf(baseValueToCheck) > -1; // ? True if found, False if NOT found
    }
    return true; // ? Show, because the rootQuestion is not list/listmany
  }
  return true; // ? Show, because there are no display conditions
}

/**
 ** roundNumber, Custom JS rounding
 * @param number The number to apply rounding to. Must be of type Number.
 * @param decimals Number of decimal points to keep. Must be in the range of 0 - 20.
 * @param useToFixed Should Number.toFixed(decimals) be used or not
 */
const roundNumber = (number, decimals = 0, useToFixed = false) => {
  const roundedNumber = Number((`${Math.round(`${number}e${decimals}`)}e-${decimals}`));
  if (useToFixed) return Number(roundedNumber.toFixed(decimals));
  return roundedNumber;
};

/**
 ** toTotallyFixed, could use this instead of String().padEnd()
 * @param number The number to apply rounding to.
 * @param decimals amount of 0's to pad with
 */
const toTotallyFixed = (number, decimals) => {
  // If the number is negative, remove the minus sign from the string and add it back to the string after it has been processed
  let minus = number < 0 ? '-' : '';
  number = Math.abs(number);
  let str = number.toString();
  // If the string does not contain a decimal point, add one
  let arr = (str + (str.indexOf('.') !== -1 ? '' : '.0')).split('.');
  // If the string is longer than the given number of digits, return the string truncated to the given number of digits
  if (arr[0].length > decimals) {
    return minus + str.slice(0, decimals);
  }
  // Otherwise, return the string with the given number of digits after the decimal place
  return minus + arr[0] + (+(`.${arr[1]}`)).toFixed(decimals - (arr[0].length - 1)).slice(1);
};

/**
 ** Custom lodash-based _.merge(), reactive deep merge of objects and arrays
 * based on https://github.com/vuejs/vue/issues/9853
 */
const reactiveMerge = (baseValue, value) => {
  if (Array.isArray(baseValue) && Array.isArray(value)) {
    // merge arrays
    value.forEach((v, i) => {
      let val = { ...baseValue[i], ...v };
      if (val === undefined) { return; }
      if (baseValue[i]) { baseValue[i] = val; } else { baseValue.push(val); }
    });
  } else if (
    value
    && baseValue
    && typeof value === 'object'
    && typeof object === 'object'
  ) {
    // merge objects
    Object.keys(value).forEach((key) => {
      let val = reactiveMerge(baseValue[key], value[key]);
      if (val === undefined) { return; }
      baseValue[key] = val;
    });
  } else if (value !== undefined) {
    return value;
  }
  return baseValue;
};

/**
 ** Custom Object.map() loop that works "the same way" as Array.map(), receives and returns object
 * based on https://stackoverflow.com/a/14810722
 */
const objectMap = (obj, fn) => Object.fromEntries(
  Object.entries(obj).map(
    ([k, v], i) => [k, fn(v, k, i)],
  ),
);

/**
 ** formatUserDisplayName - return whatever info is available
 * @param {Object} me, the user object, called 'me' in the store
 * @param {Boolean} includeLastName?, optional, choose to never include last name
 *
 * @returns {String} userDisplayName
 */
function formatUserDisplayName(user = {}, includeLastName = true) {
  if (includeLastName) {
    if (user.first_name && user.last_name) return `${user.first_name} ${user.last_name}`;
    if (user.first_name) return user.first_name;
    if (user.last_name) return user.last_name;
  }
  if (user.first_name) return user.first_name;
  if (user.username) return user.username;
  return user.email || '';
}

/**
 ** Formats and returns the display name in a nicer way. Recieves an array and returns an array
 */
function formatUserDisplayNames(members, includeLastName = true) {
  return members.map(({ user }) => formatUserDisplayName(user, includeLastName));
}

// function sameAsCardBenchmark(payload, cardBenchmark) {
//   const mapIfExist = (arr) => {
//     if (arr === undefined) return undefined;
//     return arr.map((obj) => obj.value);
//   };
//   if (cardBenchmark === undefined) return false;
//   /* eslint-disable max-len */
//   const sector = JSON.stringify(mapIfExist(payload.sector)) === JSON.stringify(cardBenchmark.sector);
//   const size = JSON.stringify(mapIfExist(payload.size)) === JSON.stringify(cardBenchmark.size);
//   const location = JSON.stringify(mapIfExist(payload.location)) === JSON.stringify(cardBenchmark.location);
//   const industry = JSON.stringify(mapIfExist(payload.industry)) === JSON.stringify(cardBenchmark.industry);
//   /* eslint-enable max-len */
//   if (size && location && industry && sector) return true;
//   return false;
// }

export {
  // documentOffset,
  striphtml,
  markHits,
  trimString,
  vacuum,
  createCacheKey,
  processText,
  translateBaseValue,
  translateTerm,
  // translateTermOption,
  // translateCountryCode,
  nameCase,
  translateBenchmark,
  copyToClipboard,
  getCaretPosition,
  setCaretPosition,
  serializeQuery,
  toggleClass,
  questionInShow,
  hydrateMissingStatsKeys,
  removeEvent,
  addEvent,
  getNPSCatFromRating,
  getNPSColorClass,
  prefixNPSValue,
  globalTuplets,
  filterMerge,
  maslovMerge,
  reactiveMerge,
  roundNumber,
  toTotallyFixed,
  objectMap,
  filterOutUnanswerableQuestion,
  formatUserDisplayName,
  formatUserDisplayNames,
  // sameAsCardBenchmark,
};
