import { nextTick } from 'vue';

function resizeTextarea(event) {
  event.target.style.height = 'auto';
  event.target.style.height = `${event.target.scrollHeight}px`;
}

// Use on textareas v-tc-autoresize or v-tc-autoresize="1337" to set your own max-height 1337 in this case. There's a fallback on 250px max-height.
const tcAutoresize = {
  beforeMount(el, binding) {
    nextTick(() => {
      el.setAttribute('style', `
        height: 'auto';
        overflow-y: hidden;
        min-height: 48px;
        max-height: ${binding?.value ?? 250}px;
      `);
    });
    el.addEventListener('input', resizeTextarea);
  },
  mounted(el) {
    setTimeout(() => {
      resizeTextarea({ target: el });
    }, 1);
  },
  beforeUnmount(el) {
    el.removeEventListener('input', resizeTextarea);
  },
};

export default tcAutoresize;
