<template>
  <div class="performance-score-table-wrapper">
    <ol
      v-if="items.length !== 0"
      class="tc-list-group no-border tc-bg-white pt-5"
    >
      <template
        v-for="(response, index) in items"
      >
        <template v-if="isPrintViewRoute">
          <transition
            :key="response.index"
            name="slide-down-fade"
          >
            <performance-score-table-row
              :comparable-option="comparableOption"
              :item="response"
              :placement="index + 1"
              :skeleton-loader="skeletonLoader"
              :style="`transition-delay: ${toggleMid(index, items.length) && showAll ? 20 * index : 0}ms;`"
              :usecase="usecase"
            />
          </transition>
        </template>
        <template v-else>
          <li
            v-if="showExpand && index === amountTop"
            :key="`expand-btn-${index}`"
            class="tc-performance-score-expander"
            @click="showAll = !showAll"
          >
            <button class="btn">
              {{ expandBtnText }}
            </button>
          </li>

          <transition
            :key="response.index"
            name="slide-down-fade"
            @enter="updateLayout"
          >
            <performance-score-table-row
              v-show="toggleMid(index, items.length)"
              :comparable-option="comparableOption"
              :item="response"
              :placement="index + 1"
              :loading="loading"
              :skeleton-loader="skeletonLoader"
              :style="`transition-delay: ${toggleMid(index, items.length) && showAll ? 20 * index : 0}ms;`"
              :usecase="usecase"
            />
          </transition>
        </template>
      </template>
    </ol>
    <ol
      v-else
      class="tc-list-group no-border tc-bg-white pt-4 pb-3 px-3"
    >
      <li>
        <div>
          <h3
            class="text-center mb-0 super-subtle-text"
            data-testid="emptyItems"
          >
            {{ noMoreItemsText }}
          </h3>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventBus from 'Utils/eventBus';
import PerformanceScoreTableRow from './PerformanceScoreTableRow';

export default {
  name: 'PerformanceScoreTable',
  components: {
    PerformanceScoreTableRow,
  },
  props: {
    card: Object,
    isPrintViewRoute: Boolean,
    items: Array,
    loading: {
      type: Boolean,
      default: false,
    },
    skeletonLoader: {
      type: Boolean,
      default: false,
    },
    amountTop: {
      type: Number,
      default: 3,
    },
    amountBottom: {
      type: Number,
      default: 3,
    },
    amountForExpandability: {
      type: Number,
      default: 10,
    },
    comparableOption: Array,
    usecase: {
      type: String,
      default: 'performance',
      validator: (val) => ['performance', 'swot'].includes(val),
    },
  },
  emits: ['update-drag-area'],
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    ...mapGetters(['segmentId']),
    showExpand() {
      return !this.showAll && this.items.length > this.amountForExpandability;
    },
    expandBtnText() {
      return this.$gettextInterpolate(
        this.$pgettext('Button — Expand', 'Visa %{rows} fler rader'),
        { rows: this.items.length - this.amountTop - this.amountBottom || 0 },
      );
    },
    noMoreItemsText() {
      return this.$pgettext('Placeholder — Performance, No more data', 'Det finns ingen data tillgängligt');
    },
  },
  watch: {
    segmentId(id) {
      this.showAll = false;
    },
  },
  mounted() {
    if (this.card?.id) {
      eventBus.$on(`expand-card-${this.card.id}`, () => {
        this.showAll = true;
        eventBus.$emit(`download-card-${this.card.id}`);
      });
    }
  },

  unmounted() {
    eventBus.$off(`expand-card-${this.card.id}`);
  },
  methods: {
    updateLayout() {
      // emits when transiton is done
      this.$emit('update-drag-area');
    },
    toggleMid(index, length) {
      if (this.showAll) return true;
      if (
        length > this.amountForExpandability
        && index <= (length - 1) - (this.amountBottom)
        && index >= this.amountTop
      ) return false;
      this.updateLayout();
      return true;
    },
  },
};
</script>
