<template>
  <div class="new-tag-filter">
    <transition name="fade-in">
      <div
        v-if="adding"
        class="form-group form-group-highlight tc-pane-bleed mb-0"
      >
        <label
          id="aria-label-tag-key"
          class="flex-apart"
        >
          <span>{{ $gettext('Tag') }}</span>
          <i
            class="zmdi zmdi-close subtle-text cursor-pointer mr-0.5"
            :title="$gettext('Stäng')"
            @click.prevent="adding = false"
          />
        </label>
        <select-filter-type-key
          ref="selectFilterTypeKey"
          v-model="tags"
          aria-labelledby="aria-label-tag-key"
          filter-type="tags"
          :filter-out-keys="filterOutKeys"
          :filter-out-segment-tags="filterOutSegmentTags"
          :customer="customer"
        />
        <select-filter-type-value
          v-if="hasTag"
          ref="selectFilterTypeValue"
          v-model="values"
          :label="$gettext('Är något av värdena')"
          class="mt-1"
          filter-type="tags"
          :filter-type-key="tags[0].value"
          :return-unwrapped-values="false"
          :customer="customer"
          @update:model-value="updateValues"
        />
        <button
          v-if="enableAddBtn"
          :disabled="!hasTag || values.length < 1"
          class="btn btn-link small-text ml-1 mr-2 mt-2"
          @click.prevent="addTag"
        >
          <i class="zmdi zmdi-plus mr-0.5" />
          <span>{{ $pgettext('Button — New tag filter add','Lägg till') }}</span>
        </button>
      </div>
    </transition>
    <button
      v-if="!adding"
      class="btn btn-link small-text mr-2"
      type="button"
      @click.prevent="adding = !adding"
    >
      <i class="zmdi zmdi-plus mr-0.5" />
      <span>{{ $pgettext('Button — NewTagFilter', 'Lägg till avgränsning på tag') }}</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty, isEqual } from 'lodash-es';
import SelectFilterTypeKey from 'Components/parts/filters/SelectFilterTypeKey';
import SelectFilterTypeValue from 'Components/parts/filters/SelectFilterTypeValue';

export default {
  components: {
    SelectFilterTypeKey,
    SelectFilterTypeValue,
  },
  props: {
    customer: {
      type: Boolean,
      default: false,
    },
    enableAddBtn: { // ? Listen on `@add-filter` for what was added
      type: Boolean,
      default: false,
    },
    filterOutKeys: {
      type: Array,
      default: () => [],
      validator(value) {
        return value.every((v) => typeof v === 'string');
      },
    },
    filterOutSegmentTags: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:filter', 'add-filter'],
  data() {
    return {
      tags: [],
      values: [],
      adding: false,
      filter: {},
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'customerId',
    ]),
    hasTag() {
      return this.tags[0] !== undefined && this.tags[0].value;
    },
  },
  watch: {
    tags: {
      deep: true,
      handler(tag, oldtag) {
        if (!isEmpty(this.filter) && oldtag?.[0]?.value && !isEqual(tag, oldtag)) delete this.filter[oldtag[0].value];
        this.changeTag();
        this.$nextTick(() => {
          if (this.$refs?.selectFilterTypeValue) this.$refs?.selectFilterTypeValue.clear();
        });
      },
    },

  },
  methods: {
    clear() {
      this.tags = [];
      this.values = [];
      this.filter = {};
      this.adding = false;
    },
    updateValues(values) {
      this.updateFilter(values);
    },
    updateFilter(values) {
      if (this.hasTag) {
        const filterKey = [this.tags[0]?.value] || {};
        const filterValues = values.map((value) => value.value) || [];
        this.filter[filterKey] = filterValues;
        this.$emit('update:filter', this.filter);
      }
    },
    addTag(evt) {
      this.updateFilter(this.values);
      this.$emit('add-filter', this.filter);
      this.clear();
    },
    changeTag(tag) {
      if (tag === this.tags[0]) return;
      this.values = [];
    },
  },
};
</script>
